import { BLANKET_COMPONENTS_NAMES } from '@che-ins-ui/blanket';
export const getBlanketTheme = (theme) => ({
    base: {
        [BLANKET_COMPONENTS_NAMES.overlay]: {
            transitionDuration: theme.transition.duration,
            zIndex: theme.zIndex.max,
            height: '100%',
            minHeight: '100%',
        },
    },
});
