import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { IconButton } from '@che-ins-ui/button';
import { ChevronUpIcon } from '@che-ins-ui/icons/ChevronUpIcon';
import { Accordion } from '@che-ins-ui/accordion';
import { BREAKPOINTS, useScreenSizes } from '@che-ins-ui/theme';
import { DESKTOP_SEARCH_FORM_BREAKPOINT } from '@/styles/themes/components';
import { useStyles } from '../styles';
import { AccordionTitleButton } from './AccordionTitleButton';
import { Countries } from './Countries';
import { CommonComponents } from './CommonComponents';
const MobileAccordionComponents = ({ setIsOpen }) => {
    const classes = useStyles();
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.fields, children: _jsx(CommonComponents, { setIsOpen: setIsOpen, mode: "calculation" }) }), _jsx(IconButton, { shouldFitContent: true, icon: _jsx(ChevronUpIcon, {}), appearance: "header-search-close", onClick: () => setIsOpen(false) })] }));
};
export const Search = memo(() => {
    const classes = useStyles();
    const { width } = useScreenSizes();
    const isDesktop = width > BREAKPOINTS[DESKTOP_SEARCH_FORM_BREAKPOINT];
    if (isDesktop) {
        return (_jsxs("div", { className: classes.fields, children: [_jsx(Countries, { mode: "calculation" }), _jsx(CommonComponents, { mode: "calculation" })] }));
    }
    return (_jsx(Accordion, { passSetIsOpenToChildren: true, passSetIsOpenToTitle: true, shouldFitContent: true, titleButtonProps: { component: AccordionTitleButton }, children: _jsx(MobileAccordionComponents, {}) }));
});
