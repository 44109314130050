import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toErrorWithMessage, transformToJson } from '@/libs/helpers';
import { getCalculationState } from '@/libs/helpers/getCalculationState';
import { transformCalculation } from '@/libs/transformers/transformerCalculation';
import { captureError } from '@/libs/helpers/utils';
import { touristsForApiSelector } from '../forms/selectors';
export const fetchCalculation = createAsyncThunk('api/fetchCalculation', async (_, thunkApi) => {
    try {
        const state = thunkApi.getState();
        const { request, api } = state;
        const tourists = touristsForApiSelector(state);
        const transformedRequest = transformCalculation(request, tourists);
        const { calculations } = await fetch(`${__VZR_API_URL__}calculate`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(transformedRequest),
        }).then(transformToJson);
        return getCalculationState(calculations, api.companies, request);
    }
    catch (error) {
        captureError(toErrorWithMessage(error), 'api/fetchCalcalation');
        console.error(error);
        throw new Error('api/fetchCalculation');
    }
});
export const calculationState = {
    matchedOffers: [],
    partiallyOffers: [],
    missingOffers: [],
    originalCalculations: [],
    isLoading: true,
    lastRequestId: null,
};
const calculationSlice = createSlice({
    name: 'calculation',
    initialState: calculationState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCalculation.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(fetchCalculation.fulfilled, (_state, action) => {
            return action.payload;
        });
    },
});
export const { reducer: calculationReducer, actions: calculationActions } = calculationSlice;
export { CALCULATION_STATE_NAMESPACE } from './consts';
