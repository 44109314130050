import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Skeleton } from '@che-ins-ui/skeleton';
import { useScreenSizes } from '@che-ins-ui/theme';
import { useStyles } from '../styles';
import { useStyles as useSkeletonStyles } from './styles';
export const HeaderSkeleton = () => {
    const { isMobile } = useScreenSizes();
    const classes = useStyles();
    const skeletonClasses = useSkeletonStyles();
    const iconSkeletonDimensions = isMobile
        ? {
            width: '18px',
            height: '18px',
            borderRadius: '3px',
        }
        : {
            width: '32px',
            height: '32px',
            borderRadius: '10px',
        };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: classes.root, children: [_jsx(Skeleton, { width: isMobile ? '20px' : '90px', height: '13px', margin: "4px 0px 0px 15px" }), _jsxs("div", { className: classes.company, children: [_jsx(Skeleton, { ...iconSkeletonDimensions, margin: "0px 10px 3px 0px" }), _jsx(Skeleton, { width: '100px', height: isMobile ? '13px' : '18px' })] }), _jsx(Skeleton, { width: isMobile ? '20px' : '90px', height: '13px', margin: "4px 15px 0px 0px" })] }), _jsxs("div", { className: skeletonClasses.tagsSkeletonWrapper, children: [_jsx(Skeleton, { width: '40px', height: '13px', margin: "10px 15px 0px 0px" }), _jsx(Skeleton, { width: '81px', height: '13px', margin: "10px 15px 0px 0px" }), _jsx(Skeleton, { width: '100px', height: '13px', margin: "10px 15px 0px 0px" }), _jsx(Skeleton, { width: '131px', height: '13px', margin: "10px 15px 0px 0px" }), _jsx(Skeleton, { width: '65px', height: '13px', margin: "10px 15px 0px 0px" }), _jsx(Skeleton, { width: '65px', height: '13px', margin: "10px 15px 0px 0px" })] })] }));
};
