import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSearchParams } from 'react-router-dom';
import { Tag } from '@che-ins-ui/tag';
import { CheckIcon } from '@che-ins-ui/icons/CheckIcon';
import { useCalculationFranhcise } from '@/libs/hooks';
import { useCheTheme } from '@/styles/themes';
import { MODAL_PRODUCT_ID_QUERY_PARAM } from '@/libs/constants';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { getEcommerceYMAction, } from '@/models/process/utils';
import { analytics } from '@/libs/analytics';
import { ButtonWrapperWithTooltip } from '@/components/common/ButtonWrapperWithTooltip/ButtonWrapperWithTooltip';
import { useStyles } from './styles';
export const Tags = ({ offer }) => {
    const theme = useCheTheme();
    const classes = useStyles();
    const { FranchiseIcon, value } = useCalculationFranhcise(offer.calculation);
    const [_, setSearchParams] = useSearchParams();
    const openedModalQueryString = useCalculationQueryString({
        addParams: {
            [MODAL_PRODUCT_ID_QUERY_PARAM]: offer.calculation.productId,
        },
    });
    const handleOpenModal = () => {
        setSearchParams(openedModalQueryString);
        analytics.sendEcommerceYM(getEcommerceYMAction(offer, 'detail'));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Tag, { appearance: "option", prefix: FranchiseIcon, children: ["\u0424\u0440\u0430\u043D\u0448\u0438\u0437\u0430: \u00A0", _jsx("b", { children: value })] }), _jsxs(Tag, { baseAppearance: "option", appearance: "card-assistance", prefix: _jsx(CheckIcon, { fill: theme.colors.success }), children: ["\u0410\u0441\u0441\u0438\u0441\u0442\u0430\u043D\u0441: \u00A0", _jsx("b", { className: classes.trimmableText, children: offer.calculation.assistances[0].name })] }), _jsx(ButtonWrapperWithTooltip, { tooltipLabel: "\u0411\u043E\u043B\u044C\u0448\u0435 \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u0438", onClick: handleOpenModal, position: "bottom", tooltipY: 5, additionalStyles: { marginLeft: -5 }, children: _jsx(Tag, { appearance: "more-options", children: "\u0435\u0449\u0451" }) })] }));
};
