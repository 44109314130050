import { createAsyncThunk } from '@reduxjs/toolkit';
import { modalActions } from '@che-ins-ui/modal';
import { getKeys } from '@/libs/helpers/utils';
import { EDIT_PERSON_MODAL_NAME } from '@/components/common/EditPersonModal/EditPersonModal';
import { NameField } from '../forms/interface';
import { setNotebookFormNameFields } from './setNotebookFormNameFields';
import { setNotebookFormBirthday } from './setNotebookFormBirthday';
import { setNotebookFormGender } from './setNotebookFormGender';
import { notebookActions } from './index';
export const openNotebookForm = createAsyncThunk('', async ({ personId, isFromInsurer }, thunkApi) => {
    const { notebook: { persons }, } = thunkApi.getState();
    const person = persons.find((person) => person.id === personId);
    if (!person) {
        return;
    }
    const { firstNameRu, lastNameRu, secondNameRu, emoji, birthday, gender } = person;
    getKeys(NameField).forEach((field) => {
        thunkApi.dispatch(setNotebookFormNameFields({
            field: field,
            value: person[field],
        }));
    });
    if (!secondNameRu && firstNameRu && lastNameRu) {
        thunkApi.dispatch(notebookActions.setHasSecondNameRu(false));
    }
    thunkApi.dispatch(setNotebookFormBirthday(birthday));
    if (gender) {
        thunkApi.dispatch(setNotebookFormGender(gender));
    }
    if (emoji) {
        thunkApi.dispatch(notebookActions.setEmoji(emoji));
    }
    thunkApi.dispatch(modalActions.open({
        name: EDIT_PERSON_MODAL_NAME,
        data: {
            personId,
            isFromInsurer,
        },
    }));
});
