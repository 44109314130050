import { createAsyncThunk } from '@reduxjs/toolkit';
import { COUNTRY_CODES } from '@che-ins-ui/phone-input';
import { DEFAULT_FIELD_ERROR } from '@/libs/validation/constants';
import { InsurerField } from './interface';
import { formsActions } from './index';
const field = InsurerField.phoneBody;
export const setPhone = createAsyncThunk('', async (value, thunkApi) => {
    const { form: { insurer: { phoneCountryCode }, }, } = thunkApi.getState();
    thunkApi.dispatch(formsActions._setInsurerField({ field, value }));
    const selectedCountryCode = COUNTRY_CODES.find((country) => country.countryCode === Number(phoneCountryCode));
    if (!selectedCountryCode) {
        return;
    }
    const requiredLength = selectedCountryCode.mask.replace(/\D/g, '').length;
    const validation = [
        {
            validator: (value) => Boolean(value?.trim()),
            message: DEFAULT_FIELD_ERROR,
        },
        {
            validator: (value) => value.length >= requiredLength,
            message: 'Введите номер телефона',
        },
    ];
    const error = validation.find(({ validator }) => !validator(value));
    thunkApi.dispatch(formsActions._setInsurerFieldError({
        field,
        error: error ? error.message : null,
    }));
});
