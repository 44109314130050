export const ACTIVE_SPORTS_CODES_FOR_UPSELL = [
    'aquapark',
    'amusement',
    'beachVolleyballFootballGandball',
    'bicycle',
    'boating',
    'bowling',
    'excursionRiding',
    'fitness',
    'jeeps',
    'kickScooter',
    'motorboat',
    'sledgeTube',
    'snorkeling',
    'waterActivities',
    'waterAttractions',
];
export const UPSELL_CHE_PRICE_TITLE = 'Бесценно';
export const UPSELL_CHECKED_PRICE_TITLE = 'Добавлено';
export const UPSELL_INCLUDE_PRICE_TITLE = 'Включено';
export const COUNTRY_CODES_FOR_MOTO_UPSELL = [
    'brunei',
    'cambodia',
    'india',
    'indonesia',
    'laos',
    'malaysia',
    'myanmar',
    'philippines',
    'singapore',
    'sri_lanka',
    'south-asia',
    'thailand',
    'vietnam',
];
export const COMPANY_CODES_FOR_MOTO_UPSELL = [
    'sovcombank',
    'ingos',
    'reso',
    'soglasie',
    'tinkoff',
];
