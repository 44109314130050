import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ConnectedTabs } from '@che-ins-ui/tabs';
import { Button } from '@che-ins-ui/button';
import { ChevronIcon } from '@che-ins-ui/icons/ChevronIcon';
import { useCheTheme } from '@/styles/themes';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { MODAL_PRODUCT_ID_QUERY_PARAM } from '@/libs/constants';
import { CompanyLogo } from '../CompanyLogo';
import { IconsBlock } from '../IconsBlock';
import { useStyles } from './styles';
import { TABS } from './tabs';
import { offerModalSelector } from './selector';
import { HeaderSkeleton } from './skeletons';
export const Header = () => {
    const theme = useCheTheme();
    const classes = useStyles();
    const { offer, state } = useSelector(offerModalSelector);
    const closedModalQueryString = useCalculationQueryString({
        removeParams: [MODAL_PRODUCT_ID_QUERY_PARAM],
    });
    const [_, setSearchParams] = useSearchParams();
    const handleModalClose = () => {
        setSearchParams(closedModalQueryString);
    };
    if (state === 'loading') {
        return _jsx(HeaderSkeleton, {});
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: classes.root, children: [_jsxs(Button, { baseAppearance: "text", appearance: "offer-modal-back", onClick: handleModalClose, children: [_jsx(ChevronIcon, {}), _jsx("span", { className: classes.backButtonText, children: "\u041D\u0430\u0437\u0430\u0434" })] }), _jsxs("div", { className: classes.company, children: [_jsx(CompanyLogo, { alt: offer.company.name, src: offer.company.linkToLogo, className: classes.companyLogo }), _jsx("span", { className: classes.companyName, children: offer.company.name })] }), _jsx(IconsBlock, { company: offer.company, productId: offer?.calculation?.productId })] }), _jsx(ConnectedTabs.TabsList, { delayBeforeCreatingCoordsMap: theme.transition.duration, appearance: "offer-modal-tabs", children: TABS.map(({ panelName, label }) => (_jsx(ConnectedTabs.Tab, { label: label, panelName: panelName }, panelName))) })] }));
};
