import { SKELETON_COMPONENTS_NAMES } from '@che-ins-ui/skeleton';
export const getSkeletonTheme = (theme) => ({
    base: {
        [SKELETON_COMPONENTS_NAMES.wrapper]: {
            '&[data-animated="true"]': {
                background: `linear-gradient(0.25turn, transparent, ${theme.colors.textBackground}, transparent), linear-gradient(rgba(113, 130, 153, 0.12), rgba(113, 130, 153, 0.12))`,
            },
            '&[data-animated="false"]': {
                background: theme.colors.textBackground,
            },
        },
    },
});
