import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { requestSelector } from '@/models/request/selectors';
import { MustReadNepal } from './MustReadNepal';
import { MustReadIsrael, MustReadLebanon } from './MustReadIsraelLebanon';
import { MustReadAbroad } from './MustReadAbroad';
const countryComponents = {
    nepal: MustReadNepal,
    israel: MustReadIsrael,
    lebanon: MustReadLebanon,
};
export const MustReadInfo = memo(() => {
    const { countries, dateStart } = useSelector(requestSelector);
    return (_jsxs(_Fragment, { children: [countries.map((country) => {
                const Component = countryComponents[country];
                return Component ? _jsx(Component, {}, country) : null;
            }), _jsx(MustReadAbroad, { withRussia: countries.includes('russia'), dateStart: dateStart })] }));
});
