import { createAsyncThunk } from '@reduxjs/toolkit';
import { capitalize } from '@/libs/helpers';
import { validateName } from '@/libs/validation/validateName';
import { formsActions } from './index';
export const setTouristName = createAsyncThunk('', async ({ _uuid, field, value }, thunkApi) => {
    const transformedValue = capitalize(value);
    const { error } = validateName(transformedValue);
    thunkApi.dispatch(formsActions._setTouristField({ _uuid, field, value: transformedValue }));
    thunkApi.dispatch(formsActions._setTouristFieldError({ _uuid, field, error }));
});
