import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '@che-ins-ui/input';
import { notebookFormDirtySelector, notebookFormErrorsSelector, notebookFormValuesSelector, } from '@/models/notebook/selectors';
import { setNotebookFormNameFields } from '@/models/notebook/setNotebookFormNameFields';
import { notebookActions } from '@/models/notebook';
import { formatNameOnBlur } from '@/models/forms/helpers';
import { InputError } from '../InputError';
import { notebookFormNameFieldsLabels, notebookFormNameFieldsNames, notebookFormNameFieldsPlaceholders, } from './consts';
import { useStyles } from './styles';
export const EditPersonFormNameField = memo(({ field, disabled }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const notebookFormValues = useSelector(notebookFormValuesSelector);
    const notebookFormErrors = useSelector(notebookFormErrorsSelector);
    const notebookFormDirty = useSelector(notebookFormDirtySelector);
    const value = disabled ? '' : notebookFormValues[field];
    const isFieldDirty = notebookFormDirty[field];
    const errorMessage = !disabled && isFieldDirty && notebookFormErrors[field];
    const setFieldValue = (value) => {
        dispatch(setNotebookFormNameFields({ field, value }));
    };
    return (_jsxs("div", { className: classes[field], children: [_jsx(Input, { label: notebookFormNameFieldsLabels[field], name: notebookFormNameFieldsNames[field], placeholder: notebookFormNameFieldsPlaceholders[field], onChange: setFieldValue, invalid: Boolean(errorMessage), value: value, disabled: disabled, onBlur: () => {
                    setFieldValue(formatNameOnBlur(value));
                    dispatch(notebookActions.setFormFieldIsDirty(field));
                }, shouldFitContent: true }), _jsx(InputError, { error: errorMessage })] }));
});
