import { createUseStyles } from 'react-jss';
export const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 15,
        paddingTop: 1,
        '& svg > path': {
            fill: theme.colors.brand,
        },
    },
    crossedPupil: {
        position: 'absolute',
        height: 5,
        width: 5,
        borderRadius: 100,
        backgroundColor: theme.colors.brand,
        transition: theme.transition.global,
        ['&[data-shown="false"]']: {
            width: 0,
            height: 0,
        },
    },
    crossWrapper: {
        transform: 'rotate(40deg)',
        padding: 1,
        backgroundColor: theme.colors.cloud,
        position: 'absolute',
        transition: theme.transition.global,
        ['&[data-shown="false"]']: {
            backgroundColor: 'transparent',
        },
    },
    cross: {
        height: 1,
        width: 22,
        borderRadius: 100,
        backgroundColor: theme.colors.brand,
        transition: theme.transition.global,
        ['&[data-shown="false"]']: {
            width: 0,
        },
    },
}), {
    name: 'commission-button-icon',
});
