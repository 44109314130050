import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@che-ins-ui/select';
import { requestSelector } from '@/models/request/selectors';
import { apiSelector } from '@/models/api/selectors';
import { requestActions } from '@/models/request';
import { travelFilter } from '@/libs/helpers';
import { upsellIsLoadingSelector } from '@/models/upsell/selectors';
import { useStyles } from '../styles';
export const Countries = ({ mode }) => {
    const { countries: apiCountries, countryGroups: apiCountryGroups } = useSelector(apiSelector);
    const { countries, countryGroups } = useSelector(requestSelector);
    const isLoadingUpsell = useSelector(upsellIsLoadingSelector);
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectValue = useMemo(() => {
        return [...countryGroups, ...countries];
    }, [countryGroups, countries]);
    const selectOptions = useMemo(() => {
        return [...apiCountryGroups, ...apiCountries].map(({ code, name }) => ({
            value: code,
            label: name,
        }));
    }, [apiCountries, apiCountryGroups]);
    const handleCountriesChange = useCallback((newCountries, params) => {
        const countries = [];
        const countryGroups = [];
        newCountries.forEach((code) => {
            const isGroup = apiCountryGroups.some((group) => group.code === code);
            if (isGroup) {
                countryGroups.push(code);
            }
            else {
                countries.push(code);
            }
        });
        dispatch(requestActions.setCountries({
            countries,
            countryGroups,
            apiCountries,
            apiCountryGroups,
            ...params,
        }));
    }, [apiCountries, apiCountryGroups]);
    const appearance = mode === 'calculation' ? 'header-filters' : 'white-label-filters';
    return (_jsx("div", { className: classes.countries, children: _jsx(Select, { appearance: appearance, inputProps: {
                baseAppearance: 'header-filters',
                appearance: 'header-countries',
            }, filterFunction: travelFilter, options: selectOptions, value: selectValue, onChange: handleCountriesChange, disabled: isLoadingUpsell, shouldFitContent: true, label: "\u041A\u0443\u0434\u0430", placeholder: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0438\u043B\u0438 \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u0442\u0440\u0430\u043D\u0443", selectedHeader: "\u0412\u042B\u0411\u0420\u0410\u041D\u041D\u042B\u0415", unselectedHeader: "\u0412\u0421\u0415 \u0421\u0422\u0420\u0410\u041D\u042B" }) }));
};
