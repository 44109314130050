import { DROPDOWN_COMPONENTS_NAMES } from '@che-ins-ui/dropdown';
import { DESKTOP_SIZES_BREAKPOINT, SEARCH_INPUT_HEIGHT } from './consts';
export const getDropdownTheme = (theme) => ({
    'white-label-filters': {
        [DROPDOWN_COMPONENTS_NAMES.wrapper]: {
            height: SEARCH_INPUT_HEIGHT.mobile,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: SEARCH_INPUT_HEIGHT.desktop,
            },
        },
        [DROPDOWN_COMPONENTS_NAMES.body]: {
            width: '216px',
            transform: 'translateY(2px)',
            backgroundColor: theme.colors.cloud,
        },
        [DROPDOWN_COMPONENTS_NAMES.childrenWrapper]: {
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            padding: '0px',
        },
    },
    'header-filters': {
        [DROPDOWN_COMPONENTS_NAMES.wrapper]: {
            height: SEARCH_INPUT_HEIGHT.mobile,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: SEARCH_INPUT_HEIGHT.desktop,
            },
        },
        [DROPDOWN_COMPONENTS_NAMES.body]: {
            width: '216px',
            backgroundColor: theme.colors.cloud,
        },
        [DROPDOWN_COMPONENTS_NAMES.childrenWrapper]: {
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            padding: '0px',
        },
    },
    'tourists-age': {
        [DROPDOWN_COMPONENTS_NAMES.body]: {
            overscrollBehaviorBlock: 'contain',
            backgroundColor: theme.colors.cloud,
            width: '88px',
            borderRadius: '12px',
            right: '0px',
            maxHeight: '200px',
            overflow: 'auto',
            '&[data-is-opened="true"]': {
                overflow: 'auto',
            },
        },
    },
    'new-tourist': {
        [DROPDOWN_COMPONENTS_NAMES.body]: {
            backgroundColor: theme.colors.cloud,
        },
        [DROPDOWN_COMPONENTS_NAMES.icon]: {
            display: 'inline-block',
            fill: theme.colors.white,
            '&[data-is-open="true"]': {
                transform: 'rotate(90deg)',
            },
        },
    },
});
