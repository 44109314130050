import { createAsyncThunk } from '@reduxjs/toolkit';
import { capitalize } from '@/libs/helpers';
import { validateName } from '@/libs/validation/validateName';
import { getKeys } from '@/libs/helpers/utils';
import { RuName } from '../forms/interface';
import { notebookActions } from './index';
export const setNotebookFormNameFields = createAsyncThunk('', async ({ field, value }, thunkApi) => {
    const transformedValue = capitalize(value);
    const isCyrillic = getKeys(RuName).includes(field);
    thunkApi.dispatch(notebookActions.setFormFieldValue({ field, value: transformedValue }));
    const { error } = validateName(transformedValue, { isCyrillic });
    thunkApi.dispatch(notebookActions.setFormFieldError({ field, value: error || null }));
});
