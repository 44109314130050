import { TECHNICAL_PAGE_COMPONENTS_NAMES } from '@che-ins-ui/technical-pages';
export const getTechnicalPagesTheme = (theme) => ({
    base: {
        [TECHNICAL_PAGE_COMPONENTS_NAMES.backButton]: {
            fontFamily: theme.font.primary,
            backgroundColor: theme.colors.brand,
        },
        [TECHNICAL_PAGE_COMPONENTS_NAMES.title]: {
            fontFamily: theme.font.secondary,
            color: theme.colors.sky,
        },
        [TECHNICAL_PAGE_COMPONENTS_NAMES.text]: {
            fontFamily: theme.font.primary,
        },
    },
});
