import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { captureError } from '@/libs/helpers/utils';
class ErrorBoundary extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }
    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            errorInfo: null,
            error,
        };
    }
    componentDidCatch(error, errorInfo) {
        console.error('Uncaught error:', error, errorInfo);
        captureError(error, 'componentDidCatch');
        return {
            hasError: true,
            errorInfo,
            error,
        };
    }
    render() {
        if (this.state.hasError) {
            return (_jsxs("div", { children: [_jsx("h2", { children: "Something went wrong." }), _jsxs("details", { style: { whiteSpace: 'pre-wrap' }, children: [this.state.error && this.state.error.toString(), _jsx("br", {}), this.state.errorInfo?.componentStack] })] }));
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
