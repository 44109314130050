import { PALETTE } from '../palette';
import { themeComponents } from './components';
import { AbstractTheme } from './AbstractTheme';
export const LIGHT_COLORS = {
    white: PALETTE.white,
    black: PALETTE.black,
    brand: PALETTE.brand,
    brandTertiary: PALETTE.brandTertiary,
    success: PALETTE.success,
    error: PALETTE.error,
    warning: PALETTE.warning,
    deletion: PALETTE.deletion,
    accent: PALETTE.accent,
    yellow: PALETTE.yellow,
    textPrimary: PALETTE.textPrimary,
    textSecondary: PALETTE.textSecondary,
    textTertiary: PALETTE.textTertiary,
    textBackground: PALETTE.textBackground,
    background: PALETTE.background,
    header: PALETTE.brand,
    field: PALETTE.white,
    sky: PALETTE.black,
    cloud: PALETTE.white,
    tertiary: PALETTE.brandTertiary,
};
export class LightColors extends AbstractTheme {
    constructor() {
        super(...arguments);
        this.colors = LIGHT_COLORS;
        this.mode = 'light';
    }
}
export class LightTheme extends LightColors {
    constructor() {
        super(...arguments);
        this.components = themeComponents.reduce((acc, { name, getter }) => {
            acc[name] = getter(this);
            return acc;
        }, {});
    }
}
