import { useTheme } from 'react-jss';
import { defaultThemeMode } from '@/models/theme';
import { isIframe } from '@/libs/helpers';
import { DarkTheme } from './DarkTheme';
import { LightTheme } from './LightTheme';
const lightTheme = new LightTheme();
const darkTheme = new DarkTheme();
const themes = {
    light: lightTheme,
    dark: darkTheme,
};
export const getTheme = (theme) => themes[isIframe() ? defaultThemeMode : theme] || lightTheme;
export const useCheTheme = () => useTheme();
