import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { memo } from 'react';
import { apiSelector } from '@/models/api/selectors';
import { searchResultsSelector } from '@/models/search/selectors';
import { additionalServicesCodes } from '@/libs/static';
import { FilterLayout } from './FilterLayout';
import { FiltersList } from './FiltersList';
import { SearchBlockWrapper } from './SearchElementWrapper';
export const AdditionalFilter = memo(() => {
    const { additionalServices } = useSelector(apiSelector);
    const searchResults = useSelector(searchResultsSelector);
    return (_jsx(SearchBlockWrapper, { searchResults: searchResults, codes: additionalServicesCodes, children: _jsx(FilterLayout, { heading: "\u0423\u043B\u0443\u0447\u0448\u0438\u0442\u044C \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043A\u0443", children: _jsx(FiltersList, { list: additionalServices, searchResults: searchResults, withServiceInfoImage: true }) }) }));
});
