import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classNames from 'classnames';
import { useCalculationFranhcise } from '@/libs/hooks';
import { useStyles } from '../styles';
const Franchise = ({ calculation, className }) => {
    const classes = useStyles();
    const { FranchiseIcon, value } = useCalculationFranhcise(calculation);
    return (_jsxs("div", { className: className, children: [_jsx("span", { className: classes.serviceIcon, children: FranchiseIcon }), _jsx("div", { className: classNames(classes.serviceName), children: "\u0424\u0440\u0430\u043D\u0448\u0438\u0437\u0430: " }), _jsx("span", { className: classes.dashedLine }), _jsx("span", { className: classes.serviceValue, children: `${value}` })] }));
};
export default memo(Franchise);
