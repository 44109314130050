import { FOOTER_COMPONENTS_NAMES } from '@che-ins-ui/footer';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, GLOBAL_LINE_HEIGHT, MOBILE_FONT_SIZES, } from './consts';
export const getFooterTheme = (theme) => ({
    base: {
        [FOOTER_COMPONENTS_NAMES.infoCheName]: {
            lineHeight: GLOBAL_LINE_HEIGHT,
            letterSpacing: GLOBAL_LETTER_SPACING,
            fontSize: MOBILE_FONT_SIZES.main,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
        [FOOTER_COMPONENTS_NAMES.infoCopyright]: {
            lineHeight: GLOBAL_LINE_HEIGHT,
            letterSpacing: GLOBAL_LETTER_SPACING,
            fontSize: MOBILE_FONT_SIZES.main,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
            '& a': {
                color: theme.colors.brand,
                '&:-webkit-any-link, &:visited': {
                    color: theme.colors.brand,
                },
            },
        },
        [FOOTER_COMPONENTS_NAMES.infoCompanyInfo]: {
            fontSize: MOBILE_FONT_SIZES.main,
            lineHeight: '19px',
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
        [FOOTER_COMPONENTS_NAMES.navigationListTitle]: {
            fontSize: MOBILE_FONT_SIZES.main,
            color: theme.colors.sky,
        },
        [FOOTER_COMPONENTS_NAMES.bottomBlock]: {
            margin: '15px 0px',
            [theme.breakpoints.up('md')]: {
                margin: '25px 0px',
            },
        },
        [FOOTER_COMPONENTS_NAMES.infoCheLogo]: {
            '& span': {
                color: theme.colors.brand,
            },
            '& svg': {
                fill: theme.colors.brand,
                '& path:first-of-type': {
                    fill: theme.colors.brand,
                },
            },
        },
        [FOOTER_COMPONENTS_NAMES.bottomWrapper]: {
            backgroundColor: theme.colors.background,
        },
        [FOOTER_COMPONENTS_NAMES.sloganIcon]: {
            '& path': {
                fill: theme.colors.brand,
            },
            '& path:nth-child(9)': {
                fill: theme.colors.error,
            },
            '& path:nth-child(10)': {
                fill: '#68DEFB',
            },
        },
        [FOOTER_COMPONENTS_NAMES.sloganText]: {
            color: theme.colors.brand,
        },
        [FOOTER_COMPONENTS_NAMES.skolkovoIcon]: {
            '& path:nth-child(n+3)': {
                fill: {
                    light: '#424B52',
                    dark: theme.colors.textPrimary,
                }[theme.mode],
            },
        },
    },
});
