import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useScreenSizes } from '@che-ins-ui/theme';
import { CURRENCIES_BADGES } from '@/libs/constants';
import { BREAKPOINTS } from '@/styles/breakpoints';
import { separatePriceBySpaces } from '@/libs/helpers/utils';
import { useStyles } from './styles';
export const OfferCommission = memo(({ index, isShown, commission }) => {
    const classes = useStyles();
    const { width } = useScreenSizes();
    const isMobile = width <= BREAKPOINTS['md'];
    const commissionVariants = {
        hidden: {
            opacity: 0,
            ...(isMobile && { transition: { duration: 0 } }),
        },
        visible: { opacity: 1 },
    };
    return (_jsx(AnimatePresence, { mode: "wait", children: isShown && (_jsx(motion.div, { variants: commissionVariants, initial: "hidden", animate: "visible", exit: "hidden", transition: {
                delay: isMobile ? 0 : index * 0.075,
                type: 'just',
            }, className: classes.commissionBlock, children: _jsxs("p", { className: classes.commission, children: ["\u041A\u043E\u043C\u0438\u0441\u0441\u0438\u044F:", ' ', _jsxs("b", { children: [separatePriceBySpaces(commission.toFixed(2)), ' ', CURRENCIES_BADGES.rub] })] }) })) }));
});
