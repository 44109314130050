import { createAsyncThunk } from '@reduxjs/toolkit';
import { stringify } from 'qs';
import { notify } from '@che-ins-ui/notification';
import { IS_DEVELOPMENT, ORDER_ID_QUERY_PARAM } from '@/libs/constants';
import { overlayLoaderModalOpen } from '@/components/common/OverlayLoaderModal';
import { shareLinkModalOpen } from '@/components/common/Hint/actions';
import { getErrorMessage, toErrorWithMessage } from '@/libs/helpers';
import { captureError } from '@/libs/helpers/utils';
import { overlayLoaderModalClose } from '@/components/common/OverlayLoaderModal/index';
import { basketProductSelector } from '../basket/selectors';
import { DEFAULT_PARTNER_ID } from '../user';
import { prepareStoreModelToServerCreateOrder } from './utils';
export const shareOrder = createAsyncThunk('process/shareOrder', async (_, store) => {
    try {
        store.dispatch(overlayLoaderModalOpen());
        const state = store.getState();
        const body = await prepareStoreModelToServerCreateOrder({ state });
        const partnerIdToShare = body?.partnerId;
        const isDefaultPartnerId = partnerIdToShare === DEFAULT_PARTNER_ID;
        if (isDefaultPartnerId) {
            delete body.partnerId;
        }
        const response = await fetch(`${__VZR_API_URL__}orderImage`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        const result = await response.json();
        if (response.status >= 400) {
            throw new Error(JSON.stringify(result));
        }
        const { uuid } = result;
        const { company: { name, linkToLogo }, } = basketProductSelector(state);
        const urlParams = {
            [ORDER_ID_QUERY_PARAM]: uuid,
            ...(!isDefaultPartnerId && { partnerId: partnerIdToShare }),
        };
        const shareUrl = __HOMEPAGE__ + '/widget?route=insurance&' + stringify(urlParams);
        const devShareUrl = `http://localhost:8097/insurance?orderId=${uuid}`;
        store.dispatch(shareLinkModalOpen({
            companyName: name,
            companyLogoUrl: linkToLogo,
            shareUrl: IS_DEVELOPMENT ? devShareUrl : shareUrl,
            additionalText: '',
        }));
    }
    catch (error) {
        captureError(toErrorWithMessage(error), 'process/shareOrder');
        notify.error({
            title: 'Validation Error',
            message: getErrorMessage(error),
            autoClose: 60 * 1000,
        });
        console.error(error);
        throw new Error('process/shareOrder');
    }
    finally {
        store.dispatch(overlayLoaderModalClose());
    }
});
