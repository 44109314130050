import { createUseStyles } from 'react-jss';
export const useStyles = createUseStyles((theme) => ({
    servicesBlock: {
        marginBottom: '40px',
        marginTop: '-10px',
        flexGrow: 1,
        [theme.breakpoints.up('md')]: {
            marginTop: '5px',
        },
    },
    serviceSkeletonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexGrow: 1,
    },
    servicesRowSkeleton: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '12px',
        gap: '12px',
    },
    servicesRowDesktopSkeleton: {
        flexDirection: 'row',
        gap: '41px',
    },
    tagsSkeletonWrapper: {
        marginLeft: '16px',
        marginBottom: '10px',
        display: 'flex',
        gap: '13px',
        '& span': {
            flexShrink: 0,
        },
    },
}));
