import { jsx as _jsx } from "react/jsx-runtime";
import { unstable_renderSubtreeIntoContainer } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { Button } from '@che-ins-ui/button';
import { requestIsServiceSelectedSelector } from '@/models/request/selectors';
import { requestActions } from '@/models/request';
unstable_renderSubtreeIntoContainer;
export const ToggleServiceButton = ({ serviceCode, value = 1, onModalClose, }) => {
    const dispatch = useDispatch();
    const isSelected = useSelector(requestIsServiceSelectedSelector(serviceCode));
    const handleAddService = () => {
        dispatch(requestActions.setServices({ [serviceCode]: value }));
        onModalClose();
    };
    const handleRemoveService = () => {
        dispatch(requestActions.setServices({ [serviceCode]: 0 }));
        onModalClose();
    };
    const button = useMemo(() => {
        if (isSelected) {
            return (_jsx(Button, { shouldFitContent: true, onClick: handleRemoveService, appearance: "warn", baseAppearance: "hint-modal-button", children: "\u0423\u0431\u0440\u0430\u0442\u044C \u043E\u043F\u0446\u0438\u044E" }));
        }
        else {
            return (_jsx(Button, { shouldFitContent: true, onClick: handleAddService, baseAppearance: "hint-modal-button", children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }));
        }
    }, []);
    return button;
};
