import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectedModal, modalActions, modalSelector } from '@che-ins-ui/modal';
import { WarnTriagleIcon } from '@che-ins-ui/icons/WarnTriagleIcon';
import { AgreeButton } from '@/components/common/Hint';
import { useStyles } from '@/components/common/Hint/styles';
import { CALCULATION_ERROR_HINT, OFFER_IS_MISSING_MODAL_NAME } from '../consts';
export const OfferIsMissingModal = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isOpen } = useSelector(modalSelector(OFFER_IS_MISSING_MODAL_NAME));
    const handleClose = useCallback(() => {
        dispatch(modalActions.close({ name: OFFER_IS_MISSING_MODAL_NAME }));
    }, []);
    useEffect(() => () => {
        isOpen && handleClose();
    }, []);
    return (_jsxs(ConnectedModal, { name: OFFER_IS_MISSING_MODAL_NAME, appearance: "hint", title: _jsxs(_Fragment, { children: [_jsx(WarnTriagleIcon, { className: classes.modalTitleIcon }), _jsx("span", { className: classes.modalTitle, children: CALCULATION_ERROR_HINT.title })] }), children: [CALCULATION_ERROR_HINT.message, _jsx(AgreeButton, { onClick: handleClose })] }));
});
