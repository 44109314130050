import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useScrollbarWidth } from 'react-use';
import { isSomeModalOpenSelector } from '@che-ins-ui/modal';
import { tabsActions } from '@che-ins-ui/tabs';
import { HeaderNavigation as CheHeaderNavigation } from '@che-ins-ui/header';
import { isFrameSelector, isIgnoreUpsellSelector, isVascoPartnerSelector, } from '@/models/request/selectors';
import { useActivePanelName } from '@/components/insurance/components/hooks';
import { INSURANCE_TABS_NAME, TABS_PANELS_NAMES, } from '@/components/insurance/consts';
import { TABS } from '@/components/insurance/components';
import { ROUTES } from '@/components/routes';
import { UPSELL_ENABLED, VASCO_HEADER_PROPS } from '@/libs/constants';
import { authStatusSelector } from '@/models/user/selectors';
import { useStyles } from '../Header/styles';
export const HeaderNavigation = memo(() => {
    const classes = useStyles();
    const authStatus = useSelector(authStatusSelector);
    const isSomeModalOpen = useSelector(isSomeModalOpenSelector);
    const isIgnoreUpsell = useSelector(isIgnoreUpsellSelector);
    const isVascoPartner = useSelector(isVascoPartnerSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const scrollbarWidth = useScrollbarWidth();
    const isFrame = useSelector(isFrameSelector);
    const paddingRight = isSomeModalOpen ? scrollbarWidth + 'px' : '0px';
    const activeTab = useActivePanelName() || 'TOURISTS';
    const tabIndex = Object.values(TABS_PANELS_NAMES).indexOf(activeTab);
    const onClick = () => {
        if (tabIndex === 0) {
            navigate(ROUTES.calculation);
            return;
        }
        dispatch(tabsActions.setActiveTab({
            tabsName: INSURANCE_TABS_NAME,
            activePanelName: TABS[tabIndex - 1].panelName,
        }));
    };
    const stepName = TABS[tabIndex].label;
    const stepNumber = tabIndex + 2;
    const stepsAmount = UPSELL_ENABLED && !isIgnoreUpsell ? 4 : 3;
    if (isFrame) {
        return null;
    }
    return (_jsx("div", { className: classes.root, style: { paddingRight }, "data-not-centered": true, children: _jsx(CheHeaderNavigation, { homepageUrl: __HOMEPAGE__, status: authStatus, withBackButton: true, onBackButtonClick: onClick, title: stepName, description: `Шаг ${stepNumber}/${stepsAmount}`, ...(isVascoPartner && { status: VASCO_HEADER_PROPS.status }) }) }));
});
