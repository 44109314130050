import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import Title from '@/components/common/Title';
import OfferCard from '../OfferCard';
import { useStyles } from './styles';
export { OffersSkeleton } from './OffersSkeleton';
const labels = {
    full: null,
    partial: 'Частично соответствует',
    none: 'Не соответствует',
};
const Offers = ({ offers, matchedType, handleAddToBasket, }) => {
    const classes = useStyles();
    const label = labels[matchedType];
    if (!offers.length) {
        return null;
    }
    return (_jsxs("div", { className: classNames(classes.root), children: [label && (_jsx(Title, { className: classes.label, tag: "h6", children: label })), _jsx("ul", { children: offers.map((offer, index) => (_jsx("li", { className: classes.item, children: matchedType === 'none' ? (_jsx(OfferCard, { index: index, offer: offer, matchedType: matchedType })) : (_jsx(OfferCard, { index: index, offer: offer, matchedType: matchedType, handleAddToBasket: handleAddToBasket })) }, offer.company.id))) })] }));
};
export default memo(Offers);
