import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_CODE } from '@/components/common/CodeInput';
import { DELAY_FROM_LAST_REQUEST, EmailConfirmationStatuses, emptyOrder, FORCED_CANCELLATION_TIME, } from './consts';
import { createOrder } from './createOrder';
const initialState = {
    isLoadingPayment: false,
    isOrderCreating: false,
    order: emptyOrder,
    emailConfirmationStatus: EmailConfirmationStatuses.none,
    code: DEFAULT_CODE,
};
const processSlice = createSlice({
    name: 'process',
    initialState,
    reducers: {
        setIsLoadingPayment: (state, { payload }) => {
            state.isLoadingPayment = payload;
        },
        setIsOrderCreating: (state, { payload }) => {
            state.isOrderCreating = payload;
        },
        setOrder: (state, { payload }) => {
            state.order = payload;
        },
        setEmailConfirmationStatus: (state, { payload }) => {
            state.emailConfirmationStatus = payload;
        },
        setCode: (state, { payload }) => {
            state.code = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createOrder.pending, (state) => {
            state.isLoadingPayment = true;
        });
        builder.addCase(createOrder.rejected, (state) => {
            state.isLoadingPayment = false;
        });
    },
});
export const { reducer: processReducer, actions: processActions } = processSlice;
export { PROCESS_STATE_NAMESPACE } from './consts';
export { DELAY_FROM_LAST_REQUEST, FORCED_CANCELLATION_TIME };
export * from './selectors';
