import Color from 'color';
const square = (size) => {
    const value = typeof size === 'number' ? `${size}px` : size;
    return {
        width: value,
        height: value,
    };
};
const overlay = () => ({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
});
const visuallyHidden = () => ({
    ...square(1),
    border: 0,
    overflow: 'hidden',
    margin: -1,
    padding: 0,
    clip: 'rect(0,0,0,0)',
    position: 'absolute',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
});
const centeredContent = () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
const withoutBorderBottomRadius = () => ({
    borderBottomLeftRadius: 'initial',
    borderBottomRightRadius: 'initial',
});
const rgba = (hexColor, opacity) => Color(hexColor).rgb().alpha(opacity).string();
export const inputPlaceholderColor = (color) => ({
    '&::placeholder': {
        color,
    },
    '&::-webkit-input-placeholder': {
        color,
    },
    '&::-moz-placeholder': {
        color,
    },
    '&:-ms-input-placeholder': {
        color,
    },
});
export const mixins = {
    square,
    overlay,
    centeredContent,
    visuallyHidden,
    rgba,
    withoutBorderBottomRadius,
    inputPlaceholderColor,
};
