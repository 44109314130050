import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { comparingProductsSelector } from '@/models/comparing/selectors';
import { matchedOffersSelector } from '@/models/calculation/selectors';
import { useComparingButtonStylesStyles } from './comparingButtonStyles';
import { getMatchingOffersLogos } from './helpers';
export const COMPARING_BUTTON_OFFER_LOGOS_ITEM_DELAY = 0.04;
export const ComparingButtonOffersLogos = memo(({ isVisible, maxItemsAmount }) => {
    const classes = useComparingButtonStylesStyles();
    const matchedOffers = useSelector(matchedOffersSelector);
    const comparing = useSelector(comparingProductsSelector);
    const comparingProductsLogos = useMemo(() => {
        return getMatchingOffersLogos(comparing, matchedOffers);
    }, [comparing, matchedOffers]);
    const itemsAmount = comparingProductsLogos.length;
    const [isInitial, setIsInitial] = useState(true);
    const [shouldShowItems, setShouldShowItems] = useState(false);
    useEffect(() => {
        if (isVisible) {
            setShouldShowItems(true);
            return;
        }
        const timer = setTimeout(() => {
            setShouldShowItems(false);
        }, 50);
        return () => clearTimeout(timer);
    }, [isVisible]);
    useEffect(() => {
        if (!isVisible) {
            setIsInitial(true);
            return;
        }
        const timer = setTimeout(() => {
            setIsInitial(false);
        }, itemsAmount * COMPARING_BUTTON_OFFER_LOGOS_ITEM_DELAY * 1000 +
            70);
        return () => clearTimeout(timer);
    }, [isVisible]);
    return (_jsx("div", { className: classes.logos, children: _jsx(AnimatePresence, { mode: "popLayout", children: shouldShowItems &&
                comparingProductsLogos.map((linkToLogo, index) => (_jsx(Offer, { index: index, linkToLogo: linkToLogo, isInStash: index >= maxItemsAmount && !isInitial, isInitial: isInitial, itemsAmount: itemsAmount, maxItemsAmount: maxItemsAmount }, linkToLogo))) }) }));
});
const MAX_Z_INDEX = 15;
const OFFER_INITIAL_OFFSET = 18;
export const getComparingButtonLogosItemOffset = (index) => index * OFFER_INITIAL_OFFSET - Math.pow(index, 2);
const Offer = memo(({ index, linkToLogo, isInStash, isInitial, itemsAmount, maxItemsAmount, }) => {
    const maxNormalOffset = getComparingButtonLogosItemOffset(maxItemsAmount);
    const classes = useComparingButtonStylesStyles();
    const transition = isInitial
        ? {
            delay: COMPARING_BUTTON_OFFER_LOGOS_ITEM_DELAY *
                (itemsAmount - index - 1),
        }
        : {};
    const variants = {
        hidden: {
            scale: 0,
            x: -35,
            y: 0,
        },
        visible: {
            scale: 1,
            x: isInitial
                ? Math.min(maxNormalOffset - 14, getComparingButtonLogosItemOffset(index))
                : getComparingButtonLogosItemOffset(index),
            zIndex: MAX_Z_INDEX - index,
        },
        inStash: {
            scale: 0.8,
            x: maxNormalOffset - 20,
            zIndex: MAX_Z_INDEX - index,
        },
        exit: {
            x: -35,
            ...(index === 0 && { zIndex: MAX_Z_INDEX + 1 }),
            scale: 0.5,
        },
    };
    return (_jsx(motion.div, { layout: "position", variants: variants, initial: "hidden", animate: isInStash ? 'inStash' : 'visible', exit: "exit", transition: transition, className: classes.logo, style: { zIndex: itemsAmount + 1 - index }, children: _jsx("img", { alt: "", src: linkToLogo, style: { height: 28, width: 28 } }) }));
});
