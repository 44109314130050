import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup } from '@che-ins-ui/button';
import { CURRENCIES_BADGES } from '@/libs/constants';
import { requestActions } from '@/models/request';
import { requestCurrencySelector, requestSelector, } from '@/models/request/selectors';
import { useStyles } from './styles';
import { formatMultiValueByCode } from './utils';
export const DefaultAccordionMultiValueChildren = ({ subDescription, code, serviceValues, }) => {
    const classes = useStyles();
    const { services } = useSelector(requestSelector);
    const dispatch = useDispatch();
    const currency = useSelector(requestCurrencySelector);
    const currencyBadge = CURRENCIES_BADGES[currency];
    const handleChangeMultiValue = (code) => (value) => {
        if (value) {
            dispatch(requestActions.setServices({ [code]: Number(value) }));
        }
    };
    return (_jsxs(_Fragment, { children: [subDescription && (_jsx("p", { className: classes.subDescription, dangerouslySetInnerHTML: {
                    __html: subDescription,
                } })), _jsx(ButtonGroup, { value: services[code], onChange: handleChangeMultiValue(code), appearance: "filter-multi-value", children: serviceValues.map((value) => {
                    return (_jsx(Button, { value: value, appearance: "filter-multi-value", children: formatMultiValueByCode(code, value, {
                            currencyBadge,
                        }) }, value));
                }) })] }));
};
