import { NOTIFICATION_COMPONENT_NAMES } from '@che-ins-ui/notification';
export const getNotificationTheme = (theme) => ({
    [NOTIFICATION_COMPONENT_NAMES.closeButton]: {},
    [NOTIFICATION_COMPONENT_NAMES.titleWrapper]: {
        fontSize: theme.rem(18),
    },
    [NOTIFICATION_COMPONENT_NAMES.message]: {
        fontSize: theme.rem(13),
    },
    info: {
        [NOTIFICATION_COMPONENT_NAMES.toast]: {
            background: theme.colors.brand,
        },
    },
    success: {
        [NOTIFICATION_COMPONENT_NAMES.toast]: {
            background: theme.colors.success,
        },
    },
    warning: {
        [NOTIFICATION_COMPONENT_NAMES.toast]: {
            background: theme.colors.warning,
        },
    },
    error: {
        [NOTIFICATION_COMPONENT_NAMES.toast]: {
            background: theme.colors.error,
        },
    },
    default: {
        [NOTIFICATION_COMPONENT_NAMES.toast]: {
            background: theme.colors.brand,
        },
    },
});
