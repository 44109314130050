import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useScrollbarWidth } from 'react-use';
import { isSomeModalOpenSelector } from '@che-ins-ui/modal';
import { FooterLite } from '@che-ins-ui/footer';
import { isFrameSelector } from '@/models/request/selectors';
import { info } from './config';
import { useStyles } from './styles';
export const Footer = () => {
    const classes = useStyles();
    const ref = useRef(null);
    const isSomeModalOpen = useSelector(isSomeModalOpenSelector);
    const scrollbarWidth = useScrollbarWidth();
    const isFrame = useSelector(isFrameSelector);
    useEffect(() => {
        const parentElement = ref.current?.parentElement;
        if (!parentElement)
            return;
        const observer = new ResizeObserver(([entry]) => {
            window.requestAnimationFrame(() => {
                if (!parentElement || !entry) {
                    return;
                }
                parentElement.style.paddingBottom = `${entry.contentRect.height}px`;
            });
        });
        observer.observe(ref.current);
        return () => {
            parentElement.style.paddingBottom = `0px`;
            ref.current && observer.unobserve(ref.current);
            observer.disconnect();
        };
    }, []);
    const paddingRight = isSomeModalOpen ? scrollbarWidth + 'px' : '0px';
    if (isFrame)
        return null;
    return (_jsx("div", { className: classes.wrapper, ref: ref, style: {
            paddingRight,
        }, children: _jsx(FooterLite, { info: info }) }));
};
