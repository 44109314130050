import { createUseStyles } from 'react-jss';
import { MOBILE_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, DEKSTOP_FONT_SIZES, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    root: {},
    label: {
        fontWeight: 400,
        color: theme.colors.textPrimary,
        marginBottom: 10,
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    item: {
        marginBottom: 8,
        '&:last-child': {
            marginBottom: 0,
        },
    },
}), { name: 'offers-list' });
