import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Dropdown } from '@che-ins-ui/dropdown';
import { Input } from '@che-ins-ui/input';
import { TriangleIcon } from '@che-ins-ui/icons/TriangleIcon';
import { touristsFormsSelector } from '@/models/forms/selectors';
import { getTouristsLabelValue } from '@/libs/helpers/utils';
import { apiIsApiDataLoadingSelector } from '@/models/api/selectors';
import { upsellIsLoadingSelector } from '@/models/upsell/selectors';
import { useStyles } from '../styles';
import { TouristsSelector } from './TouristsSelector';
const TouristsSelectorInput = ({ setIsOpen, isOpen, }) => {
    const tourists = useSelector(touristsFormsSelector);
    const isLoading = useSelector(apiIsApiDataLoadingSelector);
    const isLoadingUpsell = useSelector(upsellIsLoadingSelector);
    const classes = useStyles();
    return (_jsx(Input, { baseAppearance: "header-filters", appearance: "header-tourists", onChange: () => { }, isActive: !!isOpen, value: getTouristsLabelValue(tourists), type: "button", disabled: isLoading || isLoadingUpsell, onClick: () => setIsOpen(!isOpen), label: "\u0422\u0443\u0440\u0438\u0441\u0442\u044B", placeholder: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0442\u0443\u0440\u0438\u0441\u0442\u043E\u0432", shouldFitContent: true, postfix: _jsx(TriangleIcon, { "data-is-open": String(!!isOpen), className: classes.touristsDropdownIcon }) }));
};
export const TouristsDropdown = ({ mode }) => {
    const classes = useStyles();
    const appearance = mode === 'calculation' ? 'header-filters' : 'white-label-filters';
    return (_jsx("div", { className: classes.tourists, children: _jsx(Dropdown, { appearance: appearance, shouldFitContent: true, passSetIsOpenToTitle: true, passSetIsOpenToChildren: true, titleButtonProps: {
                component: TouristsSelectorInput,
            }, children: _jsx(TouristsSelector, {}) }) }));
};
