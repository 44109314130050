import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@che-ins-ui/button';
import { tabsActions } from '@che-ins-ui/tabs';
import { useLocation } from 'react-router-dom';
import { basketPriceSelector, basketProductIsMissingSelector, } from '@/models/basket/selectors';
import { CURRENCIES_BADGES } from '@/libs/constants';
import { formatPrice } from '@/libs/helpers/utils';
import { upsellActions } from '@/models/upsell';
import { EditPersonModal } from '@/components/common/EditPersonModal/EditPersonModal';
import { DeletePersonModal } from '@/components/common/EditPersonModal/DeletePersonModal';
import { AdminPartnerIdEditBlock } from '@/components/common/AdminPartnerIdEditBlock/AdminPartnerIdEditBlock';
import { roleSelector } from '@/models/user/selectors';
import { formsSelector, isFormValidatedSelector, } from '@/models/forms/selectors';
import { formsActions } from '@/models/forms';
import { validateForms } from '@/models/forms/validateForms';
import { FormsInfo } from '@/components/common/FormsInfo/FormsInfo';
import { INSURANCE_TABS_NAME } from '../consts';
import { Insurer, INSURER_CONTAINER_ID } from './Insurer';
import { useStyles } from './styles';
import { TABS } from './tabs';
import { GoToCalculationsBtn } from './GoToCalculationsBtn';
import { UserAgreementBlock } from './UserAgreementBlock';
import { Tourists } from './Tourists';
import { TOURISTS_CONTAINER_ID } from './Tourists';
export const TouristsTab = () => {
    const classes = useStyles();
    const role = useSelector(roleSelector);
    const isFormValidated = useSelector(isFormValidatedSelector);
    const isFormDirty = useSelector(isFormValidatedSelector);
    const forms = useSelector(formsSelector);
    const dispatch = useDispatch();
    const priceInRub = useSelector(basketPriceSelector);
    const productIsMissing = useSelector(basketProductIsMissingSelector);
    useEffect(() => {
        dispatch(upsellActions.reset());
    }, []);
    useEffect(() => {
        dispatch(validateForms());
    }, [forms]);
    const routerLocation = useLocation();
    const locationState = routerLocation.state;
    useEffect(() => {
        if ([TOURISTS_CONTAINER_ID, INSURER_CONTAINER_ID].includes(locationState)) {
            const containerRef = document.getElementById(locationState);
            containerRef?.scrollIntoView({ behavior: 'smooth' });
            routerLocation.state = null;
        }
    }, [locationState]);
    const handleChangeTab = (e) => {
        e.preventDefault();
        dispatch(formsActions.setFormDirty());
        if (!isFormValidated) {
            return;
        }
        dispatch(tabsActions.setActiveTab({
            tabsName: INSURANCE_TABS_NAME,
            activePanelName: TABS[1].panelName,
        }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormsInfo, {}), _jsx(Tourists, {}), _jsx(Insurer, {}), _jsx("div", { className: classes.buttons, children: productIsMissing ? (_jsx(GoToCalculationsBtn, {})) : (_jsxs(_Fragment, { children: [role === 'admin' && (_jsx(AdminPartnerIdEditBlock, { isFormStep: true })), _jsxs(Button, { disabled: isFormDirty && !isFormValidated, onClick: handleChangeTab, shouldFitContent: true, type: "submit", appearance: "continue", children: ["\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C\u00A0", _jsxs("b", { children: [formatPrice(priceInRub), " \u00A0", CURRENCIES_BADGES['rub']] })] })] })) }), _jsx(UserAgreementBlock, {}), _jsx(EditPersonModal, {}), _jsx(DeletePersonModal, {})] }));
};
