import { getCheCustomPalette, mergeCustomPalette } from './paletteUtils';
export const CUSTOM_PALETTE_COLORS = [
    'primary',
    'light',
    'background',
    'success',
    'warning',
    'error',
    'deletion',
    'search',
];
export const BASE_PALETTE = {
    black: '#101820',
    white: '#FFFFFF',
    brand: '#636AFF',
    brandTertiary: '#EDF4FE',
    darkBrand: '#6F75F5',
    darkBrandTertiary: '#303150',
    success: '#41CC78',
    error: '#FF6666',
    warning: '#FB751C',
    deletion: '#FFECEC',
    accent: '#F54B92',
    yellow: '#FFF400',
    darkSuccess: '#30A45E',
    darkDeletion: '#493336',
    textPrimary: '#718299',
    textSecondary: '#B9C1CC',
    textTertiary: '#D8DFE8',
    textBackground: '#F4F5F7',
    darkTextSecondary: '#60636D',
    darkTextTertiary: '#3A3D51',
    darkTextBackground: '#272B3D',
    background: '#F5F7FB',
    darkHeader: '#202330',
    darkField: '#2C3040',
    darkCloud: '#202330',
    darkBackground: '#15141F',
};
export const PALETTE = mergeCustomPalette(getCheCustomPalette(window.name));
