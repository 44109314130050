import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { motion } from 'framer-motion';
import { Button } from '@che-ins-ui/button';
import { TouristBirthdayInput } from './TouristBirthdayInput';
export const TouristBirthdayMobile = memo(({ _uuid, withButton, onDelete, appearance, birthday, previewedBirthday, errorMessage, }) => {
    const animation = {
        scale: withButton ? 1 : 0,
        opacity: withButton ? 1 : 0,
        height: withButton ? 'auto' : 0,
    };
    return (_jsxs(_Fragment, { children: [_jsx(TouristBirthdayInput, { _uuid: _uuid, appearance: appearance, birthday: birthday, previewedBirthday: previewedBirthday, errorMessage: errorMessage }), _jsx(motion.div, { initial: animation, animate: animation, transition: { type: 'just' }, children: _jsx(Button, { onClick: onDelete, appearance: "warn", baseAppearance: "toggle-item-forms-page", shouldFitContent: true, tabIndex: withButton ? 0 : -1, children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" }) })] }));
});
