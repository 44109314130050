import { createUseStyles } from 'react-jss';
import { DEKSTOP_FONT_SIZES, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    image: {
        marginTop: 7,
        marginBottom: 30,
        width: 270,
        height: 145,
    },
    title: {
        fontWeight: 700,
        fontFamily: theme.font.secondary,
        letterSpacing: '0.45px',
        marginBottom: '6px',
        color: theme.colors.sky,
        fontSize: MOBILE_FONT_SIZES.hintModalTitle,
        [theme.breakpoints.up('xl')]: {
            fontSize: DEKSTOP_FONT_SIZES.hintModalTitle,
        },
    },
    description: {
        lineHeight: '19px',
        [theme.breakpoints.up('md')]: {
            lineHeight: '18px',
            maxWidth: 340,
        },
    },
}), { name: 'hint-more-offers-tomorrow' });
