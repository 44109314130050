import { NameField, BaseNonNameTouristField, } from './../forms/interface';
var FormField;
(function (FormField) {
    FormField["emoji"] = "emoji";
    FormField["gender"] = "gender";
})(FormField || (FormField = {}));
export const NotebookFormFields = {
    ...FormField,
    ...BaseNonNameTouristField,
    ...NameField,
};
