import { createAsyncThunk } from '@reduxjs/toolkit';
import { getKeys } from '@/libs/helpers/utils';
import { isInsurerInRussianSelector } from '../basket/selectors';
import { InsurerField, TouristField } from './interface';
import { formsActions } from './index';
const touristFields = getKeys(TouristField);
export const validateForms = createAsyncThunk('', async (_, thunkApi) => {
    const { form: { tourists, insurer, emails }, } = thunkApi.getState();
    const isInsurerInRussian = isInsurerInRussianSelector(thunkApi.getState());
    const checkAreTouristsValid = () => tourists.every((tourist) => touristFields.every((field) => !tourist.fieldsErrors[field]));
    const insurerFields = [
        InsurerField.birthday,
        InsurerField.phoneBody,
        ...(isInsurerInRussian
            ? [
                InsurerField.firstNameRu,
                InsurerField.lastNameRu,
                ...(insurer.hasSecondNameRu
                    ? [InsurerField.secondNameRu]
                    : []),
            ]
            : [InsurerField.firstName, InsurerField.lastName]),
    ];
    const checkIsInsurerValid = () => insurerFields.every((field) => !insurer.fieldsErrors[field]);
    const checkIsEmailsValid = () => emails.every((email) => !email.errorMessage);
    const isFormValidated = checkAreTouristsValid() &&
        checkIsInsurerValid() &&
        checkIsEmailsValid();
    thunkApi.dispatch(formsActions._setFormValidated(isFormValidated));
});
