import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useStyles } from './styles';
const ITEMS_COUNT = 8;
const ANIMATION_DURATION = 0.7;
export const CircleLoader = memo(({ boxSize = 22, color }) => {
    const classes = useStyles();
    return (_jsx("div", { className: classes.root, style: {
            width: boxSize,
            height: boxSize,
            animationDuration: `${ANIMATION_DURATION * 25}s`,
        }, children: Array.from({ length: ITEMS_COUNT }).map((_, index) => (_jsx("div", { className: classes.itemWrapper, style: {
                transform: `rotate(${(360 / ITEMS_COUNT) * index}deg) translateY(-${boxSize / 3}px)`,
            }, children: _jsx("div", { className: classes.item, style: {
                    animationDelay: `${(ANIMATION_DURATION / ITEMS_COUNT) * index}s`,
                    height: boxSize / 3,
                    width: boxSize / 10,
                    opacity: `${(1 / ITEMS_COUNT) *
                        (ITEMS_COUNT - index + 1)}`,
                    animationDuration: `${ANIMATION_DURATION}s`,
                    backgroundColor: color,
                } }) }, index))) }));
});
