import { createUseStyles } from 'react-jss';
import { GLOBAL_LETTER_SPACING } from '@/styles/themes/components/consts';
import { MOBILE_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, DEKSTOP_FONT_SIZES, BLOCK_BOX_SHADOW, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    root: {
        padding: '12px 12px 12px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        maxWidth: '900px',
        background: theme.colors.cloud,
        borderRadius: '12px',
        marginBottom: 8,
        gap: 8,
        boxShadow: BLOCK_BOX_SHADOW,
        [theme.breakpoints.up('md')]: {
            padding: '15px',
        },
    },
    company: {
        display: 'flex',
        gap: 10,
    },
    companyLogo: {
        flexShrink: 0,
        width: '32px',
        height: '32px',
        borderRadius: '9px',
        '&[data-loading]': {
            backgroundColor: theme.colors.textTertiary,
        },
    },
    companyName: {
        fontWeight: 400,
        color: theme.colors.sky,
        fontSize: 15,
        marginTop: -3,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
            marginTop: -2,
        },
    },
    companyNamePlaceholder: {
        width: 120,
        height: 14,
        backgroundColor: theme.colors.textTertiary,
        borderRadius: 999,
        margin: '1px 0 3px',
    },
    companyDescription: {
        fontWeight: 400,
        color: theme.colors.textPrimary,
        fontSize: MOBILE_FONT_SIZES.small,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    companyDescriptionPlaceholder: {
        width: 160,
        height: 13,
        backgroundColor: theme.colors.textTertiary,
        borderRadius: 999,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    changeButton: {
        borderRadius: 6,
        height: 32,
        width: 32,
        padding: 4,
        margin: -4,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        justifyContent: 'center',
        transition: '.2s ease',
        '& span': {
            display: 'none',
        },
        '& svg': {
            flexShrink: 0,
            '& path': {
                fill: theme.colors.brand,
            },
        },
        [theme.breakpoints.up('lg')]: {
            '&:hover': {
                backgroundColor: theme.colors.brandTertiary,
            },
            '&[data-renaming="false"]': {
                width: 'unset',
                borderRadius: 999,
                padding: '0 12px',
                margin: 0,
                border: `1px solid ${theme.colors.textTertiary}`,
                '& span': {
                    display: 'block',
                    fontSize: 13,
                    letterSpacing: GLOBAL_LETTER_SPACING,
                    color: theme.colors.brand,
                },
                '& svg': {
                    display: 'none',
                },
            },
        },
    },
}), { name: 'forms-info' });
