import set from 'lodash.set';
import get from 'lodash.get';
import isDeepEqual from 'fast-deep-equal';
import { formatDate, isBefore, parseDate } from '@che-ins-ui/datepicker';
import { requestInitialState, REQUEST_STATE_NAMESPACE, todayPlus3Days, DEFAULT_MARKETING_PARAMS, } from '@/models/request';
import { getStartOfDay } from '@/libs/dates';
import { isIframe, parseQueryString, transformToBoolean } from '@/libs/helpers';
import { DEFAULT_PARTNER_ID } from '@/models/user';
import { defaultTourist } from '@/models/forms';
import { MARKETING_PARAMS, MAX_AGE } from '../constants';
import { prepareDates } from '../helpers/utils';
import { MULTIPOLICY_FULL_YEAR_VALUE, MULTIPOLICY_CODE } from '../static';
export const setNormalizedActualDates = ({ persistedState, }) => {
    const { dateStart, dateEnd } = persistedState[REQUEST_STATE_NAMESPACE];
    const normalizedDateStart = isBefore(parseDate(dateStart), getStartOfDay())
        ? formatDate(todayPlus3Days)
        : dateStart;
    const normalizedDateEnd = isBefore(parseDate(dateEnd), parseDate(dateStart))
        ? dateStart
        : dateEnd;
    set(persistedState, `${REQUEST_STATE_NAMESPACE}.dateStart`, normalizedDateStart);
    set(persistedState, `${REQUEST_STATE_NAMESPACE}.dateEnd`, normalizedDateEnd);
};
export const setTouristsToPersistedState = ({ localeStorageValue, touristsParamsQS, persistedState, isIgnoreLocalStorage, }) => {
    if (!touristsParamsQS || !touristsParamsQS.length) {
        return;
    }
    try {
        const queryTouristsAges = touristsParamsQS.map((tourist) => tourist.age);
        const stateTourists = persistedState?.form.tourists;
        const stateTouristsAges = stateTourists.map((tourist) => tourist.age);
        if (isDeepEqual(queryTouristsAges, stateTouristsAges)) {
            return;
        }
        const newFormTourists = queryTouristsAges.map((age) => {
            const touristFromStateIndex = stateTourists.findIndex((tourist) => tourist.age === age);
            if (touristFromStateIndex !== -1) {
                const [tourist] = stateTourists.splice(touristFromStateIndex, 1);
                return tourist;
            }
            return defaultTourist(age);
        });
        set(persistedState, 'form.tourists', newFormTourists);
        set(localeStorageValue, 'form.tourists', newFormTourists);
    }
    catch (e) {
        console.error('Setting tourists error', e);
    }
};
export const setCountriesAndCountryGroups = ({ localeStorageValue, countriesParamsQS, countriesParamsDataCheOptions, persistedState, isIgnoreLocalStorage, }) => {
    const LSCountries = get(localeStorageValue, `${[REQUEST_STATE_NAMESPACE]}.countries`, []);
    const LSCountryGroups = get(localeStorageValue, `${[REQUEST_STATE_NAMESPACE]}.countryGroups`, []);
    const { countries: QSCountries, countryGroups: QSCountryGroups } = countriesParamsQS;
    const { countries: DCECountries, countryGroups: DCECountryGroups } = countriesParamsDataCheOptions;
    let countries = [];
    let countryGroups = [];
    if (DCECountries.length || DCECountryGroups.length) {
        countries = DCECountries;
        countryGroups = DCECountryGroups;
    }
    else if (QSCountries.length || QSCountryGroups.length) {
        countries = QSCountries;
        countryGroups = QSCountryGroups;
    }
    else if (!isIgnoreLocalStorage &&
        (LSCountries.length || LSCountryGroups.length)) {
        countries = LSCountries;
        countryGroups = LSCountryGroups;
    }
    else {
        countries = requestInitialState.countries;
        countryGroups = requestInitialState.countryGroups;
    }
    set(persistedState, `${REQUEST_STATE_NAMESPACE}.countries`, countries);
    set(persistedState, `${REQUEST_STATE_NAMESPACE}.countryGroups`, countryGroups);
};
export const setMarketingsParamsToState = ({ localeStorageValue, marketingParamsQS, persistedState, marketingParamsDataCheOptions, }) => {
    const LSRequest = get(localeStorageValue, REQUEST_STATE_NAMESPACE, {});
    const marketingParamsSources = [
        marketingParamsDataCheOptions,
        marketingParamsQS,
        LSRequest,
    ];
    const prioritizedMarketingParams = marketingParamsSources.find(({ partnerId }) => Boolean(partnerId)) ||
        requestInitialState;
    MARKETING_PARAMS.forEach((paramName) => {
        let value = prioritizedMarketingParams[paramName] || null;
        if (paramName === 'partnerId' &&
            String(value) === String(DEFAULT_PARTNER_ID)) {
            value = null;
        }
        set(persistedState, `${REQUEST_STATE_NAMESPACE}.${paramName}`, value);
    });
};
function prepareArrays(query) {
    const arrays = {
        countries: [],
        countryGroups: [],
    };
    const QSCountries = query.countries || query.country;
    const QSCountryGroups = query.countryGroups || query.countryGroup;
    if (QSCountries && Array.isArray(QSCountries)) {
        arrays.countries = QSCountries;
    }
    if (QSCountryGroups && Array.isArray(QSCountryGroups)) {
        arrays.countryGroups = QSCountryGroups;
    }
    return arrays;
}
function prepareMarketingParams(query) {
    const result = {
        ...DEFAULT_MARKETING_PARAMS,
    };
    if (!isNaN(Number(query.partnerId))) {
        result.partnerId = Number(query.partnerId);
    }
    else {
        return result;
    }
    if (query.marker) {
        result.marker = query.marker;
    }
    if (query.marker2) {
        result.marker2 = query.marker2;
    }
    if (query.srcsale) {
        result.srcsale = query.srcsale;
    }
    return result;
}
const prepareServices = (services) => {
    return Object.entries(services).reduce((acc, [serviceName, serviceValue]) => {
        switch (serviceName) {
            case MULTIPOLICY_CODE: {
                const isFullYear = serviceValue === MULTIPOLICY_FULL_YEAR_VALUE;
                const formattedValue = Number(serviceValue);
                if (isFullYear || !isNaN(formattedValue)) {
                    acc[serviceName] = isFullYear
                        ? serviceValue
                        : formattedValue;
                }
                break;
            }
            default: {
                const formattedValue = Number(serviceValue);
                if (!isNaN(formattedValue)) {
                    acc[serviceName] = formattedValue;
                }
            }
        }
        return acc;
    }, {});
};
const prepareTourists = (tourist) => {
    if (Array.isArray(tourist) && tourist.length > 0) {
        return tourist.reduce((acc, { age }) => {
            const formattedAge = Number(age);
            if (!isNaN(formattedAge) &&
                formattedAge >= 0 &&
                formattedAge <= MAX_AGE) {
                acc.push({ age: formattedAge });
            }
            return acc;
        }, []);
    }
    else {
        return [];
    }
};
export function transformQuery(query) {
    const { isIgnoreLocalStorage, dateStart = '', dateEnd = '', currency = '', assistances, sports, } = query;
    const services = 'services' in query || 'service' in query
        ? { services: prepareServices(query.services || query.service) }
        : {};
    return {
        isIgnoreLocalStorage: Number(isIgnoreLocalStorage) === 1,
        requestParamsQS: {
            ...prepareDates(dateStart, dateEnd),
            ...services,
            ...(currency ? { currency: String(currency) } : {}),
            ...(assistances ? { assistances } : {}),
            ...(sports ? { sports } : {}),
        },
        countriesParamsQS: prepareArrays(query),
        marketingParamsQS: prepareMarketingParams(query),
        touristsParamsQS: prepareTourists(query.tourist),
    };
}
export const getWhiteLabelParams = () => {
    const { isFrame: isFrameProp, isLogo: isLogoProps, isShowExpiredCalculationModal: isShowExpiredCalculationModalProps, isPaytureInPay: isPaytureInPayProps, isIgnoreForm: isIgnoreFormProps, isIgnoreUpsell: isIgnoreUpsellProps, partnerId, marker, marker2, srcsale, sports = [], services = {}, dateStart = '', dateEnd = '', countries = [], countryGroups = [], basename = '/', } = JSON.parse(document
        .getElementById('cheFormScript')
        ?.getAttribute('data-che-options') ||
        (isIframe() ? '{ "isFrame": true }' : '{ "isFrame": false }'));
    const parsedQuery = parseQueryString();
    const isFrame = isFrameProp === 'true' || isFrameProp === true;
    const isWhiteLabelPage = isIframe() || (isFrame && window.location.pathname === basename);
    let isIgnoreFormProp = isIgnoreFormProps;
    let isIgnoreUpsellProp = isIgnoreUpsellProps;
    let isCheSupportProp = parsedQuery.isCheSupport;
    if (isIframe()) {
        isIgnoreFormProp = parsedQuery.isIgnoreForm;
        isIgnoreUpsellProp = parsedQuery.isIgnoreUpsell;
    }
    const requestParamsDataCheOptions = {
        ...(sports?.length ? { sports } : {}),
        ...(Object.keys(services).length ? { services } : {}),
        ...prepareDates(dateStart, dateEnd),
    };
    const countriesParamsDataCheOptions = isWhiteLabelPage
        ? {
            countries,
            countryGroups,
        }
        : {
            countries: [],
            countryGroups: [],
        };
    const marketingParamsDataCheOptions = {
        partnerId: partnerId ? Number(partnerId) : null,
        marker,
        marker2,
        srcsale,
    };
    const [isLogo, isShowExpiredCalculationModal, isPaytureInPay, isIgnoreForm, isCheSupport, isIgnoreUpsell,] = [
        isLogoProps,
        isShowExpiredCalculationModalProps,
        isPaytureInPayProps,
        isIgnoreFormProp,
        isCheSupportProp,
        isIgnoreUpsellProp,
    ].map(transformToBoolean);
    return {
        isWhiteLabelPage,
        requestParamsDataCheOptions,
        countriesParamsDataCheOptions,
        marketingParamsDataCheOptions,
        isFrame,
        isShowExpiredCalculationModal,
        isLogo: isLogo && !isIframe(),
        isPaytureInPay,
        isIgnoreForm,
        isIgnoreUpsell,
        isCheSupport,
        basename,
    };
};
