import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '@che-ins-ui/modal';
import { ConnectedModal } from '@che-ins-ui/modal';
import { ASSETS_SRC } from '@/libs/constants';
import { upsellSelector } from '@/models/upsell/selectors';
import { upsellActions } from '@/models/upsell';
import { themeModeSelector } from '@/models/theme';
import { useStyles } from './styles';
import { AddButton } from './AddButton';
import { activeRestHintModalSelector } from './selectors';
export const modalNameActiveRest = 'DETAILS_ACTIVE_REST';
const MODAL_TITLE = 'Активный отдых';
const MODAL_TEXT = 'Добавьте "Активный отдых" и ваша страховка будет распостранаяться на такие активности как: Посещение парка атракционов и аквапарка , водные атракционы (банан, ватрушка, катамаран, др.), боулинг, пляжный волейбол/футбол/гандбол, снорклинг, велосипедные прогулки (кроме катания по пересеченной местности), катание на самокате, на лодке, каяке, каноэ, байдарке (не спортивное), на моторных лодках, плотах и маломерных судах (экскурсионное), на джипах по бездорожью (экскурсионное, без управления), на санях, ледянках, тюбинге, ,экскурсионное катание на животных, купание и водные развлечения в бассейне отеля (гостиницы), занятия фитнесом.';
export const DetailsActiveRestHint = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const themeMode = useSelector(themeModeSelector);
    const { chosen } = useSelector(upsellSelector);
    const { isIncluded } = useSelector(activeRestHintModalSelector);
    const chosenActiveRest = Boolean(chosen?.activeRest);
    const handleClose = () => dispatch(modalActions.close({ name: modalNameActiveRest }));
    const handleAdd = () => {
        dispatch(upsellActions.setChosen({
            type: 'activeRest',
            value: !chosenActiveRest,
        }));
        handleClose();
    };
    const isDisabledAddButton = Boolean(chosenActiveRest || isIncluded);
    return (_jsxs(ConnectedModal, { name: modalNameActiveRest, appearance: "hint", withCloseButton: true, title: _jsxs(_Fragment, { children: [_jsx("img", { className: classes.image, src: `${ASSETS_SRC.IMAGES}/modals/service-info-${themeMode}.webp`, alt: "" }), _jsx("span", { className: classes.title, children: MODAL_TITLE })] }), children: [_jsx("p", { className: classes.description, children: MODAL_TEXT }), _jsx(AddButton, { onClick: handleAdd, disabled: isDisabledAddButton })] }));
};
