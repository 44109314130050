import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';
import { Button } from '@che-ins-ui/button';
import { basketActions } from '@/models/basket';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { ROUTES } from '@/components/routes';
export const GoToCalculationsBtn = () => {
    const dispatch = useDispatch();
    const queryString = useCalculationQueryString();
    const handleClearBasket = useCallback(() => {
        dispatch(basketActions.clearBasket({ lastChangesId: nanoid() }));
    }, []);
    return (_jsx(Button, { component: Link, to: {
            pathname: ROUTES.calculation,
            search: queryString,
        }, shouldFitContent: true, onClick: handleClearBasket, passAppearancesToComponent: false, appearance: "continue", children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u0432\u044B\u0431\u043E\u0440\u0443 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043E\u0439 \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438" }));
};
