import { createAsyncThunk } from '@reduxjs/toolkit';
import { toErrorWithMessage, transformToJson } from '@/libs/helpers';
import { captureError } from '@/libs/helpers/utils';
import { apiActions } from './slice';
export const fetchApiServiceStatus = createAsyncThunk('api/fetchApiServiceStatus', async (_, listenerApi) => {
    try {
        const serviceStatus = await fetch(__VZR_API_URL__.slice(0, -1)).then(transformToJson);
        listenerApi.dispatch(apiActions.setServiceStatus(serviceStatus));
    }
    catch (error) {
        captureError(toErrorWithMessage(error), 'api/fetchApiServiceStatus');
        console.error(error);
        throw new Error('При получении данных из Service Status произошла ошибка');
    }
});
