import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { BREAKPOINTS, useScreenSizes } from '@che-ins-ui/theme';
import { DESKTOP_SEARCH_FORM_BREAKPOINT } from '@/styles/themes/components';
import { useStyles } from '../styles';
import { Countries } from './Countries';
import { CommonComponents } from './CommonComponents';
export const WhiteLabelSearch = memo(() => {
    const classes = useStyles();
    const { width } = useScreenSizes();
    const isDesktop = width > BREAKPOINTS[DESKTOP_SEARCH_FORM_BREAKPOINT];
    if (isDesktop) {
        return (_jsxs("div", { className: classes.fields, children: [_jsx(Countries, { mode: "while-label" }), _jsx(CommonComponents, { mode: "while-label" })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Countries, { mode: "while-label" }), _jsx("div", { className: classes.fields, children: _jsx(CommonComponents, { mode: "while-label" }) })] }));
});
