import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { memo, useCallback, useMemo } from 'react';
import { comparingProductsSelector } from '@/models/comparing/selectors';
import { matchedOffersSelector } from '@/models/calculation/selectors';
import { apiCompaniesSelector, apiSelector } from '@/models/api/selectors';
import { requestCurrencySelector } from '@/models/request/selectors';
import { filterRepeatingServices, getAllServices, getComparingOffers, } from './helpers';
import { ComparingTableOffers } from './ComparingTableOffers';
export const ComparingBody = memo(({ handleAddToBasket }) => {
    const matchedOffers = useSelector(matchedOffersSelector);
    const addToBasket = useCallback((offerId) => {
        const offer = matchedOffers.find((offer) => offer.company.id === offerId);
        if (offer) {
            handleAddToBasket(offer);
        }
    }, [matchedOffers, handleAddToBasket]);
    const companies = useSelector(apiCompaniesSelector);
    const comparing = useSelector(comparingProductsSelector);
    const currency = useSelector(requestCurrencySelector);
    const { servicesDictionary } = useSelector(apiSelector);
    const matching = useMemo(() => {
        return getComparingOffers({
            comparingIds: comparing,
            companies,
            matchedOffers,
            servicesDictionary,
            currency,
        });
    }, [comparing, companies, matchedOffers, servicesDictionary, currency]);
    const { offers, repeatingServices } = useMemo(() => {
        return filterRepeatingServices(matching);
    }, [matching]);
    const allServices = useMemo(() => {
        return getAllServices(matching);
    }, [matching.length]);
    return (_jsx(ComparingTableOffers, { ...{ offers, repeatingServices, allServices, addToBasket } }));
});
