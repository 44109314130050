import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useStyles } from './styles';
export const RestrictionsTab = ({ offer }) => {
    const classes = useStyles();
    const restrictions = useMemo(() => {
        return offer.company.additionalInfo
            .filter(({ type }) => type === 'limitation')
            .reduce((acc, { description }) => [...acc, ...description], []);
    }, [offer.company.additionalInfo]);
    if (!restrictions.length) {
        return _jsx("p", { className: classes.tabTitle, children: "\u041E\u0433\u0440\u0430\u043D\u0438\u0447\u0435\u043D\u0438\u0439 \u043D\u0435\u0442" });
    }
    return (_jsxs(_Fragment, { children: [_jsx("p", { className: classes.tabTitle, children: "\u041E\u0433\u0440\u0430\u043D\u0438\u0447\u0435\u043D\u0438\u044F:" }), _jsx("ul", { children: restrictions.map((restriction, idx) => (_jsx("li", { className: classes.tabText, style: { listStyle: 'disc', marginLeft: '1em' }, children: restriction }, idx))) })] }));
};
