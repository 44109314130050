import { CURRENCIES_BADGES } from '@/libs/constants';
import { formatPrice } from '@/libs/helpers/utils';
import { appState } from '@/store';
export const getNextMedicineSum = (currentSum) => {
    const { basket } = appState.getState();
    const isBasketCompanyCodeSberbank = basket?.product?.company?.code === 'sberbank';
    if (isBasketCompanyCodeSberbank) {
        if (currentSum < 60000) {
            return 60000;
        }
        if (currentSum === 60000) {
            return 120000;
        }
        return null;
    }
    else {
        if (currentSum === 100000) {
            return null;
        }
        return currentSum < 50000 ? 50000 : 100000;
    }
};
export const formatCardPriceInRub = (price) => {
    return `${!price ? 0 : formatPrice(price)} ${CURRENCIES_BADGES.rub}`;
};
export const formatCardPriceInUsd = (price) => {
    return `${!price ? 0 : formatPrice(price)} ${CURRENCIES_BADGES.usd}`;
};
