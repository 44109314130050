import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ASSETS_SRC, CURRENCIES_BADGES } from '@/libs/constants';
import { getNextMedicineSum } from '@/models/upsell/helpers';
import { formatPrice } from '@/libs/helpers/utils';
import { MEDICINE_CODE } from '@/libs/static';
import { modalNameActiveRest, modalNameMoto, modalNameStrongerProtection, } from '../hints';
export const constructUpsellCards = ({ basketMedicine, isCheckedMedicine, isCheckedActiveRest, isCheckedMoto, isShowMedicine, isShowActiveRest, isShowMoto, isIncludedActiveRest, isIncludedMoto, toggleMedicine, toggleActiveRest, toggleMoto, requestCurrency, }) => [
    {
        type: null,
        isChecked: true,
        title: 'Отличного отдыха!',
        subTitle: 'Верим, что отдых пройдет без происшествий и с кучей эмоций!',
        imgSrc: `${ASSETS_SRC.IMAGES}/upsell/great-holiday.png`,
        imgWidth: 154,
        imgHeight: 157,
        isShow: true,
        onClick: () => { },
    },
    {
        type: MEDICINE_CODE,
        isChecked: isCheckedMedicine,
        title: 'Защита покрепче',
        subTitle: (_jsxs(_Fragment, { children: ['Увеличим лимит страховки до ', _jsx("span", { style: { whiteSpace: 'nowrap' }, children: `${CURRENCIES_BADGES[requestCurrency]}${formatPrice(getNextMedicineSum(basketMedicine))}` }), ', теперь у вас точно крепкая страховка'] })),
        imgSrc: `${ASSETS_SRC.IMAGES}/upsell/stronger-protection.png`,
        imgWidth: 226,
        imgHeight: 145,
        detailsModalName: modalNameStrongerProtection,
        isShow: isShowMedicine,
        onClick: toggleMedicine,
    },
    {
        type: 'activeRest',
        isChecked: isCheckedActiveRest,
        title: 'Активный отдых',
        subTitle: 'Страховка будет работать даже при занятиях спортом',
        imgSrc: `${ASSETS_SRC.IMAGES}/upsell/active-rest.png`,
        imgWidth: 260,
        imgHeight: 157,
        detailsModalName: modalNameActiveRest,
        isShow: isShowActiveRest,
        onClick: toggleActiveRest,
        isIncluded: isIncludedActiveRest,
    },
    {
        type: 'moto',
        isChecked: isCheckedMoto,
        title: 'Мотобайк',
        subTitle: (_jsxs(_Fragment, { children: ['Не забудьте', _jsx("br", {}), 'себя застраховать на байке'] })),
        imgSrc: `${ASSETS_SRC.IMAGES}/upsell/motocycle.png`,
        imgWidth: 260,
        imgHeight: 157,
        detailsModalName: modalNameMoto,
        isShow: isShowMoto,
        onClick: toggleMoto,
        isIncluded: isIncludedMoto,
    },
];
