import { createUseStyles } from 'react-jss';
export const useStyles = createUseStyles((theme) => ({
    root: {
        textAlign: 'left',
        fontSize: 13,
        marginLeft: 12,
        marginTop: -17,
        color: theme.colors.error,
        opacity: 0,
        transition: '.15s ease-out',
        pointerEvents: 'none',
        [theme.breakpoints.up('md')]: {
            fontSize: 12,
        },
        '&[data-error="true"]': {
            marginTop: 5,
            opacity: 1,
            transition: '.2s ease-out, opacity .1s ease-in-out 0.05s',
        },
        '&[data-warning-color="true"]': {
            color: theme.colors.warning,
        },
    },
}), { name: 'input-error' });
