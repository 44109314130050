import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { BREAKPOINTS, useScreenSizes } from '@che-ins-ui/theme';
import ErrorBoundary from '@/components/common/ErrorBoundry';
import { apiIsApiDataLoadingSelector } from '@/models/api/selectors';
import { DESKTOP_FILTERS_BREAKPOINT } from '@/styles/themes/components';
import { ComparingButtonFilters } from '../common/Comparing/ComparingButtonFilters';
import { AdminPartnerIdEditBlock } from '../common/AdminPartnerIdEditBlock/AdminPartnerIdEditBlock';
import { CommissionButton } from '../common/CommissionButton/CommissionButton';
import { HealthInsuranceFilter } from './HealthInsuranceFilter';
import { AdditionalFilter } from './AdditionalFilter';
import { PopularFilters } from './PopularFilters';
import { RecommendFilter } from './RecommendFilter';
import { useStyles } from './styles';
import { FiltersPanelHeader } from './FiltersPanelHeader';
import { MobileFilterShowResultButton } from './MobileFilterShowResultButton';
import { FiltersSkeleton } from './FiltersSkeleton';
import { FiltersSearch } from './FiltersSearch';
import { SportsFilter } from './SportsFilter';
import { SearchNotFound } from './SearchNotFound';
import { WorkAndEducationFilter } from './WorkAndEducationFilter';
import { SearchSecretResult } from './SearchSecretResult';
const FiltersPanel = () => {
    const classes = useStyles();
    const { width } = useScreenSizes();
    const isDesktop = width > BREAKPOINTS[DESKTOP_FILTERS_BREAKPOINT];
    const apiIsLoading = useSelector(apiIsApiDataLoadingSelector);
    const filtersRef = useRef(null);
    const content = useMemo(() => {
        if (apiIsLoading) {
            return _jsx(FiltersSkeleton, {});
        }
        return (_jsxs(_Fragment, { children: [_jsx(AdminPartnerIdEditBlock, {}), isDesktop && _jsx(CommissionButton, {}), _jsx(ComparingButtonFilters, {}), _jsx(FiltersSearch, {}), _jsx(FiltersPanelHeader, {}), _jsx(RecommendFilter, {}), _jsx(PopularFilters, {}), _jsx(HealthInsuranceFilter, {}), _jsx(SportsFilter, {}), _jsx(AdditionalFilter, {}), _jsx(WorkAndEducationFilter, {}), _jsx(SearchNotFound, {}), _jsx(SearchSecretResult, {}), _jsx(MobileFilterShowResultButton, {})] }));
    }, [isDesktop, apiIsLoading]);
    return (_jsx(ErrorBoundary, { children: _jsx("div", { ref: filtersRef, className: classes.wrapper, children: content }) }));
};
export default memo(FiltersPanel);
