import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { ConnectedModal, modalActions } from '@che-ins-ui/modal';
import { notebookActions } from '@/models/notebook';
import { EditPersonHeader } from './EditPersonHeader';
import { EditPersonForm } from './EditPersonForm';
import { useStyles } from './styles';
export const EDIT_PERSON_MODAL_NAME = 'EDIT_PERSON_MODAL_NAME';
export const closeEditPersonModal = () => modalActions.close({ name: EDIT_PERSON_MODAL_NAME });
export const EditPersonModal = memo(() => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const closeModal = () => {
        dispatch(closeEditPersonModal());
        setTimeout(() => dispatch(notebookActions.resetForm()), 300);
    };
    return (_jsx(ConnectedModal, { name: EDIT_PERSON_MODAL_NAME, withCloseButton: true, closeClickByBlanket: false, appearance: "notebook-form", children: _jsxs("div", { className: classes.root, children: [_jsx(EditPersonHeader, { onClose: closeModal }), _jsx(EditPersonForm, { onClose: closeModal })] }) }));
});
