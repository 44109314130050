import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Skeleton } from '@che-ins-ui/skeleton';
import { useScreenSizes } from '@che-ins-ui/theme';
import { useStyles as useStylesOfferCard } from '../OfferCard/styles';
import { TagsSkeleton } from '../OfferCard/TagsSkeleton';
import { useStyles as useStylesOffers } from './styles';
export const OffersSkeleton = () => {
    const classesOffers = useStylesOffers();
    const classesOfferCard = useStylesOfferCard();
    const { isMobile } = useScreenSizes();
    const isDesktop = !isMobile;
    return (_jsx("div", { className: classNames(classesOffers.root), children: _jsx("ul", { children: new Array(12).fill(0).map((_, idx) => {
                return (_jsx("li", { className: classesOffers.item, children: _jsxs("div", { className: classNames(classesOfferCard.root), children: [_jsxs("div", { className: classesOfferCard.info, children: [_jsx("div", { className: classesOfferCard.offerInfo, children: _jsxs("div", { className: classesOfferCard.companyInfo, children: [_jsx(Skeleton, { width: '32px', height: '32px', borderRadius: 9 }), _jsx(Skeleton, { width: '120px', height: '13px', borderRadius: 7, margin: '0px 0px 0px 10px' })] }) }), _jsx("div", { className: classesOfferCard.tags, children: _jsx(TagsSkeleton, { animated: true }) })] }), _jsx("div", { className: classNames(classesOfferCard.payButtonBlock, classesOfferCard.payButtonSkeletonWrapper), children: _jsx(Skeleton, { shouldFitContent: !isDesktop, width: isDesktop
                                        ? '100%'
                                        : undefined, height: isDesktop ? '59px' : '35px', borderRadius: 10 }) })] }) }, idx));
            }) }) }));
};
