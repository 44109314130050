import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure, usePrevious } from 'react-use';
import { motion, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import { Button as Button } from '@che-ins-ui/button';
import { MAX_TOURISTS_AMOUNT } from '@/libs/constants';
import { Hint } from '@/components/common/Hint';
import { AuthButton } from '@/components/common/AuthButton/AuthButton';
import { touristsFormsSelector } from '@/models/forms/selectors';
import { formsActions } from '@/models/forms';
import { whyPassportIsUnnesessutyHintText } from '../consts';
import { useStyles } from './styles';
import { useIsMobileViewInsuranceForm } from './hooks';
import { Tourist } from './Tourist/Tourist';
export const TOURISTS_MAX_LAYOUT_ANIMATION_DURATION = 0.4;
export const TOURISTS_CONTAINER_ID = 'tourists-container';
export const Tourists = memo(() => {
    const tourists = useSelector(touristsFormsSelector);
    const touristsAmount = tourists.length;
    const [amountIncreased, setAmountIncreased] = useState(true);
    const [wasLastTouristDeleted, setWasLastTouristDeleted] = useState(false);
    const prevTourists = usePrevious(tourists);
    const classes = useStyles();
    const dispatch = useDispatch();
    const isMobileFormView = useIsMobileViewInsuranceForm();
    const [ref, { height }] = useMeasure();
    const rootAnimation = useMemo(() => (height ? { height: height + 30 } : {}), [height]);
    const rootDuration = useMemo(() => {
        const baseDuration = amountIncreased || wasLastTouristDeleted
            ? 0.15
            : (touristsAmount + 1) * 0.1;
        return {
            duration: Math.min(baseDuration, TOURISTS_MAX_LAYOUT_ANIMATION_DURATION),
        };
    }, [amountIncreased, wasLastTouristDeleted, touristsAmount]);
    const handleAddTourist = () => {
        dispatch(formsActions.addTourist());
    };
    useEffect(() => {
        const prevLength = prevTourists?.length ?? 0;
        if (tourists.length === prevLength) {
            return;
        }
        setAmountIncreased(tourists.length > prevLength);
        const checkWasLastTouristDeleted = () => {
            if (!prevTourists || !prevLength || tourists.length >= prevLength) {
                return false;
            }
            const lastPrevTourist = prevTourists.at(-1);
            const lastTourist = tourists.at(-1);
            const isLastTouristDeleted = lastPrevTourist?._uuid !== lastTourist?._uuid;
            return isLastTouristDeleted;
        };
        setWasLastTouristDeleted(checkWasLastTouristDeleted());
    }, [tourists, prevTourists]);
    return (_jsx(motion.div, { layout: true, initial: rootAnimation, animate: rootAnimation, className: classes.root, transition: rootDuration, id: TOURISTS_CONTAINER_ID, children: _jsx("div", { ref: ref, children: _jsxs(AnimatePresence, { initial: false, mode: "popLayout", children: [_jsxs("div", { className: classNames(classes.touristsHeader, classes.header), children: [_jsxs("div", { children: [_jsx("h3", { className: classes.title, children: "\u0422\u0443\u0440\u0438\u0441\u0442\u044B" }), _jsx(Hint, { id: "touristPassport", label: "\u041F\u043E\u0447\u0435\u043C\u0443 \u043D\u0435 \u043D\u0443\u0436\u0435\u043D \u043F\u0430\u0441\u043F\u043E\u0440\u0442?", title: "\u041F\u043E\u0447\u0435\u043C\u0443 \u043D\u0435 \u043D\u0443\u0436\u0435\u043D \u043F\u0430\u0441\u043F\u043E\u0440\u0442?", actionType: "agree", children: whyPassportIsUnnesessutyHintText })] }), _jsx(AuthButton, {})] }, "header"), tourists.map((tourist, index) => (_jsx(Tourist, { ...tourist, index: index }, tourist._uuid))), touristsAmount < MAX_TOURISTS_AMOUNT && (_jsx(motion.div, { layout: true, className: classes.addTouristButtonWrapper, initial: { opacity: 0.8, scale: 0.4 }, animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.3 }, transition: {
                            layout: rootDuration,
                        }, children: _jsx(Button, { baseAppearance: "toggle-item-forms-page", appearance: "secondary", shouldFitContent: isMobileFormView, onClick: handleAddTourist, children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0442\u0443\u0440\u0438\u0441\u0442\u0430" }) }, "button"))] }) }) }));
});
