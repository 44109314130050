import { sendLeadhitOrder } from './leadhit';
import { getYandexAnalyticsProvider, } from './yandex';
class Analytics {
    constructor() {
        this.providers = {};
        this.call = (method) => {
            return (options = {}) => {
                if (!Object.keys(this.providers).length) {
                    this.initProviders();
                }
                Object.keys(this.providers).forEach((provider) => {
                    this.providers[provider]?.[method](options);
                });
            };
        };
        this.ecommerceYM = () => (action) => this.providers.yandex?.sendEcommerce(action);
        this.ecommerceGA = () => (action) => this.providers.google?.sendEcommerce(action);
        this.leadhit = () => (order) => sendLeadhitOrder(order);
        this.sendEvent = this.call('sendEvent');
        this.sendView = this.call('sendView');
        this.sendImpression = this.call('sendImpression');
        this.sendProduct = this.call('sendProduct');
        this.sendAction = this.call('sendAction');
        this.sendEcommerceYM = this.ecommerceYM();
        this.sendEcommerceGA = this.ecommerceGA();
        this.sendLeadhit = this.leadhit();
    }
    initProviders() {
        const yandexCounterProp = `yaCounter${__YA_COUNTER_ID__}`;
        const { [yandexCounterProp]: yandexCounter, dataLayer = [], } = window;
        this.providers = {
            yandex: getYandexAnalyticsProvider(yandexCounter, dataLayer),
        };
    }
}
export const analytics = new Analytics();
