import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider as JssThemeProvider } from 'react-jss';
import { CheJssProvider } from '@che-ins-ui/theme';
import { getTheme } from '@/styles/themes';
import { themeModeSelector } from '@/models/theme';
import { GlobalStylesInjector } from '../GlobalStylesInjector';
export const ThemeProvider = ({ children }) => {
    const themeMode = useSelector(themeModeSelector);
    const theme = getTheme(themeMode);
    return (_jsx(CheJssProvider, { children: _jsxs(JssThemeProvider, { theme: theme, children: [_jsx(GlobalStylesInjector, {}), children] }) }));
};
export default memo(ThemeProvider);
