import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useScrollbarWidth } from 'react-use';
import { motion } from 'framer-motion';
import { useScreenSizes } from '@che-ins-ui/theme';
import { isSomeModalOpenSelector } from '@che-ins-ui/modal';
import { Container } from '@che-ins-ui/container';
import { Header as CheHeader } from '@che-ins-ui/header';
import { isFrameSelector, isVascoPartnerSelector, } from '@/models/request/selectors';
import { analytics } from '@/libs/analytics';
import { userSelector } from '@/models/user/selectors';
import { ACCOUNT_URL, VASCO_HEADER_PROPS } from '@/libs/constants';
import { DEFAULT_PARTNER_ID } from '@/models/user';
import { BREAKPOINTS } from '@/styles/breakpoints';
import { useCheTheme } from '@/styles/themes';
import { changingToThemeSelector, themeModeSelector, toggleTheme, } from '@/models/theme';
import { useStyles } from './styles';
export const Header = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isVascoPartner = useSelector(isVascoPartnerSelector);
    const isSomeModalOpen = useSelector(isSomeModalOpenSelector);
    const scrollbarWidth = useScrollbarWidth();
    const { role, status, adminPartnerId } = useSelector(userSelector);
    const isFrame = useSelector(isFrameSelector);
    const themeMode = useSelector(themeModeSelector);
    const { width } = useScreenSizes();
    const paddingRight = isSomeModalOpen ? scrollbarWidth + 'px' : '0px';
    if (isFrame) {
        return null;
    }
    const withPartnerHeader = Boolean(role === 'partner' ||
        (role === 'admin' &&
            adminPartnerId &&
            adminPartnerId !== DEFAULT_PARTNER_ID));
    const withLeading = width >= BREAKPOINTS.md;
    return (_jsx("div", { className: classes.root, style: { paddingRight }, children: _jsx(Container, { children: _jsx(CheHeader, { contactsProps: { appearance: 'header' }, homepageUrl: __HOMEPAGE__, ...(!withPartnerHeader && { status }), ...(withLeading && {
                    leading: _jsx(Leading, { ...{ withPartnerHeader } }),
                }), ...(isVascoPartner && VASCO_HEADER_PROPS), ...(themeMode === 'dark' && {
                    tooltipClasses: {
                        tooltipBody: classes.tooltipBodyDark,
                        tooltipArrow: classes.tooltipArrowDark,
                    },
                }), withThemeSwitcher: true, themeIcon: _jsx(ThemeModeSwitcher, {}), onThemeChange: () => dispatch(toggleTheme()) }) }) }));
});
const animation = { initial: { scale: 0, x: 15 }, animate: { scale: 1, x: 0 } };
const Leading = memo(({ withPartnerHeader }) => {
    const classes = useStyles();
    const generateLeadingItem = ({ href, text, onClick, opensInSameTab, }) => (_jsx(motion.a, { ...animation, href: href, className: classes.leadingLink, target: opensInSameTab ? '_self' : '_blank', onClick: onClick, children: _jsxs("div", { className: classes.leadingLinkLabel, children: [text, " ", _jsx(ArrowIcon, {})] }) }, text));
    const baseLeading = [
        generateLeadingItem({
            href: __HOMEPAGE__ + '/business',
            text: 'Оплата по безналу',
            onClick: () => {
                analytics.sendEvent({
                    event: 'click',
                    category: 'header',
                    action: 'vzr_business_button',
                });
            },
            opensInSameTab: true,
        }),
    ];
    const partnerLeading = [
        generateLeadingItem({
            href: __PARTNERS_URL__,
            text: 'Партнёрский кабинет',
        }),
        generateLeadingItem({
            href: ACCOUNT_URL,
            text: 'Личный кабинет',
        }),
    ];
    const leading = withPartnerHeader ? partnerLeading : baseLeading;
    return _jsxs("div", { className: classes.leadingWrapper, children: [...leading] });
});
const ArrowIcon = () => (_jsx("svg", { width: "6", height: "12", viewBox: "0 0 6 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: { transform: 'translate(1px, 1px)', marginLeft: 4 }, children: _jsx("path", { d: "M1.45 1.5L5 6L1.45 10.5", stroke: "white", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) }));
const SIZE = 18;
const HALF_SIZE = SIZE / 2;
const CIRCLE_STROKE = 1.5;
const CENTER_SIZE = SIZE + 2;
const CIRCLE_DIMENSIONS = {
    cx: HALF_SIZE,
    cy: HALF_SIZE,
    r: HALF_SIZE - CIRCLE_STROKE,
};
const ThemeModeSwitcher = memo(() => {
    const theme = useCheTheme();
    const themeMode = useSelector(themeModeSelector);
    const changingToTheme = useSelector(changingToThemeSelector);
    const isLight = (changingToTheme || themeMode) === 'light';
    const posX = isLight ? -CENTER_SIZE : 0;
    const gradientStops = [
        { offset: '0', stopColor: theme.colors.yellow },
        { offset: '33.3%', stopColor: theme.colors.yellow },
        { offset: '66.6%', stopColor: theme.colors.white },
        { offset: '100%', stopColor: theme.colors.white },
    ];
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: SIZE, height: SIZE, viewBox: `0 0 ${SIZE} ${SIZE}`, fill: "none", children: [_jsxs("defs", { children: [_jsx("clipPath", { id: "clip", children: _jsx("circle", { ...CIRCLE_DIMENSIONS }) }), _jsx("linearGradient", { id: "gradient", x1: "0%", y1: "0%", x2: "100%", y2: "0%", children: gradientStops.map(({ offset, stopColor }) => (_jsx("stop", { offset: offset, style: { stopColor, stopOpacity: 1 } }, offset))) })] }), _jsx("g", { clipPath: "url(#clip)", children: _jsxs("g", { style: {
                        transform: `translateX(${posX}px)`,
                        transition: '0.4s cubic-bezier(0.22, 1, 0.36, 1)',
                    }, children: [_jsx("rect", { x: "0", y: "0", width: HALF_SIZE, height: SIZE }), _jsx("rect", { x: HALF_SIZE, y: "0", width: CENTER_SIZE, height: SIZE, fill: "url(#gradient)" }), _jsx("rect", { x: CENTER_SIZE + HALF_SIZE, y: "0", width: HALF_SIZE, height: SIZE })] }) }), _jsx("circle", { ...CIRCLE_DIMENSIONS, strokeWidth: CIRCLE_STROKE, fill: "none", stroke: isLight ? theme.colors.white : theme.colors.yellow, style: { transition: theme.transition.global } })] }));
});
