import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { emojis as allEmojis, notebookActions } from '@/models/notebook';
import { notebookFormValuesSelector, wasScrollingEmojisAnimationShownSelector, } from '@/models/notebook/selectors';
import { getEmojiImage } from '@/models/notebook/helpers';
import { useStyles } from './styles';
import { useIsDesktopEditPersonModalView } from './consts';
export const EditPersonFormEmojiPicker = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { emoji: personEmoji } = useSelector(notebookFormValuesSelector);
    const wasScrollingEmojisAnimationShown = useSelector(wasScrollingEmojisAnimationShownSelector);
    const isDesktopView = useIsDesktopEditPersonModalView();
    useEffect(() => {
        dispatch(notebookActions.setWasScrollingEmojisAnimationShown(true));
    }, []);
    const [isHovered, setIsHovered] = useState(false);
    const [isAnimationCompleted, setIsAnimationCompleted] = useState(false);
    const shouldShowAnimation = !wasScrollingEmojisAnimationShown;
    const currentEmoji = personEmoji || 'empty';
    const emojis = useMemo(() => {
        const emojisToSelect = [...allEmojis, 'empty'];
        return emojisToSelect.sort((a, b) => {
            if (a === currentEmoji) {
                return -1;
            }
            if (b === currentEmoji) {
                return 1;
            }
            return 0;
        });
    }, []);
    const containerInitialX = isDesktopView ? -300 : -160;
    const emojiInitialX = isDesktopView ? -70 : -40;
    return (_jsx("div", { className: classes.emojisBlock, onPointerEnter: () => setIsHovered(true), onPointerLeave: () => setIsHovered(false), "data-animation-completed": isAnimationCompleted, children: _jsx(motion.div, { initial: { x: shouldShowAnimation ? containerInitialX : 0 }, animate: { x: 0 }, transition: {
                delay: isDesktopView ? 0 : 0.22,
                type: 'spring',
                stiffness: 140,
                damping: 8,
            }, className: classes.emojisContainer, onAnimationComplete: () => setIsAnimationCompleted(true), children: emojis.map((emoji, index) => {
                const isActive = emoji === currentEmoji;
                return (_jsx("div", { className: classes.emojiButtonWrapper, children: _jsx(motion.button, { initial: {
                            x: shouldShowAnimation ? emojiInitialX : 0,
                        }, animate: { x: 0 }, transition: {
                            delay: 0.02 * index +
                                (isDesktopView ? 0 : 0.22),
                            type: 'spring',
                            stiffness: 280,
                            mass: 0.6,
                        }, onClick: () => {
                            dispatch(notebookActions.setEmoji(emoji !== 'empty' ? emoji : null));
                        }, children: _jsx("div", { className: classes.emojiButton, "data-active": isActive, "data-blured": !isHovered &&
                                !isActive &&
                                isAnimationCompleted, children: _jsx("img", { src: getEmojiImage(emoji), alt: emoji }) }) }) }, emoji));
            }) }) }));
});
