import { convertDateFromStateToISO } from '@che-ins-ui/datepicker';
export const transformInsurerNames = (insurer, isInsurerInRussian) => {
    const { firstName, lastName, lastNameRu, firstNameRu, secondNameRu, birthday, } = insurer;
    return {
        ...(isInsurerInRussian
            ? {
                lastName: lastNameRu,
                firstName: firstNameRu,
                secondName: secondNameRu || null,
            }
            : {
                lastName,
                firstName,
                secondName: null,
            }),
        birthday,
    };
};
export const transformInsurerForApi = (insurer, isInsurerInRussian) => {
    const { firstName, lastName, birthday, lastNameRu, firstNameRu, secondNameRu, hasSecondNameRu, } = insurer;
    return {
        ...(isInsurerInRussian
            ? {
                lastName: lastNameRu,
                firstName: firstNameRu,
                secondName: hasSecondNameRu ? secondNameRu : null,
            }
            : {
                lastName,
                firstName,
                secondName: null,
            }),
        birthday: convertDateFromStateToISO(birthday),
    };
};
export function containsLatin(str) {
    const latinPattern = /[A-Za-z]/;
    return latinPattern.test(str);
}
export const formatNameOnBlur = (value) => value?.trim().split(' ').filter(Boolean).join(' ');
