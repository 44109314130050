import { createUseStyles } from 'react-jss';
import { withAlpha } from '@/styles/utils';
import { mixins } from '@/styles/mixins';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
import { HEADER_HEIGHT } from '../Header/styles';
import { EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT } from './consts';
const MOBILE_FORM_PADDING = 12;
export const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: theme.colors.background,
        position: 'relative',
        overflow: 'auto',
        paddingTop: HEADER_HEIGHT,
        [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {
            backgroundColor: theme.colors.cloud,
            padding: 0,
        },
    },
    headerMobile: {
        position: 'fixed',
        width: '100%',
        top: 0,
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: HEADER_HEIGHT,
        background: `linear-gradient(to bottom, 
                ${withAlpha(theme.colors.cloud, 0.98)} 10%,
                ${withAlpha(theme.colors.cloud, 0.7)} 50%
            )`,
        backdropFilter: 'blur(7px)',
        padding: '0 15px',
        letterSpacing: GLOBAL_LETTER_SPACING,
        fontWeight: 500,
        color: theme.colors.sky,
        fontSize: MOBILE_FONT_SIZES.title,
        boxShadow: `0px 4px 8px 0px ${withAlpha(theme.colors.black, 0.05)}`,
        [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {
            display: 'none',
        },
    },
    headerMobileBackButton: {
        position: 'absolute',
        height: HEADER_HEIGHT - 10,
        backgroundColor: 'transparent',
        border: 'none',
        left: 0,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        padding: '15px 5px 15px 15px',
        fontSize: MOBILE_FONT_SIZES.main,
        color: theme.colors.brand,
        letterSpacing: GLOBAL_LETTER_SPACING,
        fontWeight: 400,
        '-webkit-tap-highlight-color': 'transparent',
        '-webkit-user-select': 'none',
        '& svg > path': {
            stroke: theme.colors.brand,
        },
    },
    body: {
        padding: '15px 15px 50px',
        width: '100%',
        [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {
            backgroundColor: theme.colors.cloud,
            padding: 0,
        },
    },
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 30,
        padding: MOBILE_FORM_PADDING,
        borderRadius: 12,
        backgroundColor: theme.colors.cloud,
        boxShadow: `0px 4px 30px 0px ${withAlpha(theme.colors.black, 0.05)}`,
        [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {},
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 6,
        flexDirection: 'row-reverse',
        '& h1': {
            color: theme.colors.sky,
            textAlign: 'start',
            marginBottom: 0,
        },
        [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {
            display: 'grid',
            gridTemplateColumns: '1fr 2fr 1fr',
            '& h1': {
                textAlign: 'center',
            },
        },
    },
    desktopCloseButtonWrapper: {
        display: 'none',
        [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: -8,
        },
    },
    emojisBlock: {
        overflow: 'scroll',
        margin: `-10px -${MOBILE_FORM_PADDING}px`,
        padding: `10px 0`,
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '&[data-animation-completed="true"]': {
            scrollSnapType: 'x proximity',
        },
    },
    emojisContainer: {
        display: 'flex',
        width: 'max-content',
    },
    emojiButtonWrapper: {
        scrollSnapAlign: 'start',
        paddingLeft: MOBILE_FORM_PADDING,
        '&:last-child': {
            paddingRight: MOBILE_FORM_PADDING,
        },
        '& button': {
            '-webkit-tap-highlight-color': 'transparent',
            '-webkit-user-select': 'none',
            userSelect: 'none',
        },
    },
    emojiButton: {
        width: 60,
        height: 60,
        borderRadius: 100,
        backgroundColor: theme.colors.brandTertiary,
        border: `3px solid ${theme.colors.brandTertiary}`,
        ...mixins.centeredContent(),
        transition: theme.transition.global,
        '-webkit-tap-highlight-color': 'transparent',
        '-webkit-user-select': 'none',
        '& img': {
            transition: theme.transition.global,
            width: 32,
            height: 32,
            '-webkit-touch-callout': 'none',
            '-webkit-user-select': 'none',
            userSelect: 'none',
            pointerEvents: 'none',
        },
        '&[data-active="true"]': {
            borderColor: {
                light: '#C1C3FF',
                dark: withAlpha(theme.colors.brand, 0.7),
            }[theme.mode],
            backgroundColor: {
                light: '#E6EAFC',
                dark: withAlpha(theme.colors.brand, 0.3),
            }[theme.mode],
            cursor: 'default',
            '&:hover': {
                transform: 'scale(1)',
            },
            '& img': {
                width: 35,
                height: 35,
            },
        },
        '&[data-blured="true"]': {
            [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {
                transition: `${theme.transition.global}, opacity 0.3s ease-in-out`,
                opacity: 0.6,
            },
        },
        [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {
            '&:hover': {
                transform: 'scale(1.1)',
            },
        },
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 7,
        [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {
            display: 'grid',
            gap: '12px 6px',
            gridTemplateAreas: `
                    "lastNameRu firstNameRu secondNameRu"
                    ".          .           hasSecondName"
                    "lastName   firstName   ."
                    "birthday   gender      ."
                `,
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
    },
    lastNameRu: { gridArea: 'lastNameRu' },
    firstNameRu: { gridArea: 'firstNameRu' },
    secondNameRu: { gridArea: 'secondNameRu' },
    lastName: { gridArea: 'lastName' },
    firstName: {
        gridArea: 'firstName',
        [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {
            maxWidth: 240,
        },
    },
    hasSecondName: {
        gridArea: 'hasSecondName',
        margin: '6px 0 8px',
        height: 30,
        [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {
            margin: 0,
            height: 20,
            maxHeight: 20,
            display: 'flex',
            alignItems: 'center',
        },
    },
    gender: {
        gridArea: 'gender',
        [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {
            maxWidth: 240,
        },
    },
    birthday: { gridArea: 'birthday' },
    genderButtonsWrapper: {
        display: 'flex',
    },
    submitButtonWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& #input-error': {
            fontSize: MOBILE_FONT_SIZES.main,
            marginLeft: 0,
            [theme.breakpoints.up(EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
            '&[data-error="true"]': {
                marginBottom: 10,
            },
        },
    },
    submitButtonIconWrapper: {
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    submitButtonCheckIconWrapper: {
        height: 24,
        width: 24,
        borderRadius: 100,
        border: `${theme.colors.white} 2px solid`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&[data-deletion-appearance="true"]': {
            borderColor: theme.colors.error,
        },
    },
    deleteModalTitleIcon: {
        width: 64,
        height: 64,
        backgroundColor: '#F4F5F7',
        borderRadius: 100,
        marginBottom: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            width: 36,
            height: 36,
            '-webkit-touch-callout': 'none',
            '-webkit-user-select': 'none',
            userSelect: 'none',
            pointerEvents: 'none',
        },
    },
    deleteModalTitleName: {
        fontSize: MOBILE_FONT_SIZES.hintModalTitle,
        fontWeight: 500,
        letterSpacing: GLOBAL_LETTER_SPACING,
        color: theme.colors.error,
        marginBottom: 28,
    },
    deleteModalTitle: {
        fontWeight: 700,
        fontFamily: theme.font.secondary,
        letterSpacing: '0.45px',
        marginBottom: '6px',
        fontSize: MOBILE_FONT_SIZES.hintModalTitle,
        [theme.breakpoints.up('xl')]: {
            fontSize: DEKSTOP_FONT_SIZES.hintModalTitle,
        },
    },
    deleteModalHintText: {
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
}), { name: 'edit-person-modal' });
