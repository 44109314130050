import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Accordion } from '@che-ins-ui/accordion';
import { CheckIcon } from '@che-ins-ui/icons/CheckIcon';
import { activeRestServicesCodes, mainServicesCodes, popularServicesCodes, additionalServicesCodes, MEDICINE_CODE, workAndEducationServicesCodes, } from '@/libs/static';
import { CURRENCIES_BADGES, MAXIMUM_VISIBLE_SERVICE } from '@/libs/constants';
import { useCheTheme } from '@/styles/themes';
import { ServiceType } from '@/libs/types/global';
import { apiServicesSelector, apiSportsSelector } from '@/models/api/selectors';
import { requestCurrencySelector } from '@/models/request/selectors';
import { useStyles } from '../styles';
import ServiceView from './service';
import Franchise from './franchise';
const ServiceList = ({ services, product, sports, isDesktopView, withTitle, isExpanded, }) => {
    const apiServices = useSelector(apiServicesSelector);
    const apiSports = useSelector(apiSportsSelector);
    const currency = useSelector(requestCurrencySelector);
    const classes = useStyles();
    const theme = useCheTheme();
    const { calculation, lowerSumServices, missingServices } = product;
    const currencyBadge = CURRENCIES_BADGES[currency];
    const { fullCoverageServices, partialCoverageServices, notCoveredServices, notVisiblePartialCoverageServices, notVisibleFullCoverageServices, notVisibleNotCoveredServices, } = useMemo(() => {
        return [
            ...popularServicesCodes,
            MEDICINE_CODE,
            ...mainServicesCodes,
            ...activeRestServicesCodes,
            ...additionalServicesCodes,
            ...workAndEducationServicesCodes,
        ].reduce((acc, serviceCode) => {
            const isServiceInProduct = serviceCode in calculation.serviceProduct &&
                Number(calculation.serviceProduct[serviceCode]) > 0;
            const isServiceInLowerSums = lowerSumServices.length > 0 &&
                lowerSumServices.filter(({ code }) => code === serviceCode)
                    .length > 0;
            const isServiceInMissing = missingServices.length > 0 &&
                missingServices.filter(({ code }) => code === serviceCode)
                    .length > 0;
            const isServiceInRequest = serviceCode in services;
            const service = apiServices.find((apiService) => apiService.code === serviceCode);
            const visibleServiceSum = acc.fullCoverageServices.length +
                acc.partialCoverageServices.length +
                acc.notCoveredServices.length;
            const isNotVisibleService = visibleServiceSum >= MAXIMUM_VISIBLE_SERVICE - 1;
            if (service === undefined) {
                return acc;
            }
            if (isServiceInProduct) {
                isNotVisibleService
                    ? acc.notVisibleFullCoverageServices.push(service)
                    : acc.fullCoverageServices.push(service);
                return acc;
            }
            if (isServiceInLowerSums) {
                isNotVisibleService
                    ? acc.notVisiblePartialCoverageServices.push(service)
                    : acc.partialCoverageServices.push(service);
                return acc;
            }
            if (isServiceInMissing) {
                isNotVisibleService
                    ? acc.notVisibleNotCoveredServices.push(service)
                    : acc.notCoveredServices.push(service);
                return acc;
            }
            return acc;
        }, {
            fullCoverageServices: [],
            partialCoverageServices: [],
            notCoveredServices: [],
            notVisibleServices: [],
            notVisibleFullCoverageServices: [],
            notVisiblePartialCoverageServices: [],
            notVisibleNotCoveredServices: [],
        });
    }, [product, apiServices]);
    const sportNames = useMemo(() => {
        return apiSports
            .filter((apiSport) => sports.includes(apiSport.code))
            .map(({ isCompetition, name }) => {
            return isCompetition ? `${name} (соревнования)` : name;
        });
    }, [sports, apiSports]);
    const servicesClassName = classNames(classes.services, isDesktopView && classes.servicesDesktop);
    const extraServicesComponent = useMemo(() => {
        return (_jsxs(_Fragment, { children: [notVisibleFullCoverageServices.map(({ name, description, code }) => (_jsx(ServiceView, { serviceName: name, type: ServiceType.FULL, serviceCode: code, serviceDescription: description, currency: currencyBadge, serviceValue: calculation.serviceProduct[code], isDesktopView: isDesktopView }, code))), notVisiblePartialCoverageServices.map(({ name, description, code }) => (_jsx(ServiceView, { serviceName: name, type: ServiceType.PARTIAL, serviceCode: code, serviceDescription: description, currency: currencyBadge, serviceValue: calculation.serviceProduct[code], isDesktopView: isDesktopView }, code))), notVisibleNotCoveredServices.map(({ name, description, code }) => (_jsx(ServiceView, { serviceName: name, type: ServiceType.NOTCOVERED, serviceCode: code, serviceDescription: description, currency: currencyBadge, serviceValue: calculation.serviceProduct[code], isDesktopView: isDesktopView }, code)))] }));
    }, [
        notVisibleFullCoverageServices,
        notVisiblePartialCoverageServices,
        notVisibleNotCoveredServices,
        currencyBadge,
        calculation,
        isDesktopView,
    ]);
    const sportsComponent = useMemo(() => {
        return (_jsx(_Fragment, { children: sportNames.length > 0 && (_jsxs("div", { className: classes.sports, children: [_jsx(CheckIcon, { fill: theme.colors.success, className: classes.serviceIcon }), _jsx("span", { className: classes.serviceName, children: "\u0417\u0430\u043D\u044F\u0442\u0438\u044F \u0441\u043F\u043E\u0440\u0442\u043E\u043C:" }), _jsx("span", { className: classes.sportValues, children: sportNames.join(', ').replace(/,\s*$/, '') })] })) }));
    }, [sportNames, theme, classes]);
    return (_jsxs("div", { className: classes.serviceBox, children: [withTitle && _jsx("div", { className: classes.optionsTitle, children: "\u041E\u043F\u0446\u0438\u0438" }), _jsxs("div", { className: servicesClassName, children: [_jsx(Franchise, { calculation: calculation, className: classNames(classes.service, isDesktopView && classes.serviceDesktop) }), fullCoverageServices.length > 0 &&
                        fullCoverageServices.map(({ name, description, code }) => (_jsx(ServiceView, { serviceName: name, type: ServiceType.FULL, serviceCode: code, serviceDescription: description, serviceValue: calculation.serviceProduct[code], currency: currencyBadge, isDesktopView: isDesktopView }, code))), partialCoverageServices.length > 0 &&
                        partialCoverageServices.map(({ name, description, code }) => (_jsx(ServiceView, { serviceName: name, type: ServiceType.PARTIAL, serviceCode: code, serviceDescription: description, currency: currencyBadge, serviceValue: calculation.serviceProduct[code], isDesktopView: isDesktopView }, code))), notCoveredServices.length > 0 &&
                        notCoveredServices.map(({ name, description, code }) => (_jsx(ServiceView, { serviceName: name, type: ServiceType.NOTCOVERED, serviceCode: code, serviceDescription: description, currency: currencyBadge, serviceValue: calculation.serviceProduct[code], isDesktopView: isDesktopView }, code))), !isDesktopView && extraServicesComponent] }), !isDesktopView && sportsComponent, isDesktopView &&
                !![
                    ...notVisibleFullCoverageServices,
                    ...notVisiblePartialCoverageServices,
                    ...notVisibleNotCoveredServices,
                    ...sportNames,
                ].length && (_jsxs(Accordion, { appearance: "services", shouldFitContent: true, titleButtonProps: {
                    baseAppearance: 'text',
                    appearance: 'toggle-show-services',
                    shouldFitContent: false,
                    disabled: isExpanded,
                }, isOpen: isExpanded, title: isExpanded
                    ? null
                    : ({ isOpen }) => isOpen ? 'Скрыть' : 'Показать все', children: [_jsx("div", { className: servicesClassName, children: extraServicesComponent }), sportsComponent] }))] }));
};
export default memo(ServiceList);
