import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useScrollbarWidth, useTitle } from 'react-use';
import { Container } from '@che-ins-ui/container';
import { useSelector } from 'react-redux';
import { isSomeModalOpenSelector } from '@che-ins-ui/modal';
import FiltersPanel from '@/components/filters/Filters';
import { Showcase } from '@/components/Showcase/Showcase';
import { useIsMobileFilters } from '@/libs/hooks';
import { useInitCurrency } from '@/libs/hooks/useInitCurrency';
import { SelectedFilters } from '../SelectedFilters/SelectedFilters';
import { TravelForm } from '../TravelForm';
import { ShowcaseModals } from './ShowcaseModals';
import { useStyles } from './styles';
export const Calculation = memo(() => {
    const isMobileFilters = useIsMobileFilters();
    const isSomeModalOpen = useSelector(isSomeModalOpenSelector);
    const classes = useStyles();
    const scrollbarWidth = useScrollbarWidth();
    useInitCurrency();
    useTitle('Расчёт стоимости страховки');
    if (isMobileFilters) {
        return _jsx(FiltersPanel, {});
    }
    return (_jsxs("div", { style: {
            paddingRight: isSomeModalOpen ? scrollbarWidth + 'px' : '0px',
            width: '100%',
        }, children: [_jsx(TravelForm, {}), _jsx(Container, { baseAppearance: "page", children: _jsxs("div", { style: { width: '100%' }, children: [_jsx(SelectedFilters, {}), _jsxs("div", { className: classes.container, children: [_jsx("aside", { className: classes.aside, children: _jsx(FiltersPanel, {}) }), _jsx("main", { className: classes.main, children: _jsx(Showcase, {}) })] })] }) }), _jsx(ShowcaseModals, {})] }));
});
