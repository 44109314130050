import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TechnicalPage } from '@che-ins-ui/technical-pages';
import { useTitle } from 'react-use';
import { Footer } from '../common/Footer';
import { Header } from '../common/Header';
import { useStyles } from './styles';
export const PageNotFound = () => {
    const classes = useStyles();
    useTitle('Страница не найдена');
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsx("div", { className: classes.componentWrapper, children: _jsx(TechnicalPage, { locale: "ru", pageType: "page404", homepageUrl: __HOMEPAGE__ }) }), _jsx(Footer, {})] }));
};
