import { createSelector } from '@reduxjs/toolkit';
import { DEFAULT_ORDER_STATUS, PROCESS_STATE_NAMESPACE, orderStatuses, } from './consts';
export const processSelector = (state) => state[PROCESS_STATE_NAMESPACE];
export const emailConfirmationStatusSelector = createSelector(processSelector, ({ emailConfirmationStatus }) => emailConfirmationStatus);
export const isLoadingPaymentSelector = createSelector(processSelector, ({ isLoadingPayment }) => isLoadingPayment);
export const isOrderCreatingSelector = createSelector(processSelector, ({ isOrderCreating }) => isOrderCreating);
export const codeSelector = createSelector(processSelector, ({ code }) => code);
export const orderProgressSelector = createSelector(processSelector, ({ order }) => order?.progress);
export const orderStatusSelector = createSelector(processSelector, ({ order }) => {
    const status = order?.status;
    return status && orderStatuses.includes(status)
        ? status
        : DEFAULT_ORDER_STATUS;
});
export const orderPoliciesSelector = createSelector(processSelector, ({ order }) => order?.policies);
export const orderUuidSelector = createSelector(processSelector, ({ order }) => order?.uuid);
