import { BREAKPOINTS } from '../breakpoints';
import { useGlobalStyles } from '../global';
import { Z_INDEX_MAP } from '../zIndexMap';
import { convertPxToRem, TYPOGRAPHY } from './components';
export class AbstractTheme {
    constructor() {
        this.breakpoints = BREAKPOINTS;
        this.zIndex = Z_INDEX_MAP;
        this.font = TYPOGRAPHY;
        this.headerHeight = 52;
        this.transition = {
            duration: 300,
            global: 'all .2s ease',
        };
        this.useGlobalStyles = useGlobalStyles;
        this.rem = (size) => convertPxToRem(size);
    }
}
