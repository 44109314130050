import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { PhoneInputControlled as PhoneInput } from '@che-ins-ui/phone-input';
import { InputError } from '@/components/common/InputError';
import { insurerFormsSelector, isFormDirtySelector, } from '@/models/forms/selectors';
import { formsActions } from '@/models/forms';
import { InsurerField } from '@/models/forms/interface';
import { setPhone } from '@/models/forms/setPhone';
import { useStyles as useStylesWrapper } from './styles';
const field = InsurerField.phoneBody;
export const FormFieldPhoneInput = () => {
    const classesWrapper = useStylesWrapper();
    const dispatch = useDispatch();
    const isFormDirty = useSelector(isFormDirtySelector);
    const { phoneBody, phoneCountryCode, fieldsDirty, fieldsErrors } = useSelector(insurerFormsSelector);
    const isDirtyPhone = fieldsDirty[field];
    const phoneError = fieldsErrors[field];
    const phoneErrorMessage = (isFormDirty || isDirtyPhone) && phoneError;
    return (_jsxs(motion.div, { className: classesWrapper.insurerFieldWrapper, layout: true, transition: { duration: 0.15 }, children: [_jsx(PhoneInput, { name: "phone-input", countryCode: phoneCountryCode, setCountryCode: (value) => {
                    dispatch(formsActions.setPhoneCountryCode(value));
                }, phoneBody: phoneBody, setPhoneBody: (value) => {
                    dispatch(setPhone(value));
                }, isDirty: isDirtyPhone, onBlur: () => {
                    dispatch(formsActions.setInsurerFieldDirty(field));
                }, setError: () => { }, invalid: Boolean(phoneErrorMessage) }), _jsx(InputError, { error: phoneErrorMessage })] }));
};
