import { CURRENCIES_BADGES } from '@/libs/constants';
import { declOfNum } from '@/libs/helpers';
import { getKeys, separatePriceBySpaces } from '@/libs/helpers/utils';
import { ASSISTANCE_CODE, FRANCHISE_CODE, getTypes, MEDICINE_CODE, MULTIPOLICY_CODE, PREGNANCY_CODE, } from '@/libs/static';
import { allComparingServices, comparingAdditionalDictionary, comparingServicesNames, } from '@/models/comparing/consts';
const getCompanyInfo = (company) => {
    const { id, name, linkToLogo } = company;
    return { id, name, linkToLogo };
};
const formatePostfix = (serviceCode, value, currency) => {
    if (serviceCode === PREGNANCY_CODE) {
        return `До ${value} нед.`;
    }
    if (serviceCode === MULTIPOLICY_CODE) {
        if (typeof value === 'number') {
            return `${value} ${declOfNum(value, getTypes('DAY'))}`;
        }
        return 'Включено';
    }
    return separatePriceBySpaces(value) + ' ' + CURRENCIES_BADGES[currency];
};
const getComparingOffer = ({ companyId, companies, matchedOffers, servicesDictionary, currency, }) => {
    try {
        const company = companies.find((company) => company.id === companyId);
        if (!company) {
            return null;
        }
        const companyInfo = getCompanyInfo(company);
        const offer = [...matchedOffers].find((offer) => offer.company.id === companyId);
        if (!offer) {
            return { company: companyInfo };
        }
        const { priceRub, matchDetails, productId, serviceProduct, info, assistances, } = offer?.calculation;
        const { missingServices: missingServicesCount } = matchDetails;
        const isFullMatching = missingServicesCount === 0;
        const assistance = assistances[0]?.name || 'Неизвестно';
        const services = [
            { service: 'Ассистанс', postfix: assistance },
        ];
        const missingServicesCodes = offer?.missingServices?.map(({ code }) => ({
            service: servicesDictionary?.[code].name,
            postfix: 'Отсутствует',
        }));
        const missingServices = [
            ...missingServicesCodes,
        ].filter(({ service }) => service !== 'Без франшизы');
        const franchise = info?.[MEDICINE_CODE]?.find(({ type }) => type === FRANCHISE_CODE)?.value;
        const limitations = [];
        for (const key in info) {
            for (const item of info[key]) {
                if (item.type === 'limitation') {
                    limitations.push(item.value);
                }
            }
        }
        const franchiseItem = {
            service: 'Франшиза',
            description: comparingAdditionalDictionary[FRANCHISE_CODE],
        };
        if (franchise) {
            missingServices.push({
                ...franchiseItem,
                postfix: franchise + ' ' + CURRENCIES_BADGES[currency],
            });
        }
        else {
            services.push({ ...franchiseItem, postfix: 'Нет' });
        }
        getKeys(serviceProduct).forEach((serviceCode) => {
            const value = serviceProduct[serviceCode];
            if (value === 0) {
                return;
            }
            const dictionary = servicesDictionary?.[serviceCode];
            const service = dictionary?.name || serviceCode;
            const postfix = value !== 1
                ? formatePostfix(serviceCode, value, currency)
                : 'Включено';
            services.push({ service, postfix });
        });
        return {
            company: companyInfo,
            info: {
                price: priceRub,
                servicesCount: matchDetails.totalServices - 9,
                productId,
                services,
                missingServices,
                missingServicesCount,
                limitations,
            },
            matchedType: isFullMatching ? 'full' : 'partial',
        };
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
export const getComparingOffers = ({ comparingIds, companies, matchedOffers, servicesDictionary, currency, }) => comparingIds.reduce((acc, id) => {
    const offer = getComparingOffer({
        companyId: id,
        companies,
        matchedOffers,
        servicesDictionary,
        currency,
    });
    if (offer && 'matchedType' in offer) {
        acc.push(offer);
    }
    return acc;
}, []);
export const filterRepeatingServices = (offers) => {
    const checkedServicesItems = new Map();
    const checkedMissingServicesItems = new Map();
    const splitter = '%%';
    const checkServices = (offer, services, checkedItems, isService) => services?.map((serviceItem) => {
        const { service, postfix = '' } = serviceItem;
        const key = service + splitter + postfix;
        if (checkedItems.has(key)) {
            return checkedItems.get(key)
                ? { ...serviceItem, isRepeating: true }
                : serviceItem;
        }
        let isPresentInAll = true;
        for (const otherOffer of offers) {
            if (otherOffer !== offer) {
                const comparableServices = isService
                    ? otherOffer.info.services
                    : otherOffer.info.missingServices;
                const isFound = comparableServices.some(({ service, postfix }) => {
                    const currentKey = service + splitter + (postfix || '');
                    return currentKey === key;
                });
                if (!isFound) {
                    isPresentInAll = false;
                    break;
                }
            }
        }
        checkedItems.set(key, isPresentInAll);
        return isPresentInAll
            ? { ...serviceItem, isRepeating: true }
            : serviceItem;
    });
    const areMultipleOffers = offers.length > 1;
    return {
        offers: areMultipleOffers
            ? offers.map((offer) => ({
                ...offer,
                info: {
                    ...offer.info,
                    services: checkServices(offer, offer.info.services, checkedServicesItems, true),
                    missingServices: checkServices(offer, offer.info.missingServices, checkedMissingServicesItems, false),
                },
            }))
            : offers,
        repeatingServices: areMultipleOffers
            ? [
                ...checkedServicesItems.entries(),
                ...checkedMissingServicesItems.entries(),
            ]
                .filter(([, value]) => value)
                .map(([key]) => key.split(splitter)[0])
            : [],
    };
};
export const getAllServices = (offers) => {
    const allKeys = offers.flatMap((offer) => [
        ...offer.info.services.map((serviceItem) => serviceItem.service),
        ...offer.info.missingServices.map((serviceItem) => serviceItem.service),
    ]);
    return Array.from(new Set(allKeys)).reduce((sortedArray, serviceName) => {
        const index = comparingServicesNames.indexOf(serviceName);
        if (index !== -1) {
            let insertionIndex = sortedArray.findIndex((item) => comparingServicesNames.indexOf(item) > index);
            if (insertionIndex === -1) {
                sortedArray.push(serviceName);
            }
            else {
                sortedArray.splice(insertionIndex, 0, serviceName);
            }
        }
        return sortedArray;
    }, []);
};
export const getMatchingOffersAmount = (comparingIds, offers) => offers?.filter((offer) => comparingIds.includes(offer.company.id)).length;
export const getMatchingOffersLogos = (comparingIds, offers) => comparingIds.reduce((acc, id) => {
    const offer = offers.find((offer) => offer.company.id === id);
    if (offer) {
        acc.unshift(offer.company.linkToLogo);
    }
    return acc;
}, []);
export const getServicesDescription = (services, servicesDictionary) => {
    const nameToKey = {
        ...Object.keys(servicesDictionary).reduce((acc, key) => {
            acc[servicesDictionary[key].name] = key;
            return acc;
        }, {}),
        [allComparingServices[FRANCHISE_CODE]]: FRANCHISE_CODE,
        [allComparingServices[ASSISTANCE_CODE]]: ASSISTANCE_CODE,
    };
    return services.map((service) => {
        const key = nameToKey[service];
        const description = comparingAdditionalDictionary?.[key] ||
            servicesDictionary[key]?.description;
        if (key && description) {
            return {
                service,
                description,
            };
        }
        return { service };
    });
};
