import { TABS_PANELS_NAMES } from '../consts';
import { TouristsTab } from './TouristsTab';
import { OptionsTab } from './OptionsTab';
import { UpsellTab } from './Upsell/UpsellTab';
export const TABS = [
    {
        panelName: TABS_PANELS_NAMES.TOURISTS,
        label: 'Туристы',
        Component: TouristsTab,
    },
    {
        panelName: TABS_PANELS_NAMES.OPTIONS,
        label: 'Опции',
        Component: OptionsTab,
    },
    {
        panelName: TABS_PANELS_NAMES.UPSELL,
        label: 'Рекомендации',
        Component: UpsellTab,
    },
];
