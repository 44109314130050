// Файл был пропущен через https://obfuscator.io/#code, а его исходный код внизу файла
// Файл добавлен в .eslintignore

function _0x23b0(_0x2a9b05, _0x179b8d) {
    const _0x81a005 = _0x81a0();

    return (
        (_0x23b0 = function (_0x23b08f, _0x19b47d) {
            _0x23b08f = _0x23b08f - 0x134;
            let _0xb15d9f = _0x81a005[_0x23b08f];

            return _0xb15d9f;
        }),
        _0x23b0(_0x2a9b05, _0x179b8d)
    );
}
(function (_0x14431c, _0xff43d8) {
    const _0x481977 = _0x23b0,
        _0x602cb8 = _0x14431c();
    while ([]) {
        try {
            const _0x1b2c62 =
                (parseInt(_0x481977(0x13c)) / 0x1) *
                    (-parseInt(_0x481977(0x142)) / 0x2) +
                -parseInt(_0x481977(0x13d)) / 0x3 +
                (-parseInt(_0x481977(0x13e)) / 0x4) *
                    (-parseInt(_0x481977(0x136)) / 0x5) +
                -parseInt(_0x481977(0x13a)) / 0x6 +
                -parseInt(_0x481977(0x140)) / 0x7 +
                parseInt(_0x481977(0x137)) / 0x8 +
                parseInt(_0x481977(0x134)) / 0x9;

            if (_0x1b2c62 === _0xff43d8) break;
            else _0x602cb8['push'](_0x602cb8['shift']());
        } catch (_0x4adf65) {
            _0x602cb8['push'](_0x602cb8['shift']());
        }
    }
})(_0x81a0, 0xeeb8c);
export function hashFromUuid(_0x1dd47b) {
    const _0x279cb5 = _0x23b0;
    function _0x58aedc(_0x4652d2) {
        const _0x345e51 = _0x23b0;
        let _0x3ac6a5 = 0x0;
        for (
            let _0x2fd0ff = 0x0;
            _0x2fd0ff < _0x4652d2[_0x345e51(0x135)];
            _0x2fd0ff++
        ) {
            const _0x12f144 = _0x4652d2['charCodeAt'](_0x2fd0ff);
            (_0x3ac6a5 = (_0x3ac6a5 << 0x7) - _0x3ac6a5 + _0x12f144),
                (_0x3ac6a5 &= _0x3ac6a5);
        }

        return _0x3ac6a5;
    }
    const _0x5cc14e = _0x58aedc(_0x1dd47b),
        _0x318959 = (_0x5cc14e >>> 0x0)['toString'](0x10);
    let _0x49ef3e = '';
    for (
        let _0x58baed = 0x0;
        _0x58baed < _0x318959['length'] * 0x2;
        _0x58baed++
    ) {
        const _0x2c0c95 = _0x318959[_0x279cb5(0x13b)](
                _0x58baed % _0x318959['length']
            ),
            _0x115875 =
                (((_0x2c0c95 * (_0x58baed + 0x1)) ^ (_0x58baed * 0x2a)) +
                    _0x2c0c95 * 0x3) %
                0x100;
        _0x49ef3e += String[_0x279cb5(0x138)](_0x115875);
    }
    let _0x5ca326 = '';
    for (
        let _0x4b9b29 = 0x0;
        _0x4b9b29 < _0x49ef3e[_0x279cb5(0x135)];
        _0x4b9b29++
    ) {
        const _0x25bdc4 = _0x49ef3e[_0x279cb5(0x13b)](_0x4b9b29);
        _0x5ca326 += _0x25bdc4[_0x279cb5(0x139)](0x10)['padStart'](0x2, '0');
    }
    const _0x541ef2 = ['d', 'e', '8'][_0x279cb5(0x141)](_0x5ca326[0x0]),
        _0x1ab4f4 = ['c', 'f', '5', '9'][_0x279cb5(0x141)](
            _0x5ca326[_0x5ca326[_0x279cb5(0x135)] - 0x1]
        );

    return _0x5ca326[_0x279cb5(0x13f)](
        _0x541ef2 ? 0x3 : 0x5,
        _0x1ab4f4 ? -0x6 : -0x3
    );
}
function _0x81a0() {
    const _0x20ca56 = [
        'includes',
        '1610xpxHET',
        '13579173btzibW',
        'length',
        '2313755yJVcrf',
        '3017880yTuGhE',
        'fromCharCode',
        'toString',
        '3536982hlIllE',
        'charCodeAt',
        '1189aLYjjJ',
        '589638dKYTpU',
        '16eHpGxF',
        'slice',
        '7112308AoCemZ',
    ];
    _0x81a0 = function () {
        return _0x20ca56;
    };

    return _0x81a0();
}

// function hashFromUuid(uuid: string) {
//     function enhancedHash(str: string) {
//         let hash = 0;
//         for (let i = 0; i < str.length; i++) {
//             const char = str.charCodeAt(i);
//             hash = (hash << 7) - hash + char;
//             hash &= hash;
//         }
//         return hash;
//     }

//     const hash = enhancedHash(uuid);
//     const hexHash = (hash >>> 0).toString(16);

//     let complexKey = '';
//     for (let i = 0; i < hexHash.length * 2; i++) {
//         const charCode = hexHash.charCodeAt(i % hexHash.length);
//         const complexOperation =
//             (((charCode * (i + 1)) ^ (i * 42)) + charCode * 3) % 256;
//         complexKey += String.fromCharCode(complexOperation);
//     }

//     let finalKey = '';
//     for (let i = 0; i < complexKey.length; i++) {
//         const charCode = complexKey.charCodeAt(i);
//         finalKey += charCode.toString(16).padStart(2, '0');
//     }

//     const startsWithCertainChars = ['d', 'e', '8'].includes(finalKey[0]);
//     const endsWithCertainChars = ['c', 'f', '5', '9'].includes(
//         finalKey[finalKey.length - 1]
//     );
//     return finalKey.slice(
//         startsWithCertainChars ? 3 : 5,
//         endsWithCertainChars ? -6 : -3
//     );
// }
