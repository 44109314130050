import { createAsyncThunk } from '@reduxjs/toolkit';
import { setEmail } from '@/models/forms/setEmail';
import { analytics } from '@/libs/analytics';
import { setPhone } from './setPhone';
import { formsActions } from './index';
const PHONE_EXCEPTIONS = [
    79000000000,
    79262823034,
    79992609204,
    72222222222,
];
export const setFormsFromUserInfo = createAsyncThunk('', async ({ role, phone, countryCode, email }, thunkApi) => {
    const shouldSetForms = !role || PHONE_EXCEPTIONS.includes(Number(phone));
    if (!shouldSetForms) {
        return;
    }
    const { form: { insurer: { fieldsErrors }, emails: [firstEmail], }, } = thunkApi.getState();
    if (countryCode && phone && fieldsErrors.phoneBody) {
        thunkApi.dispatch(formsActions.setPhoneCountryCode(String(countryCode)));
        thunkApi.dispatch(setPhone(phone.replace(`${countryCode}`, '')));
        analytics.sendEvent({
            action: 'vzr_forms_phone_from_user_info',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    }
    if (firstEmail.errorMessage && email) {
        thunkApi.dispatch(setEmail({ value: email, _uuid: firstEmail._uuid }));
        analytics.sendEvent({
            action: 'vzr_forms_email_from_user_info',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    }
});
