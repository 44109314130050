import { createUseStyles } from 'react-jss';
import { BLOCK_BOX_SHADOW, DESKTOP_FILTERS_BREAKPOINT, DESKTOP_SIZES_BREAKPOINT, TABLET_SEARCH_FORM_BREAKPOINT, } from '@/styles/themes/components';
import { mixins } from '@/styles/mixins';
export const useStyles = createUseStyles((theme) => ({
    wrapper: {
        rowGap: '24px',
        gridTemplateColumns: 'minmax(290px, 100%)',
        display: 'grid',
        width: '100%',
        gridAutoFlow: 'row dense',
        marginBottom: '14px',
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            columnGap: '12px',
            gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))',
        },
        [theme.breakpoints.up(TABLET_SEARCH_FORM_BREAKPOINT)]: {
            marginBottom: 'unset',
        },
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(292px, 1fr))',
        },
    },
    card: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: 34,
        alignSelf: 'stretch',
        borderRadius: '12px',
        border: '2px solid transparent',
        background: theme.colors.cloud,
        boxShadow: BLOCK_BOX_SHADOW,
        cursor: 'pointer',
        position: 'relative',
        '&[data-checked="true"]': {
            border: `2px solid ${theme.colors.brand}`,
            boxShadow: 'none',
        },
        transitionDuration: '100ms',
        transitionTimingFunction: 'ease-in-out',
        [theme.breakpoints.up(TABLET_SEARCH_FORM_BREAKPOINT)]: {
            '&:hover': {
                transform: 'scale(1.02)',
            },
        },
        '& > *': {
            userSelect: 'none',
            WebkitUserSelect: 'none',
        },
    },
    header: {
        height: '27px',
        width: '100%',
    },
    company: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'absolute',
        top: 16,
        left: 16,
    },
    checkedIcon: {
        position: 'absolute',
        top: 16,
        right: 16,
    },
    companyLogo: {
        ...mixins.square(32),
    },
    companyName: {
        color: theme.colors.sky,
        fontFamily: theme.font.primary,
        fontSize: theme.rem(13),
        fontWeight: 500,
        lineHeight: 1.3846,
        letterSpacing: -0.3,
    },
    text: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 8,
        alignSelf: 'stretch',
        textWrap: 'balance',
    },
    title: {
        alignSelf: 'stretch',
        color: theme.colors.sky,
        textAlign: 'center',
        fontFamily: theme.font.secondary,
        fontSize: theme.rem(16),
        fontWeight: 700,
        lineHeight: 1.375,
        letterSpacing: 0.45,
    },
    subTitle: {
        width: 238,
        color: theme.colors.textPrimary,
        textAlign: 'center',
        fontFamily: theme.font.primary,
        fontSize: theme.rem(15),
        fontWeight: 400,
        lineHeight: 1.2667,
        letterSpacing: -0.3,
        [theme.breakpoints.up(TABLET_SEARCH_FORM_BREAKPOINT)]: {
            fontSize: theme.rem(13),
            lineHeight: 1.3846,
            width: 250,
        },
    },
    imageWrapper: {
        width: '100%',
        height: '157px',
        ...mixins.centeredContent(),
    },
    image: {
        alignSelf: 'center',
    },
    footer: {
        ...mixins.centeredContent(),
        flexDirection: 'column',
        gap: 4,
        alignSelf: 'stretch',
    },
    price: {
        color: theme.colors.brand,
        textAlign: 'center',
        fontFamily: theme.font.secondary,
        fontSize: theme.rem(28),
        fontWeight: 700,
        letterSpacing: 0.7,
        lineHeght: 1.3571,
    },
    compensation: {
        color: theme.colors.textPrimary,
        textAlign: 'center',
        fontFamily: theme.font.primary,
        fontSize: theme.rem(13),
        fontWeight: 400,
        lineHeight: 1.3846,
        letterSpacing: -0.3,
    },
    details: {
        color: theme.colors.brand,
        textAlign: 'center',
        fontSize: theme.rem(15),
        fontWeight: 400,
        lineHeight: 1.2667,
        letterSpacing: -0.3,
        [theme.breakpoints.up(TABLET_SEARCH_FORM_BREAKPOINT)]: {
            fontSize: theme.rem(13),
            lineHeight: 1.3846,
        },
    },
    buyBlock: {
        position: 'sticky',
        bottom: '15px',
        margin: 'auto',
        zIndex: 1,
        width: '100%',
        [theme.breakpoints.up(TABLET_SEARCH_FORM_BREAKPOINT)]: {
            position: 'relative',
            width: '100%',
            marginTop: '42px',
        },
    },
    terms: {
        display: 'none',
        [theme.breakpoints.up(TABLET_SEARCH_FORM_BREAKPOINT)]: {
            display: 'block',
        },
    },
}), { name: 'upsell' });
