import { capitalize, lowercaseExceptFirstAndAfterSpaceOrDash, } from '@/libs/helpers';
import { getKeys } from '@/libs/helpers/utils';
import { isAboveMinInsurerAge, validateDate, } from '@/libs/validation/validateDate';
import { InsurerField } from '../forms/interface';
import { NotebookFormFields, } from './interface';
export const transformApiPerson = ({ id, emoji, firstName, firstNameRu, lastName, lastNameRu, secondNameRu, birthday, isMan, }) => {
    const parsedBirthday = birthday?.split('-').reverse().join('.');
    const capitalizeName = (name) => name
        ? capitalize(lowercaseExceptFirstAndAfterSpaceOrDash(name)).trim()
        : '';
    const getGender = () => {
        if (isMan === null) {
            return null;
        }
        return isMan ? 'male' : 'female';
    };
    const { error: birthdayError } = validateDate(parsedBirthday);
    return {
        id: String(id),
        emoji,
        firstName: capitalizeName(firstName),
        firstNameRu: capitalizeName(firstNameRu),
        lastName: capitalizeName(lastName),
        lastNameRu: capitalizeName(lastNameRu),
        secondNameRu: capitalizeName(secondNameRu),
        birthday: birthdayError ? '' : parsedBirthday,
        gender: getGender(),
    };
};
const personFieldsToCompare = [
    'firstName',
    'lastName',
    'birthday',
    'lastNameRu',
    'firstNameRu',
    'secondNameRu',
];
export const filterUniquePersons = (persons) => {
    const uniqueMap = new Map();
    persons.forEach((person) => {
        const key = personFieldsToCompare
            .map((field) => person[field])
            .join('-');
        if (!uniqueMap.has(key)) {
            uniqueMap.set(key, person);
        }
    });
    return Array.from(uniqueMap.values());
};
export const transformFormToApiPerson = ({ firstName, lastName, lastNameRu, firstNameRu, secondNameRu, hasSecondNameRu, emoji, birthday, gender, }) => {
    const transformGender = () => {
        if (gender === null) {
            return null;
        }
        return gender === 'male';
    };
    return {
        emoji,
        firstName,
        firstNameRu,
        lastName,
        lastNameRu,
        secondNameRu: hasSecondNameRu ? secondNameRu : null,
        birthday: birthday.split('.').reverse().join('-'),
        isMan: transformGender(),
    };
};
const touristToString = ({ firstName, lastName, birthday, }) => [firstName?.trim(), birthday, lastName?.trim()].join('-').toLowerCase();
export const getTouristSuggestions = (persons, tourists) => {
    const suggestionsFromPersons = persons.reduce((acc, { id, emoji, birthday, lastName, firstName }) => {
        if (firstName && lastName) {
            acc.push({
                _uuid: id,
                emoji,
                birthday: birthday || '',
                lastName,
                firstName,
                firstNameRu: '',
                lastNameRu: '',
                secondNameRu: '',
                fromNotebook: true,
            });
        }
        return acc;
    }, []);
    if (suggestionsFromPersons.length === 0) {
        return [];
    }
    const touristsConvertedToString = tourists.map(touristToString);
    return suggestionsFromPersons.filter((suggestion) => !touristsConvertedToString.includes(touristToString(suggestion)));
};
export const getInsurerSuggestions = (persons, insurer, tourists) => {
    const suggestionsFromPersons = persons.reduce((acc, { id, emoji, birthday, lastName, firstName, firstNameRu, lastNameRu, secondNameRu, }) => {
        const { error: birthdayError } = validateDate(birthday, {
            isInsurer: true,
        });
        if (firstName && lastName && !birthdayError) {
            acc.push({
                _uuid: id,
                emoji,
                birthday,
                lastName,
                firstName,
                firstNameRu,
                lastNameRu,
                secondNameRu,
                fromNotebook: true,
            });
        }
        return acc;
    }, []);
    const suggestionsConvertedToString = suggestionsFromPersons.map(touristToString);
    const suggestionsFromTourists = tourists.reduce((accumulator, { _uuid, birthday, lastName, firstName, fieldsErrors }) => {
        const touristString = touristToString({
            firstName,
            lastName,
            birthday,
        });
        if (!suggestionsConvertedToString.includes(touristString) &&
            !fieldsErrors.birthday &&
            !fieldsErrors.lastName &&
            !fieldsErrors.firstName &&
            isAboveMinInsurerAge(birthday)) {
            accumulator.push({
                _uuid,
                emoji: null,
                birthday,
                lastName,
                firstName,
                firstNameRu: '',
                lastNameRu: '',
                secondNameRu: '',
                fromNotebook: false,
            });
        }
        return accumulator;
    }, []);
    const insurerConvertedToString = touristToString(insurer);
    return [...suggestionsFromTourists, ...suggestionsFromPersons].filter((suggestion) => touristToString(suggestion) !== insurerConvertedToString);
};
export const getInsurerSuggestionsInRussian = (persons, insurer) => {
    const insurerInRussianToString = ({ firstNameRu, lastNameRu, secondNameRu, birthday, }) => [firstNameRu, birthday, lastNameRu, secondNameRu]
        .join('-')
        .toLowerCase();
    const insurerConvertedToString = insurerInRussianToString(insurer);
    return persons.reduce((acc, { id, emoji, birthday, lastName, firstName, lastNameRu, firstNameRu, secondNameRu, }) => {
        if (firstNameRu &&
            lastNameRu &&
            !validateDate(birthday, { isInsurer: true }).error &&
            insurerConvertedToString !==
                insurerInRussianToString({
                    firstNameRu,
                    lastNameRu,
                    secondNameRu,
                    birthday,
                })) {
            acc.push({
                _uuid: id,
                emoji,
                birthday,
                lastName,
                firstName,
                lastNameRu,
                firstNameRu,
                secondNameRu,
                fromNotebook: true,
            });
        }
        return acc;
    }, []);
};
export const getDeepCopy = (target) => JSON.parse(JSON.stringify(target));
export const getEmojiImage = (emoji) => `${__HOMEPAGE__}/files/common/emojis/${emoji}.png`;
export const getPersonDeletionModalData = (person) => {
    const { firstName, lastName, firstNameRu, lastNameRu, secondNameRu, birthday, } = person;
    const emoji = person.emoji || 'empty';
    const emojiImage = getEmojiImage(emoji);
    const name = lastNameRu && firstNameRu
        ? `${lastNameRu} ${firstNameRu} ${secondNameRu}`.trim()
        : `${lastName} ${firstName}`;
    return {
        emojiImage,
        name,
        birthday,
    };
};
export const getNotebookUsageRate = ({ persons, personsIdsUsedForTourists, personIdUsedForInsurer, insurer, touristsForms, isInsurerInRussian, }) => {
    if (persons.length === 0 ||
        (personsIdsUsedForTourists.length === 0 &&
            personIdUsedForInsurer === null)) {
        return null;
    }
    const touristsStrings = touristsForms.map(touristToString);
    let rate = 0;
    personsIdsUsedForTourists.forEach((id) => {
        const person = persons.find((person) => person.id === id);
        if (!person) {
            return;
        }
        const personString = touristToString(person);
        if (touristsStrings.includes(personString)) {
            rate += 1;
        }
    });
    if (personIdUsedForInsurer) {
        const person = persons.find((person) => person.id === personIdUsedForInsurer);
        if (person) {
            const fieldsToCompare = [
                InsurerField.birthday,
                ...(isInsurerInRussian
                    ? [InsurerField.lastNameRu, InsurerField.firstNameRu]
                    : [InsurerField.lastName, InsurerField.firstName]),
            ];
            const isTouristFromNotebook = fieldsToCompare.every((field) => person[field] === insurer[field]);
            if (isTouristFromNotebook) {
                rate += 1;
            }
        }
    }
    if (rate === 0) {
        return null;
    }
    return rate / (touristsForms.length + 1);
};
export const comparePersonWithForm = (person, formValues) => {
    const fieldsToCompare = getKeys(NotebookFormFields);
    const formFields = {
        ...formValues,
        secondNameRu: formValues.hasSecondNameRu ? formValues.secondNameRu : '',
    };
    const isFormEqualsPerson = fieldsToCompare.every((field) => formFields[field] === person[field]);
    return isFormEqualsPerson;
};
