import { createUseStyles } from 'react-jss';
import { mixins } from '@/styles/mixins';
export const useCodeInputStyles = createUseStyles((theme) => ({
    root: {
        position: 'relative',
        ...mixins.centeredContent(),
        fontSize: theme.rem(40),
        fontWeight: 400,
        width: 65,
        height: 71,
        [theme.breakpoints.up('md')]: {
            width: 62,
            height: 69,
        },
    },
    input: {
        extend: 'root',
        fontFamily: theme.font.primary,
        border: 'none',
        position: 'relative',
        textAlign: 'center',
        outline: 'none!important',
        letterSpacing: -0.3,
        color: theme.colors.textPrimary,
        caretColor: 'transparent',
        '&:disabled': {
            background: theme.colors.white,
            color: theme.colors.white,
            border: 'none',
        },
        [theme.breakpoints.up('md')]: {
            width: 62,
        },
    },
    focused: {
        color: theme.colors.brand,
    },
    valid: {
        color: theme.colors.success,
    },
    error: {
        color: theme.colors.textPrimary,
    },
    disabled: {
        color: theme.colors.white,
    },
}), { name: 'code-input' });
export const useCodeFieldsStyles = createUseStyles((theme) => ({
    '@keyframes cursorBlink': {
        '50%': {
            borderBottomColor: theme.colors.textTertiary,
        },
    },
    codeInputWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        color: theme.colors.error,
    },
    unorderedList: {
        display: 'flex',
        justifyContent: 'space-between',
        transform: 'translateY(-3px)',
        [theme.breakpoints.up('md')]: {
            transform: 'initial',
            justifyContent: 'center',
            gap: 8,
        },
    },
    codeItem: {
        marginRight: 6,
        borderBottom: `2px solid ${theme.colors.textTertiary}`,
        [theme.breakpoints.up('md')]: {
            marginRight: 8,
        },
        '&:last-of-type': {
            marginRight: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 0,
        },
    },
    isFocusedAndEmpty: {
        borderBottom: `2px solid ${theme.colors.brand}`,
        webkitAnimation: '$cursorBlink 1s infinite ease-in-out',
        animation: '$cursorBlink 1s infinite ease-in-out',
    },
    isFocusedAndFilled: {
        borderBottom: `2px solid ${theme.colors.brand}`,
    },
    isError: {
        borderBottom: `2px solid ${theme.colors.error}`,
    },
}), { name: 'code-fields' });
