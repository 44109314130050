import { TAG_COMPONENTS_NAMES } from '@che-ins-ui/tag';
import { MOBILE_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, DEKSTOP_FONT_SIZES, } from './consts';
export const getTagTheme = (theme) => ({
    base: {
        [TAG_COMPONENTS_NAMES.wrapper]: {
            borderRadius: '7px',
            background: theme.colors.textBackground,
            fontSize: MOBILE_FONT_SIZES.main,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
        [TAG_COMPONENTS_NAMES.postfix]: {
            '& svg > path': {
                fill: theme.colors.brand,
            },
        },
    },
    option: {
        [TAG_COMPONENTS_NAMES.wrapper]: {
            backgroundColor: theme.colors.textBackground,
            color: theme.colors.sky,
            marginRight: 0,
        },
    },
    optionSkeleton: {
        [TAG_COMPONENTS_NAMES.wrapper]: {
            padding: '0px',
            marginRight: 0,
        },
    },
    'card-assistance': {
        [TAG_COMPONENTS_NAMES.wrapper]: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'inline-flex',
            },
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
            [theme.breakpoints.up('xl')]: {
                display: 'inline-flex',
            },
        },
    },
    'more-options': {
        [TAG_COMPONENTS_NAMES.wrapper]: {
            cursor: 'pointer !important',
            background: theme.colors.brandTertiary,
            color: theme.colors.brand,
            margin: '0px 0px 0px 0px',
        },
    },
    insurance: {
        [TAG_COMPONENTS_NAMES.wrapper]: {
            maxWidth: '100%',
            borderRadius: '10px',
            margin: '12px 8px 0px 0px',
            padding: '10px 14px 11px 12px',
            color: theme.colors.textPrimary,
            width: '100%',
            [theme.breakpoints.up('lg')]: {
                width: 'auto',
            },
        },
        [TAG_COMPONENTS_NAMES.postfix]: {
            marginLeft: '14px',
            alignSelf: 'flex-start',
            cursor: 'pointer',
        },
    },
});
