export const travelAliases = [
    {
        name: 'Все страны Шенгена',
        search: ['все страны шенгена', 'шенген', 'schengen'],
    },
    {
        name: 'Весь мир',
        search: ['весь мир', 'world'],
    },
    {
        name: 'Весь мир, кроме России',
        search: ['весь мир, кроме россии'],
    },
    {
        name: 'Юго-Восточная Азия',
        search: ['юго-восточная азия', 'юва'],
    },
    {
        name: 'Острова Карибского бассейна',
        search: ['острова карибского бассейна'],
    },
    {
        name: 'Острова Океании',
        search: ['острова океании'],
    },
    {
        name: 'Абхазия',
        search: ['абхазия', 'abkhazia', 'ab', 'abh'],
    },
    {
        name: 'Австралия',
        search: ['австралия', 'australia', 'au', 'aus'],
    },
    {
        name: 'Австрия',
        search: ['австрия', 'austria', 'at', 'aut'],
    },
    {
        name: 'Азербайджан',
        search: ['азербайджан', 'azerbaijan', 'az', 'aze'],
    },
    {
        name: 'Албания',
        search: ['албания', 'albania', 'al', 'alb'],
    },
    {
        name: 'Алжир',
        search: ['алжир', 'algeria', 'dz', 'dza'],
    },
    {
        name: 'Ангилья',
        search: ['ангилья', 'anguilla', 'ai', 'aia'],
    },
    {
        name: 'Андорра',
        search: ['андорра', 'andorra', 'ad', 'and'],
    },
    {
        name: 'Антигуа и Барбуда',
        search: ['антигуа и барбуда', 'antigua and barbuda', 'ag', 'atg'],
    },
    {
        name: 'Аргентина',
        search: ['аргентина', 'argentina', 'ar', 'arg'],
    },
    {
        name: 'Армения',
        search: ['армения', 'armenia', 'am', 'arm'],
    },
    {
        name: 'Багамы',
        search: ['багамы', 'bahamas', 'bs', 'bhs', 'багамские острова'],
    },
    {
        name: 'Бангладеш',
        search: ['бангладеш', 'bangladesh', 'bd', 'bgd'],
    },
    {
        name: 'Барбадос',
        search: ['барбадос', 'barbados', 'bb', 'brb'],
    },
    {
        name: 'Бахрейн',
        search: ['бахрейн', 'bahrain', 'bh', 'bhr'],
    },
    {
        name: 'Белиз',
        search: ['белиз', 'belize', 'bz', 'blz'],
    },
    {
        name: 'Белоруссия',
        search: [
            'белоруссия',
            'belarus',
            'by',
            'blr',
            'беларусь',
            'белоруссия - республика беларусь',
        ],
    },
    {
        name: 'Бельгия',
        search: ['бельгия', 'belgium', 'be', 'bel'],
    },
    {
        name: 'Бенин',
        search: ['бенин', 'benin', 'bj', 'ben'],
    },
    {
        name: 'Бермуды',
        search: ['бермуды', 'bermuda', 'bm', 'bmu', 'бермудские острова'],
    },
    {
        name: 'Болгария',
        search: ['болгария', 'bulgaria', 'bg', 'bgr'],
    },
    {
        name: 'Боливия',
        search: [
            'боливия',
            'bolivia',
            'bo',
            'bol',
            'боливия многонациональное государство',
        ],
    },
    {
        name: 'Босния и Герцеговина',
        search: [
            'босния и герцеговина',
            'bosnia and herzegovina',
            'ba',
            'bih',
            'bosnia and herzegovina',
        ],
    },
    {
        name: 'Ботсвана',
        search: ['ботсвана', 'botswana', 'bw', 'bwa'],
    },
    {
        name: 'Бразилия',
        search: ['бразилия', 'рио', 'brazil', 'br', 'bra'],
    },
    {
        name: 'Бруней',
        search: ['бруней', 'brunei', 'bn', 'brn', 'бруней-даруссалам'],
    },
    {
        name: 'Буркина-Фасо',
        search: ['буркина-фасо', 'burkina faso', 'bf', 'bfa'],
    },
    {
        name: 'Бутан',
        search: ['бутан', 'bhutan', 'bt', 'btn'],
    },
    {
        name: 'Великобритания',
        search: [
            'великобритания',
            'united kingdom',
            'gb',
            'gbr',
            'uk',
            'great britain',
            'соединенное королевство',
            'англия',
            'great britain',
            'англия - великобритания',
            'британия',
        ],
    },
    {
        name: 'Венгрия',
        search: ['венгрия', 'hungary', 'hu', 'hun'],
    },
    {
        name: 'Венесуэла',
        search: [
            'венесуэла',
            'venezuela',
            've',
            'ven',
            'венесуэлла',
            'венесуэла боливарианская республика',
        ],
    },
    {
        name: 'Вьетнам',
        search: ['вьетнам', 'vietnam', 'vn', 'vnm'],
    },
    {
        name: 'Габон',
        search: ['габон', 'gabon', 'ga', 'gab'],
    },
    {
        name: 'Гаити',
        search: ['гаити', 'haiti', 'ht', 'hti'],
    },
    {
        name: 'Гайана',
        search: ['гайана', 'guyana', 'gy', 'guy'],
    },
    {
        name: 'Гамбия',
        search: ['гамбия', 'gambia', 'gm', 'gmb'],
    },
    {
        name: 'Гана',
        search: ['гана', 'ghana', 'gh', 'gha'],
    },
    {
        name: 'Гваделупа',
        search: ['гваделупа', 'guadeloupe', 'gp', 'glp'],
    },
    {
        name: 'Гватемала',
        search: ['гватемала', 'guatemala', 'gt', 'gtm'],
    },
    {
        name: 'Гвиана',
        search: [
            'гвиана',
            'guyana',
            'gf',
            'guf',
            'гвиана французская',
            'французская гвиана',
        ],
    },
    {
        name: 'Гвинея',
        search: ['гвинея', 'guinea', 'gn', 'gin'],
    },
    {
        name: 'Гвинея-Бисау',
        search: ['гвинея-бисау', 'guinea-bissau', 'gw', 'gnb'],
    },
    {
        name: 'Германия',
        search: ['германия', 'germany', 'de', 'deu'],
    },
    {
        name: 'Гондурас',
        search: ['гондурас', 'honduras', 'hn', 'hnd'],
    },
    {
        name: 'Гонконг',
        search: [
            'гонконг',
            'hong kong',
            'hk',
            'hkg',
            'hong-kong',
            'гонконг (китай)',
        ],
    },
    {
        name: 'Гренада',
        search: ['гренада', 'grenada', 'gd', 'grd'],
    },
    {
        name: 'Греция',
        search: ['греция', 'Крит', 'greece', 'gr', 'grc'],
    },
    {
        name: 'Грузия',
        search: ['грузия', 'georgia', 'ge', 'geo'],
    },
    {
        name: 'Гуам',
        search: ['гуам', 'guam', 'gu', 'gum', 'гуам (сша)'],
    },
    {
        name: 'Дания',
        search: ['дания', 'denmark', 'dk', 'dnk'],
    },
    {
        name: 'Джибути',
        search: ['джибути', 'djibouti', 'dj', 'dji'],
    },
    {
        name: 'Доминика',
        search: ['доминика', 'dominica', 'dm', 'dma'],
    },
    {
        name: 'Доминиканская Республика',
        search: ['доминиканская республика', 'dominican republic', 'do', 'dom'],
    },
    {
        name: 'Египет',
        search: ['египет', 'egypt', 'eg', 'egy'],
    },
    {
        name: 'Замбия',
        search: ['замбия', 'zambia', 'zm', 'zmb'],
    },
    {
        name: 'Зимбабве',
        search: ['зимбабве', 'zimbabwe', 'zw', 'zwe'],
    },
    {
        name: 'Израиль',
        search: ['израиль', 'israel', 'il', 'isr'],
    },
    {
        name: 'Индия',
        search: ['индия', 'india', 'in', 'ind'],
    },
    {
        name: 'Индонезия',
        search: ['индонезия', 'бали', 'indonesia', 'id', 'idn'],
    },
    {
        name: 'Иордания',
        search: ['иордания', 'jordan', 'jo', 'jor'],
    },
    {
        name: 'Иран',
        search: ['иран', 'iran', 'ir', 'irn', 'иран, исламская республика'],
    },
    {
        name: 'Ирландия',
        search: ['ирландия', 'ireland', 'ie', 'irl'],
    },
    {
        name: 'Исландия',
        search: ['исландия', 'iceland', 'is', 'isl'],
    },
    {
        name: 'Испания',
        search: ['испания', 'spain', 'es', 'esp'],
    },
    {
        name: 'Италия',
        search: ['италия', 'italy', 'it', 'ita'],
    },
    {
        name: 'Кабо-Верде',
        search: [
            'кабо-верде',
            'cape verde',
            'cv',
            'cpv',
            'кабо-верде (о-ва зеленого мыса)',
        ],
    },
    {
        name: 'Казахстан',
        search: ['казахстан', 'kazakhstan', 'kz', 'kaz'],
    },
    {
        name: 'Каймановы острова',
        search: [
            'каймановы острова',
            'cayman islands',
            'ky',
            'cym',
            'острова кайман',
            'кайман',
        ],
    },
    {
        name: 'Камбоджа',
        search: ['камбоджа', 'cambodia', 'kh', 'khm', 'cambodja'],
    },
    {
        name: 'Камерун',
        search: ['камерун', 'cameroon', 'cm', 'cmr'],
    },
    {
        name: 'Канада',
        search: ['канада', 'canada', 'ca', 'can'],
    },
    {
        name: 'Катар',
        search: ['катар', 'qatar', 'qa', 'qat', 'quatar'],
    },
    {
        name: 'Кения',
        search: ['кения', 'kenya', 'ke', 'ken'],
    },
    {
        name: 'Кипр',
        search: ['кипр', 'cyprus', 'cy', 'cyp', 'северный'],
    },
    {
        name: 'Киргизия',
        search: [
            'киргизия',
            'kyrgyzstan',
            'kg',
            'kgz',
            'киргизстан',
            'кыргыстан',
            'кыргызстан',
        ],
    },
    {
        name: 'Кирибати',
        search: ['кирибати', 'kiribati', 'ki', 'kir'],
    },
    {
        name: 'Китай',
        search: [
            'китай',
            'china',
            'cn',
            'chn',
            'китайская народная республика (кнр, китай)',
            'китай - китайская народная республика',
        ],
    },
    {
        name: 'КНДР',
        search: [
            'кндр',
            'north korea',
            'kp',
            'prk',
            'korea, d.p.r.',
            'dprk',
            'северная корея',
            'корейская народно-демократическая республика (кндр, северная корея)',
            "democratic people's republic of korea (north korea)",
            'корея, народно-демократическая респ.',
            'корея, народно - демократическая республика',
            'north korea',
            'корея, народно-демократическая республика',
        ],
    },
    {
        name: 'Кокосовые острова',
        search: [
            'кокосовые острова',
            'cocos islands',
            'cc',
            'cck',
            'кокосовые (килинг) острова',
        ],
    },
    {
        name: 'Колумбия',
        search: ['колумбия', 'colombia', 'co', 'col'],
    },
    {
        name: 'Коморы',
        search: [
            'коморы',
            'comoros',
            'km',
            'com',
            'comoro islands',
            'коморские острова',
        ],
    },
    {
        name: 'Коста-Рика',
        search: ['коста-рика', 'costa rica', 'cr', 'cri'],
    },
    {
        name: 'Кот-д’Ивуар',
        search: [
            'кот-д’ивуар',
            "cote d'ivoire",
            'ci',
            'civ',
            "кот-д'ивуар",
            'кот д ивуар',
            'cote d`ivoir',
        ],
    },
    {
        name: 'Куба',
        search: ['куба', 'cuba', 'cu', 'cub'],
    },
    {
        name: 'Кувейт',
        search: ['кувейт', 'kuwait', 'kw', 'kwt'],
    },
    {
        name: 'Лаос',
        search: [
            'лаос',
            'laos',
            'la',
            'lao',
            'lao p.d.r.',
            'lpdr',
            'лаосская народно-демократическая респ.',
            'лаосская народно-демократическая республика',
        ],
    },
    {
        name: 'Латвия',
        search: ['латвия', 'latvia', 'lv', 'lva'],
    },
    {
        name: 'Лесото',
        search: ['лесото', 'lesotho', 'ls', 'lso'],
    },
    {
        name: 'Либерия',
        search: ['либерия', 'liberia', 'lr', 'lbr'],
    },
    {
        name: 'Ливан',
        search: ['ливан', 'lebanon', 'lb', 'lbn'],
    },
    {
        name: 'Литва',
        search: ['литва', 'lithuania', 'lt', 'ltu'],
    },
    {
        name: 'Лихтенштейн',
        search: ['лихтенштейн', 'liechtenstein', 'li', 'lie'],
    },
    {
        name: 'Люксембург',
        search: ['люксембург', 'luxembourg', 'lu', 'lux'],
    },
    {
        name: 'Маврикий',
        search: ['маврикий', 'mauritius', 'mu', 'mus'],
    },
    {
        name: 'Мавритания',
        search: ['мавритания', 'mauritania', 'mr', 'mrt'],
    },
    {
        name: 'Мадагаскар',
        search: ['мадагаскар', 'madagascar', 'mg', 'mdg'],
    },
    {
        name: 'Македония',
        search: [
            'македония',
            'macedonia',
            'mk',
            'mkd',
            'македония, бывшая югославская республика',
        ],
    },
    {
        name: 'Малави',
        search: ['малави', 'malawi', 'mw', 'mwi'],
    },
    {
        name: 'Малайзия',
        search: ['малайзия', 'malaysia', 'my', 'mys'],
    },
    {
        name: 'Мали',
        search: ['мали', 'mali', 'ml', 'mli'],
    },
    {
        name: 'Мальдивы',
        search: ['мальдивы', 'maldives', 'mv', 'mdv', 'мальдивские острова'],
    },
    {
        name: 'Мальта',
        search: ['мальта', 'malta', 'mt', 'mlt'],
    },
    {
        name: 'Марокко',
        search: ['марокко', 'morocco', 'ma', 'mar', 'marocco'],
    },
    {
        name: 'Мартиника',
        search: ['мартиника', 'martinique', 'mq', 'mtq'],
    },
    {
        name: 'Маршалловы Острова',
        search: ['маршалловы острова', 'marshall islands', 'mh', 'mhl'],
    },
    {
        name: 'Мексика',
        search: ['мексика', 'mexico', 'mx', 'mex'],
    },
    {
        name: 'Мозамбик',
        search: ['мозамбик', 'mozambique', 'mz', 'moz'],
    },
    {
        name: 'Молдавия',
        search: [
            'молдавия',
            'moldova',
            'md',
            'mda',
            'молдова',
            'молдова, республика',
        ],
    },
    {
        name: 'Монголия',
        search: ['монголия', 'mongolia', 'mn', 'mng'],
    },
    {
        name: 'Мьянма',
        search: ['мьянма', 'myanmar', 'mm', 'mmr', 'бирма'],
    },
    {
        name: 'Намибия',
        search: ['намибия', 'namibia', 'na', 'nam'],
    },
    {
        name: 'Науру',
        search: ['науру', 'nauru', 'nr', 'nru'],
    },
    {
        name: 'Непал',
        search: ['непал', 'nepal', 'np', 'npl'],
    },
    {
        name: 'Нигер',
        search: ['нигер', 'niger', 'ne', 'ner'],
    },
    {
        name: 'Нигерия',
        search: ['нигерия', 'nigeria', 'ng', 'nga'],
    },
    {
        name: 'Нидерланды',
        search: [
            'нидерланды',
            'netherlands',
            'nl',
            'nld',
            'нидерланды - голландия',
        ],
    },
    {
        name: 'Никарагуа',
        search: ['никарагуа', 'nicaragua', 'ni', 'nic'],
    },
    {
        name: 'Новая Зеландия',
        search: ['новая зеландия', 'new zealand', 'nz', 'nzl', 'new zealand'],
    },
    {
        name: 'Норвегия',
        search: ['норвегия', 'norway', 'no', 'nor'],
    },
    {
        name: 'ОАЭ',
        search: [
            'оаэ',
            'united arab emirates',
            'ae',
            'are',
            'uae',
            'объединенные арабские эмираты',
            'оаэ - объединённые арабские эмираты',
            'дубай',
            'объединенные',
        ],
    },
    {
        name: 'Оман',
        search: ['оман', 'oman', 'om', 'omn'],
    },
    {
        name: 'Пакистан',
        search: ['пакистан', 'pakistan', 'pk', 'pak'],
    },
    {
        name: 'Палау',
        search: ['палау', 'palau', 'pw', 'plw'],
    },
    {
        name: 'Панама',
        search: ['панама', 'panama', 'pa', 'pan'],
    },
    {
        name: 'Папуа-Новая Гвинея',
        search: [
            'папуа-новая гвинея',
            'papua new guinea',
            'pg',
            'png',
            'papua ng',
            'папуа - новая гвинея',
            'papua ng',
            'папуа — новая гвинея',
            'папуа новая гвинея',
        ],
    },
    {
        name: 'Парагвай',
        search: ['парагвай', 'paraguay', 'py', 'pry'],
    },
    {
        name: 'Перу',
        search: ['перу', 'peru', 'pe', 'per'],
    },
    {
        name: 'Польша',
        search: ['польша', 'poland', 'pl', 'pol'],
    },
    {
        name: 'Португалия',
        search: ['португалия', 'portugal', 'pt', 'prt'],
    },
    {
        name: 'Пуэрто-Рико',
        search: ['пуэрто-рико', 'puerto rico', 'pr', 'pri'],
    },
    {
        name: 'Россия',
        search: [
            'россия',
            'russia',
            'ru',
            'rus',
            'russia federation',
            'крым',
            'сочи',
        ],
    },
    {
        name: 'Руанда',
        search: ['руанда', 'rwanda', 'rw', 'rwa'],
    },
    {
        name: 'Румыния',
        search: ['румыния', 'romania', 'ro', 'rou'],
    },
    {
        name: 'Сальвадор',
        search: ['сальвадор', 'el salvador', 'sv', 'slv', 'эль-сальвадор'],
    },
    {
        name: 'Саудовская Аравия',
        search: [
            'саудовская аравия',
            'saudi arabia',
            'sa',
            'sau',
            'saudi arabia',
        ],
    },
    {
        name: 'Сейшельские Острова',
        search: [
            'сейшельские острова',
            'seychelles',
            'sc',
            'syc',
            'сейшелы',
            'seychelles islands',
        ],
    },
    {
        name: 'Сенегал',
        search: ['сенегал', 'senegal', 'sn', 'sen'],
    },
    {
        name: 'Сербия',
        search: ['сербия', 'serbia', 'rs', 'srb'],
    },
    {
        name: 'Сингапур',
        search: ['сингапур', 'singapore', 'sg', 'sgp'],
    },
    {
        name: 'Словакия',
        search: ['словакия', 'slovakia', 'sk', 'svk'],
    },
    {
        name: 'Словения',
        search: ['словения', 'slovenia', 'si', 'svn'],
    },
    {
        name: 'Соломоновы Острова',
        search: ['соломоновы острова', 'solomon islands', 'sb', 'slb'],
    },
    {
        name: 'Суринам',
        search: ['суринам', 'suriname', 'sr', 'sur'],
    },
    {
        name: 'США',
        search: [
            'сша',
            'united states',
            'us',
            'usa',
            'соединенные штаты',
            'соединенные штаты америки (сша)',
            'Америка',
        ],
    },
    {
        name: 'Таджикистан',
        search: ['таджикистан', 'tajikistan', 'tj', 'tjk'],
    },
    {
        name: 'Таиланд',
        search: [
            'таиланд',
            'thailand',
            'th',
            'tha',
            'тайланд',
            'тай',
            'пхукет',
            'самуи',
            'паттайя',
        ],
    },
    {
        name: 'Тайвань',
        search: [
            'тайвань',
            'taiwan',
            'tw',
            'twn',
            'китайская республика (тайвань)',
            'republic of china',
            'тайвань (китай)',
            'taiwan',
            'тайвань (республика китай)',
        ],
    },
    {
        name: 'Танзания',
        search: [
            'танзания',
            'tanzania',
            'tz',
            'tza',
            'танзания, объединенная республика',
            'занзибар',
        ],
    },
    {
        name: 'Того',
        search: ['того', 'togo', 'tg', 'tgo'],
    },
    {
        name: 'Тонга',
        search: ['тонга', 'tonga', 'to', 'ton'],
    },
    {
        name: 'Тринидад и Тобаго',
        search: ['тринидад и тобаго', 'trinidad and tobago', 'tt', 'tto'],
    },
    {
        name: 'Тунис',
        search: ['тунис', 'tunisia', 'tn', 'tun'],
    },
    {
        name: 'Туркмения',
        search: ['туркмения', 'turkmenistan', 'tm', 'tkm', 'туркменистан'],
    },
    {
        name: 'Турция',
        search: ['турция', 'turkey', 'tr', 'tur'],
    },
    {
        name: 'Уганда',
        search: ['уганда', 'uganda', 'ug', 'uga'],
    },
    {
        name: 'Узбекистан',
        search: ['узбекистан', 'uzbekistan', 'uz', 'uzb'],
    },
    {
        name: 'Украина',
        search: ['украина', 'ukraine', 'ua', 'ukr'],
    },
    {
        name: 'Уругвай',
        search: ['уругвай', 'uruguay', 'uy', 'ury'],
    },
    {
        name: 'Фиджи',
        search: ['фиджи', 'fiji', 'fj', 'fji', 'fidji'],
    },
    {
        name: 'Филиппины',
        search: ['филиппины', 'philippines', 'ph', 'phl'],
    },
    {
        name: 'Финляндия',
        search: ['финляндия', 'finland', 'fi', 'fin'],
    },
    {
        name: 'Франция',
        search: ['франция', 'france', 'fr', 'fra'],
    },
    {
        name: 'Хорватия',
        search: ['хорватия', 'croatia', 'hr', 'hrv'],
    },
    {
        name: 'ЦАР',
        search: [
            'цар',
            'central african republic',
            'cf',
            'caf',
            'центральноафриканская республика (цар)',
        ],
    },
    {
        name: 'Чад',
        search: ['чад', 'chad', 'td', 'tcd'],
    },
    {
        name: 'Черногория',
        search: ['черногория', 'montenegro', 'me', 'mne'],
    },
    {
        name: 'Чехия',
        search: ['чехия', 'czech republic', 'cz', 'cze'],
    },
    {
        name: 'Чили',
        search: ['чили', 'chile', 'cl', 'chl'],
    },
    {
        name: 'Швейцария',
        search: ['швейцария', 'switzerland', 'ch', 'che'],
    },
    {
        name: 'Швеция',
        search: ['швеция', 'sweden', 'se', 'swe'],
    },
    {
        name: 'Шри-Ланка',
        search: [
            'шри-ланка',
            'sri lanka',
            'lk',
            'lka',
            'шри - ланка',
            'sri lanka',
            'шри ланка',
        ],
    },
    {
        name: 'Эквадор',
        search: ['эквадор', 'ecuador', 'ec', 'ecu'],
    },
    {
        name: 'Экваториальная Гвинея',
        search: ['экваториальная гвинея', 'equatorial guinea', 'gq', 'gnq'],
    },
    {
        name: 'Эритрея',
        search: ['эритрея', 'eritrea', 'er', 'eri'],
    },
    {
        name: 'Эстония',
        search: ['эстония', 'estonia', 'ee', 'est'],
    },
    {
        name: 'Эфиопия',
        search: ['эфиопия', 'ethiopia', 'et', 'eth'],
    },
    {
        name: 'ЮАР',
        search: [
            'юар',
            'south africa',
            'za',
            'zaf',
            'южная африка',
            'юар - южно-африканская республика',
        ],
    },
    {
        name: 'Южная Корея',
        search: [
            'южная корея',
            'south korea',
            'kr',
            'kor',
            'korea',
            'республика корея',
            'республика корея (южная корея)',
            'корея южная',
            'корея, республика',
            'south korea',
            'korea, south',
            'корея',
        ],
    },
    {
        name: 'Южная Осетия',
        search: ['южная осетия', 'south ossetia', 'os', 'ost'],
    },
    {
        name: 'Ямайка',
        search: ['ямайка', 'jamaica', 'jm', 'jam'],
    },
    {
        name: 'Япония',
        search: ['япония', 'japan', 'jp', 'jpn'],
    },
];
