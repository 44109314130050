import { createUseStyles } from 'react-jss';
import { isIframe } from '@/libs/helpers';
import { DESKTOP_FILTERS_BREAKPOINT } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    icon: {
        marginRight: 8,
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '30px',
    },
    aside: {
        display: 'none',
        maxWidth: 320,
        flexShrink: 0,
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            display: 'initial',
        },
    },
    main: {
        flexGrow: 1,
    },
    offersContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        marginBottom: '15px',
        contain: 'paint',
        [theme.breakpoints.up('md')]: {
            marginBottom: '25px',
        },
        [theme.breakpoints.up('lg')]: {
            margin: `-24px -24px -${isIframe() ? 24 : 40}px`,
            padding: 24,
        },
    },
}), { name: 'showcase' });
