import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { Button } from '@che-ins-ui/button';
import { Checkbox } from '@che-ins-ui/checkbox';
import { fetchCalculation } from '@/models/calculation';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { ROUTES } from '@/components/routes';
import { apiIsApiDataLoadingSelector } from '@/models/api/selectors';
import { requestActions } from '@/models/request';
import { requestServiceSelector } from '@/models/request/selectors';
import { upsellChosenSelector, upsellIsLoadingSelector, } from '@/models/upsell/selectors';
import { useActivePanelName } from '@/components/insurance/components/hooks';
import { TABS_PANELS_NAMES } from '@/components/insurance/consts';
import { ACTIVE_SPORTS_CODES_FOR_UPSELL } from '@/components/insurance/components/Upsell/consts';
import { basketProductSelector } from '@/models/basket/selectors';
import { ABROAD_CODE, FOREIGN_CODE, MEDICINE_CODE } from '@/libs/static';
import { useStyles } from '../styles';
import { TouristsDropdown } from './TouristsDropdown';
import { Dates } from './Dates';
export const CommonComponents = ({ setIsOpen, mode, }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(apiIsApiDataLoadingSelector);
    const isLoadingUpsell = useSelector(upsellIsLoadingSelector);
    const chosenUpsell = useSelector(upsellChosenSelector);
    const queryString = useCalculationQueryString();
    const activePanelName = useActivePanelName();
    const upsellActiveRest = Boolean(chosenUpsell.activeRest);
    const upsellMedicine = chosenUpsell[MEDICINE_CODE];
    const basketProduct = useSelector(basketProductSelector);
    const isBasketCompanyCodeSberbank = basketProduct?.company?.code === 'sberbank';
    const getCorrectMedicine = (value) => {
        if (isBasketCompanyCodeSberbank) {
            if (value === 120000) {
                return 100000;
            }
            if (value === 60000) {
                return 50000;
            }
        }
        return value;
    };
    const handleSearch = useCallback(() => {
        if (mode === 'while-label') {
            dispatch(requestActions.setEmptyFieldsToDefault());
        }
        if (activePanelName === TABS_PANELS_NAMES.UPSELL) {
            upsellActiveRest &&
                dispatch(requestActions.setSports(ACTIVE_SPORTS_CODES_FOR_UPSELL));
            upsellMedicine &&
                dispatch(requestActions.setServices({
                    [MEDICINE_CODE]: getCorrectMedicine(upsellMedicine),
                }));
        }
        dispatch(fetchCalculation());
        setIsOpen?.(false);
    }, [mode, activePanelName, upsellActiveRest, upsellMedicine]);
    const requestService = useSelector(requestServiceSelector);
    const isAbroad = Boolean(requestService[ABROAD_CODE]);
    const isForeign = Boolean(requestService[FOREIGN_CODE]);
    const toggleAbroad = useCallback(() => {
        dispatch(requestActions.setServices({ [ABROAD_CODE]: isAbroad ? 0 : 1 }));
    }, [isAbroad]);
    const toggleForeign = useCallback(() => {
        dispatch(requestActions.setServices({ [FOREIGN_CODE]: isForeign ? 0 : 1 }));
    }, [isForeign]);
    const checkboxBaseAppearance = mode === 'calculation'
        ? { baseAppearance: 'whitelabel-search-calculation' }
        : {};
    return (_jsxs(_Fragment, { children: [_jsx(Dates, { mode: mode }), _jsx(TouristsDropdown, { mode: mode }), _jsx(Checkbox, { appearance: "abroad", ...checkboxBaseAppearance, value: isAbroad, onChange: toggleAbroad, label: "\u042F \u0443\u0436\u0435 \u0437\u0430 \u0433\u0440\u0430\u043D\u0438\u0446\u0435\u0439", disabled: isLoadingUpsell }), _jsx(Checkbox, { appearance: "foreign", ...checkboxBaseAppearance, value: isForeign, onChange: toggleForeign, label: "\u042F \u043D\u0435 \u0433\u0440\u0430\u0436\u0434\u0430\u043D\u0438\u043D \u0420\u043E\u0441\u0441\u0438\u0438", disabled: isLoadingUpsell }), _jsx("div", { className: classes.searchButton, children: _jsx(Button, { passAppearancesToComponent: false, shouldFitContent: true, appearance: "search", onClick: handleSearch, component: Link, to: {
                        pathname: ROUTES.calculation,
                        search: queryString,
                    }, disabled: isLoading || isLoadingUpsell, children: "\u041D\u0410\u0419\u0422\u0418" }) })] }));
};
