import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useCheTheme } from '@/styles/themes';
import { Loader } from './Loader';
import { useStyles } from './styles';
const AppLoader = ({ loaderColor }) => {
    const classes = useStyles();
    const theme = useCheTheme();
    return (_jsx("div", { className: classes.wrapper, children: _jsx(Loader, { loaderColor: loaderColor || theme.colors.white }) }));
};
export default memo(AppLoader);
export { Loader };
