import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntersection } from 'react-use';
import { motion } from 'framer-motion';
import { comparingDifferencesOnlySelector } from '@/models/comparing/selectors';
import { apiSelector } from '@/models/api/selectors';
import { useCheTheme } from '@/styles/themes';
import { ComparingTableService } from './ComparingTableService';
import { useTableStyles } from './tableStyles';
import { getServicesDescription } from './helpers';
import { ExclamationMarkIcon } from './ExclamationMarkIcon.';
export const comparingTableAsideLeftOffset = -15;
export const ComparingTableAside = memo(({ allServices, repeatingServices, }) => {
    const classes = useTableStyles();
    const theme = useCheTheme();
    const differencesOnly = useSelector(comparingDifferencesOnlySelector);
    const { servicesDictionary } = useSelector(apiSelector);
    const nonRepeatingServices = allServices.filter((service) => !repeatingServices.includes(service));
    const allServicesWithDescriptions = useMemo(() => {
        return getServicesDescription(allServices, servicesDictionary);
    }, [allServices, servicesDictionary]);
    const [isStickyDefined, setIsStickyDefined] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsStickyDefined(true);
        }, 400);
        return () => clearTimeout(timeout);
    }, []);
    const observedRef = useRef(null);
    const entry = useIntersection(observedRef, {});
    const isSticky = isStickyDefined && !entry?.isIntersecting;
    return (_jsxs(motion.div, { layout: "position", className: classes.aside, transition: {
            duration: 0.2,
            height: { duration: 0.1 },
            layout: { duration: 0.2 },
        }, animate: {
            borderRight: `1px solid ${isSticky ? theme.colors.textTertiary : 'transparent'}`,
        }, children: [_jsx("div", { className: classes.asideObservedElement, ref: observedRef }), allServicesWithDescriptions.map(({ service, description }, index) => (_jsx(ComparingTableService, { value: service, isHidden: repeatingServices.includes(service) &&
                    differencesOnly, isEven: Boolean((differencesOnly
                    ? nonRepeatingServices.indexOf(service)
                    : index) % 2), description: description }, service))), _jsxs("div", { className: classes.asideLimitations, children: [_jsx("div", { className: classes.asideLimitationsIcon, children: _jsx(ExclamationMarkIcon, {}) }), _jsx(ComparingTableService, { value: "\u041E\u0433\u0440\u0430\u043D\u0438\u0447\u0435\u043D\u0438\u044F", isHidden: false })] })] }));
});
