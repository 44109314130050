import { CURRENCIES_BADGES } from '@/libs/constants';
import { declOfNum } from '@/libs/helpers';
import { separatePriceBySpaces } from '@/libs/helpers/utils';
import { getTypes, MULTIPOLICY_CODE, PREGNANCY_CODE } from '@/libs/static';
import { SPORTS_SEARCH_CODE } from '@/models/search/filtersSearchTags';
import { SELECTED_FILTERSS_CODES_TO_IGNORE, selectedFiltersShortLabelsDictionary, } from './consts';
export const servicesToSelectedFilters = (services, currency, dictionary) => {
    return Object.keys(services)
        .reduce((acc, code) => {
        const value = services[code];
        if (!value || SELECTED_FILTERSS_CODES_TO_IGNORE.includes(code)) {
            return acc;
        }
        acc.push({
            code,
            label: dictionary?.[code]?.name || code,
            shortLabel: selectedFiltersShortLabelsDictionary?.[code],
            postfix: servicePostfix(code, value, currency),
        });
        return acc;
    }, [])
        .reverse();
};
const servicePostfix = (serviceCode, value, currency) => {
    if (serviceCode === PREGNANCY_CODE) {
        return `до ${value} нед.`;
    }
    if (serviceCode === MULTIPOLICY_CODE) {
        if (typeof value === 'number') {
            return `${value} ${declOfNum(value, getTypes('DAY'))}`;
        }
        return null;
    }
    if (value === 1) {
        return null;
    }
    return separatePriceBySpaces(value) + ' ' + CURRENCIES_BADGES[currency];
};
const MAX_ITEMS_SEEN = 2;
export const displayMultipleSelected = (selectedCodes, options, label) => {
    const amount = selectedCodes.length;
    const isMultiple = amount > MAX_ITEMS_SEEN;
    const seenAssistances = [];
    for (let i = 0; i < Math.min(amount, MAX_ITEMS_SEEN); i++) {
        seenAssistances.push(options.find(({ code }) => code === selectedCodes[i])?.name ||
            selectedCodes[i]);
    }
    return {
        code: SPORTS_SEARCH_CODE,
        label,
        postfix: seenAssistances.join(', ') +
            (isMultiple
                ? ` и еще ${selectedCodes.length - MAX_ITEMS_SEEN}`
                : ''),
    };
};
