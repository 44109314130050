import { createSelector } from '@reduxjs/toolkit';
import { BASKET_STATE_NAMESPACE } from './consts';
export const basketSelector = (state) => state[BASKET_STATE_NAMESPACE];
export const basketProductSelector = createSelector(basketSelector, ({ product }) => product);
export const basketPriceSelector = createSelector(basketProductSelector, (product) => product?.calculation.priceRub);
export const basketProductIdSelector = createSelector(basketProductSelector, (product) => product?.calculation.productId);
export const basketProductIsMissingSelector = createSelector(basketSelector, ({ productIsMissing }) => productIsMissing);
export const basketProductIsLoadingSelector = createSelector(basketSelector, ({ productIsLoading }) => productIsLoading);
export const basketLastChangesIdSelector = createSelector(basketSelector, ({ lastChangesId }) => lastChangesId);
export const isInsurerInRussianSelector = createSelector(basketProductSelector, (product) => {
    if (!product) {
        return false;
    }
    const { company: { isInsurerInRussian }, } = product;
    return isInsurerInRussian;
});
