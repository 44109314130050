import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchApiServiceStatus } from '@/models/api';
import { SEC } from '../constants';
const DELAY_SEC = 15 * SEC;
export const useApiServiceStatus = () => {
    const intervalRef = useRef();
    const dispatch = useDispatch();
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            dispatch(fetchApiServiceStatus());
        }, DELAY_SEC);
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);
};
