import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useStyles } from './styles';
export const ButtonWrapperWithTooltip = memo(({ children, tooltipLabel, onClick, tooltipX: x = 0, tooltipY: y = 0, height, position = 'top', additionalStyles, }) => {
    const classes = useStyles();
    const [hovered, setHovered] = useState(false);
    const variants = {
        visible: { opacity: 1, y: 0, scale: 1 },
        hidden: {
            opacity: 0,
            y: -10 * (position === 'top' ? 1 : -1),
            scale: 0.7,
        },
    };
    return (_jsxs("button", { className: classes.buttonWrapper, onClick: onClick, onPointerEnter: () => setHovered(true), onPointerLeave: () => setHovered(false), tabIndex: 0, style: additionalStyles, children: [_jsx("div", { className: classes.childWrapper, style: { height }, children: children }), _jsx(AnimatePresence, { children: hovered && tooltipLabel && (_jsx(motion.div, { variants: variants, initial: "hidden", animate: "visible", exit: "hidden", transition: {
                        type: 'just',
                        duration: 0.1,
                        opacity: { duration: 0.05 },
                    }, className: classes.tooltipWrapper, children: _jsxs("div", { className: classes.tooltip, style: {
                            transform: `translate(${x}px, ${y}px)`,
                        }, "data-position": position, children: [_jsx("div", { className: classes.tooltipBody, "data-position": position, children: tooltipLabel }), _jsx("div", { className: classes.tooltipArrow, "data-position": position })] }) }, tooltipLabel)) })] }));
});
