let SMOOTH_DROPDOWN_COMPONENTS_NAMES = {};
import('@/components/common/Header/constants').then((headerConst) => {
    SMOOTH_DROPDOWN_COMPONENTS_NAMES =
        headerConst.default.SMOOTH_DROPDOWN_COMPONENTS_NAMES;
});
export const getSmoothDropdownTheme = (theme) => ({
    'white-label-help': {
        [SMOOTH_DROPDOWN_COMPONENTS_NAMES.dropdownWrapper]: {
            [theme.breakpoints.up('md')]: {
                position: 'absolute',
                right: '0px',
            },
            [theme.breakpoints.up('xl')]: {
                right: '147px',
            },
        },
        [SMOOTH_DROPDOWN_COMPONENTS_NAMES.dropdownTitle]: {
            color: theme.colors.white,
        },
    },
});
