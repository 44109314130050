import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectedModal, modalActions, modalSelector } from '@che-ins-ui/modal';
import { notebookDeletingPersonStatusSelector, personsSelector, } from '@/models/notebook/selectors';
import { getPersonDeletionModalData } from '@/models/notebook/helpers';
import { deleteNotebookPerson } from '@/models/notebook/deleteNotebookPerson';
import { useStyles } from './styles';
import { EditPersonFormSubmitButton } from './EditPersonFormSubmitButton';
import { EDIT_PERSON_MODAL_NAME } from './EditPersonModal';
const modalName = 'DELETE_PERSON_MODAL_NAME';
export const openDeletePersonModal = () => modalActions.open({ name: modalName });
export const closeDeletePersonModal = () => modalActions.close({ name: modalName });
export const DeletePersonModal = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { data: { personId }, } = useSelector(modalSelector(EDIT_PERSON_MODAL_NAME));
    const { isLoading, isSuccessfullyDeleted, error } = useSelector(notebookDeletingPersonStatusSelector);
    const persons = useSelector(personsSelector);
    const person = persons.find((person) => person.id === personId);
    if (!person) {
        return null;
    }
    const { emojiImage, name, birthday } = getPersonDeletionModalData(person);
    return (_jsxs(ConnectedModal, { name: modalName, appearance: "hint", title: _jsxs(_Fragment, { children: [_jsx("div", { className: classes.deleteModalTitleIcon, children: _jsx("img", { src: emojiImage, alt: person.emoji || '' }) }), _jsxs("span", { className: classes.deleteModalTitleName, children: [name, " \u00B7 ", birthday] }), _jsx("span", { className: classes.deleteModalTitle, children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0438\u0437 \u0437\u0430\u043F\u0438\u0441\u043D\u043E\u0439 \u043A\u043D\u0438\u0436\u043A\u0438?" })] }), children: [_jsx("span", { className: classes.deleteModalHintText, children: "\u042D\u0442\u043E \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u043D\u0435\u043B\u044C\u0437\u044F \u0431\u0443\u0434\u0435\u0442 \u043E\u0442\u043C\u0435\u043D\u0438\u0442\u044C, \u043D\u043E \u0432\u044B \u0432\u0441\u0435\u0433\u0434\u0430 \u0441\u043C\u043E\u0436\u0435\u0442\u0435 \u0434\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u044D\u0442\u043E\u0433\u043E \u0447\u0435\u043B\u043E\u0432\u0435\u043A\u0430 \u0437\u0430\u043D\u043E\u0432\u043E \u0432 \u043B\u0438\u0447\u043D\u043E\u043C \u043A\u0430\u0431\u0438\u043D\u0435\u0442\u0435" }), _jsx(EditPersonFormSubmitButton, { isLoading: isLoading, isSuccessfullyDone: isSuccessfullyDeleted, onClick: () => {
                    dispatch(deleteNotebookPerson(personId));
                }, disabled: isLoading || isSuccessfullyDeleted, errorMessage: error, isDeletionAppearance: true, defaultText: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C", successText: "\u0423\u0434\u0430\u043B\u0435\u043D\u043E" })] }));
});
