import { createUseStyles } from 'react-jss';
import { COMPARING_BREAKPOINT } from '@/components/common/Comparing/const';
export const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        alignSelf: 'baseline',
        paddingRight: 12,
        flexDirection: 'row',
        '&[data-no-padding-right="true"]': {
            paddingRight: 0,
        },
    },
    exportIcon: {
        '& path': {
            fill: `${theme.colors.textSecondary} !important`,
            fillOpacity: 1,
        },
    },
    compare: {
        display: 'none',
        [theme.breakpoints.up(COMPARING_BREAKPOINT)]: {
            display: 'initial',
        },
    },
}), { name: 'icons-block' });
