import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { BREAKPOINTS, useScreenSizes } from '@che-ins-ui/theme';
import { requestAssistancesSelector, requestCurrencySelector, requestServiceSelector, requestSportsSelector, } from '@/models/request/selectors';
import { apiSelector } from '@/models/api/selectors';
import { requestActions } from '@/models/request';
import { AVIA_CARGO_CODE, SUNBURN_ASSISTANCE_CODE } from '@/libs/static';
import { setServiceToSearchInput } from '@/models/search/setServiceToSearchInput';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { RESTORABLE_QS_PARAMS } from '@/libs/constants';
import { scrollToTop } from '@/libs/helpers';
import { DESKTOP_FILTERS_BREAKPOINT } from '@/styles/themes/components';
import { analytics } from '@/libs/analytics';
import { TagButton } from '../common/TagButton/TagButton';
import { useStyles } from './styles';
import { servicesToSelectedFilters, displayMultipleSelected } from './helpers';
import { SelectedFiltersScrollButton } from './ScrollButton';
import { useScrollButtons } from './useScrollButtons';
import { SelectedFixedPart } from './SelectedFixedPart';
export const SelectedFilters = memo(() => {
    const dispatch = useDispatch();
    const [_, setSearch] = useSearchParams();
    const queryString = useCalculationQueryString({
        addParams: { [RESTORABLE_QS_PARAMS.mobileFilters]: 'true' },
    });
    const { width } = useScreenSizes();
    const isDesktop = width > BREAKPOINTS[DESKTOP_FILTERS_BREAKPOINT];
    const { assistances: apiAssistances, sports: apiSports, servicesDictionary, } = useSelector(apiSelector);
    const services = useSelector(requestServiceSelector);
    const assistances = useSelector(requestAssistancesSelector);
    const sports = useSelector(requestSportsSelector);
    const currency = useSelector(requestCurrencySelector);
    const isDictionaryLoaded = Object.keys(servicesDictionary).length > 0;
    const changeService = (code, value) => {
        dispatch(requestActions.setServices({ [code]: value }));
    };
    const openFilters = () => {
        if (!isDesktop) {
            setSearch(queryString);
            scrollToTop({ behavior: 'auto' });
        }
    };
    const searchService = (label, code) => {
        dispatch(setServiceToSearchInput({ input: label, service: code }));
        openFilters();
    };
    const classes = useStyles();
    const selectedFilters = useMemo(() => {
        return servicesToSelectedFilters(services, currency, servicesDictionary);
    }, [services, currency, servicesDictionary]);
    const reccomendatedFilters = useMemo(() => {
        return [
            {
                code: SUNBURN_ASSISTANCE_CODE,
                label: 'Солнечные ожоги',
                onClick: () => {
                    changeService(SUNBURN_ASSISTANCE_CODE, 1);
                },
            },
            {
                code: AVIA_CARGO_CODE,
                label: 'Страхование багажа',
                onClick: () => {
                    changeService(AVIA_CARGO_CODE, 500);
                },
            },
        ].filter(({ code }) => !selectedFilters.some((filter) => filter.code === code));
    }, [selectedFilters]);
    const assistancesInfo = Boolean(assistances.length) &&
        displayMultipleSelected(assistances, apiAssistances, 'Ассистанс');
    const sportsInfo = Boolean(sports.length) &&
        displayMultipleSelected(sports, apiSports, 'Спорт');
    const selectedCount = selectedFilters.length +
        Number(Boolean(assistancesInfo)) +
        Number(Boolean(sportsInfo));
    const { scrollableRef, canScrollLeft, canScrollRight, scroll } = useScrollButtons();
    const [withAppearAnimation, setWithAppearAnimation] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setWithAppearAnimation(true), 100;
        });
        return () => clearTimeout(timeout);
    }, []);
    const onFilterRemoval = () => {
        analytics.sendEvent({
            action: 'travel_filters_row_removal',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    };
    const onFilterInfoClick = () => {
        analytics.sendEvent({
            action: 'travel_filters_row_info',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    };
    const onRecommendationClick = () => {
        analytics.sendEvent({
            action: 'travel_filters_row_recommendations_selection',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    };
    return (_jsxs("div", { className: classes.container, children: [_jsx("div", { className: classes.scrollable, ref: scrollableRef, children: _jsx("div", { className: classes.contentWrapper, children: _jsxs("div", { className: classes.content, style: { opacity: isDictionaryLoaded ? 1 : 0 }, children: [_jsx(SelectedFixedPart, { isDesktop: isDesktop, selectedCount: selectedCount, openFilters: openFilters }), selectedFilters.map(({ code, shortLabel, label, postfix }) => (_jsx(TagButton, { label: shortLabel || label, postfix: postfix, onClick: () => {
                                    searchService(label, code);
                                    onFilterInfoClick();
                                }, onPostfixClick: () => {
                                    changeService(code, 0);
                                    onFilterRemoval();
                                }, type: "removing", withAppearAnimation: withAppearAnimation }, code))), assistancesInfo && (_jsx(TagButton, { label: assistancesInfo.label, postfix: assistancesInfo.postfix, onClick: () => {
                                    searchService(assistancesInfo.label, assistancesInfo.code);
                                    onFilterInfoClick();
                                }, onPostfixClick: () => {
                                    dispatch(requestActions.setAssistance([]));
                                    onFilterRemoval();
                                }, type: "removing", withAppearAnimation: withAppearAnimation }, assistancesInfo.code)), sportsInfo && (_jsx(TagButton, { label: sportsInfo.label, postfix: sportsInfo.postfix, onClick: () => {
                                    searchService(sportsInfo.label, sportsInfo.code);
                                    onFilterInfoClick();
                                }, onPostfixClick: () => {
                                    dispatch(requestActions.setSports([]));
                                    onFilterRemoval();
                                }, type: "removing", withAppearAnimation: withAppearAnimation }, sportsInfo.code)), Boolean(reccomendatedFilters.length) && (_jsx(motion.div, { className: classes.splitter, layout: "position" }, "splitter")), Boolean(reccomendatedFilters.length) && (_createElement(motion.div, { ...(withAppearAnimation && {
                                    initial: { opacity: 0, x: -100 },
                                }), animate: { opacity: 1, x: 0 }, exit: {
                                    opacity: 0,
                                    x: 100,
                                    transition: { duration: 0.05 },
                                }, className: classes.sectionLabel, key: "recommendations", layout: "position", "data-small-gap": true },
                                _jsx("svg", { enableBackground: "new 0 0 24 24", viewBox: "0 0 24 24", children: _jsx("path", { d: "m16 6-.44.55c-.42.52-.98.75-1.54.75C13 7.3 12 6.52 12 5.3V2S4 6 4 13c0 4.42 3.58 8 8 8s8-3.58 8-8c0-2.96-1.61-5.62-4-7zm-4 13c-1.1 0-2-.87-2-1.94 0-.51.2-.99.58-1.36L12 14.3l1.43 1.4c.37.37.57.85.57 1.36 0 1.07-.9 1.94-2 1.94zm3.96-1.5c.04-.36.22-1.89-1.13-3.22L12 11.5l-2.83 2.78C7.81 15.62 8 17.16 8.04 17.5A5.982 5.982 0 016 13c0-3.16 2.13-5.65 4.03-7.25a4.024 4.024 0 003.99 3.55c.78 0 1.54-.23 2.18-.66A6.175 6.175 0 0118 13c0 1.79-.79 3.4-2.04 4.5z" }) }),
                                _jsx("span", { children: "\u0420\u0435\u043A\u043E\u043C\u0435\u043D\u0434\u0443\u0435\u043C:" }))), reccomendatedFilters.map((tag) => (_jsx(TagButton, { ...tag, onPostfixClick: () => {
                                    tag.onClick();
                                    onRecommendationClick();
                                }, onClick: () => {
                                    tag.onClick();
                                    onRecommendationClick();
                                }, type: "adding" }, tag.code)))] }) }) }), _jsx(SelectedFiltersScrollButton, { direction: "left", disabled: !canScrollLeft, onClick: () => {
                    scroll('left');
                } }), _jsx(SelectedFiltersScrollButton, { direction: "right", disabled: !canScrollRight, onClick: () => {
                    scroll('right');
                } })] }));
});
