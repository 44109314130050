export * from './consts';
import { BUTTON_COMPONENT_NAMESPACE, BUTTON_GROUP_COMPONENT_NAMESPACE, ICON_BUTTON_COMPONENT_NAMESPACE, } from '@che-ins-ui/button';
import { CODE_INPUT_COMPONENT_NAMESPACE, INPUT_COMPONENT_NAMESPACE, } from '@che-ins-ui/input';
import { TABS_COMPONENT_NAMESPACE } from '@che-ins-ui/tabs';
import { CHECKBOX_COMPONENT_NAMESPACE } from '@che-ins-ui/checkbox';
import { ACCORDION_COMPONENT_NAMESPACE } from '@che-ins-ui/accordion';
import { BLANKET_COMPONENT_NAMESPACE } from '@che-ins-ui/blanket';
import { MODAL_COMPONENT_NAMESPACE } from '@che-ins-ui/modal';
import { TAG_COMPONENT_NAMESPACE } from '@che-ins-ui/tag';
import { NOTIFICATION_COMPONENT_NAMESPACE } from '@che-ins-ui/notification';
import { DROPDOWN_COMPONENT_NAMESPACE } from '@che-ins-ui/dropdown';
import { DATEPICKER_COMPONENT_NAMESPACE } from '@che-ins-ui/datepicker';
import { SELECT_COMPONENT_NAMESPACE } from '@che-ins-ui/select';
import { CONTAINER_COMPONENT_NAMESPACE } from '@che-ins-ui/container';
import { CONTACTS_COMPONENT_NAMESPACE } from '@che-ins-ui/contacts';
import { FOOTER_COMPONENT_NAMESPACE } from '@che-ins-ui/footer';
import { PHONE_INPUT_COMPONENT_NAMESPACE } from '@che-ins-ui/phone-input';
import { TOOLTIP_COMPONENT_NAMESPACE } from '@che-ins-ui/tooltip';
import { SKELETON_COMPONENT_NAMESPACE } from '@che-ins-ui/skeleton';
import { HEADER_COMPONENT_NAMESPACE } from '@che-ins-ui/header';
import { TECHNICAL_PAGE_COMPONENT_NAMESPACE } from '@che-ins-ui/technical-pages';
import { getDatepickerTheme } from './datepickerTheme';
import { getNotificationTheme } from './notificationTheme';
import { getTagTheme } from './tagTheme';
import { getBlanketTheme } from './blanketTheme';
import { getModalTheme } from './modalTheme';
import { getButtonTheme } from './buttonTheme';
import { getInputTheme } from './inputTheme';
import { getTabsTheme } from './tabsTheme';
import { getButtonsGroupTheme } from './buttonsGroupTheme';
import { getCheckboxTheme } from './checkboxTheme';
import { getAccordionTheme } from './accordionTheme';
import { getIconButtonTheme } from './iconButtonTheme';
import { getSelectTheme } from './selectTheme';
import { getDropdownTheme } from './dropdownTheme';
import { getHeaderTheme } from './headerTheme';
import { getContainerTheme } from './containerTheme';
import { getContactsTheme } from './contactsTheme';
import { getSmoothDropdownTheme } from './smoothDropdownTheme';
import { getFooterTheme } from './footerTheme';
import { getCodeInputTheme } from './codeInputTheme';
import { getPhoneInputTheme } from './phoneInputTheme';
import { getTooltipTheme } from './tooltipTheme';
import { getSkeletonTheme } from './skeletonTheme';
import { getTechnicalPagesTheme } from './technicalPages';
let SMOOTH_DROPDOWN_COMPONENT_NAMESPACE = '@che/smooth-dropdown';
import('@/components/common/Header/constants').then((headerConst) => {
    SMOOTH_DROPDOWN_COMPONENT_NAMESPACE =
        headerConst.default.SMOOTH_DROPDOWN_COMPONENT_NAMESPACE;
});
export const themeComponents = [
    { name: NOTIFICATION_COMPONENT_NAMESPACE, getter: getNotificationTheme },
    { name: TAG_COMPONENT_NAMESPACE, getter: getTagTheme },
    { name: BLANKET_COMPONENT_NAMESPACE, getter: getBlanketTheme },
    { name: MODAL_COMPONENT_NAMESPACE, getter: getModalTheme },
    { name: BUTTON_COMPONENT_NAMESPACE, getter: getButtonTheme },
    { name: ICON_BUTTON_COMPONENT_NAMESPACE, getter: getIconButtonTheme },
    { name: INPUT_COMPONENT_NAMESPACE, getter: getInputTheme },
    { name: TABS_COMPONENT_NAMESPACE, getter: getTabsTheme },
    { name: BUTTON_GROUP_COMPONENT_NAMESPACE, getter: getButtonsGroupTheme },
    { name: CHECKBOX_COMPONENT_NAMESPACE, getter: getCheckboxTheme },
    { name: ACCORDION_COMPONENT_NAMESPACE, getter: getAccordionTheme },
    { name: SELECT_COMPONENT_NAMESPACE, getter: getSelectTheme },
    { name: DROPDOWN_COMPONENT_NAMESPACE, getter: getDropdownTheme },
    { name: DATEPICKER_COMPONENT_NAMESPACE, getter: getDatepickerTheme },
    { name: HEADER_COMPONENT_NAMESPACE, getter: getHeaderTheme },
    { name: CONTAINER_COMPONENT_NAMESPACE, getter: getContainerTheme },
    { name: CONTACTS_COMPONENT_NAMESPACE, getter: getContactsTheme },
    { name: FOOTER_COMPONENT_NAMESPACE, getter: getFooterTheme },
    { name: CODE_INPUT_COMPONENT_NAMESPACE, getter: getCodeInputTheme },
    { name: PHONE_INPUT_COMPONENT_NAMESPACE, getter: getPhoneInputTheme },
    { name: TOOLTIP_COMPONENT_NAMESPACE, getter: getTooltipTheme },
    { name: SKELETON_COMPONENT_NAMESPACE, getter: getSkeletonTheme },
    {
        name: SMOOTH_DROPDOWN_COMPONENT_NAMESPACE,
        getter: getSmoothDropdownTheme,
    },
    {
        name: TECHNICAL_PAGE_COMPONENT_NAMESPACE,
        getter: getTechnicalPagesTheme,
    },
];
