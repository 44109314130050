import { CHECKBOX_COMPONENTS_NAMES } from '@che-ins-ui/checkbox';
import { withAlpha } from '@/styles/utils';
import { DEKSTOP_FONT_SIZES, DESKTOP_SEARCH_FORM_BREAKPOINT, DESKTOP_SIZES_BREAKPOINT, MOBILE_FONT_SIZES, } from './consts';
export const getCheckboxTheme = (theme) => ({
    base: {
        [CHECKBOX_COMPONENTS_NAMES.wrapper]: {
            height: '34px',
            '-webkit-tap-highlight-color': 'transparent',
            '-webkit-user-select': 'none',
            userSelect: 'none',
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: '30px',
            },
        },
        [CHECKBOX_COMPONENTS_NAMES.iconWrapper]: {
            flexShrink: 0,
            width: '24px',
            height: '24px',
            transition: theme.transition.global,
            backgroundColor: theme.colors.field,
            '&[data-checked="true"]': {
                backgroundColor: theme.colors.brand,
                borderColor: theme.colors.brand,
            },
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                width: '20px',
                height: '20px',
            },
            '& svg > path': {
                transition: theme.transition.global,
            },
        },
        [CHECKBOX_COMPONENTS_NAMES.label]: {
            display: 'inline-flex',
            fontSize: MOBILE_FONT_SIZES.main,
            color: theme.colors.sky,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
    },
    compare: {
        [CHECKBOX_COMPONENTS_NAMES.iconWrapper]: {
            border: 'none',
            '&[data-checked="true"]': {
                backgroundColor: 'transparent',
            },
        },
        [CHECKBOX_COMPONENTS_NAMES.label]: {
            marginTop: '4px',
        },
    },
    filters: {
        [CHECKBOX_COMPONENTS_NAMES.wrapper]: {
            transformOrigin: '20%',
            padding: '0px 12px',
            width: '100%',
            '&:hover': {
                background: withAlpha(theme.colors.brandTertiary, 0.5),
            },
        },
        [CHECKBOX_COMPONENTS_NAMES.label]: {
            flexShrink: 1,
            overflow: 'hidden',
        },
    },
    'whitelabel-search-calculation': {
        [CHECKBOX_COMPONENTS_NAMES.wrapper]: {
            [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
                display: 'none',
            },
        },
    },
    abroad: getSearchFormCheckboxStyles(theme, 'abroad'),
    foreign: getSearchFormCheckboxStyles(theme, 'foreign'),
    preview: {
        [CHECKBOX_COMPONENTS_NAMES.iconWrapper]: {
            '&[data-checked="true"]': {
                backgroundColor: theme.colors.brandTertiary,
                border: `1px solid ${theme.colors.brand}`,
                '& svg > path': {
                    fill: theme.colors.brand,
                },
            },
        },
    },
    comparing: {
        [CHECKBOX_COMPONENTS_NAMES.wrapper]: {
            flexShrink: 0,
            padding: 0,
            maxHeight: 24,
            '&:active': {
                transform: 'scale(1) !important',
            },
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                maxHeight: 20,
            },
        },
    },
});
const getSearchFormCheckboxStyles = (theme, gridArea) => ({
    [CHECKBOX_COMPONENTS_NAMES.wrapper]: {
        gridArea,
        height: 46,
        maxWidth: 'max-content',
        '&:active[data-disabled="false"]': {
            transform: 'scale(1)',
        },
        [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
            height: 20,
            margin: '8px 0 0 12px',
        },
    },
    [CHECKBOX_COMPONENTS_NAMES.label]: {
        color: theme.colors.white,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    [CHECKBOX_COMPONENTS_NAMES.iconWrapper]: {
        border: `1px solid ${theme.colors.field}`,
        borderRadius: 7,
        backgroundColor: 'transparent',
        '& svg': {
            height: 12,
            width: 12,
        },
        [theme.breakpoints.up('sm')]: {
            '& svg': {
                height: 10,
                width: 10,
            },
        },
        '&[data-checked="true"]': {
            backgroundColor: theme.colors.field,
            '& path': {
                fill: theme.colors.brand,
            },
        },
    },
});
