import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCalculationQueryString, } from '../helpers/utils';
export const useCalculationQueryString = (params) => {
    const state = useSelector((state) => state);
    const link = useMemo(() => {
        return getCalculationQueryString(state, params);
    }, [state.form, state.request, params]);
    return link;
};
