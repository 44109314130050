import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_PARTNER_ID, USER_STATE_NAMESPACE } from './consts';
export const userInitialState = {
    status: 'unauthenticated',
    role: null,
    adminPartnerId: DEFAULT_PARTNER_ID,
    partnerCommissionRate: null,
    isCommissionShown: false,
};
const userSlice = createSlice({
    name: USER_STATE_NAMESPACE,
    initialState: userInitialState,
    reducers: {
        setAuthStatus: (state, action) => {
            state.status = action.payload;
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setAdminPartnerId: (state, action) => {
            state.adminPartnerId = action.payload || DEFAULT_PARTNER_ID;
        },
        setPartnerCommissionRate: (state, action) => {
            state.partnerCommissionRate = action.payload;
        },
        setIsCommissionShown: (state, action) => {
            state.isCommissionShown = action.payload;
        },
    },
});
export const { actions: userActions, reducer: userReducer } = userSlice;
export { USER_STATE_NAMESPACE };
export * from './consts';
