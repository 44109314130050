import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ComparingTableOffer } from './ComparingTableOffer';
import { useTableStyles } from './tableStyles';
import { ComparingTableAside } from './ComparingTableAside';
export const comparingTableOfferMoveDuration = 350;
export const ComparingTableOffers = memo(({ offers, repeatingServices, allServices, addToBasket, }) => {
    const classes = useTableStyles();
    const [movedOfferId, setMovedOfferId] = useState(null);
    useEffect(() => {
        if (!movedOfferId) {
            return;
        }
        const timeout = setTimeout(() => {
            setMovedOfferId(null);
        }, comparingTableOfferMoveDuration);
        return () => {
            clearTimeout(timeout);
        };
    }, [movedOfferId]);
    const offersAmount = offers.length;
    return (_jsxs(motion.div, { className: classes.offers, transition: {
            duration: 0.2,
            height: { duration: 0.1 },
            layout: { duration: 0.2 },
        }, children: [_jsxs(AnimatePresence, { initial: false, mode: "popLayout", children: [_jsx(ComparingTableAside, { allServices: allServices, repeatingServices: repeatingServices }, "aside"), offers.map(({ company: { id, name, linkToLogo }, info: { price, servicesCount, services, limitations, missingServices, missingServicesCount, }, }) => (_jsx(ComparingTableOffer, { ...{
                            id,
                            name,
                            linkToLogo,
                            price,
                            services,
                            allServices,
                            repeatingServices,
                            missingServices,
                            servicesCount,
                            missingServicesCount,
                            limitations,
                        }, isExpanded: offersAmount <= 2, addToBasket: () => addToBasket(id) }, id)))] }), _jsx("div", { className: classes.bottomGradient })] }));
});
