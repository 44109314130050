import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { CheckIcon } from '@che-ins-ui/icons/CheckIcon';
import { apiSelector } from '@/models/api/selectors';
import { useStyles } from './styles';
export const MedicineHintContent = memo(() => {
    const { mandatoryServices } = useSelector(apiSelector);
    const classes = useStyles();
    return (_jsx("ul", { className: classes.medicineBlockHintList, children: mandatoryServices.map(({ name }) => (_jsxs("li", { className: classes.medicineBlockHintListItem, children: [_jsx(CheckIcon, { className: classes.medicineHintCheckIcon, fill: "inherit" }), name] }, name))) }));
});
