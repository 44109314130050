import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { modalActions } from '@che-ins-ui/modal';
import { ChevronIcon } from '@che-ins-ui/icons/ChevronIcon';
import { calculationIsLoadingSelector, matchedOffersSelector, } from '@/models/calculation/selectors';
import { comparingProductsSelector } from '@/models/comparing/selectors';
import { analytics } from '@/libs/analytics';
import { comparingActions } from '@/models/comparing';
import { isIframe } from '@/libs/helpers';
import { getMatchingOffersAmount } from './helpers';
import { COMPARING_MODAL_NAME } from './const';
import { useComparingButtonStylesStyles } from './comparingButtonStyles';
import { ComparingButtonOffersLogos, COMPARING_BUTTON_OFFER_LOGOS_ITEM_DELAY, getComparingButtonLogosItemOffset, } from './ComparingButtonOffersLogos';
export const ComparingButtonOffers = () => {
    if (isIframe()) {
        return null;
    }
    return _jsx(Button, {});
};
const MAX_OFFER_LOGOS_AMOUNT = 6;
const Button = memo(() => {
    const classes = useComparingButtonStylesStyles();
    const dispatch = useDispatch();
    const matchedOffers = useSelector(matchedOffersSelector);
    const isLoadingCalculation = useSelector(calculationIsLoadingSelector);
    const comparing = useSelector(comparingProductsSelector);
    const matchingOffersAmount = getMatchingOffersAmount(comparing, matchedOffers);
    const isClickable = !isLoadingCalculation && Boolean(matchingOffersAmount);
    const onClick = () => {
        if (!isClickable) {
            return;
        }
        dispatch(comparingActions.setWasAddingUsed());
        dispatch(modalActions.open({ name: COMPARING_MODAL_NAME }));
        analytics.sendEvent({
            action: 'comparison_opened',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    };
    const variants = {
        hidden: { y: 70, scale: 0.5, opacity: 0.6 },
        visible: { y: 0, scale: 1, opacity: 1 },
    };
    const buttonVisibility = Boolean(matchingOffersAmount)
        ? 'visible'
        : 'hidden';
    return (_jsxs(motion.button, { variants: variants, initial: buttonVisibility, animate: buttonVisibility, whileHover: { scale: isClickable ? 1.02 : 1 }, whileTap: { scale: 1 }, onClick: onClick, className: classes.offersButton, "data-disabled": !isClickable, children: [_jsx(motion.div, { children: _jsx(ComparingButtonOffersLogos, { isVisible: isClickable, maxItemsAmount: MAX_OFFER_LOGOS_AMOUNT }) }), _jsx(motion.div, { className: classes.offersButtonTitle, variants: {
                    initial: {
                        left: 12,
                        transition: {
                            delay: COMPARING_BUTTON_OFFER_LOGOS_ITEM_DELAY *
                                matchingOffersAmount,
                        },
                    },
                    animate: {
                        left: getComparingButtonLogosItemOffset(Math.min(matchingOffersAmount, MAX_OFFER_LOGOS_AMOUNT)) + 50,
                    },
                }, initial: "initial", animate: isLoadingCalculation ? 'initial' : 'animate', children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u0441\u0440\u0430\u0432\u043D\u0435\u043D\u0438\u044E" }), _jsx("div", { className: classes.offersButtonArrowWrapper, children: _jsx(ChevronIcon, {}) })] }));
});
