import { addYears } from '@che-ins-ui/datepicker';
import { MAX_AGE, MIN_INSURER_AGE } from '@/libs/constants';
import { DEFAULT_FIELD_ERROR, dateRegexp } from './constants';
const isNotEmpty = (value) => Boolean(value?.trim());
const isMatchingRegexp = (value) => dateRegexp.test(value);
const isWithinAgeLimit = (value) => {
    try {
        const MAX_AGE = 100;
        const maxAllowedDate = new Date();
        maxAllowedDate.setFullYear(maxAllowedDate.getFullYear() - MAX_AGE);
        const [day, month, year] = value.split('.');
        const date = new Date(`${year}-${month}-${day}`);
        return date >= maxAllowedDate;
    }
    catch (error) {
        return false;
    }
};
export const isAboveMinInsurerAge = (value) => {
    try {
        const today = addYears(new Date(), -MIN_INSURER_AGE);
        const [day, month, year] = value.split('.');
        const date = new Date(`${year}-${month}-${day}`);
        return date <= today;
    }
    catch (error) {
        return false;
    }
};
const notLaterThanToday = (value) => {
    try {
        const today = new Date();
        const [day, month, year] = value.split('.');
        const date = new Date(`${year}-${month}-${day}`);
        return date <= today;
    }
    catch (error) {
        return false;
    }
};
export const validateDate = (value, { isInsurer } = { isInsurer: false }) => {
    const validation = [
        {
            validator: isNotEmpty,
            message: DEFAULT_FIELD_ERROR,
        },
        {
            validator: isMatchingRegexp,
            message: 'Введите дату в формате ДД.ММ.ГГГГ',
        },
        {
            validator: notLaterThanToday,
            message: 'Дата не может быть позже текущей',
        },
        {
            validator: isWithinAgeLimit,
            message: `Не старше ${MAX_AGE} лет`,
        },
    ];
    if (isInsurer) {
        validation.push({
            validator: isAboveMinInsurerAge,
            message: `Страхователь должен быть старше ${MIN_INSURER_AGE} лет`,
        });
    }
    const error = validation.find(({ validator }) => !validator(value));
    return { error: error ? error.message : null };
};
