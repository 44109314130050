import { createAsyncThunk } from '@reduxjs/toolkit';
import { isIframe } from '@/libs/helpers';
import { getPersons } from '../notebook/getPersons';
import { requestActions } from '../request';
import { setFormsFromUserInfo } from '../forms/setFormsFromUserInfo';
import { getUserToken, headersWithToken } from './helpers';
import { getPartnerCommissionRate } from './getPartnerCommissionRate';
import { userActions } from './index';
export const getUserInfo = createAsyncThunk('/user', async (_, thunkApi) => {
    const userToken = getUserToken();
    const resetStatus = () => {
        thunkApi.dispatch(userActions.setAuthStatus('unauthenticated'));
        thunkApi.dispatch(userActions.setRole(null));
    };
    if (!userToken || isIframe()) {
        resetStatus();
        return;
    }
    try {
        const response = await fetch(`${__AUTH_API_URL__}user`, {
            method: 'GET',
            ...headersWithToken(userToken),
        });
        const data = await response.json();
        if (!response.ok || !data?.uuid) {
            throw new Error(response.status.toString());
        }
        const { isAdmin, partnerId, phone, countryCode, email } = data;
        const getRole = () => {
            if (isAdmin) {
                return 'admin';
            }
            else if (partnerId) {
                return 'partner';
            }
            return null;
        };
        const role = getRole();
        thunkApi.dispatch(userActions.setAuthStatus('authenticated'));
        thunkApi.dispatch(userActions.setRole(role));
        if (role === 'admin') {
            thunkApi.dispatch(userActions.setAdminPartnerId(partnerId));
        }
        if (role === 'partner') {
            thunkApi.dispatch(requestActions.setPartnerId(partnerId));
            thunkApi.dispatch(getPartnerCommissionRate());
        }
        thunkApi.dispatch(setFormsFromUserInfo({ role, phone, countryCode, email }));
        thunkApi.dispatch(getPersons());
    }
    catch (error) {
        resetStatus();
    }
});
