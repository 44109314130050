export var Environments;
(function (Environments) {
    Environments["DEVELOPMENT"] = "development";
    Environments["PRODUCTION"] = "production";
})(Environments || (Environments = {}));
export var BreadcrumbCategories;
(function (BreadcrumbCategories) {
    BreadcrumbCategories["XHR"] = "xhr";
    BreadcrumbCategories["CONSOLE"] = "console";
})(BreadcrumbCategories || (BreadcrumbCategories = {}));
export var ErrorCustomParametres;
(function (ErrorCustomParametres) {
    ErrorCustomParametres["STATE"] = "state";
    ErrorCustomParametres["SOURCE"] = "source";
    ErrorCustomParametres["ERROR"] = "error";
})(ErrorCustomParametres || (ErrorCustomParametres = {}));
export var ErrorSourceNames;
(function (ErrorSourceNames) {
    ErrorSourceNames["CLIENT"] = "client";
    ErrorSourceNames["API"] = "api";
    ErrorSourceNames["EXTERNAL"] = "external";
})(ErrorSourceNames || (ErrorSourceNames = {}));
export var ServiceType;
(function (ServiceType) {
    ServiceType["FULL"] = "full";
    ServiceType["PARTIAL"] = "partial";
    ServiceType["NOTCOVERED"] = "notCovered";
})(ServiceType || (ServiceType = {}));
