import { activeRestServicesCodes, mainServicesCodes, popularServicesCodes, recommendServices, additionalServicesCodes, URGENCY_STOMATOLOGY_CODE, workAndEducationServicesCodes, } from '../static';
function carriedFilterService(availableServices) {
    return ({ code }) => availableServices.includes(code);
}
export const getMainServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(mainServicesCodes));
};
export const getPopularServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(popularServicesCodes));
};
export const getMandatoryServices = (apiServices) => {
    return apiServices.filter(({ serviceValues, code }) => code !== 'pcr' &&
        (serviceValues.length === 1 || code === URGENCY_STOMATOLOGY_CODE));
};
export const getRecomendServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(recommendServices));
};
export const getActiveRestServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(activeRestServicesCodes));
};
export const getAdditionalServices = (apiServices) => {
    return [
        ...apiServices.filter(carriedFilterService(additionalServicesCodes)),
    ].sort((a, b) => additionalServicesCodes.indexOf(a.code) -
        additionalServicesCodes.indexOf(b.code));
};
export const getWorkAndEducationServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(workAndEducationServicesCodes));
};
export const transformServicesArrayToDictionary = (apiServices) => {
    return apiServices.reduce((acc, { code, ...service }) => ({ ...acc, [code]: service }), {});
};
export const buildDirectionDictionary = (directions) => {
    return directions.reduce((acc, { code, name }) => ({ ...acc, [code]: name }), {});
};
