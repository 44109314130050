import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { modalSelector } from '@che-ins-ui/modal';
import { codeSelector, emailConfirmationStatusSelector, processActions, } from '@/models/process';
import { EmailConfirmationStatuses, KeyboardKey, } from '@/models/process/consts';
import { validationCode } from '@/libs/helpers/utils';
import { EMAIL_CONFIRMATION_MODAL_NAME } from '../EmailConfirmationModal/const';
import { DEFAULT_CODE, inputDefaultValue, inputMode } from './constants';
import { useCodeFieldsStyles } from './styles';
import CodeInput from './CodeInput';
export const CodeFields = ({ focusedIndex, setFocusedIndex, setFocusAtApplyButton, }) => {
    const classes = useCodeFieldsStyles();
    const dispatch = useDispatch();
    const { isOpen } = useSelector(modalSelector(EMAIL_CONFIRMATION_MODAL_NAME));
    const emailConfirmationStatus = useSelector(emailConfirmationStatusSelector);
    const code = useSelector(codeSelector);
    const firstFieldRef = useRef(null);
    const secondFieldRef = useRef(null);
    const thirdFieldRef = useRef(null);
    const fourthFieldRef = useRef(null);
    const refs = [firstFieldRef, secondFieldRef, thirdFieldRef, fourthFieldRef];
    useEffect(() => {
        if (isOpen) {
            dispatch(processActions.setCode(DEFAULT_CODE));
            setTimeout(() => {
                firstFieldRef.current?.focus();
            }, 10);
        }
    }, [isOpen]);
    useEffect(() => {
        const isValidAndFilledCode = code.every((symbol) => /^\d+$/.test(symbol));
        if (isValidAndFilledCode) {
            setFocusedIndex(null);
            setFocusAtApplyButton();
        }
    }, [code]);
    useEffect(() => {
        if (emailConfirmationStatus === 'error') {
            fourthFieldRef.current?.focus();
        }
    }, [emailConfirmationStatus]);
    const handleSetCodeByIndex = (inputValue, index) => {
        const value = inputValue.length === 1 ? inputValue : inputValue?.[1] || '_';
        let newCode = [...code];
        newCode[index] = value;
        dispatch(processActions.setCode([...newCode]));
    };
    const handleInputPaste = (e) => {
        e.preventDefault();
        const pastedValue = e.clipboardData.getData('text');
        const { isValid, code } = validationCode(pastedValue);
        if (!isValid || !code) {
            return;
        }
        dispatch(processActions.setCode(code.split('')));
        setFocusedIndex(null);
        setFocusAtApplyButton();
    };
    const isFocusedAndEmpty = (index) => focusedIndex === index && code[index] === '_';
    const isFocusedAndFilled = (index) => focusedIndex === index && code[index] !== '_';
    return (_jsxs("div", { className: classes.codeInputWrapper, children: [_jsx("ul", { className: classes.unorderedList, children: code.map((value, index) => {
                    const isDisabled = Boolean(index &&
                        (!value || value === inputDefaultValue) &&
                        (!code[index - 1] ||
                            code[index - 1] === inputDefaultValue));
                    const onChange = (event) => {
                        if (!Number.isInteger(Number(event.target.value))) {
                            return;
                        }
                        dispatch(processActions.setEmailConfirmationStatus(EmailConfirmationStatuses.none));
                        handleSetCodeByIndex(event.target.value, index);
                        if (!refs[index + 1] || !event.target.value) {
                            return;
                        }
                        if (!refs[index + 1].current?.value ||
                            refs[index + 1].current?.value === inputDefaultValue) {
                            setTimeout(() => {
                                refs[index + 1].current?.focus();
                            }, 50);
                        }
                    };
                    const onFocus = (index) => {
                        if (value !== inputDefaultValue) {
                            return;
                        }
                        handleSetCodeByIndex('', index);
                        setFocusedIndex(index);
                    };
                    const onBlur = (event) => {
                        if (event.target.value) {
                            return;
                        }
                        handleSetCodeByIndex(inputDefaultValue, index);
                    };
                    const onKeyDown = (event) => {
                        if (emailConfirmationStatus === 'error' &&
                            (event.key === KeyboardKey.Delete ||
                                event.key === KeyboardKey.Backspace)) {
                            dispatch(processActions.setCode(DEFAULT_CODE));
                            setFocusedIndex(0);
                            dispatch(processActions.setEmailConfirmationStatus(EmailConfirmationStatuses.none));
                            return refs[0].current?.focus();
                        }
                        if (event.key === KeyboardKey.Delete ||
                            event.key === KeyboardKey.Backspace) {
                            setFocusedIndex(index === 0 ? 0 : index - 1);
                            if (index) {
                                handleSetCodeByIndex(inputDefaultValue, index);
                                refs[index].current?.blur();
                            }
                            else {
                                handleSetCodeByIndex('', index);
                            }
                            for (let i = index - 1; i >= 0; i--) {
                                if (refs[i].current?.value &&
                                    refs[i].current?.value !== inputDefaultValue) {
                                    refs[i].current?.focus();
                                    break;
                                }
                            }
                        }
                    };
                    return (_jsx("li", { className: classNames(classes.codeItem, isFocusedAndEmpty(index) &&
                            classes.isFocusedAndEmpty, isFocusedAndFilled(index) &&
                            classes.isFocusedAndFilled, emailConfirmationStatus === 'error' &&
                            classes.isError), children: _jsx(CodeInput, { disabled: isDisabled, error: emailConfirmationStatus === 'error', index: index, inputMode: inputMode, name: `code${index}`, ref: refs[index], success: emailConfirmationStatus === 'success', value: value, onBlur: onBlur, onChange: onChange, onFocus: onFocus, onKeyDown: onKeyDown, onPaste: handleInputPaste }) }, `codeInputItem${index}`));
                }) }), emailConfirmationStatus === 'error' && (_jsx("span", { children: "\u0412\u0432\u0435\u0434\u0451\u043D \u043D\u0435\u0432\u0435\u0440\u043D\u044B\u0439 \u043A\u043E\u0434" }))] }));
};
