import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { Button, IconButton } from '@che-ins-ui/button';
import { CrossIcon } from '@che-ins-ui/icons/CrossIcon';
import { modalActions } from '@che-ins-ui/modal';
import { comparingDifferencesOnlySelector, comparingProductsSelector, } from '@/models/comparing/selectors';
import { comparingActions } from '@/models/comparing';
import { matchedOffersSelector } from '@/models/calculation/selectors';
import { analytics } from '@/libs/analytics';
import { useStyles } from './styles';
import { COMPARING_MODAL_NAME } from './const';
import { getMatchingOffersAmount } from './helpers';
export const ComparingHeader = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const differencesOnly = useSelector(comparingDifferencesOnlySelector);
    const matchedOffers = useSelector(matchedOffersSelector);
    const comparing = useSelector(comparingProductsSelector);
    const matchingOffersAmount = getMatchingOffersAmount(comparing, matchedOffers);
    const toggleDifferencesOnly = (value) => {
        dispatch(comparingActions.setDifferenceOnly(value));
        analytics.sendEvent({
            action: 'toggle_only_differences',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    };
    const onClose = () => {
        dispatch(modalActions.close({
            name: COMPARING_MODAL_NAME,
        }));
    };
    return (_jsx("div", { className: classes.headerWrapper, children: _jsxs("div", { className: classes.header, children: [matchingOffersAmount > 1 && (_jsxs(_Fragment, { children: [_jsx(Checkbox, { label: "\u0412\u0441\u0435 \u043E\u043F\u0446\u0438\u0438", value: !differencesOnly, onChange: () => {
                                toggleDifferencesOnly(false);
                            } }), _jsx(Checkbox, { label: "\u0422\u043E\u043B\u044C\u043A\u043E \u0440\u0430\u0437\u043B\u0438\u0447\u0438\u044F", value: differencesOnly, onChange: () => {
                                toggleDifferencesOnly(true);
                            } })] })), _jsx("div", { className: classes.right, children: _jsx(Button, { appearance: "comparing-clear", onClick: () => {
                            onClose();
                            dispatch(comparingActions.clearComparing());
                        }, children: "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u044C \u0441\u0440\u0430\u0432\u043D\u0435\u043D\u0438\u0435" }) }), _jsx(IconButton, { icon: _jsx(CrossIcon, {}), baseAppearance: "compare-close", onClick: onClose })] }) }));
};
export const Checkbox = memo(({ label, value: isActive, onChange }) => {
    const classes = useStyles();
    const roundedVariants = {
        inactive: {
            scale: 0,
            opacity: 0,
            transition: { type: 'just', opacity: { duration: 0.15 } },
        },
        active: { scale: 1, opacity: 1 },
    };
    return (_jsxs("button", { className: classes.checkbox, onClick: onChange, id: "checkbox", children: [_jsx("div", { className: classes.checkboxIcon, "data-active": isActive, role: "checkbox", "aria-checked": isActive, children: _jsx(motion.div, { variants: roundedVariants, initial: isActive ? 'active' : 'inactive', animate: isActive ? 'active' : 'inactive', className: classes.checkboxCircle }) }), _jsx("span", { className: classes.checkboxLabel, children: label })] }));
});
