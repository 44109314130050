import { HEADER_COMPONENTS_NAMES } from '@che-ins-ui/header';
export const getHeaderTheme = (theme) => ({
    base: {
        [HEADER_COMPONENTS_NAMES.headerWrapper]: {
            minWidth: 320,
        },
        [HEADER_COMPONENTS_NAMES.whyNew]: {
            fontFamily: theme.font.primary,
        },
        [HEADER_COMPONENTS_NAMES.menuListItem]: {
            '&:hover': {
                color: theme.colors.brand,
                backgroundColor: theme.colors.brandTertiary,
            },
        },
        [HEADER_COMPONENTS_NAMES.menuListItemIcon]: {
            '& svg > path': {
                stroke: 'transparent',
                fill: theme.colors.brand,
            },
        },
        [HEADER_COMPONENTS_NAMES.profileWrapper]: {
            fontFamily: theme.font.primary,
        },
        [HEADER_COMPONENTS_NAMES.title]: {
            fontFamily: theme.font.primary,
        },
        [HEADER_COMPONENTS_NAMES.description]: {
            fontFamily: theme.font.primary,
        },
        [HEADER_COMPONENTS_NAMES.navigation]: {
            minWidth: 320,
            backgroundColor: theme.colors.header,
        },
        [HEADER_COMPONENTS_NAMES.tooltipBody]: {
            fontFamily: theme.font.primary,
        },
        [HEADER_COMPONENTS_NAMES.authWrapper]: {
            overflow: 'visible',
        },
        [HEADER_COMPONENTS_NAMES.dropdownBody]: {
            backgroundColor: theme.colors.cloud,
        },
        [HEADER_COMPONENTS_NAMES.menuList]: {
            '& div > div': {
                backgroundColor: `${theme.colors.textTertiary} !important`,
            },
        },
        [HEADER_COMPONENTS_NAMES.menuTopListItem]: {
            '&:hover': {
                color: theme.colors.sky,
                backgroundColor: theme.colors.brandTertiary,
            },
        },
    },
});
