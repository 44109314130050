import { createUseStyles } from 'react-jss';
import { mixins } from '@/styles/mixins';
import { DEKSTOP_FONT_SIZES, GLOBAL_LETTER_SPACING, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    root: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 20px)',
        maxWidth: 'calc(100vw - 20px)',
        width: 'max-content',
        minHeight: 0,
    },
    headerWrapper: {
        width: '100%',
        height: 60,
    },
    header: {
        zIndex: 3,
        display: 'flex',
        gap: 18,
        alignItems: 'center',
        marginBottom: 17,
        padding: '15px 15px 3px',
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.colors.cloud,
        borderRadius: '12px 12px 0 0',
    },
    loader: {
        width: '100%',
        height: 200,
        minWidth: 700,
        ...mixins.centeredContent(),
    },
    sorting: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    sortingMenu: {
        display: 'flex',
        overflow: 'hidden',
    },
    sortingItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&[data-active="true"]': {
            zIndex: 1,
            backgroundColor: theme.colors.cloud,
        },
    },
    sortingDropdownIcon: {
        cursor: 'pointer',
        '& svg > path': {
            fill: theme.colors.brand,
        },
    },
    right: {
        userSelect: 'none',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 18,
    },
    view: {
        display: 'flex',
        alignItems: 'center',
        fontSize: DEKSTOP_FONT_SIZES.main,
        color: theme.colors.sky,
        letterSpacing: GLOBAL_LETTER_SPACING,
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
    },
    iconText: {
        marginTop: 2,
        textAlign: 'end',
        whiteSpace: 'nowrap',
        '& p': {
            width: 'max-content',
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    priceButton: {
        width: '100%',
        justifyContent: 'center',
        margin: '-2px -6px',
        cursor: 'pointer',
        color: theme.colors.success,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 12,
        gap: 8,
        backgroundColor: 'transparent',
        border: 'none',
        '& svg': {
            transform: 'rotate(180deg)',
            '& path': {
                stroke: theme.colors.success,
            },
        },
    },
    priceLabel: {
        marginTop: -1,
        fontSize: 17,
        userSelect: 'none',
    },
    price: {
        zIndex: 1,
        fontSize: 19,
        fontWeight: 500,
    },
    priceIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            width: 10,
            transition: theme.transition.global,
        },
        '&[data-hovered="true"]': {
            '& svg': {
                width: 9,
            },
        },
    },
    checkbox: {
        width: 'max-content',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        height: 24 + 8,
        padding: 8,
        margin: -8,
        userSelect: 'none',
        borderRadius: 12,
    },
    checkboxLabel: {
        color: theme.colors.sky,
        fontSize: DEKSTOP_FONT_SIZES.main,
    },
    checkboxIcon: {
        width: 20,
        height: 20,
        flexShrink: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid rgba(113, 130, 153, 0.45)',
        borderRadius: 100,
        '&[data-active="true"]': {
            borderWidth: 2,
            borderColor: theme.colors.brand,
        },
    },
    checkboxCircle: {
        width: 12,
        height: 12,
        flexShrink: 0,
        backgroundColor: theme.colors.brand,
        borderRadius: 100,
    },
}), { name: 'comparing' });
