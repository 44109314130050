import { configureStore, createListenerMiddleware, } from '@reduxjs/toolkit';
import isDeepEqual from 'fast-deep-equal';
import { activePanelNameTabsSelector } from '@che-ins-ui/tabs';
import { LSHelper } from '@/libs/localStorage';
import { normalizeBasename } from '@/libs/helpers/normalizeBasename';
import { isIframe } from '@/libs/helpers';
import rootReducers from './models/rootReducers';
import { getPersistedState } from './persistedState';
import { DEBOUNCE_CALCULATION_INTERVAL_MS, LOCALSTORAGE_MODELS, } from './libs/constants';
import { fetchCalculation } from './models/calculation';
import { BASKET_STATE_NAMESPACE, basketActions } from './models/basket';
import { INSURANCE_TABS_NAME, TABS_PANELS_NAMES, } from './components/insurance/consts';
import { getCalculationQueryString } from './libs/helpers/utils';
import { requestActions } from './models/request';
import { ROUTES } from './components/routes';
import { analyticsMiddlewares } from './libs/analytics';
import { requestSelector } from './models/request/selectors';
import { fetchUpsell } from './models/upsell/fetchUpsell';
import { COMPARING_STATE_NAMESPACE } from './models/comparing';
import { upsellSelector } from './models/upsell/selectors';
import { META_PARAM_TO_FORCE_RECALCULATION_ON_BIRTHDAY_COMPLETE } from './models/forms';
import { FORMS_STATE_NAMESPACE } from './models/forms';
import { touristsForApiSelector } from './models/forms/selectors';
import { USER_STATE_NAMESPACE } from './models/user';
import { FUNCTIONAL_STATE_NAMESPACE } from './models/functional';
export const REQUEST_STATE_NAMESPACE = 'request';
const preloadedState = getPersistedState();
const calculateListenerMiddleware = createListenerMiddleware();
const calculationQueryStringListenerMiddleware = createListenerMiddleware();
const recalculateUpsellListenerMiddleware = createListenerMiddleware();
const mapTouristToRecalculate = ({ birthday, age, }) => ({
    birthday,
    age,
});
const mapTouristToChangeQueryString = ({ age }) => age;
const startCalculationQueryStringListening = calculationQueryStringListenerMiddleware.startListening;
startCalculationQueryStringListening({
    predicate: (action, currentState, previousState) => {
        const pathname = window.location.pathname;
        const isCalculationPage = [
            ROUTES.calculation,
            `${ROUTES.calculation}/`,
        ].some((calculationPath) => {
            return pathname.endsWith(calculationPath);
        });
        const newTourists = touristsForApiSelector(currentState).map(mapTouristToChangeQueryString);
        const oldTourists = touristsForApiSelector(previousState).map(mapTouristToChangeQueryString);
        const areTouristsSame = isDeepEqual(newTourists, oldTourists);
        const isRequestChanged = action.type?.startsWith(REQUEST_STATE_NAMESPACE);
        return isCalculationPage && (isRequestChanged || areTouristsSame);
    },
    effect: async (_, listenerApi) => {
        const state = listenerApi.getState();
        const { basename } = requestSelector(state);
        const queryParams = getCalculationQueryString(state);
        const normalizedBasename = normalizeBasename(basename);
        const path = `${normalizedBasename}${ROUTES.calculation}?${queryParams}`;
        window.history.replaceState(null, '', path);
    },
});
const startRecalculationUpsellListening = recalculateUpsellListenerMiddleware.startListening;
startRecalculationUpsellListening({
    predicate: (action, currentState) => {
        const pathname = window.location.pathname;
        const isInsurancePage = pathname.includes(ROUTES.insurance);
        if (!isInsurancePage) {
            return false;
        }
        const activePanelNameSelector = activePanelNameTabsSelector(INSURANCE_TABS_NAME);
        const activePanelName = activePanelNameSelector(currentState);
        const { isLoading: isLoadingUpsell } = upsellSelector(currentState);
        if (activePanelName !== TABS_PANELS_NAMES.UPSELL || isLoadingUpsell) {
            return false;
        }
        return (action.type?.startsWith(REQUEST_STATE_NAMESPACE) ||
            action.type === basketActions.changeProduct.type);
    },
    effect: async (_, { dispatch }) => {
        dispatch(fetchUpsell());
    },
});
const startCalculateListening = calculateListenerMiddleware.startListening;
startCalculateListening({
    predicate: (action, currentState, previousState) => {
        const isPageWithoutCalculation = [ROUTES.success].includes(window.location.pathname);
        if (isPageWithoutCalculation || action.meta?.skipCalculation)
            return false;
        const newRequest = currentState[REQUEST_STATE_NAMESPACE];
        const oldRequest = previousState[REQUEST_STATE_NAMESPACE];
        const hasSelectedCountries = !!newRequest.countries.length || !!newRequest.countryGroups.length;
        if (!hasSelectedCountries)
            return false;
        if (action.type?.startsWith(REQUEST_STATE_NAMESPACE)) {
            return !isDeepEqual(newRequest, oldRequest);
        }
        if (action.meta?.startRecalculation) {
            const newTourists = touristsForApiSelector(currentState).map(mapTouristToRecalculate);
            const oldTourists = touristsForApiSelector(previousState).map(mapTouristToRecalculate);
            const areTouristsSame = isDeepEqual(newTourists, oldTourists);
            if (areTouristsSame &&
                !action.meta?.[META_PARAM_TO_FORCE_RECALCULATION_ON_BIRTHDAY_COMPLETE]) {
                return false;
            }
            const everyTouristHasNormalBirthday = newTourists.every(({ birthday }) => Boolean(birthday));
            return action.meta?.checkTouristsBirthdays
                ? everyTouristHasNormalBirthday
                : true;
        }
        return false;
    },
    effect: async (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        const isInsuranceFormsPagePage = window.location.pathname === ROUTES.insurance;
        if (action.type === requestActions.initializeCurrency.toString() ||
            action.meta?.forceRecalculation ||
            isInsuranceFormsPagePage) {
            listenerApi.dispatch(fetchCalculation());
        }
        else {
            await listenerApi.delay(DEBOUNCE_CALCULATION_INTERVAL_MS);
            listenerApi.dispatch(fetchCalculation());
        }
    },
});
const localeStorageMiddleware = createListenerMiddleware();
const localeStorageStart = localeStorageMiddleware.startListening;
localeStorageStart({
    predicate: (action) => {
        if (isIframe())
            return false;
        if (action.type?.startsWith(REQUEST_STATE_NAMESPACE) ||
            action.type?.startsWith(FORMS_STATE_NAMESPACE) ||
            action.type?.startsWith(BASKET_STATE_NAMESPACE) ||
            action.type?.startsWith(COMPARING_STATE_NAMESPACE) ||
            action.type?.startsWith(USER_STATE_NAMESPACE) ||
            action.type?.startsWith(FORMS_STATE_NAMESPACE) ||
            action.type?.startsWith(FUNCTIONAL_STATE_NAMESPACE))
            return true;
        return false;
    },
    effect: (_action, listenerApi) => {
        const state = listenerApi.getState();
        const statesToStorage = {};
        LOCALSTORAGE_MODELS.forEach((stateNamespace) => {
            switch (stateNamespace) {
                case BASKET_STATE_NAMESPACE:
                case COMPARING_STATE_NAMESPACE:
                case USER_STATE_NAMESPACE:
                case FORMS_STATE_NAMESPACE:
                case REQUEST_STATE_NAMESPACE:
                case FUNCTIONAL_STATE_NAMESPACE:
                    statesToStorage[stateNamespace] = state[stateNamespace];
                    break;
            }
        });
        LSHelper.set(statesToStorage);
    },
});
function configureAppStore() {
    const store = configureStore({
        reducer: rootReducers,
        preloadedState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(calculateListenerMiddleware.middleware, localeStorageMiddleware.middleware, calculationQueryStringListenerMiddleware.middleware, recalculateUpsellListenerMiddleware.middleware, ...analyticsMiddlewares),
        devTools: __ENV__ !== 'production',
        enhancers: [],
    });
    if (__ENV__ !== 'production' && module.hot) {
        module.hot.accept('./models/rootReducers.ts', () => store.replaceReducer(rootReducers));
    }
    return store;
}
const store = configureAppStore();
export const appState = store;
