import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { UpsellCard } from './UpsellCard';
import { UpsellSkeleton } from './UpsellSkeleton';
export const UpsellCards = ({ cards, isLoading, getCompensationText, getPrice, }) => {
    if (isLoading) {
        return _jsx(UpsellSkeleton, {});
    }
    const filteredCards = cards.filter(({ isShow }) => isShow);
    return (_jsx(_Fragment, { children: filteredCards.map((card) => (_jsx(UpsellCard, { ...card, getCompensationText: getCompensationText, getPrice: getPrice }, card.title))) }));
};
