import { createAsyncThunk } from '@reduxjs/toolkit';
import { notify } from '@che-ins-ui/notification';
import { getErrorMessage, toErrorWithMessage } from '@/libs/helpers';
import { captureError } from '@/libs/helpers/utils';
import { insurerEmailSelector } from '../forms/selectors';
export const sendConfirmationCode = createAsyncThunk('process/emailConfirmation', async (_, listenerApi) => {
    try {
        const email = insurerEmailSelector(listenerApi.getState());
        const body = { email };
        await fetch(`${__VZR_API_URL__}antiDos/sendCode`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
    }
    catch (error) {
        captureError(toErrorWithMessage(error), 'process/emailConfirmation');
        notify.error({
            title: 'Send code error',
            message: getErrorMessage(error),
            autoClose: 60 * 1000,
        });
        console.error(error);
        throw new Error('process/sendConfirmationCode');
    }
});
