import { createUseStyles } from 'react-jss';
import { mixins } from '@/styles/mixins';
import { withAlpha } from '@/styles/utils';
import { DEKSTOP_FONT_SIZES } from '@/styles/themes/components';
export const HEADER_HEIGHT = 52;
export const useStyles = createUseStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
        height: HEADER_HEIGHT,
        background: theme.colors.header,
        transition: '0.3s',
        zIndex: 100,
        '&:not([data-not-centered="true"])': {
            ...mixins.centeredContent(),
        },
    },
    leadingWrapper: {
        height: 32,
        display: 'flex',
    },
    leadingLink: {
        padding: '0 4.5px',
        height: '100%',
    },
    leadingLinkLabel: {
        height: '100%',
        border: '1px solid',
        borderColor: withAlpha(theme.colors.white, 0.55),
        transition: theme.transition.global,
        borderRadius: 999,
        display: 'flex',
        alignItems: 'center',
        padding: '0 12px',
        fontSize: DEKSTOP_FONT_SIZES.main,
        color: theme.colors.white,
        textDecoration: 'none',
        '&:hover': {
            borderColor: withAlpha(theme.colors.white, 1),
        },
    },
    tooltipBodyDark: {
        backgroundColor: theme.colors.sky + ' !important',
        color: theme.colors.cloud + ' !important',
    },
    tooltipArrowDark: {
        backgroundColor: theme.colors.sky + ' !important',
    },
}), { name: 'header' });
