import { createSelector } from '@reduxjs/toolkit';
import { MULTIPOLICY_CODE } from '@/libs/static';
import { isIframe } from '@/libs/helpers';
import { VASCO_PARTNER_ID } from '@/libs/constants';
import { REQUEST_STATE_NAMESPACE } from './consts';
export const requestSelector = (state) => state[REQUEST_STATE_NAMESPACE];
export const isFrameSelector = createSelector(requestSelector, ({ isFrame }) => isFrame);
export const isIgnoreUpsellSelector = createSelector(requestSelector, ({ isIgnoreUpsell }) => isIgnoreUpsell && isIframe());
export const isIgnoreFormSelector = createSelector(requestSelector, ({ isIgnoreForm }) => isIgnoreForm);
export const isLogoSelector = createSelector(requestSelector, ({ isLogo }) => isLogo);
export const requestSportsSelector = createSelector(requestSelector, ({ sports }) => sports);
export const requestServiceSelector = createSelector(requestSelector, ({ services }) => services);
export const requestServicesLengthSelector = createSelector(requestSelector, ({ services }) => Object.values(services).filter(Boolean).length);
export const requestIsServiceSelectedSelector = (code) => createSelector(requestServiceSelector, (services) => !!services[code]);
export const requestAssistancesSelector = createSelector(requestSelector, ({ assistances }) => assistances);
export const requestCurrencySelector = createSelector(requestSelector, ({ currency }) => currency);
export const requestIsCurrencyInitedSelector = createSelector(requestSelector, ({ isCurrencyInited }) => isCurrencyInited);
export const isMultipolicySelectedSelector = createSelector(requestSelector, ({ services }) => !!services[MULTIPOLICY_CODE]);
export const dateStartSelector = createSelector(requestSelector, ({ dateStart }) => dateStart);
export const dateEndSelector = createSelector(requestSelector, ({ dateEnd }) => dateEnd);
export const isVascoPartnerSelector = createSelector(requestSelector, ({ partnerId }) => partnerId === VASCO_PARTNER_ID && location.host.includes('vasco'));
export const partnerIdSelector = createSelector(requestSelector, ({ partnerId }) => partnerId);
