import { PHONE_INPUT_COMPONENTS_NAMES } from '@che-ins-ui/phone-input';
export const getPhoneInputTheme = (theme) => ({
    base: {
        [PHONE_INPUT_COMPONENTS_NAMES.phoneWrapper]: {
            backgroundColor: theme.colors.field,
            borderColor: theme.colors.textTertiary,
            transition: theme.transition.global,
        },
        [PHONE_INPUT_COMPONENTS_NAMES.phoneInput]: {
            transition: 'none',
            color: theme.colors.sky,
        },
        [PHONE_INPUT_COMPONENTS_NAMES.placeHolder]: {
            color: theme.colors.textSecondary,
        },
        [PHONE_INPUT_COMPONENTS_NAMES.phoneInputContent]: {
            minWidth: '266px',
            backgroundColor: theme.colors.field,
            borderColor: theme.colors.textTertiary,
            transition: theme.transition.global,
            '&.isFocused': {
                borderColor: theme.colors.brand,
            },
            '&.withError': {
                borderColor: theme.colors.error,
            },
            [theme.breakpoints.up('md')]: {
                minWidth: '245px',
            },
        },
        [PHONE_INPUT_COMPONENTS_NAMES.popUpLabel]: {
            color: theme.colors.textSecondary,
            '&.isCorrect, &.withError, &.isActiveLabel': {
                color: theme.colors.textSecondary,
            },
            '&[data-hasvalue="true"]': {
                color: theme.colors.textPrimary,
            },
            '&[data-focused="true"]': {
                color: theme.colors.brand,
            },
        },
        [PHONE_INPUT_COMPONENTS_NAMES.icon]: {
            '& svg > path': {
                fill: theme.colors.brand,
            },
        },
        [PHONE_INPUT_COMPONENTS_NAMES.codeWrapper]: {
            borderColor: theme.colors.textTertiary,
        },
        [PHONE_INPUT_COMPONENTS_NAMES.checkedIcon]: {
            '& svg > path': {
                fill: theme.colors.brand,
            },
        },
        [PHONE_INPUT_COMPONENTS_NAMES.codeSelectionCountry]: {
            color: theme.colors.sky,
            '&:hover': {
                color: theme.colors.brand,
                background: theme.colors.brandTertiary,
            },
        },
        [PHONE_INPUT_COMPONENTS_NAMES.codeSelectionButton]: {
            backgroundColor: 'transparent',
            color: theme.colors.sky,
        },
        [PHONE_INPUT_COMPONENTS_NAMES.codeSelectionWrapper]: {
            backgroundColor: theme.colors.cloud,
        },
    },
});
