import { CONTAINER_COMPONENTS_NAMES } from '@che-ins-ui/container';
import { DESKTOP_SEARCH_FORM_BREAKPOINT, } from './consts';
export const getContainerTheme = (theme) => ({
    base: {
        [CONTAINER_COMPONENTS_NAMES.wrapper]: {
            padding: '0px 15px',
        },
    },
    page: {
        [CONTAINER_COMPONENTS_NAMES.wrapper]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '30px',
            margin: '16px auto',
            [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
                margin: '16px auto 40px',
            },
        },
    },
    'white-label-search-form': {
        [CONTAINER_COMPONENTS_NAMES.wrapper]: {
            flex: 'initial',
        },
    },
    faq: {
        [CONTAINER_COMPONENTS_NAMES.wrapper]: {
            maxWidth: 'calc(652px + 30px)',
            position: 'relative',
        },
    },
});
