import { createUseStyles } from 'react-jss';
export const useStyles = createUseStyles((theme) => ({
    root: {
        padding: '13.5px 0 1.5px 6.5px',
        [theme.breakpoints.up('lg')]: {
            padding: '1.5px 0 1.5px 1.5px',
        },
    },
    tooltipItem: {
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 4,
        [theme.breakpoints.up('lg')]: {
            marginBottom: 10,
            '&:last-child': {
                marginBottom: 0,
            },
        },
    },
    iconAlert: {
        marginRight: 7.5,
        flexShrink: 0,
    },
    iconClose: {
        marginLeft: 1,
        marginRight: 8.5,
        flexShrink: 0,
    },
}), { name: 'missing-services-hint' });
