import { createAsyncThunk } from '@reduxjs/toolkit';
import { nanoid } from '@reduxjs/toolkit';
import { addDays, formatDate } from '@che-ins-ui/datepicker';
import { basketActions } from '@/models/basket';
import { toErrorWithMessage, transformToJson } from '@/libs/helpers';
import { ROUTES } from '@/components/routes';
import { captureError, prepareDates } from '@/libs/helpers/utils';
import { overlayLoaderModalClose } from '@/components/common/OverlayLoaderModal';
import { requestActions } from '../request';
import { fetchCalculation } from '../calculation';
import { setFormsFromOrder } from '../forms/setFormsFromOrder';
export const getSharedOrder = createAsyncThunk('process/getSharedOrder', async (uuid, thunkApi) => {
    try {
        const orderResponse = await fetch(`${__VZR_API_URL__}orderImage/${uuid}`);
        if (orderResponse.status >= 400) {
            throw new Error('Get order error');
        }
        const order = await transformToJson(orderResponse);
        const { assistances = [], countries = [], countryGroups = [], currency, dateStart: dateStartFromSavedOrder, insuredDays, marker, marker2, affiliateTool: srcsale, partnerId, services, sports = [], productId, payment: { isPaytureInPay }, } = order;
        const dateEndFromSavedOrder = formatDate(addDays(dateStartFromSavedOrder, insuredDays - 1));
        const { dateStart, dateEnd } = prepareDates(dateStartFromSavedOrder, dateEndFromSavedOrder);
        const requestState = {
            isPaytureInPay,
            assistances,
            countryGroups,
            sports,
            countries,
            services,
            dateStart: dateStart,
            dateEnd: dateEnd,
            partnerId,
            marker,
            marker2,
            srcsale,
            currency: currency.toLowerCase(),
        };
        thunkApi.dispatch(setFormsFromOrder(order));
        thunkApi.dispatch(requestActions.init(requestState, { skipCalculation: true }));
        const setCalculationsStateAction = await thunkApi.dispatch(fetchCalculation());
        if (setCalculationsStateAction.type ===
            fetchCalculation.rejected.toString()) {
            throw new Error('Get calculation by order image error');
        }
        const { payload } = setCalculationsStateAction;
        const { partiallyOffers, matchedOffers } = payload;
        const product = [...partiallyOffers, ...matchedOffers].find((offer) => {
            return offer.calculation.productId === productId;
        });
        if (product) {
            thunkApi.dispatch(basketActions.addToBasket({
                product: product,
                lastChangesId: nanoid(),
            }));
        }
        else {
            throw new Error('Product not found');
        }
        window.history.pushState(null, '', ROUTES.insurance);
    }
    catch (error) {
        captureError(toErrorWithMessage(error), 'process/getSharedOrder');
        console.error(error);
        window.location.href = ROUTES.calculation;
    }
    finally {
        thunkApi.dispatch(overlayLoaderModalClose());
        thunkApi.dispatch(basketActions.setProductIsLoading(false));
    }
});
