import { createAsyncThunk } from '@reduxjs/toolkit';
import { createMaskedValue } from '@/libs/helpers/utils';
import { validateDate } from '@/libs/validation/validateDate';
import { NotebookFormFields } from './interface';
import { notebookActions } from './index';
const field = NotebookFormFields.birthday;
export const setNotebookFormBirthday = createAsyncThunk('', async (value, thunkApi) => {
    const maskedValue = createMaskedValue(value, {
        mask: '00.00.0000',
    });
    const { error } = validateDate(maskedValue);
    thunkApi.dispatch(notebookActions.setFormFieldValue({
        field,
        value: maskedValue,
    }));
    thunkApi.dispatch(notebookActions.setFormFieldError({ field, value: error }));
});
