import { recommendSports } from '../static';
export const getRecomendSports = (apiSports) => {
    return apiSports.filter(({ code }) => recommendSports.includes(code));
};
export const getCommonSports = (apiSports) => {
    return apiSports
        .filter(({ isCompetition }) => !isCompetition)
        .sort((first, second) => (first.name > second.name ? 1 : -1));
};
export const getCompetitionSports = (apiSports) => {
    return apiSports
        .filter(({ isCompetition }) => isCompetition)
        .sort((first, second) => (first.name > second.name ? 1 : -1));
};
