import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { memo } from 'react';
import { apiSelector } from '@/models/api/selectors';
import { searchResultsSelector } from '@/models/search/selectors';
import { workAndEducationServicesCodes } from '@/libs/static';
import { FilterLayout } from './FilterLayout';
import { FiltersList } from './FiltersList';
import { SearchBlockWrapper } from './SearchElementWrapper';
export const WorkAndEducationFilter = memo(() => {
    const { workAndEducationServices } = useSelector(apiSelector);
    const searchResults = useSelector(searchResultsSelector);
    return (_jsx(SearchBlockWrapper, { searchResults: searchResults, codes: workAndEducationServicesCodes, children: _jsx(FilterLayout, { heading: "\u0420\u0430\u0431\u043E\u0442\u0430 \u0438 \u0443\u0447\u0451\u0431\u0430", isLast: true, children: _jsx(FiltersList, { list: workAndEducationServices, searchResults: searchResults, withServiceInfoImage: true }) }) }));
});
