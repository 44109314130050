import { createListenerMiddleware, isAnyOf, } from '@reduxjs/toolkit';
import { getEcommerceYMAction, getEcommerceYMPurchaseAction, } from '@/models/process/utils';
import { basketActions } from '@/models/basket';
import { basketProductIdSelector, basketProductSelector, } from '@/models/basket/selectors';
import { fetchCalculation } from '@/models/calculation';
import { calculationLastRequestIdSelector } from '@/models/calculation/selectors';
import { requestActions } from '@/models/request';
import { requestCurrencySelector, requestServicesLengthSelector, } from '@/models/request/selectors';
import { IS_PRODUCTION } from '../constants';
import { FOREIGN_CODE, FRANCHISE_CODE } from '../static';
import { analyticsActions } from './actions';
import { analytics } from './analytics';
const initializaCalculationMiddleware = createListenerMiddleware();
const initializaCalculationListening = initializaCalculationMiddleware.startListening;
initializaCalculationListening({
    predicate: (action, _currentState, previousState) => {
        if (action.type === fetchCalculation.fulfilled.type) {
            const oldLastRequestId = calculationLastRequestIdSelector(previousState);
            return !oldLastRequestId;
        }
        else {
            return false;
        }
    },
    effect: (_action, listenerApi) => {
        const servicesLength = requestServicesLengthSelector(listenerApi.getState());
        analytics.sendEvent({
            action: 'Инициализация калькулятора',
            label: 'Системные события',
            category: 'Cherehapa',
            value: servicesLength,
        });
    },
});
const changeServicesMiddleware = createListenerMiddleware();
const changeServicesListening = changeServicesMiddleware.startListening;
changeServicesListening({
    matcher: isAnyOf(requestActions.setServices, requestActions.setDefault),
    effect: (_action, listenerApi) => {
        const servicesLength = requestServicesLengthSelector(listenerApi.getState());
        analytics.sendEvent({
            action: 'Изменены опции',
            label: 'Действие пользователя',
            category: 'Cherehapa',
            value: servicesLength,
        });
    },
});
const clickToBuyButtonMiddleware = createListenerMiddleware();
const clickToBuyButtonListening = clickToBuyButtonMiddleware.startListening;
clickToBuyButtonListening({
    type: basketActions.addToBasket.type,
    effect: (_action, listenerApi) => {
        const productId = basketProductIdSelector(listenerApi.getState());
        analytics.sendEvent({
            action: 'Нажата кнопка "Купить"',
            label: 'Действие пользователя',
            category: 'Cherehapa',
            value: productId,
        });
        const product = basketProductSelector(listenerApi.getState());
        if (product) {
            analytics.sendEcommerceYM(getEcommerceYMAction(product, 'add'));
        }
    },
});
const fillingPersonalDataMiddleware = createListenerMiddleware();
const fillingPersonalDataListening = fillingPersonalDataMiddleware.startListening;
fillingPersonalDataListening({
    type: analyticsActions.fillingPersonalData.type,
    effect: () => {
        analytics.sendEvent({
            action: 'Заполнение персональных данных',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    },
});
const receiptPolicyMiddleware = createListenerMiddleware();
const receiptPolicyListening = receiptPolicyMiddleware.startListening;
receiptPolicyListening({
    type: analyticsActions.receiptPolicy.type,
    effect: () => {
        analytics.sendEvent({
            action: 'Получение полиса (ссылка)',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
        setTimeout(() => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const ecommerceParams = ['orderUuid', 'price', 'name', 'p'].reduce((object, field) => ((object[field] = urlParams.get(field)), object), {});
            const ecommerceYMAction = getEcommerceYMPurchaseAction(ecommerceParams);
            if (ecommerceYMAction) {
                analytics.sendEcommerceYM(ecommerceYMAction);
                if (IS_PRODUCTION) {
                    analytics.sendLeadhit(ecommerceYMAction);
                }
            }
        }, 500);
    },
});
const changeCurrencyMiddleware = createListenerMiddleware();
const changeCurrencyListening = changeCurrencyMiddleware.startListening;
changeCurrencyListening({
    predicate: (action, currentState, previousState) => {
        if (isAnyOf(requestActions.changeCurrency, requestActions.setDefault)(action)) {
            const newCurrency = requestCurrencySelector(currentState);
            const oldCurrency = requestCurrencySelector(previousState);
            const isCurrencyChanged = newCurrency !== oldCurrency;
            return isCurrencyChanged;
        }
        else {
            return false;
        }
    },
    effect: () => {
        analytics.sendEvent({
            action: 'currency_change',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    },
});
const changeForeignServiceMiddleware = createListenerMiddleware();
const changeForeignServiceListening = changeForeignServiceMiddleware.startListening;
changeForeignServiceListening({
    predicate: (action) => action.type === requestActions.setServices.type &&
        action.payload?.[FOREIGN_CODE],
    effect: () => {
        analytics.sendEvent({
            action: 'non_citizen',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    },
});
const withoutFranchiseServiceMiddleware = createListenerMiddleware();
const withoutFranchiseServiceListening = withoutFranchiseServiceMiddleware.startListening;
withoutFranchiseServiceListening({
    predicate: (action) => action.type === requestActions.setServices.type &&
        action.payload?.[FRANCHISE_CODE],
    effect: () => {
        analytics.sendEvent({
            action: 'vzr_no_fransh',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    },
});
export const analyticsMiddlewares = [
    initializaCalculationMiddleware.middleware,
    changeServicesMiddleware.middleware,
    clickToBuyButtonMiddleware.middleware,
    fillingPersonalDataMiddleware.middleware,
    receiptPolicyMiddleware.middleware,
    changeCurrencyMiddleware.middleware,
    changeForeignServiceMiddleware.middleware,
    withoutFranchiseServiceMiddleware.middleware,
];
