import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatDate } from '@che-ins-ui/datepicker';
import { preparePhoneCodeCountryAndPhoneForStore } from '../process/utils';
import { InsurerField } from './interface';
import { setInsurerName } from './setInsurerName';
import { setInsurerBirthday } from './setInsurerBirthday';
import { containsLatin } from './helpers';
import { setPhone } from './setPhone';
import { setTouristBirthday } from './setTouristBirthday';
import { setTouristName } from './setTouristName';
import { setEmail } from './setEmail';
import { validateForms } from './validateForms';
import { formsActions } from './index';
export const setFormsFromOrder = createAsyncThunk('', async ({ insurer, tourists, additionalEmails, email, phone, phoneCountryCode: phoneCountryCodeFromApi, }, { dispatch, getState }) => {
    const emails = [email, ...additionalEmails];
    dispatch(formsActions._resetForms({
        touristsAmount: tourists.length,
        emailsAmount: emails.length,
    }));
    dispatch(formsActions.setFormDirty());
    const { form: { tourists: touristsUuids, emails: emailsUuids }, } = getState();
    touristsUuids.forEach(({ _uuid }, index) => {
        const { lastName, firstName, birthday } = tourists[index];
        dispatch(setTouristBirthday({
            _uuid,
            value: formatDate(birthday),
            noMeta: true,
        }));
        dispatch(setTouristName({
            _uuid,
            field: InsurerField.lastName,
            value: lastName,
        }));
        dispatch(setTouristName({
            _uuid,
            field: InsurerField.firstName,
            value: firstName,
        }));
    });
    const isInsurerInRussian = !containsLatin(insurer.lastName);
    dispatch(setInsurerBirthday(formatDate(insurer.birthday)));
    dispatch(setInsurerName({
        field: isInsurerInRussian
            ? InsurerField.lastNameRu
            : InsurerField.lastName,
        value: insurer.lastName,
    }));
    dispatch(setInsurerName({
        field: isInsurerInRussian
            ? InsurerField.firstNameRu
            : InsurerField.firstName,
        value: insurer.firstName,
    }));
    if (isInsurerInRussian) {
        if (insurer.secondName) {
            dispatch(setInsurerName({
                field: InsurerField.secondNameRu,
                value: insurer.secondName,
            }));
        }
        else {
            dispatch(formsActions.setInsurerHasSecondNameRu(false));
        }
    }
    const { phoneCountryCode, phoneBody } = preparePhoneCodeCountryAndPhoneForStore(phone, phoneCountryCodeFromApi);
    dispatch(formsActions.setPhoneCountryCode(phoneCountryCode));
    dispatch(setPhone(phoneBody));
    emailsUuids.forEach(({ _uuid }, index) => {
        dispatch(setEmail({
            _uuid,
            value: emails[index],
        }));
    });
    dispatch(validateForms());
});
