// Файл был пропущен через https://obfuscator.io/#code, а его исходный код внизу файла
// Файл добавлен в .eslintignore

(function (_0x3b8eb9, _0x4549b8) {
    const _0x3ce8c5 = _0x4043,
        _0x40d7f9 = _0x3b8eb9();
    while ([]) {
        try {
            const _0x4f31d2 =
                parseInt(_0x3ce8c5(0x1c6)) / 0x1 +
                (parseInt(_0x3ce8c5(0x1ca)) / 0x2) *
                    (parseInt(_0x3ce8c5(0x1c4)) / 0x3) +
                -parseInt(_0x3ce8c5(0x1be)) / 0x4 +
                (parseInt(_0x3ce8c5(0x1c1)) / 0x5) *
                    (-parseInt(_0x3ce8c5(0x1c2)) / 0x6) +
                (parseInt(_0x3ce8c5(0x1c8)) / 0x7) *
                    (-parseInt(_0x3ce8c5(0x1bd)) / 0x8) +
                (-parseInt(_0x3ce8c5(0x1c5)) / 0x9) *
                    (parseInt(_0x3ce8c5(0x1bc)) / 0xa) +
                (parseInt(_0x3ce8c5(0x1c0)) / 0xb) *
                    (parseInt(_0x3ce8c5(0x1c7)) / 0xc);

            if (_0x4f31d2 === _0x4549b8) break;
            else _0x40d7f9['push'](_0x40d7f9['shift']());
        } catch (_0x537102) {
            _0x40d7f9['push'](_0x40d7f9['shift']());
        }
    }
})(_0x1ba8, 0xdff65);
function _0x4043(_0x5021e9, _0x4f56f5) {
    const _0x1ba893 = _0x1ba8();

    return (
        (_0x4043 = function (_0x4043c7, _0x531b67) {
            _0x4043c7 = _0x4043c7 - 0x1bc;
            const _0x233f90 = _0x1ba893[_0x4043c7];

            return _0x233f90;
        }),
        _0x4043(_0x5021e9, _0x4f56f5)
    );
}
export function fakeHash() {
    const _0x58467e = _0x4043,
        _0x404ace = 'abcdef0123456789',
        _0x497e40 = 0x23,
        _0x1f8ac6 = 0x37,
        _0x3c3080 =
            Math['floor'](
                Math[_0x58467e(0x1c3)]() * (_0x1f8ac6 - _0x497e40 + 0x1)
            ) + _0x497e40;
    let _0x1fa57b = '';
    for (let _0x41f8e8 = 0x0; _0x41f8e8 < _0x3c3080; _0x41f8e8++) {
        const _0x64218e = Math[_0x58467e(0x1bf)](
            Math[_0x58467e(0x1c3)]() * _0x404ace[_0x58467e(0x1c9)]
        );
        _0x1fa57b += _0x404ace[_0x64218e];
    }

    return _0x1fa57b;
}
function _0x1ba8() {
    const _0x2190cc = [
        '3183232MKZlux',
        'floor',
        '11jAZvMS',
        '994690IbNCpy',
        '18UcezWM',
        'random',
        '18rUKzRd',
        '782064smItWz',
        '774595dBcetJ',
        '30931968bIxnop',
        '65842kXWvBU',
        'length',
        '409844EZrDhG',
        '190uWruEF',
        '528CUmTpi',
    ];
    _0x1ba8 = function () {
        return _0x2190cc;
    };

    return _0x1ba8();
}

// function fakeHash() {
//     const characters = 'abcdef0123456789';
//     const minLength = 35;
//     const maxLength = 55;

//     const length =
//         Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

//     let randomString = '';
//     for (let i = 0; i < length; i++) {
//         const randomIndex = Math.floor(Math.random() * characters.length);
//         randomString += characters[randomIndex];
//     }

//     return randomString;
// }
