import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseIcon } from '@che-ins-ui/icons/CloseIcon';
import { useCheTheme } from '@/styles/themes';
import { getAgeLabel } from '@/libs/helpers/utils';
import { useStyles } from '../styles';
export const TouristButtonTitle = ({ age, isRemovable, onRemove, }) => {
    const theme = useCheTheme();
    const classes = useStyles();
    return (_jsxs(_Fragment, { children: ["\u0422\u0443\u0440\u0438\u0441\u0442: ", getAgeLabel(age), isRemovable && (_jsx(CloseIcon, { className: classes.removeTouristIcon, fill: theme.colors.brand, onClick: onRemove }))] }));
};
