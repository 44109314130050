import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { ChevronIcon } from '@che-ins-ui/icons/ChevronIcon';
import { motion } from 'framer-motion';
import { withAlpha } from '@/styles/utils';
import { useCheTheme } from '@/styles/themes';
import { analytics } from '@/libs/analytics';
import { blendHexColor } from '@/libs/helpers';
import { formatPrice } from '@/libs/helpers/utils';
import { useStyles } from './styles';
export const ComparingPriceButton = memo(({ price, onClick }) => {
    const classes = useStyles();
    const [hovered, setHovered] = useState(false);
    const theme = useCheTheme();
    const successColor = theme.colors.success;
    const backgroundColor = blendHexColor(successColor, 0.08, theme.colors.cloud);
    const variants = {
        unhovered: {
            padding: '2px 6px',
            border: `1px solid ${withAlpha(successColor, 1)}`,
            backgroundColor: theme.colors.cloud,
        },
        hovered: {
            padding: '2px 12px',
            border: `1px solid ${withAlpha(successColor, 1)}`,
            backgroundColor,
        },
    };
    const slidingVariants = {
        hovered: { opacity: 1, width: 'auto' },
        unhovered: { opacity: 0, width: 0 },
    };
    const priceVariants = {
        unhovered: { backgroundColor: theme.colors.cloud },
        hovered: { backgroundColor },
    };
    const iconVariants = {
        unhovered: { x: -1 },
        hovered: { x: 1 },
    };
    const delay = hovered ? 0.08 : 0;
    const animationProps = {
        initial: 'unhovered',
        animate: hovered ? 'hovered' : 'unhovered',
    };
    return (_jsxs(motion.button, { className: classes.priceButton, onPointerEnter: () => setHovered(true), onPointerLeave: () => setHovered(false), tabIndex: 0, onClick: () => {
            onClick();
            analytics.sendEvent({
                action: 'comparison_buy_button',
                label: 'Действие пользователя',
                category: 'Cherehapa',
            });
        }, variants: variants, ...animationProps, transition: { delay }, children: [_jsx(motion.div, { variants: slidingVariants, ...animationProps, transition: {
                    duration: 0.2,
                    delay,
                }, className: classes.priceLabel, children: "\u041A\u0443\u043F\u0438\u0442\u044C" }), _jsxs(motion.div, { variants: priceVariants, ...animationProps, className: classes.price, transition: { delay }, children: [formatPrice(price), " \u20BD"] }), _jsx(motion.div, { variants: iconVariants, ...animationProps, className: classes.priceIconWrapper, transition: { delay }, "data-hovered": hovered, children: _jsx(ChevronIcon, {}) })] }));
});
