import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, memo } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { TriangleIcon } from '@che-ins-ui/icons/TriangleIcon';
import { useStyles } from './styles';
export const InputDropdpownPostfix = memo(({ isShown, isActive, onClick }) => {
    const classes = useStyles();
    const controls = useAnimation();
    useEffect(() => {
        controls.set({ scale: isShown ? 1 : 0 });
        if (isActive) {
            const sequence = async () => {
                await controls.start({
                    y: 6,
                    transition: { duration: 0.075 },
                });
                await controls.start({
                    y: 0,
                    rotate: 180,
                    transition: { duration: 0.15 },
                });
            };
            sequence();
        }
        else {
            controls.start({
                rotate: 0,
                transition: { duration: 0.175 },
            });
        }
    }, [isActive, controls]);
    useEffect(() => {
        controls.start({
            scale: isShown ? 1 : 0,
            transition: { duration: 0.2 },
        });
    }, [isShown, controls]);
    return (_jsx("button", { onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
        }, className: classes.dropdownButton, "data-hidden": !isShown, tabIndex: isShown ? 0 : -1, children: _jsx(motion.div, { animate: controls, children: _jsx(TriangleIcon, {}) }) }));
});
