import Cookies from 'js-cookie';
import { USER_TOKEN } from './consts';
export const getUserToken = () => Cookies.get(USER_TOKEN);
export const headersWithToken = (userToken, customHeaders = {}) => ({
    headers: {
        ...customHeaders,
        'cherehapa-token': userToken || '',
    },
});
export const getMindBoxDeviceUUID = () => Cookies.get('mindboxDeviceUUID');
