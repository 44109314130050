import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { create } from 'jss';
import preset from 'jss-preset-default';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { appState } from './store';
import ThemeProvider from './components/theming/ThemeProvider';
import { initializeErrorReporter } from './libs/helpers/utils';
import { IS_PRODUCTION, SENTRY_CONFIG } from './libs/constants';
const jss = create();
jss.setup(preset());
IS_PRODUCTION && initializeErrorReporter(SENTRY_CONFIG);
const { basename } = appState.getState().request;
const root = createRoot(document.getElementById('cherehapa-root'));
root.render(_jsx(Provider, { store: appState, children: _jsx(ThemeProvider, { children: _jsxs(Router, { basename: basename, children: [_jsx(App, {}), _jsx("div", { style: { height: 0 }, "data-iframe-height": true })] }) }) }));
