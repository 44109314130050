import { createUseStyles } from 'react-jss';
export const useStyles = createUseStyles((theme) => ({
    root: {
        marginTop: '13px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '16px',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            marginBottom: '28px',
        },
    },
    company: {
        display: 'flex',
        alignItems: 'center',
        margin: '0px 10px',
        maxWidth: 'calc(100% - 180px)',
    },
    backButtonText: {
        display: 'none',
        color: theme.colors.brand,
        [theme.breakpoints.up('md')]: {
            display: 'initial',
            fontSize: theme.rem(15),
            marginLeft: '7px',
        },
    },
    tabContentWrapper: {
        minHeight: '156px',
        overflow: 'auto',
        outline: 'none',
        height: '100%',
    },
    companyLogo: {
        width: '18px',
        height: '18px',
        marginRight: '10px',
        borderRadius: '3px',
        [theme.breakpoints.up('md')]: {
            width: '32px',
            height: '32px',
            borderRadius: '9px',
        },
    },
    companyName: {
        fontSize: theme.rem(18),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.colors.sky,
        [theme.breakpoints.up('md')]: {
            fontSize: theme.rem(26),
        },
    },
}), { name: 'offer-modal' });
