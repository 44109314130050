import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from '@che-ins-ui/dropdown';
import { tabsActions } from '@che-ins-ui/tabs';
import { MAX_TOURISTS_AMOUNT, MIN_TOURISTS_AMOUNT } from '@/libs/constants';
import { setTouristAge } from '@/models/forms/setTouristAge';
import { touristsFormsSelector } from '@/models/forms/selectors';
import { formsActions } from '@/models/forms';
import { TABS } from '@/components/insurance/components';
import { useActivePanelName } from '@/components/insurance/components/hooks';
import { INSURANCE_TABS_NAME, TABS_PANELS_NAMES, } from '@/components/insurance/consts';
import { useStyles } from '../styles';
import { Ages } from './Ages';
import { TouristButtonTitle } from './TouristButtonTitle';
export const TouristsSelector = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const tourists = useSelector(touristsFormsSelector);
    const insuranceActiveTab = useActivePanelName();
    const changeInsuranceTabs = useCallback(() => {
        if (insuranceActiveTab !== TABS_PANELS_NAMES.TOURISTS) {
            dispatch(tabsActions.setActiveTab({
                tabsName: INSURANCE_TABS_NAME,
                activePanelName: TABS[0].panelName,
            }));
        }
    }, [insuranceActiveTab]);
    return (_jsxs("div", { className: classes.touristsSelector, children: [tourists.map(({ age, _uuid }) => (_jsx(Dropdown, { appearance: "tourists-age", passSetIsOpenToChildren: true, title: _jsx(TouristButtonTitle, { age: age, isRemovable: tourists.length > MIN_TOURISTS_AMOUNT, onRemove: () => {
                        dispatch(formsActions.removeTourist(_uuid));
                        changeInsuranceTabs();
                    } }), titleButtonProps: {
                    baseAppearance: 'tourist',
                    appearance: 'secondary',
                }, children: _jsx(Ages, { selectedAge: age, onClick: (age) => {
                        dispatch(setTouristAge({ _uuid, age }));
                        changeInsuranceTabs();
                    } }) }, _uuid))), tourists.length < MAX_TOURISTS_AMOUNT && (_jsx(Dropdown, { passSetIsOpenToChildren: true, appearance: "tourists-age", baseAppearance: "new-tourist", title: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0442\u0443\u0440\u0438\u0441\u0442\u0430", children: _jsx(Ages, { onClick: (age) => {
                        dispatch(formsActions.addTourist(age));
                        changeInsuranceTabs();
                    } }) }))] }));
});
