import { createUseStyles } from 'react-jss';
import { DELAY_FROM_LAST_REQUEST } from '@/models/process';
import { mixins } from '@/styles/mixins';
import { withAlpha } from '@/styles/utils';
import { ASSETS_SRC } from '@/libs/constants';
import { DESKTOP_SIZES_BREAKPOINT, MOBILE_FONT_SIZES, DEKSTOP_FONT_SIZES, GLOBAL_LETTER_SPACING, } from '@/styles/themes/components';
import { DESKTOP_SUCCESS_PAGE_BREAKPOINT } from '../../styles';
export const SUCCESS_LOGO_SIZE = 45;
export const useStyles = createUseStyles((theme) => ({
    root: {
        paddingBottom: '12px',
        paddingRight: '12px',
        paddingLeft: '12px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 1,
        paddingTop: '55px',
        [theme.breakpoints.up(DESKTOP_SUCCESS_PAGE_BREAKPOINT)]: {
            paddingTop: '50px',
            paddingBottom: '40px',
        },
    },
    mainTitle: {
        textAlign: 'center',
        marginBottom: '15px',
        color: theme.colors.white,
        fontFamily: theme.font.secondary,
        fontWeight: 700,
        fontSize: theme.rem(22),
        letterSpacing: 0.25,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: theme.rem(28),
            letterSpacing: 0.7,
        },
    },
    text: {
        color: theme.colors.white,
        textAlign: 'center',
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
        '&[data-firstscreen="true"]': {
            '& a': {
                color: theme.colors.white,
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
                '&:-webkit-any-link, &:visited': {
                    color: theme.colors.white,
                    textDecoration: 'underline',
                },
            },
        },
    },
    loader: {
        marginTop: '51px',
        height: 15,
        border: `1px solid rgba(255,255,255,0.55)`,
        borderRadius: '8px',
        marginBottom: 9,
        width: '190px',
        overflow: 'hidden',
    },
    progress: {
        background: theme.colors.white,
        height: '100%',
        transitionTimingFunction: 'linear',
        transition: `${DELAY_FROM_LAST_REQUEST}ms`,
    },
    buttons: {
        position: 'sticky',
        bottom: '15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        gap: '8px',
        [theme.breakpoints.up(DESKTOP_SUCCESS_PAGE_BREAKPOINT)]: {
            width: '450px',
            flexDirection: 'row',
        },
    },
    info: {
        textAlign: 'center',
        letterSpacing: GLOBAL_LETTER_SPACING,
        '&[data-multipolicy="true"]': {
            marginBottom: '58px',
            [theme.breakpoints.up(DESKTOP_SUCCESS_PAGE_BREAKPOINT)]: {
                marginBottom: '0',
            },
        },
        '& br': {
            display: 'none',
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                display: 'block',
            },
        },
    },
    singlePolicy: {
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        padding: 10,
        '& .title': {
            lineHeight: '21px',
            fontWeight: 500,
        },
        '& .link': {
            lineHeight: '19px',
            textDecoration: 'underline',
        },
    },
    companyTitles: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        flexGrow: 1,
        textAlign: 'left',
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: MOBILE_FONT_SIZES.main,
        },
    },
    companyLogo: {
        width: SUCCESS_LOGO_SIZE,
        height: SUCCESS_LOGO_SIZE,
        flexShrink: 0,
        '&[data-outline="true"]': {
            borderRadius: 12,
            outline: `3px solid ${theme.colors.header}`,
        },
    },
    multiPolicy: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 12,
    },
    multiPolicyLogos: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: `0 0 16px 10px ${theme.colors.header}`,
        borderRadius: 8,
    },
    confetti: {
        opacity: 0.7,
        transform: 'scale(0.87)',
        position: 'absolute',
        zIndex: theme.zIndex.underline,
        width: '100%',
        height: 224,
        backgroundImage: `url(${ASSETS_SRC.IMAGES}/success/confetti.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    bluredTextWrapper: {
        display: 'block',
        backgroundColor: theme.colors.header,
        ...mixins.overlay(),
        overflow: 'hidden',
    },
    bluredText: {
        userSelect: 'none',
        filter: 'blur(5px)',
        position: 'absolute',
        width: '100%',
        maxWidth: 987,
        color: withAlpha(theme.colors.white, 0.2),
        fontSize: 30,
        [theme.breakpoints.up(DESKTOP_SUCCESS_PAGE_BREAKPOINT)]: {
            top: '50%',
            transform: 'translateY(-50%)',
        },
    },
    downloadArray: {
        position: 'absolute',
        transform: 'translate(-100%, -16px)',
        left: '-26px',
        display: 'none',
        [theme.breakpoints.up(DESKTOP_SUCCESS_PAGE_BREAKPOINT)]: {
            display: 'block',
        },
    },
}), { name: 'content' });
