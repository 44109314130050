import { createAsyncThunk } from '@reduxjs/toolkit';
import { createMaskedValue } from '@/libs/helpers/utils';
import { DATE_MASK } from '@/libs/constants';
import { validateDate } from '@/libs/validation/validateDate';
import { InsurerField } from './interface';
import { formsActions } from './index';
const field = InsurerField.birthday;
export const setInsurerBirthday = createAsyncThunk('', async (value, thunkApi) => {
    const maskedValue = createMaskedValue(value, DATE_MASK);
    const { error } = validateDate(maskedValue, { isInsurer: true });
    thunkApi.dispatch(formsActions._setInsurerField({ field, value: maskedValue }));
    thunkApi.dispatch(formsActions._setInsurerFieldError({ field, error }));
});
