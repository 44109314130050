import { createUseStyles } from 'react-jss';
export const useStyles = createUseStyles((theme) => ({
    '@keyframes rotate': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(-360deg)' },
    },
    '@keyframes fadeInOut': {
        from: { opacity: 1 },
        to: { opacity: 0.125 },
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        animation: '$rotate linear infinite',
    },
    itemWrapper: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    item: {
        backgroundColor: theme.colors.white,
        borderRadius: 100,
        animation: `$fadeInOut ease-in-out infinite`,
    },
}), { name: 'circle-loader' });
