import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { addDays } from '@che-ins-ui/datepicker';
import { isIframe } from '../helpers';
const INITIAL_URL_KEY = 'initialUrl';
export const getInitialUrl = () => {
    const cookie = Cookies.get(INITIAL_URL_KEY);
    return cookie ? decodeURIComponent(cookie) : null;
};
export const useInitialUrlCookie = () => {
    useEffect(() => {
        if (isIframe() || getInitialUrl()) {
            return;
        }
        Cookies.set(INITIAL_URL_KEY, encodeURIComponent(window.location.href), {
            expires: addDays(new Date(), 180),
            httpOnly: false,
        });
    }, []);
};
