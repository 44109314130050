import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '@che-ins-ui/modal';
import { ConnectedModal } from '@che-ins-ui/modal';
import { ASSETS_SRC } from '@/libs/constants';
import { upsellSelector } from '@/models/upsell/selectors';
import { upsellActions } from '@/models/upsell';
import { themeModeSelector } from '@/models/theme';
import { MEDICINE_CODE } from '@/libs/static';
import { useStyles } from './styles';
import { AddButton } from './AddButton';
export const modalNameStrongerProtection = 'DETAILS_STRONGER_PROTECTION';
const MODAL_TITLE = 'Защита покрепче';
const MODAL_TEXT = 'Как правило, медицинские услуги за рубежом стоят недешево. Лучше увеличить лимит страховки, чтоб его хватило при любых обстоятельствах';
export const DetailsStrongerProtectionHint = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const themeMode = useSelector(themeModeSelector);
    const { offers, chosen } = useSelector(upsellSelector);
    const chosenMedicine = chosen?.[MEDICINE_CODE];
    const offersMedicice = offers.map((offer) => offer[MEDICINE_CODE]);
    const maxMedicine = Math.max(...offersMedicice);
    const isDisabledAddButton = () => {
        return (typeof chosenMedicine === 'number' && chosenMedicine === maxMedicine);
    };
    const handleClose = () => dispatch(modalActions.close({ name: modalNameStrongerProtection }));
    const handleAdd = () => {
        dispatch(upsellActions.setChosen({
            type: MEDICINE_CODE,
            value: maxMedicine,
        }));
        handleClose();
    };
    return (_jsxs(ConnectedModal, { name: modalNameStrongerProtection, appearance: "hint", withCloseButton: true, title: _jsxs(_Fragment, { children: [_jsx("img", { className: classes.image, src: `${ASSETS_SRC.IMAGES}/modals/service-info-${themeMode}.webp`, alt: "" }), _jsx("span", { className: classes.title, children: MODAL_TITLE })] }), children: [_jsx("p", { className: classes.description, children: MODAL_TEXT }), _jsx(AddButton, { onClick: handleAdd, disabled: isDisabledAddButton() })] }));
};
