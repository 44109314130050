import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useMeasure } from 'react-use';
import { motion, AnimatePresence } from 'framer-motion';
import { useCheTheme } from '@/styles/themes';
import { useStyles } from './styles';
export const ComparingIcon = memo(({ checked, withText, }) => {
    const theme = useCheTheme();
    const classes = useStyles();
    const fill = checked ? theme.colors.brand : theme.colors.textSecondary;
    const variants = {
        hidden: { opacity: 0.7, scale: 0.3 },
        visible: { opacity: 1, scale: 1 },
    };
    const basePathProps = { clipRule: 'evenodd', fill };
    const staticPathProps = {
        ...basePathProps,
        animate: { fill },
    };
    const animatedPathProps = {
        variants,
        initial: 'hidden',
        animate: 'visible',
        exit: 'hidden',
        ...basePathProps,
        transition: { duration: 0.08, bounce: 40 },
    };
    const [ref, { width }] = useMeasure();
    return (_jsxs("div", { className: classes.iconWrapper, children: [_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 20 20", style: { transform: 'translateY(2px)' }, children: [_jsx(AnimatePresence, { mode: "wait", children: _jsx(motion.path, { ...animatedPathProps, d: checked
                                ? 'M11.3,4.3l2.3,2.4c0.4,0.4,1.1,0.4,1.5,0l4.6-4.8c0.4-0.4,0.4-1.1,0-1.6c-0.4-0.4-1.1-0.4-1.5,0l-3.8,4  l-1.5-1.6c-0.4-0.4-1.1-0.4-1.5,0C10.9,3.1,10.9,3.9,11.3,4.3z'
                                : 'M15,8c0.6,0,1-0.4,1-1V5h2c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v2h-2c-0.6,0-1,0.4-1,1 s0.4,1,1,1h2v2C14,7.6,14.4,8,15,8z' }, checked ? 'checked' : 'unchecked') }), _jsxs("g", { children: [_jsx(motion.path, { ...staticPathProps, d: "M1,18c-0.6,0-1-0.4-1-1s0.4-1,1-1h14c0.6,0,1,0.4,1,1s-0.4,1-1,1H1z" }), _jsx(motion.path, { ...staticPathProps, d: "M1,12c-0.6,0-1-0.4-1-1s0.4-1,1-1h14c0.6,0,1,0.4,1,1s-0.4,1-1,1H1z" }), _jsx(motion.path, { ...staticPathProps, d: "M0,5c0-0.6,0.4-1,1-1h7.8c0.6,0,1,0.4,1,1s-0.4,1-1,1H1C0.4,6,0,5.6,0,5z" })] })] }, "svg"), _jsx(motion.div, { initial: { color: fill, scale: 0, width: 0, marginLeft: 0 }, animate: {
                    color: fill,
                    scale: withText ? 1 : 0,
                    width: withText && width ? width : 0,
                    marginLeft: withText ? 2 : 0,
                }, className: classes.iconText, children: _jsx("p", { ref: ref, children: "\u0421\u0440\u0430\u0432\u043D\u0438\u0442\u044C" }) }, "span")] }));
});
