export const NOTEBOOK_STATE_NAMESPACE = 'notebook';
export const emojis = [
    'koala',
    'dog',
    'panda',
    'fox',
    'bear',
    'tiger',
    'polarbear',
    'lion',
    'rabbit',
    'cat',
    'hamster',
    'monkey',
    'mouse',
];
export const genders = ['male', 'female'];
export const ARTIFICIAL_DELAY = 600;
export const SUCCESS_DISPLAY_DURATION = 600;
