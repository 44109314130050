import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '@che-ins-ui/modal';
import { ConnectedModal } from '@che-ins-ui/modal';
import { ASSETS_SRC } from '@/libs/constants';
import { upsellSelector } from '@/models/upsell/selectors';
import { upsellActions } from '@/models/upsell';
import { useStyles } from './styles';
import { AddButton } from './AddButton';
import { motoHintModalSelector } from './selectors';
export const modalNameMoto = 'DETAILS_MOTO';
const MODAL_TITLE = 'Мотобайк';
const MODAL_TEXT = 'Медицинская страховка будет работать при использовании мототранспорта и покрывать травмы, полученные при езде на нем. Обратите внимание! У вас должно быть водительское удостоверение нужной категории. Не покрывается спортивное использование мототранспорта (мотокросс, эндуро и т. п.)';
export const DetailsMotoHint = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { chosen } = useSelector(upsellSelector);
    const { isIncluded } = useSelector(motoHintModalSelector);
    const chosenMoto = Boolean(chosen?.moto);
    const handleClose = () => dispatch(modalActions.close({ name: modalNameMoto }));
    const handleAdd = () => {
        dispatch(upsellActions.setChosen({
            type: 'moto',
            value: !chosenMoto,
        }));
        handleClose();
    };
    const isDisabledAddButton = Boolean(chosenMoto || isIncluded);
    return (_jsxs(ConnectedModal, { name: modalNameMoto, appearance: "hint", withCloseButton: true, title: _jsxs(_Fragment, { children: [_jsx("img", { className: classes.image, src: `${ASSETS_SRC.IMAGES}/modals/service-info.webp`, alt: "" }), _jsx("span", { className: classes.title, children: MODAL_TITLE })] }), children: [_jsx("p", { className: classes.description, children: MODAL_TEXT }), _jsx(AddButton, { onClick: handleAdd, disabled: isDisabledAddButton })] }));
};
