export function getYandexAnalyticsProvider(ya = null, dataLayer) {
    if (!ya) {
        return null;
    }
    const sendEvent = ({ action = 'undefinedEvent' }) => {
        ya.reachGoal(action);
    };
    const sendView = ({ title, page }) => {
        ya.hit(page, {
            title,
        });
    };
    const sendEcommerce = (action) => {
        const ecommerce = {
            currencyCode: 'RUB',
            ...action,
        };
        dataLayer.push({
            ecommerce,
        });
    };
    return Object.freeze({
        sendEvent,
        sendView,
        sendEcommerce,
    });
}
