import { MIN_INSURER_AGE } from '@/libs/constants';
export const INSURANCE_TABS_NAME = 'insurance';
export const OFFER_IS_MISSING_MODAL_NAME = 'OFFER_IS_MISSING_MODAL_NAME';
export const placeholders = {
    lastName: 'Ivanov',
    firstName: 'Dmitry',
    lastNameRu: 'Иванов',
    firstNameRu: 'Дмитрий',
    secondNameRu: 'Иванович',
    birthday: 'Дата рождения',
};
export const TABS_PANELS_NAMES = {
    TOURISTS: 'TOURISTS',
    OPTIONS: 'OPTIONS',
    UPSELL: 'UPSELL',
};
export const whyPassportIsUnnesessutyHintText = `
Потому что так проще. Страховая компания идентифицирует туриста по номеру полиса, имени и фамилии, а также дате рождения`;
export const whoCanBayHintText = `
Купить полис может каждый, кому исполнилось ${MIN_INSURER_AGE} лет. Приобрести страховку можно для себя или любого другого человека`;
export const CALCULATION_ERROR_HINT = {
    title: 'Пожалуйста, измените условия!',
    message: `
        Выбранный вариант страховки не может быть рассчитан при данных условиях. 
        Вернитесь на расчёт и выберите другую страховую компанию.
    `,
};
export const inputLabels = {
    lastName: 'Фамилия',
    firstName: 'Имя',
    lastNameRu: 'Фамилия',
    firstNameRu: 'Имя',
    secondNameRu: 'Отчество',
    birthday: 'Дата рождения',
};
export const inputNames = {
    lastName: 'lastname',
    firstName: 'firstname',
    lastNameRu: 'lastname-ru',
    firstNameRu: 'firstname-ru',
    secondNameRu: 'additional-name',
    birthday: 'birthday',
};
