export var EnName;
(function (EnName) {
    EnName["firstName"] = "firstName";
    EnName["lastName"] = "lastName";
})(EnName || (EnName = {}));
export var RuName;
(function (RuName) {
    RuName["firstNameRu"] = "firstNameRu";
    RuName["lastNameRu"] = "lastNameRu";
    RuName["secondNameRu"] = "secondNameRu";
})(RuName || (RuName = {}));
export const NameField = { ...EnName, ...RuName };
export var BaseNonNameTouristField;
(function (BaseNonNameTouristField) {
    BaseNonNameTouristField["birthday"] = "birthday";
})(BaseNonNameTouristField || (BaseNonNameTouristField = {}));
var ExtraField;
(function (ExtraField) {
    ExtraField["phoneBody"] = "phoneBody";
})(ExtraField || (ExtraField = {}));
export const TouristField = { ...BaseNonNameTouristField, ...EnName };
export const InsurerField = {
    ...EnName,
    ...RuName,
    ...BaseNonNameTouristField,
    ...ExtraField,
};
