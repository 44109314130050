import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { removeTrailingSlash } from '@/libs/helpers';
import { DownloadDocumentIcon } from './DownloadDocumentIcon';
import { useStyles } from './styles';
export var FileName;
(function (FileName) {
    FileName["accident"] = "\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u0438\u044F \u043E\u0442 \u043D\u0435\u0441\u0447\u0430\u0441\u0442\u043D\u044B\u0445 \u0441\u043B\u0443\u0447\u0430\u0435\u0432";
    FileName["travel"] = "\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u0438\u044F \u043F\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u0439";
    FileName["kid"] = "\u041F\u0430\u043C\u044F\u0442\u043A\u0430 \u041A\u0418\u0414";
})(FileName || (FileName = {}));
const files = {
    absolut: [
        {
            label: FileName.kid,
            file: 'Абсолют КИД ВЗР.pdf',
        },
        {
            label: FileName.travel,
            file: 'Абсолют правила ВЗР от 15.11.2021.pdf',
        },
    ],
    alfa: [
        {
            label: FileName.kid,
            file: 'АльфаСтрахование КИД ВЗР.pdf',
        },
        {
            label: FileName.travel,
            file: 'АльфаСтрахование правила ВЗР от 08.07.2024.pdf',
        },
        {
            label: FileName.accident,
            file: 'АльфаСтрахование правила НС 13 03 20.pdf',
        },
    ],
    energogarant: [
        {
            label: FileName.kid,
            file: 'Энергогарант КИД ВЗР.pdf',
        },
        {
            label: FileName.travel,
            file: 'Энергогарант правила ВЗР от 30.07.2020.pdf',
        },
    ],
    ingos: [
        {
            label: FileName.travel,
            file: 'Ингосстрах правила ВЗР от 28.01.2021.pdf',
        },
    ],
    renins: [
        {
            label: FileName.kid,
            file: 'Ренессанс КИД ВЗР.pdf',
        },
        {
            label: FileName.travel,
            file: 'Ренессанс правила ВЗР от 25.04.2019.pdf',
        },
    ],
    reso: [
        {
            label: FileName.kid,
            file: 'РЕСО КИД ВЗР.pdf',
        },
        {
            label: FileName.travel,
            file: 'РЕСО правила ВЗР от 09.03.2023.pdf',
        },
    ],
    rosbank: [
        {
            label: FileName.kid,
            file: 'ЛУЧИ КИД ВЗР.pdf',
        },
        {
            label: FileName.travel,
            file: 'ЛУЧИ правила ВЗР от 12.02.2024.pdf',
        },
    ],
    rstandart: [
        {
            label: FileName.kid,
            file: 'Русский Стандарт КИД ВЗР.pdf',
        },
        {
            label: FileName.travel,
            file: 'Русский Стандарт правила ВЗР 03.10.2022.pdf',
        },
    ],
    sberbank: [
        {
            label: FileName.kid,
            file: 'СБЕР КИД ВЗР.pdf',
        },
        {
            label: FileName.travel,
            file: 'СБЕР Условия ВЗР от 25.09.2023.pdf',
        },
    ],
    soglasie: [
        {
            label: FileName.kid,
            file: 'Согласие КИД ВЗР.pdf',
        },
        {
            label: FileName.travel,
            file: 'Согласие правила ВЗР от 11.06.2020.pdf',
        },
    ],
    sovcombank: [
        {
            label: FileName.kid,
            file: 'Совкомбанк КИД ВЗР.pdf',
        },
        {
            label: FileName.travel,
            file: 'Совкомбанк правила ВЗР от 07.04.22.pdf',
        },
    ],
    tinkoff: [
        {
            label: FileName.kid,
            file: 'Тинькофф КИД ВЗР.pdf',
        },
        {
            label: FileName.accident,
            file: 'Тинькофф правила НС от 17.01.2024.pdf',
        },
        {
            label: FileName.travel,
            file: 'Тинькофф правила ВЗР от 08.08.2023.pdf',
        },
    ],
    vsk: [
        {
            label: FileName.kid,
            file: 'ВСК КИД ВЗР.pdf',
        },
        {
            label: FileName.travel,
            file: 'ВСК правила ВЗР от 25.12.2017.pdf',
        },
    ],
    zetta: [
        {
            label: FileName.kid,
            file: 'ЗЕТТА КИД ВЗР.pdf',
        },
        {
            label: FileName.travel,
            file: 'ЗЕТТА правила ВЗР от 15.04.2024.pdf',
        },
    ],
};
const _baseLink = removeTrailingSlash(__HOMEPAGE__) + '/files/vzr/docs/';
export const getKidFileLink = (company) => {
    if (!company) {
        return null;
    }
    const fileData = files?.[company]?.find(({ label }) => label === FileName.kid);
    return fileData ? _baseLink + fileData.file : null;
};
export const DocsTab = ({ offer }) => {
    const classes = useStyles();
    const code = offer.company.code;
    const filesList = code in files ? files[code] : null;
    return (_jsxs("div", { className: classes.filesContainer, children: [!filesList && (_jsx("span", { className: classes.tabText, children: "\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u0438\u044F \u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u044E\u0442" })), filesList?.map(({ label, file }) => (_jsxs("a", { className: classes.downloadLink, target: "_blank", href: _baseLink + file, children: [_jsx("span", { className: classes.downloadLinkIcon, children: _jsx(DownloadDocumentIcon, {}) }), _jsxs("span", { className: classes.downloadLabel, children: [_jsx("span", { className: classes.downloadSubtitle, children: label }), _jsx("span", { className: classes.downloadLinkDownloadText, children: "\u041E\u0442\u043A\u0440\u044B\u0442\u044C" })] })] }, label)))] }));
};
