import { getDaysLabel, getPregnancyLabel } from '@/libs/helpers/utils';
import { MULTIPOLICY_CODE, PREGNANCY_CODE } from '@/libs/static';
import { ServiceType } from '@/libs/types/global';
export const textValue = {
    [ServiceType.FULL]: 'Включено',
    [ServiceType.PARTIAL]: 'Частично',
    [ServiceType.NOTCOVERED]: 'Отсутствует',
};
export const getServiceValue = (type, serviceCode, serviceValue, currency) => {
    switch (serviceCode) {
        case MULTIPOLICY_CODE:
            return getDaysLabel(serviceValue);
        case PREGNANCY_CODE:
            return getPregnancyLabel(serviceValue);
        default:
            return serviceValue > 1
                ? `${serviceValue} ${currency}`
                : textValue[type];
    }
};
