import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { activePanelNameTabsSelector } from '@che-ins-ui/tabs';
import { useScreenSizes } from '@che-ins-ui/theme';
import { BREAKPOINTS } from '@/styles/breakpoints';
import { isIgnoreUpsellSelector } from '@/models/request/selectors';
import { UPSELL_ENABLED } from '@/libs/constants';
import { INSURANCE_TABS_NAME, TABS_PANELS_NAMES } from '../consts';
import { TABS } from './tabs';
export const useIsMobileViewInsuranceForm = () => {
    const { width } = useScreenSizes();
    return width <= BREAKPOINTS.lg;
};
export const useIsMobileInputSuggestions = () => {
    const { width } = useScreenSizes();
    return width <= BREAKPOINTS.md;
};
export const useActivePanelName = () => {
    const activePanelNameSelector = activePanelNameTabsSelector(INSURANCE_TABS_NAME);
    const activePanelName = useSelector(activePanelNameSelector);
    return activePanelName;
};
export const useAsideTabs = () => {
    const isIgnoreUpsell = useSelector(isIgnoreUpsellSelector);
    const asideTabs = useMemo(() => {
        const filterTabs = (tab) => {
            if (tab.panelName !== TABS_PANELS_NAMES.UPSELL) {
                return true;
            }
            return UPSELL_ENABLED && !isIgnoreUpsell;
        };
        return TABS.filter(filterTabs);
    }, [isIgnoreUpsell]);
    return asideTabs;
};
