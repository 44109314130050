import { formatDate, getToday, parseDate } from '@che-ins-ui/datepicker';
export function diffDates(date1, date2, interval = 'days') {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const firstDate = parseDate(date1);
    const secondDate = parseDate(date2);
    const timediff = secondDate - firstDate;
    switch (interval) {
        case 'years':
            return secondDate.getFullYear() - firstDate.getFullYear();
        case 'months':
            return (secondDate.getFullYear() * 12 +
                secondDate.getMonth() -
                (firstDate.getFullYear() * 12 + firstDate.getMonth()) +
                1);
        case 'weeks':
            return Math.floor(timediff / week);
        case 'days':
            return Math.floor(timediff / day) + 1;
        case 'hours':
            return Math.floor(timediff / hour);
        case 'minutes':
            return Math.floor(timediff / minute);
        case 'seconds':
            return Math.floor(timediff / second);
        default:
            return 0;
    }
}
export function getTodayString() {
    return formatDate(getToday());
}
export function getStartOfDay() {
    const startOfDay = getToday();
    startOfDay.setHours(0, 0, 0, 0);
    return startOfDay;
}
