import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectedModal } from '@che-ins-ui/modal';
import { Button } from '@che-ins-ui/button';
import { emailConfirmation } from '@/models/process/emailConfirmation';
import { insurerEmailSelector } from '@/models/forms/selectors';
import { codeSelector, emailConfirmationStatusSelector, } from '@/models/process';
import { FIELDS_COUNT } from '../CodeInput';
import { CodeFields } from '../CodeInput/CodeFields';
import { EmailIcon } from './EmailIcon';
import { useStyles } from './styles';
import { APPLY_BUTTON_DISABLED_STATUSES, EMAIL_CONFIRMATION_MODAL_NAME, } from './const';
export const EmailConfirmationModal = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const emailConfirmationStatus = useSelector(emailConfirmationStatusSelector);
    const email = useSelector(insurerEmailSelector);
    const code = useSelector(codeSelector);
    const [focusedIndex, setFocusedIndex] = useState(0);
    const applyButtonRef = useRef(null);
    const setFocusAtApplyButton = () => {
        applyButtonRef.current?.focus();
    };
    const handleApply = useCallback(() => {
        const normalizedCode = code.join('');
        dispatch(emailConfirmation({ email, code: normalizedCode }));
    }, [email, code]);
    const isApplyButtonDisabled = useMemo(() => {
        return (APPLY_BUTTON_DISABLED_STATUSES.includes(emailConfirmationStatus) ||
            code.filter((symbol) => /^\d+$/.test(symbol)).length < FIELDS_COUNT);
    }, [emailConfirmationStatus, code]);
    return (_jsxs(ConnectedModal, { name: EMAIL_CONFIRMATION_MODAL_NAME, appearance: "hint", title: _jsxs(_Fragment, { children: [_jsx(EmailIcon, { className: classes.modalTitleIcon }), _jsx("span", { className: classes.modalTitle, children: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043A\u043E\u0434" })] }), children: [_jsxs("span", { className: classes.modalHintText, children: ["\u041D\u0430 \u0430\u0434\u0440\u0435\u0441 ", email, " \u0431\u044B\u043B ", _jsx("br", {}), " \u0432\u044B\u0441\u043B\u0430\u043D \u043A\u043E\u0434 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F"] }), _jsx(CodeFields, { focusedIndex: focusedIndex, setFocusedIndex: setFocusedIndex, setFocusAtApplyButton: setFocusAtApplyButton }), _jsx(Button, { shouldFitContent: true, onClick: handleApply, appearance: "hint-modal-button", disabled: isApplyButtonDisabled, innerRef: applyButtonRef, children: "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C" })] }));
};
