import { createUseStyles } from 'react-jss';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    modalTitleIcon: {
        margin: '16px 0px',
    },
    modalTitle: {
        fontWeight: 700,
        fontFamily: theme.font.secondary,
        letterSpacing: '0.45px',
        marginBottom: '6px',
        color: theme.colors.sky,
        fontSize: MOBILE_FONT_SIZES.hintModalTitle,
        [theme.breakpoints.up('xl')]: {
            fontSize: DEKSTOP_FONT_SIZES.hintModalTitle,
        },
    },
    modalHintText: {
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
}), { name: 'hint' });
