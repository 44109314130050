import { BUTTON_GROUP_COMPONENTS_NAMES } from '@che-ins-ui/button';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, GLOBAL_LINE_HEIGHT, MOBILE_FONT_SIZES, } from './consts';
export const getButtonsGroupTheme = (theme) => ({
    base: {
        [BUTTON_GROUP_COMPONENTS_NAMES.wrapper]: {
            flexWrap: 'wrap',
            gap: '5px',
            margin: '5px 0px',
        },
        [BUTTON_GROUP_COMPONENTS_NAMES.button]: {
            letterSpacing: GLOBAL_LETTER_SPACING,
            lineHeight: GLOBAL_LINE_HEIGHT,
            fontWeight: 400,
            fontSize: MOBILE_FONT_SIZES.main,
            margin: '0px',
            borderRadius: '10px',
            borderColor: theme.colors.brand,
            color: theme.colors.brand,
            '&[data-selected="true"]': {
                background: theme.colors.brand,
                '&:hover': {
                    background: theme.colors.brand,
                },
            },
            '&:hover': {
                background: theme.colors.brandTertiary,
            },
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
            },
        },
    },
    'filters-medicine-options': {
        [BUTTON_GROUP_COMPONENTS_NAMES.wrapper]: {
            marginLeft: '12px',
        },
    },
    'filter-multi-value': {
        [BUTTON_GROUP_COMPONENTS_NAMES.button]: {
            background: 'transparent',
        },
    },
    currencies: {
        [BUTTON_GROUP_COMPONENTS_NAMES.wrapper]: {
            flexWrap: 'nowrap',
            height: '22px',
            margin: '0px',
        },
        [BUTTON_GROUP_COMPONENTS_NAMES.button]: {
            color: theme.colors.brand,
            marginRight: '0px',
            marginBottom: '0px',
            padding: '0px 3px',
            border: 'none',
            '&:hover': {
                background: 'transparent',
                opacity: 0.8,
                color: theme.colors.brand,
            },
            '&[data-selected="true"]': {
                background: 'transparent',
                opacity: 0.6,
                color: theme.colors.brand,
                '&:hover': {
                    background: 'transparent',
                },
            },
        },
    },
});
