import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';
import { useSearchParams } from 'react-router-dom';
import { ConnectedTabs, tabsActions } from '@che-ins-ui/tabs';
import { Modal } from '@che-ins-ui/modal';
import { useCheTheme } from '@/styles/themes';
import { MODAL_PRODUCT_ID_QUERY_PARAM } from '@/libs/constants';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { calculationIsLoadingSelector } from '@/models/calculation/selectors';
import { OFFER_MODAL_NAME } from '../../consts';
import { Header } from './Header';
import { TABS } from './tabs';
import { offerModalSelector } from './selector';
import { OfferModalContent } from './OfferModalContent';
const OFFER_MODAL_TABS_NAME = 'OFFER_MODAL_TABS_NAME';
export const OfferModal = memo(({ handleAddToBasket }) => {
    const dispatch = useDispatch();
    const { isOpen } = useSelector(offerModalSelector);
    const isLoadingCalculation = useSelector(calculationIsLoadingSelector);
    const theme = useCheTheme();
    const timeoutRef = useRef();
    useEffect(() => {
        if (!isOpen) {
            timeoutRef.current = setTimeout(() => {
                dispatch(tabsActions.setActiveTab({
                    tabsName: OFFER_MODAL_TABS_NAME,
                    activePanelName: TABS[0].panelName,
                }));
            }, theme.transition.duration);
        }
    }, [isOpen, theme]);
    const closedModalQueryString = useCalculationQueryString({
        removeParams: [MODAL_PRODUCT_ID_QUERY_PARAM],
    });
    const [_, setSearchParams] = useSearchParams();
    const handleModalClose = () => {
        setSearchParams(closedModalQueryString);
    };
    useUnmount(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    });
    return (_jsx(ConnectedTabs.TabsContext, { tabsName: OFFER_MODAL_TABS_NAME, defaultActivePanelName: TABS[0].panelName, children: _jsx(Modal, { withCloseButton: false, title: _jsx(Header, {}), name: OFFER_MODAL_NAME, appearance: "offer", baseAppearance: isLoadingCalculation ? 'offer-loading' : '', isOpen: isOpen, onClose: handleModalClose, children: _jsx(OfferModalContent, { handleAddToBasket: handleAddToBasket }) }) }));
});
