import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotebookFormFields } from './interface';
import { notebookActions } from './index';
export const setNotebookFormGender = createAsyncThunk('', async (value, thunkApi) => {
    thunkApi.dispatch(notebookActions.setGender(value));
    thunkApi.dispatch(notebookActions.setFormFieldError({
        field: NotebookFormFields.gender,
        value: null,
    }));
});
