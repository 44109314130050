import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { THEME_MODE_KEY, changingToThemeSelector, themeModeSelector, updateThemeAccordingToLs, } from '@/models/theme';
import { PALETTE } from '@/styles/palette';
import { isIframe } from '@/libs/helpers';
import { useStyles } from './styles';
export const ThemeBackground = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const themeMode = useSelector(themeModeSelector);
    const changingToTheme = useSelector(changingToThemeSelector);
    const isChanging = changingToTheme !== null;
    useEffect(() => {
        if (isIframe()) {
            return;
        }
        const handleStorageChange = (event) => {
            if (event.key === THEME_MODE_KEY) {
                dispatch(updateThemeAccordingToLs(event.newValue));
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(motion.div, { initial: { opacity: 0 }, animate: { opacity: isChanging ? 1 : 0 }, transition: { delay: isChanging ? 0 : 0.1, duration: 0.15 }, className: classes.blanket }), _jsx(motion.div, { transition: { duration: 0.1, type: 'just' }, animate: {
                    backgroundColor: {
                        light: PALETTE.background,
                        dark: PALETTE.darkBackground,
                    }[changingToTheme || themeMode],
                }, className: classes.background })] }));
});
