import { createSlice } from '@reduxjs/toolkit';
import { getObjectFilledWithValue } from '@/libs/helpers/utils';
import { DEFAULT_FIELD_ERROR } from '@/libs/validation/constants';
import { NOTEBOOK_STATE_NAMESPACE } from './consts';
import { NotebookFormFields, } from './interface';
import { getDeepCopy } from './helpers';
const defaultFormProps = {
    formValues: {
        ...getObjectFilledWithValue(NotebookFormFields, ''),
        gender: null,
        emoji: null,
        hasSecondNameRu: true,
    },
    formErrors: {
        ...getObjectFilledWithValue(NotebookFormFields, DEFAULT_FIELD_ERROR),
        emoji: null,
    },
    formDirty: getObjectFilledWithValue(NotebookFormFields, false),
    formStatus: {
        isValidated: false,
        isSubmitting: false,
        submittingError: null,
        isSuccessfullySubmitted: false,
    },
    deletingPersonStatus: {
        isLoading: false,
        error: null,
        isSuccessfullyDeleted: false,
    },
    wasScrollingEmojisAnimationShown: false,
    personsIdsUsedForTourists: [],
    personIdUsedForInsurer: null,
};
const initialState = {
    persons: [],
    ...defaultFormProps,
};
const notebookSlice = createSlice({
    name: NOTEBOOK_STATE_NAMESPACE,
    initialState,
    reducers: {
        setPersons: (state, action) => {
            state.persons = action.payload;
        },
        updatePerson: (state, action) => {
            const { id, person: updatePerson } = action.payload;
            state.persons = state.persons.map((person) => person.id === id ? updatePerson : person);
        },
        deletePerson: (state, action) => {
            const id = action.payload;
            state.persons = state.persons.filter((person) => person.id !== id);
        },
        resetForm: (state) => {
            state.formValues = getDeepCopy(defaultFormProps.formValues);
            state.formErrors = getDeepCopy(defaultFormProps.formErrors);
            state.formDirty = getDeepCopy(defaultFormProps.formDirty);
            state.formStatus = getDeepCopy(defaultFormProps.formStatus);
        },
        setFormFieldValue: (state, action) => {
            const { field, value } = action.payload;
            state.formValues[field] = value;
        },
        setHasSecondNameRu: (state, action) => {
            state.formValues.hasSecondNameRu = action.payload;
        },
        setGender: (state, action) => {
            state.formValues.gender = action.payload;
        },
        setEmoji: (state, action) => {
            state.formValues.emoji = action.payload;
        },
        setFormFieldError: (state, action) => {
            const { field, value } = action.payload;
            state.formErrors[field] = value;
        },
        setFormFieldIsDirty: (state, action) => {
            state.formDirty[action.payload] = true;
        },
        setAllFieldsDirty: (state) => {
            state.formDirty = getObjectFilledWithValue(NotebookFormFields, true);
        },
        setIsFormValidated: (state, action) => {
            state.formStatus.isValidated = action.payload;
        },
        setIsSubmitting: (state, action) => {
            state.formStatus.isSubmitting = action.payload;
        },
        setSubmittingError: (state, action) => {
            state.formStatus.submittingError = action.payload;
        },
        setIsSuccessfullySubmitted: (state, action) => {
            state.formStatus.isSuccessfullySubmitted = action.payload;
        },
        setDeletingPersonIsLoading: (state, action) => {
            state.deletingPersonStatus.isLoading = action.payload;
        },
        setDeletingPersonError: (state, action) => {
            state.deletingPersonStatus.error = action.payload;
        },
        setDeletingPersonIsSuccessfullyDeleted: (state, action) => {
            state.deletingPersonStatus.isSuccessfullyDeleted = action.payload;
        },
        resetDeletingPersonStatus: (state) => {
            state.deletingPersonStatus = getDeepCopy(defaultFormProps.deletingPersonStatus);
        },
        setWasScrollingEmojisAnimationShown: (state, action) => {
            state.wasScrollingEmojisAnimationShown = action.payload;
        },
        addPersonIdUsedForTourists: (state, action) => {
            const { payload } = action;
            if (!state.personsIdsUsedForTourists.includes(payload)) {
                state.personsIdsUsedForTourists.push(payload);
            }
        },
        setPersonIdUsedForInsurer: (state, action) => {
            state.personIdUsedForInsurer = action.payload;
        },
    },
});
export const { actions: notebookActions, reducer: notebookReducer } = notebookSlice;
export { NOTEBOOK_STATE_NAMESPACE };
export * from './consts';
