import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectedModal, modalActions, modalSelector } from '@che-ins-ui/modal';
import { ASSETS_SRC } from '@/libs/constants';
import { themeModeSelector } from '@/models/theme';
import { useStyles } from './styles';
import { hintModalIcons } from './hintModalIcons';
import { AgreeButton } from './AgreeButton';
import { ToggleServiceButton } from './ToggleServiceButton';
export const HintModal = memo(({ title, children, name, type = 'hint', actionType, serviceCode, value, withIcon = true, moreExpensiveTomorrowImage, missingOptionsImage, withServiceInfoImage, }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const themeMode = useSelector(themeModeSelector);
    const { isOpen } = useSelector(modalSelector(name));
    const Icon = hintModalIcons[type];
    const handleClose = useCallback(() => {
        dispatch(modalActions.close({ name }));
    }, [name]);
    useEffect(() => () => {
        isOpen && handleClose();
    }, [name]);
    const button = useMemo(() => {
        switch (actionType) {
            case 'agree':
                return _jsx(AgreeButton, { onClick: handleClose });
            case 'toggle-service':
                return (_jsx(ToggleServiceButton, { serviceCode: serviceCode, value: value, onModalClose: handleClose }));
            default:
                break;
        }
    }, [actionType]);
    const getImage = () => {
        if (withServiceInfoImage) {
            return (_jsx("img", { className: classes.serviceInfo, src: `${ASSETS_SRC.IMAGES}/modals/service-info-${themeMode}.webp`, alt: "" }));
        }
        if (moreExpensiveTomorrowImage) {
            return (_jsx("img", { className: classes.moreExpensiveTomorrowImage, src: `${ASSETS_SRC.IMAGES}/modals/more-expensive-tomorrow-${themeMode}.webp`, alt: "" }));
        }
        if (missingOptionsImage) {
            return (_jsx("img", { className: classes.missingOptionsImage, src: `${ASSETS_SRC.IMAGES}/modals/missing-options-${themeMode}.webp`, alt: "" }));
        }
        if (withIcon) {
            return _jsx(Icon, { className: classes.modalTitleIcon });
        }
    };
    return (_jsxs(ConnectedModal, { name: name, appearance: "hint", withCloseButton: actionType !== 'agree', title: _jsxs(_Fragment, { children: [getImage(), _jsx("span", { className: classes.modalTitle, children: title })] }), children: [children, button] }));
});
