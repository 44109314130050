import { createAsyncThunk } from '@reduxjs/toolkit';
import { getErrorMessage, wait } from '@/libs/helpers';
import { closeDeletePersonModal } from '@/components/common/EditPersonModal/DeletePersonModal';
import { closeEditPersonModal } from '@/components/common/EditPersonModal/EditPersonModal';
import { getUserToken, headersWithToken } from '../user/helpers';
import { ARTIFICIAL_DELAY, SUCCESS_DISPLAY_DURATION, notebookActions, } from './index';
export const deleteNotebookPerson = createAsyncThunk('/person/{id}', async (id, thunkApi) => {
    const closeModal = () => {
        thunkApi.dispatch(closeDeletePersonModal());
        thunkApi.dispatch(closeEditPersonModal());
        thunkApi.dispatch(notebookActions.deletePerson(id));
        setTimeout(() => {
            thunkApi.dispatch(notebookActions.resetDeletingPersonStatus());
            thunkApi.dispatch(notebookActions.resetForm());
        }, 300);
    };
    const userToken = getUserToken();
    if (!userToken) {
        return;
    }
    try {
        thunkApi.dispatch(notebookActions.setDeletingPersonIsLoading(true));
        thunkApi.dispatch(notebookActions.setDeletingPersonError(null));
        const results = await Promise.allSettled([
            wait(ARTIFICIAL_DELAY),
            fetch(`${__ACCOUNT_API_URL__}person/${id}`, {
                method: 'DELETE',
                ...headersWithToken(userToken, {
                    Accept: 'application/json',
                }),
            }),
        ]);
        const fetchResult = results[1];
        if (fetchResult.status === 'fulfilled') {
            const response = fetchResult.value;
            if (response.status >= 400) {
                const result = await response.json();
                throw new Error(JSON.stringify(result));
            }
        }
        else if (fetchResult.status === 'rejected') {
            throw new Error(fetchResult.reason);
        }
        thunkApi.dispatch(notebookActions.setDeletingPersonIsSuccessfullyDeleted(true));
        thunkApi.dispatch(notebookActions.setDeletingPersonIsLoading(false));
        await wait(SUCCESS_DISPLAY_DURATION);
        closeModal();
    }
    catch (error) {
        console.log(error);
        const errorMessage = getErrorMessage(error);
        const errorToDisplay = typeof errorMessage === 'string'
            ? errorMessage
            : 'Произошла ошибка при удалении';
        thunkApi.dispatch(notebookActions.setDeletingPersonError(errorToDisplay));
    }
    finally {
        thunkApi.dispatch(notebookActions.setDeletingPersonIsLoading(false));
    }
});
