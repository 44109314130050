import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { searchResultsSelector } from '@/models/search/selectors';
import { analytics } from '@/libs/analytics';
import { useStyles } from './styles';
export const SearchNotFound = memo(() => {
    const searchResults = useSelector(searchResultsSelector);
    if (!searchResults || searchResults.length) {
        return null;
    }
    return _jsx(Component, {});
});
const Component = () => {
    const classes = useStyles();
    useEffect(() => {
        analytics.sendEvent({
            action: 'travel_filters_search_not_found',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    }, []);
    return (_jsx("p", { className: classes.searchNotFound, children: "\u041F\u043E \u0432\u0430\u0448\u0435\u043C\u0443 \u0437\u0430\u043F\u0440\u043E\u0441\u0443 \u043D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E" }));
};
