import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence, motion } from 'framer-motion';
import { CheckIcon } from '@che-ins-ui/icons/CheckIcon';
import { memo } from 'react';
import { Button } from '@che-ins-ui/button';
import { useCheTheme } from '@/styles/themes';
import { CircleLoader } from '../CircleLoader/CircleLoader';
import { InputError } from '../InputError';
import { useStyles } from './styles';
const heightOffset = 60;
const hidingUpVariants = {
    hidden: { y: -heightOffset, opacity: 0.6 },
    visible: { y: 0, opacity: 1 },
};
const hidingDOwnVariants = {
    hidden: { y: heightOffset, opacity: 0.6 },
    visible: { y: 0, opacity: 1 },
};
const animationProps = {
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    layout: 'position',
    transition: {
        duration: 0.2,
        layout: { duration: 0.3 },
    },
};
const iconVariants = {
    hidden: { opacity: 0.2, scale: 0.3 },
    visible: { opacity: 1, scale: 1 },
};
export const EditPersonFormSubmitButton = memo(({ disabled, isLoading, onClick, isSuccessfullyDone, errorMessage, isDeletionAppearance, defaultText, successText, }) => {
    const theme = useCheTheme();
    const classes = useStyles();
    const color = isDeletionAppearance
        ? theme.colors.error
        : theme.colors.white;
    return (_jsxs("div", { className: classes.submitButtonWrapper, children: [_jsx(InputError, { error: errorMessage }), _jsx(Button, { baseAppearance: "edit-person-form-submit", appearance: isDeletionAppearance &&
                    'edit-person-form-submit-deletion', "data-selected": isSuccessfullyDone, disabled: disabled, shouldFitContent: true, onClick: onClick, children: _jsxs(AnimatePresence, { mode: "popLayout", initial: false, children: [isLoading || isSuccessfullyDone || (_jsx(motion.div, { variants: hidingUpVariants, ...animationProps, children: defaultText }, "save")), (isLoading || isSuccessfullyDone) && (_jsx(motion.div, { variants: hidingDOwnVariants, ...animationProps, className: classes.submitButtonIconWrapper, children: _jsx(AnimatePresence, { initial: false, mode: "sync", children: _jsx(motion.div, { variants: iconVariants, ...animationProps, style: { position: 'absolute' }, children: isLoading ? (_jsx(CircleLoader, { color: color })) : (_jsx("div", { className: classes.submitButtonCheckIconWrapper, "data-deletion-appearance": isDeletionAppearance, children: _jsx(CheckIcon, { fill: color }) })) }, isLoading.toString()) }) }, "icon")), isSuccessfullyDone && (_jsx(motion.div, { variants: hidingDOwnVariants, ...animationProps, children: successText }, "saved"))] }) })] }));
});
