import { isIframe } from '@/libs/helpers';
import { BASE_PALETTE, CUSTOM_PALETTE_COLORS } from './palette';
const isHexColor = (hex) => /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex);
const isColor = (value) => isHexColor(value) || value === 'transparent';
const isColorFromCustomPalette = (value) => CUSTOM_PALETTE_COLORS.includes(value);
export const getCheCustomPalette = (value) => {
    const paletteKey = 'che-custom-palette';
    let palette = {};
    if (isIframe() &&
        value &&
        typeof value === 'string' &&
        value.startsWith('{"che-custom-palette":') &&
        value.endsWith('"}}')) {
        try {
            const parsedValue = JSON.parse(value);
            if (typeof parsedValue === 'object' && paletteKey in parsedValue) {
                for (const [key, value] of Object.entries(parsedValue[paletteKey])) {
                    if (isColorFromCustomPalette(key) &&
                        typeof value === 'string' &&
                        isColor(value)) {
                        palette[key] = value;
                    }
                }
            }
        }
        catch { }
    }
    return palette;
};
export const mergeCustomPalette = (customPalette) => ({
    ...BASE_PALETTE,
    brand: customPalette?.primary || BASE_PALETTE.brand,
    brandTertiary: customPalette?.light || BASE_PALETTE.brandTertiary,
    background: customPalette?.background || BASE_PALETTE.background,
    error: customPalette?.error || BASE_PALETTE.error,
    deletion: customPalette?.deletion || BASE_PALETTE.deletion,
    accent: customPalette?.search || BASE_PALETTE.accent,
    success: customPalette?.success || BASE_PALETTE.success,
    warning: customPalette?.warning || BASE_PALETTE.warning,
});
