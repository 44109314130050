import { PALETTE } from '../palette';
import { themeComponents } from './components';
import { AbstractTheme } from './AbstractTheme';
import { LIGHT_COLORS } from './LightTheme';
export class DarkColors extends AbstractTheme {
    constructor() {
        super(...arguments);
        this.colors = {
            ...LIGHT_COLORS,
            brand: PALETTE.darkBrand,
            brandTertiary: PALETTE.darkBrandTertiary,
            accent: PALETTE.accent,
            success: PALETTE.darkSuccess,
            deletion: PALETTE.darkDeletion,
            textSecondary: PALETTE.darkTextSecondary,
            textTertiary: PALETTE.darkTextTertiary,
            textBackground: PALETTE.darkTextBackground,
            background: PALETTE.darkBackground,
            header: PALETTE.darkHeader,
            field: PALETTE.darkField,
            sky: PALETTE.white,
            cloud: PALETTE.darkCloud,
        };
        this.mode = 'dark';
    }
}
export class DarkTheme extends DarkColors {
    constructor() {
        super(...arguments);
        this.components = themeComponents.reduce((acc, { name, getter }) => {
            acc[name] = getter(this);
            return acc;
        }, {});
    }
}
