import { createSelector } from '@reduxjs/toolkit';
import { convertDateFromStateToISO } from '@che-ins-ui/datepicker';
import { FORMS_STATE_NAMESPACE } from './consts';
export const formsSelector = (state) => state[FORMS_STATE_NAMESPACE];
export const touristsFormsSelector = createSelector(formsSelector, ({ tourists }) => tourists);
export const touristsForApiSelector = createSelector(formsSelector, ({ tourists }) => tourists.map(({ lastName, firstName, birthday, age, fieldsErrors }) => ({
    lastName,
    firstName,
    birthday: fieldsErrors.birthday
        ? ''
        : convertDateFromStateToISO(birthday),
    age,
})));
export const insurerFormsSelector = createSelector(formsSelector, ({ insurer }) => insurer);
export const emailsSelector = createSelector(formsSelector, ({ emails }) => emails);
export const insurerEmailSelector = createSelector(formsSelector, ({ emails }) => emails[0].value);
export const isFormDirtySelector = createSelector(formsSelector, ({ isFormDirty }) => isFormDirty);
export const isFormValidatedSelector = createSelector(formsSelector, ({ isFormValidated }) => isFormValidated);
