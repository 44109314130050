import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { apiCountriesSelector, apiCountryGroupsSelector, } from '@/models/api/selectors';
import { requestActions } from '@/models/request';
import { requestIsCurrencyInitedSelector } from '@/models/request/selectors';
import { ROUTES } from '@/components/routes';
import { ORDER_ID_QUERY_PARAM } from '../constants';
export const useInitCurrency = () => {
    const apiCountries = useSelector(apiCountriesSelector);
    const apiCountryGroups = useSelector(apiCountryGroupsSelector);
    const isCurrencyInited = useSelector(requestIsCurrencyInitedSelector);
    const dispatch = useDispatch();
    const [search] = useSearchParams();
    useEffect(() => {
        if (apiCountries.length &&
            apiCountryGroups.length &&
            !isCurrencyInited) {
            const skipCalculation = window.location.pathname === ROUTES.insurance &&
                !!search.get(ORDER_ID_QUERY_PARAM);
            dispatch(requestActions.initializeCurrency(undefined, {
                skipCalculation,
            }));
        }
    }, [apiCountries, apiCountryGroups, isCurrencyInited]);
};
