import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { UPSELL_ENABLED } from '@/libs/constants';
import { isIgnoreUpsellSelector } from '@/models/request/selectors';
import { DetailsActiveRestHint } from './DetailsActiveRestHint';
import { DetailsStrongerProtectionHint } from './DetailsStongerProtectionHint';
import { DetailsMotoHint } from './DetailsMotoHint';
export const UpsellDetailsHints = () => {
    const isIgnoreUpsell = useSelector(isIgnoreUpsellSelector);
    if (isIgnoreUpsell || !UPSELL_ENABLED) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(DetailsActiveRestHint, {}), _jsx(DetailsStrongerProtectionHint, {}), _jsx(DetailsMotoHint, {})] }));
};
