import { createUseStyles } from 'react-jss';
export const useStyles = createUseStyles((theme) => ({
    '@keyframes animation': {
        '0%': {
            top: '0px',
            height: '50px',
        },
        '20%, 80%': {
            top: '15px',
            height: '20px',
        },
        '100%': {
            top: '0px',
            height: '50px',
        },
    },
    wrapper: {
        width: '100vw',
        height: '100vh',
        backgroundColor: theme.colors.header,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loader: {
        display: 'inline-block',
        position: 'relative',
        width: '40px',
        height: '50px',
        '& div': {
            display: 'inline-block',
            position: 'absolute',
            left: '0px',
            width: '5px',
            animation: '$animation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite',
        },
        '& div:nth-child(1)': {
            animationDelay: '-0.6s',
        },
        '& div:nth-child(2)': {
            animationDelay: '-0.48s',
            left: '8px',
        },
        '& div:nth-child(3)': {
            left: '16px',
            animationDelay: '-0.36s',
        },
        '& div:nth-child(4)': {
            left: '24px',
            animationDelay: '-0.24s',
        },
        '& div:nth-child(5)': {
            left: '32px',
            animationDelay: '-0.12s',
        },
    },
}));
