import * as Sentry from '@sentry/react';
import { createMask } from 'imask';
import qs from 'qs';
import { addDays, addYears, convertDateFromStateToISO, differenceInDays, formatDate, parseDate, } from '@che-ins-ui/datepicker';
import { todayPlus1Day } from '@/models/request';
import { requestSelector } from '@/models/request/selectors';
import { declOfNum, toErrorWithMessage } from '@/libs/helpers/index';
import { diffDates, getTodayString } from '@/libs/dates';
import { textValue } from '@/components/Services/utils';
import { touristsFormsSelector } from '@/models/forms/selectors';
import { BreadcrumbCategories, ErrorCustomParametres, ErrorSourceNames, ServiceType, } from '../types/global';
import { DENIED_MESSAGES_FOR_BREADCRUMBS, DENIED_URLS_FOR_BREADCRUMBS, IS_DEVELOPMENT, RESTORABLE_QS_PARAMS, } from '../constants';
import { MULTIPOLICY_FULL_YEAR_VALUE, MULTIPOLICY_CODE, getTypes, } from '../static';
export const filterBreadcrumbs = (breadcrumb) => {
    const { category, message, data } = breadcrumb;
    const breadcrumbCategories = Object.values(BreadcrumbCategories);
    if (category && breadcrumbCategories.includes(category)) {
        const isXhrCategoryDeniedBreadcrumb = category === BreadcrumbCategories.XHR &&
            DENIED_URLS_FOR_BREADCRUMBS.some((deniedUrl) => data?.url.includes(deniedUrl));
        const isConsoleCategoryDeniedBreadcrumb = category === BreadcrumbCategories.CONSOLE &&
            DENIED_MESSAGES_FOR_BREADCRUMBS.some((deniedMessage) => message?.includes(deniedMessage));
        if (isXhrCategoryDeniedBreadcrumb ||
            isConsoleCategoryDeniedBreadcrumb) {
            return null;
        }
    }
    return breadcrumb;
};
export const initializeErrorReporter = (SENTRY_CONFIG) => {
    const { dsn, environment } = SENTRY_CONFIG;
    return Sentry.init({
        dsn,
        environment,
        debug: IS_DEVELOPMENT,
        beforeBreadcrumb(breadcrumb) {
            return filterBreadcrumbs(breadcrumb);
        },
    });
};
export const captureError = (error, info) => {
    let eventId = '';
    Sentry.withScope((scope) => {
        scope.setTags({
            [ErrorCustomParametres.SOURCE]: ErrorSourceNames.CLIENT,
            ...(typeof info === 'string' && { context: info }),
        });
        eventId = Sentry.captureException(error);
        if (info) {
            scope.setExtra('info', JSON.stringify(info, null, '\t'));
        }
    });
    return eventId;
};
export const createMaskedValue = (value, maskOptions) => {
    const inputMask = createMask({ ...maskOptions });
    return inputMask.resolve(value);
};
export const separatePriceBySpaces = (value) => value
    .toString()
    .replace(/\s/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
export const formatPrice = (price) => separatePriceBySpaces(Math.ceil(Number(price)));
export const getLabelDeclination = (quantity, words) => `${quantity} ${declOfNum(quantity, words)}`;
export const getCountriesLabel = (countriesQuantity) => getLabelDeclination(countriesQuantity, getTypes('COUNTRY'));
export const getDaysLabel = (daysQuantity) => getLabelDeclination(daysQuantity, getTypes('DAY'));
export const getCardOptoinsLabel = (optionsQuantity) => getLabelDeclination(optionsQuantity, getTypes('SERVICESMALL'));
export const getPregnancyLabel = (value) => value ? `до ${value} нед.` : textValue[ServiceType.NOTCOVERED];
export const getAgeLabel = (age) => {
    if (!age) {
        return 'до 1 года';
    }
    return `${age} ${declOfNum(age, getTypes('AGE'))}`;
};
export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};
export const getTouristsLabelValue = (selectedTourists = []) => {
    const transformAge = (age, isLast) => isLast || age === 0 ? getAgeLabel(age).toLowerCase() : age;
    return selectedTourists
        .map(({ age }, index) => transformAge(age, index === selectedTourists.length - 1))
        .join(', ');
};
export const getDateStartAndInsuranceDays = ({ dateStart, dateEnd, services, }) => {
    if (!dateStart || !dateEnd) {
        if (!dateStart) {
            console.error('transformCalculation: request.dateStart is not defined');
        }
        if (!dateEnd) {
            console.error('transformCalculation: request.dateEnd is not defined');
        }
        return {
            dateStart: '',
            insuredDays: 0,
            testDateEnd: 'error',
        };
    }
    const start = convertDateFromStateToISO(dateStart);
    const end = convertDateFromStateToISO(dateEnd);
    let multipolicyEndDate = null;
    if (services[MULTIPOLICY_CODE]) {
        const nextYearDate = addYears(dateStart, 1);
        const newDateEnd = addDays(nextYearDate, -1);
        multipolicyEndDate = newDateEnd;
    }
    if (services[MULTIPOLICY_CODE] === MULTIPOLICY_FULL_YEAR_VALUE) {
        delete services[MULTIPOLICY_CODE];
    }
    return {
        dateStart: start,
        insuredDays: differenceInDays(new Date(start), multipolicyEndDate || new Date(end)) + 1,
        testDateEnd: multipolicyEndDate ? 'multi' : end,
    };
};
export const addToArrayIfNotExist = (array, item) => {
    if (!array.includes(item)) {
        return [...array, item];
    }
    return array;
};
export const removeFromArray = (array, removable) => {
    const multilpleRemove = Array.isArray(removable);
    return array.filter((elem) => {
        if (multilpleRemove) {
            return !removable.includes(elem);
        }
        else {
            return elem !== removable;
        }
    });
};
const skipEmptyStrings = (_, value) => value;
export const getCalculationQueryString = (state, params) => {
    const request = requestSelector(state);
    const tourist = touristsFormsSelector(state).map(({ age }) => ({
        age,
    }));
    const currentQueryParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });
    const restoredUiParams = Object.values(RESTORABLE_QS_PARAMS).reduce((acc, param) => ({ ...acc, [param]: currentQueryParams[param] }), {});
    const queryParams = {
        ...request,
        ...restoredUiParams,
        tourist,
        ...params?.addParams,
    };
    delete queryParams.isFrame;
    delete queryParams.isCurrencyInited;
    delete queryParams.isPaytureInPay;
    params?.removeParams?.forEach((param) => {
        delete queryParams[param];
    });
    const queryString = qs.stringify(queryParams, {
        skipNulls: true,
        filter: skipEmptyStrings,
    });
    return queryString;
};
export function prepareDates(dateStart, dateEnd) {
    try {
        let newDateStart;
        let newDateEnd;
        if (dateStart) {
            newDateStart =
                diffDates(getTodayString(), dateStart, 'days') > 0
                    ? parseDate(dateStart)
                    : todayPlus1Day;
        }
        if (dateEnd) {
            const start = newDateStart || todayPlus1Day;
            newDateEnd =
                diffDates(start, dateEnd, 'days') >= 0 &&
                    diffDates(start, dateEnd, 'days') <= 365
                    ? parseDate(dateEnd)
                    : start;
        }
        if (newDateStart && !newDateEnd) {
            newDateEnd = newDateStart;
        }
        if (!newDateStart && newDateEnd) {
            newDateStart = newDateEnd;
        }
        return {
            ...(newDateStart ? { dateStart: formatDate(newDateStart) } : {}),
            ...(newDateEnd ? { dateEnd: formatDate(newDateEnd) } : {}),
        };
    }
    catch (error) {
        captureError(toErrorWithMessage(error), 'prepareDates');
        console.error(error);
        return {};
    }
}
export const getAffiliateTool = (srcsale, isWhiteLabel) => (isWhiteLabel ? 'WL' : srcsale || null);
export const validationCode = (pastedString) => {
    let isValid = false;
    let code = null;
    const codeFromPaste = pastedString.match(/\d+/)?.[0];
    if (codeFromPaste && codeFromPaste.length === 4) {
        isValid = /^\d+$/.test(codeFromPaste);
        code = codeFromPaste;
    }
    return {
        isValid,
        code,
    };
};
export const getKeys = (target) => Object.keys(target);
export const getObjectFilledWithValue = (fields, value) => getKeys(fields).reduce((object, field) => ((object[field] = value), object), {});
