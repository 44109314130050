import { createUseStyles } from 'react-jss';
import { darken } from '@/styles/utils';
import { DEKSTOP_FONT_SIZES, DESKTOP_FILTERS_BREAKPOINT, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, } from '@/styles/themes/components';
const HEIGHT = 28;
const BORDER_RADIUS = 7;
const getMainStyles = (theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    height: HEIGHT,
    padding: '5px 8px 5px 8px',
    borderRadius: BORDER_RADIUS,
    fontSize: 14,
    letterSpacing: GLOBAL_LETTER_SPACING,
    color: theme.colors.sky,
    whiteSpace: 'nowrap',
    '& b': {
        fontWeight: 500,
    },
    [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
        fontSize: DEKSTOP_FONT_SIZES.main,
    },
});
const getEventsBackground = (theme, color) => {
    const darkenedColor = {
        light: darken(color, 0.05),
        dark: darken(color, 0.1),
    }[theme.mode];
    return {
        '&:active': {
            backgroundColor: darkenedColor,
        },
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            '&:hover': {
                backgroundColor: darkenedColor,
            },
        },
    };
};
export const useStyles = createUseStyles((theme) => ({
    tag: {
        position: 'relative',
        flexShrink: 0,
        height: HEIGHT,
        backgroundColor: theme.colors.textBackground,
        borderRadius: BORDER_RADIUS,
    },
    mainButton: {
        ...getMainStyles(theme),
        paddingRight: 33,
        transition: 'background-color 0.2s',
        ...getEventsBackground(theme, theme.colors.textBackground),
    },
    postfixButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        width: HEIGHT,
        height: HEIGHT,
        borderRadius: 7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            '& path': {
                fill: theme.colors.textSecondary,
            },
        },
        transition: 'background-color 0.2s',
        ...getEventsBackground(theme, theme.colors.textBackground),
    },
    plainButton: {
        ...getMainStyles(theme),
        '& svg': {
            width: 18,
            height: 18,
        },
        '&[data-appearance="default"]': {
            backgroundColor: theme.colors.brandTertiary,
            color: theme.colors.brand,
            [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
                '&:hover': {
                    backgroundColor: darken(theme.colors.brandTertiary),
                },
            },
            '& svg > path': {
                fill: theme.colors.brand,
            },
        },
        '&[data-appearance="reset"]': {
            backgroundColor: theme.colors.deletion,
            color: theme.colors.error,
            [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
                '&:hover': {
                    backgroundColor: darken(theme.colors.deletion),
                },
            },
            '& svg > path': {
                fill: theme.colors.error,
            },
        },
    },
}), { name: 'tag-button' });
