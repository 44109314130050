import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { modalActions } from '@che-ins-ui/modal';
import FiltersPanel from '@/components/filters/Filters';
import { MoreExpensiveTomorrowAlert } from '@/components/Showcase/Showcase';
import { calculationLastRequestIdSelector } from '@/models/calculation/selectors';
import { CalculationExpiredModal, CALCULATION_EXPIRED_MODAL_NAME, ShareLinkModal, } from '@/components/common/Hint';
import { EXPIRES_CALCULATION_TIME, IS_DEVELOPMENT } from '@/libs/constants';
import { useIsMobileFilters } from '@/libs/hooks';
import { getCalculationQueryString } from '@/libs/helpers/utils';
import { MoreOffersTomorrowHint } from '@/components/Showcase/components/MoreOffersTomorrowHint';
import { formsActions } from '@/models/forms';
export const ShowcaseModals = memo(() => {
    const dispatch = useDispatch();
    const calculationLastRequestId = useSelector(calculationLastRequestIdSelector);
    const isMobileFilters = useIsMobileFilters();
    const state = useSelector((state) => state, () => true);
    const [_, setSearch] = useSearchParams();
    useEffect(() => {
        const queryString = getCalculationQueryString(state);
        setSearch(queryString);
        dispatch(formsActions.setFormDirty(false));
    }, []);
    useEffect(() => {
        if (IS_DEVELOPMENT) {
            return;
        }
        const timer = setTimeout(() => {
            dispatch(modalActions.open({ name: CALCULATION_EXPIRED_MODAL_NAME }));
        }, EXPIRES_CALCULATION_TIME);
        return () => {
            timer && clearTimeout(timer);
        };
    }, [calculationLastRequestId]);
    if (isMobileFilters) {
        return _jsx(FiltersPanel, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(CalculationExpiredModal, {}), _jsx(ShareLinkModal, {}), _jsx(MoreExpensiveTomorrowAlert, {}), _jsx(MoreOffersTomorrowHint, {})] }));
});
