import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectedModal, modalActions } from '@che-ins-ui/modal';
import { Button } from '@che-ins-ui/button';
import { fetchCalculation } from '@/models/calculation';
import { ASSETS_SRC } from '@/libs/constants';
import { themeModeSelector } from '@/models/theme';
import { useStyles } from './styles';
export const CALCULATION_EXPIRED_MODAL_NAME = 'CALCULATION_EXPIRED_MODAL_NAME';
export const CalculationExpiredModal = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const themeMode = useSelector(themeModeSelector);
    const onClick = useCallback(() => {
        dispatch(fetchCalculation());
        dispatch(modalActions.close({ name: CALCULATION_EXPIRED_MODAL_NAME }));
    }, []);
    return (_jsxs(ConnectedModal, { name: CALCULATION_EXPIRED_MODAL_NAME, appearance: "hint", withCloseButton: false, closeClickByBlanket: false, title: _jsxs(_Fragment, { children: [_jsx("img", { className: classes.calculationExpiredImage, src: `${ASSETS_SRC.IMAGES}/modals/calculation-expired-${themeMode}.webp`, alt: "" }), _jsx("span", { className: classes.modalTitle, children: "\u0426\u0435\u043D\u044B \u043C\u043E\u0433\u043B\u0438 \u0438\u0437\u043C\u0435\u043D\u0438\u0442\u044C\u0441\u044F" })] }), children: [_jsx("span", { className: classes.modalHintText, children: "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u0435 \u0440\u0430\u0441\u0447\u0451\u0442, \u0447\u0442\u043E\u0431\u044B \u0432\u0438\u0434\u0435\u0442\u044C \u0430\u043A\u0442\u0443\u0430\u043B\u044C\u043D\u044B\u0435 \u0446\u0435\u043D\u044B" }), _jsx(Button, { shouldFitContent: true, onClick: onClick, appearance: "hint-modal-button", children: "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0440\u0430\u0441\u0447\u0451\u0442" })] }));
};
