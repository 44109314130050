import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDeferredValue } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '@che-ins-ui/modal';
import { basketProductSelector } from '@/models/basket/selectors';
import { CompanyLogo } from '@/components/Showcase/components/CompanyLogo';
import { useCheTheme } from '@/styles/themes';
import { useStyles } from './styles';
import { ChePolicyLogo } from './ChePolicyLogo';
import { CheckedIcon } from './Checked';
import { NotChecked } from './NotChecked';
export const UpsellCard = ({ type, isChecked, isIncluded, title, subTitle, imgSrc, imgWidth, imgHeight, detailsModalName, onClick, getCompensationText, getPrice, }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useCheTheme();
    const basketProduct = useSelector(basketProductSelector);
    const { company: { linkToLogo: logoSrc = '', name: companyName = '' }, } = basketProduct;
    const onClickDetails = (e, name) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(modalActions.open({ name, data: { isIncluded } }));
    };
    const onCardClick = () => {
        if (isIncluded) {
            return;
        }
        onClick();
    };
    return (_jsxs("div", { className: classes.card, "data-checked": isChecked || isIncluded, onClick: onCardClick, children: [_jsx("div", { className: classes.company, children: !type ? (_jsx(ChePolicyLogo, {})) : (_jsxs(_Fragment, { children: [_jsx(CompanyLogo, { alt: companyName, src: logoSrc, className: classes.companyLogo }), _jsx("span", { className: classes.companyName, children: companyName })] })) }), _jsx("div", { className: classes.checkedIcon, children: isChecked || isIncluded ? (_jsx(CheckedIcon, { fill: theme.colors.brand })) : (_jsx(NotChecked, { fill: theme.colors.textTertiary })) }), _jsx("div", { className: classes.header }), _jsxs("div", { className: classes.text, children: [_jsx("span", { className: classes.title, children: title }), _jsx("span", { className: classes.subTitle, children: subTitle })] }), _jsx("div", { className: classes.imageWrapper, children: _jsx("img", { src: imgSrc, width: imgWidth, height: imgHeight, className: classes.image }) }), _jsxs("footer", { className: classes.footer, children: [_jsx("div", { className: classes.price, children: useDeferredValue(getPrice(type, isChecked, isIncluded)) }), _jsx("div", { className: classes.compensation, children: getCompensationText(type) }), detailsModalName ? (_jsx("a", { className: classes.details, href: '#', onClick: (e) => onClickDetails(e, detailsModalName), children: "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435" })) : (_jsx("div", { style: { height: '19px' } }))] })] }, imgSrc));
};
