import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCopyToClipboard } from 'react-use';
import { ConnectedModal } from '@che-ins-ui/modal';
import { Button, IconButton } from '@che-ins-ui/button';
import { ShareTelegramLogo } from '@che-ins-ui/icons/ShareTelegramLogo';
import { ShareVKLogo } from '@che-ins-ui/icons/ShareVKLogo';
import { ShareWhatsAppLogo } from '@che-ins-ui/icons/ShareWhatsAppLogo';
import { SHARE_LINK_MODAL_NAME } from './const';
import { shareLinkModalSelector } from './selector';
import { useStyles } from './styles';
export const ShareLinkModal = () => {
    const { isOpen, shareUrl, additionalText } = useSelector(shareLinkModalSelector);
    const classes = useStyles();
    const [_, copyToClipboard] = useCopyToClipboard();
    const [isCopied, setIsCopied] = useState(false);
    const encodedURL = useMemo(() => {
        return encodeURIComponent(shareUrl);
    }, [shareUrl]);
    const handleCopy = () => {
        copyToClipboard(shareUrl);
        setIsCopied(true);
    };
    useEffect(() => {
        if (!isOpen) {
            setIsCopied(false);
        }
    }, [isOpen]);
    return (_jsxs(ConnectedModal, { name: SHARE_LINK_MODAL_NAME, baseAppearance: "share", appearance: "hint", title: _jsx(_Fragment, { children: _jsx("span", { className: classes.modalTitle, children: "\u041F\u043E\u0434\u0435\u043B\u0438\u0442\u044C\u0441\u044F" }) }), children: [_jsx("span", { className: classes.modalHintText, children: "\u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u043F\u043E\u0434\u0435\u043B\u0438\u0442\u044C\u0441\u044F \u043D\u0430\u0445\u043E\u0434\u043A\u043E\u0439 \u0432 \u0441\u043E\u0446. \u0441\u0435\u0442\u044F\u0445, \u0438\u043B\u0438 \u0441\u043A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0441\u0441\u044B\u043B\u043A\u0443" }), _jsxs("div", { className: classes.services, children: [_jsx(IconButton, { appearance: "share", icon: _jsx(ShareWhatsAppLogo, {}), target: "_blank", href: `https://wa.me/?text=${encodedURL} ${additionalText}` }), _jsx(IconButton, { appearance: "share", icon: _jsx(ShareTelegramLogo, {}), target: "_blank", href: `https://t.me/share/url?url=${encodedURL}&text=${additionalText}` }), _jsx(IconButton, { appearance: "share", icon: _jsx(ShareVKLogo, {}), target: "_blank", href: `https://vk.com/share.php?url=${encodedURL}&title=${additionalText}` })] }), _jsxs(Button, { baseAppearance: "hint-modal-button", appearance: "share", onClick: handleCopy, shouldFitContent: true, isSelected: isCopied, disabled: isCopied, children: [isCopied ? 'Ссылка скопирована' : 'Скопировать ссылку', ' '] })] }));
};
