import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { Skeleton } from '@che-ins-ui/skeleton';
import { useStyles } from './styles';
const ServiceSkeleton = () => {
    const classes = useStyles();
    return (_jsxs("div", { className: classes.serviceSkeletonWrapper, children: [_jsx(Skeleton, { width: '150px', height: '13px' }), _jsx(Skeleton, { width: '60px', height: '13px' })] }));
};
export const ContentSkeleton = ({ isDesktopView }) => {
    const classes = useStyles();
    const servicesRowClassName = classNames(classes.servicesRowSkeleton, isDesktopView && classes.servicesRowDesktopSkeleton);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.servicesBlock, children: new Array(5).fill(0).map((_, i) => {
                    return (_jsxs("div", { className: servicesRowClassName, children: [_jsx(ServiceSkeleton, {}), _jsx(ServiceSkeleton, {})] }, i));
                }) }), _jsx(Skeleton, { shouldFitContent: true, height: '45px' })] }));
};
