import { createSlice } from '@reduxjs/toolkit';
import { BASKET_STATE_NAMESPACE } from './consts';
export const basketInitialState = {
    product: undefined,
    productIsLoading: false,
    productIsMissing: false,
    lastChangesId: null,
};
const BasketSlice = createSlice({
    name: BASKET_STATE_NAMESPACE,
    initialState: basketInitialState,
    reducers: {
        setProductIsMissing: (state, action) => {
            state.productIsMissing = action.payload.value;
            state.lastChangesId = action.payload.lastChangesId;
            state.productIsLoading = false;
        },
        setProductIsLoading: (state, action) => {
            state.productIsLoading = action.payload;
        },
        addToBasket: (state, action) => {
            state.productIsMissing = false;
            state.product = action.payload.product;
            state.lastChangesId = action.payload.lastChangesId;
            state.productIsLoading = false;
        },
        changeProduct: (state, action) => {
            state.productIsMissing = false;
            state.product = action.payload.product;
            state.lastChangesId = action.payload.lastChangesId;
            state.productIsLoading = false;
        },
        clearBasket: (state, action) => {
            state.productIsMissing = false;
            state.product = undefined;
            state.lastChangesId = action.payload.lastChangesId;
            state.productIsLoading = false;
        },
    },
});
const { reducer: basketReducer, actions: basketActions } = BasketSlice;
export { basketReducer, basketActions };
export { BASKET_STATE_NAMESPACE };
