import { createUseStyles } from 'react-jss';
import { mixins } from '@/styles/mixins';
import { withAlpha } from '@/styles/utils';
import { blendHexColor } from '@/libs/helpers';
import { BLOCK_BOX_SHADOW } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    wrapper: {
        position: 'relative',
        minWidth: 'fit-content',
        width: '100%',
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        background: theme.colors.cloud,
        boxShadow: BLOCK_BOX_SHADOW,
        minHeight: 45,
        transition: theme.transition.global,
        border: `1px solid ${theme.colors.cloud}`,
        padding: '0 6px',
        gap: 6,
        '&[data-form-step="true"]': {
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        },
        '&[data-default="false"]': {
            marginBottom: 16,
            border: `1px solid ${theme.colors.error}`,
            backgroundColor: blendHexColor(theme.colors.error, 0.05, theme.colors.cloud),
            '&[data-form-step="true"]': {
                marginTop: 0,
            },
        },
    },
    input: {
        padding: '4px 0px 3.5px',
        fontSize: 16,
        backgroundColor: 'transparent',
        borderRadius: 6,
        color: theme.colors.sky,
        textAlign: 'center',
        outline: 'none',
        border: 'none',
        pointerEvents: 'none',
    },
    label: {
        fontSize: 14,
        whiteSpace: 'nowrap',
        color: theme.colors.sky,
    },
    button: {
        ...mixins.centeredContent(),
        flexShrink: 0,
        minWidth: 28,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 8,
    },
    iconWrapper: {
        ...mixins.centeredContent(),
    },
    infoCircle: {
        flexShrink: 0,
        width: 16,
        height: 16,
        ...mixins.centeredContent(),
        borderRadius: 22,
        border: `1px solid ${theme.colors.textPrimary}`,
        transition: theme.transition.global,
        cursor: 'help',
        '& svg > path': {
            fill: theme.colors.textPrimary,
            transition: theme.transition.global,
        },
        '&:hover': {
            borderColor: theme.colors.textPrimary,
            backgroundColor: theme.colors.textPrimary,
            '& svg > path': {
                fill: theme.colors.white,
            },
        },
    },
    infoBlock: {
        zIndex: 100,
        position: 'absolute',
        textAlign: 'start',
        backgroundColor: withAlpha(theme.colors.sky, 0.97),
        padding: 10,
        color: theme.colors.cloud,
        width: 440,
        fontSize: 14,
        borderRadius: 8,
        pointerEvents: 'none',
        cursor: 'default',
        top: -1,
        left: 'calc(100% + 8px)',
        '&[data-form-step="true"]': {
            left: -1,
            bottom: 'calc(7px + 100%)',
            top: 'unset',
        },
    },
    exportButton: {
        padding: '6px 10px',
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        borderRadius: 8,
        color: theme.colors.brand,
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        transition: theme.transition.global,
        fontSize: 14,
        '& svg > path': {
            fillOpacity: 1,
            fill: theme.colors.brand,
        },
        '&:hover': {
            backgroundColor: blendHexColor(theme.colors.brand, 0.05, theme.colors.cloud),
            border: `1px solid ${theme.colors.brand}`,
        },
        '&:disabled': {
            opacity: 0.5,
            cursor: 'not-allowed',
        },
    },
}), { name: 'admin-edit-block' });
