import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { createSelector } from '@reduxjs/toolkit';
import { modalActions, modalSelector } from '@che-ins-ui/modal';
import { Button } from '@che-ins-ui/button';
import { Modal } from '@che-ins-ui/modal';
import { ASSETS_SRC, MODAL_PRODUCT_ID_QUERY_PARAM } from '@/libs/constants';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { themeModeSelector } from '@/models/theme';
import { useStyles } from './styles';
export const OFFER_NOT_FOUND_MODAL_NAME = 'OFFER_NOT_FOUND_MODAL_NAME';
export const offerNotFoundModalOpen = () => modalActions.open({ name: OFFER_NOT_FOUND_MODAL_NAME });
export const offerNotFoundModalIsOpenSelector = createSelector(modalSelector(OFFER_NOT_FOUND_MODAL_NAME), ({ isOpen }) => isOpen);
export const OfferNotFoundModal = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const isOpen = useSelector(offerNotFoundModalIsOpenSelector);
    const themeMode = useSelector(themeModeSelector);
    const [_, setSearchParams] = useSearchParams();
    const closedModalQueryString = useCalculationQueryString({
        removeParams: [MODAL_PRODUCT_ID_QUERY_PARAM],
    });
    const handleModalClose = () => {
        dispatch(modalActions.close({ name: OFFER_NOT_FOUND_MODAL_NAME }));
        setSearchParams(closedModalQueryString);
    };
    return (_jsxs(Modal, { name: OFFER_NOT_FOUND_MODAL_NAME, isOpen: isOpen, appearance: "hint", withCloseButton: false, onClose: handleModalClose, title: _jsxs(_Fragment, { children: [_jsx("img", { className: classes.companyNotFoundImage, src: `${ASSETS_SRC.IMAGES}/modals/company-unavailable-${themeMode}.webp`, alt: "" }), _jsx("span", { className: classes.modalTitle, children: "\u041F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430" })] }), children: [_jsx("span", { className: classes.modalHintText, children: "\u0412\u044B\u0431\u0440\u0430\u043D\u043D\u0430\u044F \u0432\u0430\u043C\u0438 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u0438\u044F \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044F - \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u0430. \u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u0432\u044B\u0431\u0440\u0430\u0442\u044C \u0430\u043B\u044C\u0442\u0435\u0440\u043D\u0430\u0442\u0438\u0432\u043D\u044B\u0439 \u0432\u0430\u0440\u0438\u0430\u043D\u0442." }), _jsx(Button, { shouldFitContent: true, onClick: handleModalClose, appearance: "hint-modal-button", children: "\u0421\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u0432\u0430\u0440\u0438\u0430\u043D\u0442\u044B" })] }));
};
