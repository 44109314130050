import get from 'lodash.get';
import set from 'lodash.set';
import { toErrorWithMessage } from './helpers';
import { captureError } from './helpers/utils';
export const NAME_OF_STORAGE = 'cherehapa_vzr';
export const LSHelper = {
    get() {
        try {
            const localState = localStorage?.getItem(NAME_OF_STORAGE);
            if (!localState) {
                return null;
            }
            const parsedLocalStorage = JSON.parse(localState);
            return parsedLocalStorage;
        }
        catch (error) {
            captureError(toErrorWithMessage(error), 'LSHelper/get');
            console.error(error);
            return null;
        }
    },
    getField(path, defaultValue) {
        try {
            const storageValue = this.get();
            return get(storageValue, path, defaultValue);
        }
        catch (error) {
            captureError(toErrorWithMessage(error), 'LSHelper/getField');
            console.error(error);
        }
    },
    set(value) {
        try {
            localStorage?.setItem(NAME_OF_STORAGE, JSON.stringify(value));
        }
        catch (error) {
            captureError(toErrorWithMessage(error), 'LSHelper/set');
            console.error(error);
        }
    },
    setField(path, value) {
        try {
            const storageValue = this.get();
            const newStorageValue = set(storageValue, path, value);
            this.set(newStorageValue);
        }
        catch (error) {
            captureError(toErrorWithMessage(error), 'LSHelper/setField');
            console.error(error);
        }
    },
    removeAll() {
        try {
            localStorage?.removeItem(NAME_OF_STORAGE);
        }
        catch (error) {
            captureError(toErrorWithMessage(error), 'LSHelper/removeAll');
            console.error(error);
        }
    },
    removeField(path) {
        try {
            this.setField(path, undefined);
        }
        catch (error) {
            captureError(toErrorWithMessage(error), 'LSHelper/removeField');
            console.error(error);
        }
    },
};
