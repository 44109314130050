import { jsx as _jsx } from "react/jsx-runtime";
import { ConnectedModal, modalActions } from '@che-ins-ui/modal';
import { useCheTheme } from '@/styles/themes';
import { Loader } from '../AppLoader';
export const OVERLAY_LOADER_MODAL_NAME = 'OVERLAY_LOADER_MODAL_NAME';
export const overlayLoaderModalOpen = () => modalActions.open({ name: OVERLAY_LOADER_MODAL_NAME });
export const overlayLoaderModalClose = () => modalActions.close({ name: OVERLAY_LOADER_MODAL_NAME });
export const OverlayLoaderModal = () => {
    const theme = useCheTheme();
    return (_jsx(ConnectedModal, { name: OVERLAY_LOADER_MODAL_NAME, withCloseButton: false, closeClickByBlanket: false, appearance: "overlay-loader", children: _jsx(Loader, { loaderColor: theme.colors.brand }) }));
};
