import { createAsyncThunk } from '@reduxjs/toolkit';
import { capitalize } from '@/libs/helpers';
import { validateName } from '@/libs/validation/validateName';
import { getKeys } from '@/libs/helpers/utils';
import { RuName } from './interface';
import { formsActions } from './index';
export const setInsurerName = createAsyncThunk('', async ({ field, value }, thunkApi) => {
    const transformedValue = capitalize(value);
    const isCyrillic = getKeys(RuName).includes(field);
    const { error } = validateName(transformedValue, { isCyrillic });
    thunkApi.dispatch(formsActions._setInsurerField({ field, value: transformedValue }));
    thunkApi.dispatch(formsActions._setInsurerFieldError({ field, error }));
});
