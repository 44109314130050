import { useState, useEffect, useCallback, useRef } from 'react';
export const useScrollButtons = (options = {}) => {
    const { offset = 50 } = options;
    const scrollableRef = useRef(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const updateScroll = useCallback(() => {
        if (scrollableRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollableRef.current;
            const newCanScrollLeft = scrollLeft > offset;
            const newCanScrollRight = scrollWidth - scrollLeft - clientWidth > offset;
            setCanScrollLeft(newCanScrollLeft);
            setCanScrollRight(newCanScrollRight);
        }
    }, [offset]);
    useEffect(() => {
        const handleResize = () => {
            updateScroll();
        };
        if (scrollableRef.current) {
            scrollableRef.current.addEventListener('scroll', updateScroll);
        }
        const observer = new ResizeObserver(handleResize);
        if (scrollableRef.current) {
            observer.observe(scrollableRef.current);
        }
        const mutationObserver = new MutationObserver(handleResize);
        if (scrollableRef.current) {
            mutationObserver.observe(scrollableRef.current, {
                childList: true,
                subtree: true,
            });
        }
        return () => {
            if (scrollableRef.current) {
                scrollableRef.current.removeEventListener('scroll', updateScroll);
            }
            observer.disconnect();
            mutationObserver.disconnect();
        };
    }, [updateScroll]);
    useEffect(() => {
        updateScroll();
    }, [updateScroll]);
    const scroll = (direction, offset = 380) => {
        scrollableRef.current?.scrollBy({
            left: direction === 'left' ? -offset : offset,
            behavior: 'smooth',
        });
    };
    return { scrollableRef, canScrollLeft, canScrollRight, scroll };
};
