import { createUseStyles } from 'react-jss';
export const useStyles = createUseStyles((theme) => ({
    blanket: {
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(14, 14, 14, 0.1)',
        zIndex: 100000,
        pointerEvents: 'none',
        backdropFilter: 'blur(3.5px)',
        WebkitBackdropFilter: 'blur(3.5px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            backdropFilter: 'blur(6px)',
            WebkitBackdropFilter: 'blur(6px)',
        },
    },
    background: {
        position: 'fixed',
        inset: 0,
        zIndex: -1,
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}), { name: 'theme-background' });
