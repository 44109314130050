import { createAsyncThunk } from '@reduxjs/toolkit';
import { getKeys } from '@/libs/helpers/utils';
import { NotebookFormFields } from './interface';
import { notebookActions } from './index';
export const validateNotebookForm = createAsyncThunk('', async (_, thunkApi) => {
    const { notebook: { formValues: { hasSecondNameRu }, formErrors, }, } = thunkApi.getState();
    const fields = getKeys(NotebookFormFields);
    const fieldsToValidate = hasSecondNameRu
        ? fields
        : fields.filter((field) => field !== NotebookFormFields.secondNameRu);
    const isValidated = fieldsToValidate.every((field) => !formErrors[field]);
    thunkApi.dispatch(notebookActions.setIsFormValidated(isValidated));
});
