import { jsx as _jsx } from "react/jsx-runtime";
import { BREAKPOINTS, useScreenSizes } from '@che-ins-ui/theme';
import { inputLabels, inputNames, placeholders, } from '@/components/insurance/consts';
import { MIN_INSURER_AGE } from '@/libs/constants';
import { MaleIcon } from './MaleIcon';
import { FemaleIcon } from './FemaleIcon';
export const notebookFormNameFieldsLabels = {
    firstName: 'First name',
    lastName: 'Last name',
    firstNameRu: inputLabels.firstNameRu,
    lastNameRu: inputLabels.lastNameRu,
    secondNameRu: inputLabels.secondNameRu,
};
export const notebookFormNameFieldsPlaceholders = {
    firstName: placeholders.firstName,
    lastName: placeholders.lastName,
    firstNameRu: placeholders.firstNameRu,
    lastNameRu: placeholders.lastNameRu,
    secondNameRu: placeholders.secondNameRu,
};
export const notebookFormNameFieldsNames = {
    firstName: inputNames.firstName,
    lastName: inputNames.lastName,
    firstNameRu: inputNames.firstNameRu,
    lastNameRu: inputNames.lastNameRu,
    secondNameRu: inputNames.secondNameRu,
};
export const notebookFormGenders = [
    { gender: 'male', label: 'М', icon: _jsx(MaleIcon, {}) },
    { gender: 'female', label: 'Ж', icon: _jsx(FemaleIcon, {}) },
];
export const insurerAgeWarning = `Данное лицо нельзя будет выбрать как страхователя в силу возраста. Минимальный возраст страхователя - ${MIN_INSURER_AGE} лет`;
export const EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT = BREAKPOINTS.md - 100;
export const useIsDesktopEditPersonModalView = () => {
    const { width } = useScreenSizes();
    return width >= EDIT_PERSON_FIELD_DESKTOP_BREAKPOINT;
};
