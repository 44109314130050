import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Button } from '@che-ins-ui/button';
import { ConnectedTabs } from '@che-ins-ui/tabs';
import { useScreenSizes } from '@che-ins-ui/theme';
import { CURRENCIES_BADGES } from '@/libs/constants';
import { formatPrice } from '@/libs/helpers/utils';
import { offerModalSelector } from './selector';
import { useStyles } from './styles';
import { TABS } from './tabs';
import { ContentSkeleton } from './skeletons';
export const OfferModalContent = ({ handleAddToBasket, }) => {
    const { offer, state } = useSelector(offerModalSelector);
    const classes = useStyles();
    const { isMobile } = useScreenSizes();
    const isDesktopView = !isMobile;
    if (state === 'loading') {
        return _jsx(ContentSkeleton, { isDesktopView: isDesktopView });
    }
    return (_jsxs(_Fragment, { children: [TABS.map(({ Component, panelName }) => (_jsx(ConnectedTabs.TabPanel, { panelName: panelName, children: _jsx("div", { className: classes.tabContentWrapper, children: _jsx(Component, { offer: offer, isDesktopView: isDesktopView }) }) }, panelName))), _jsx(Button, { onClick: () => handleAddToBasket(offer), baseAppearance: "success", appearance: "modal-pay", children: _jsxs(_Fragment, { children: [_jsx("span", { children: "\u041A\u0443\u043F\u0438\u0442\u044C\u00A0" }), _jsxs("b", { children: [formatPrice(offer.calculation?.priceRub), "\u00A0", CURRENCIES_BADGES.rub] })] }) })] }));
};
