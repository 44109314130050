import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from '@che-ins-ui/input';
import { InputError } from '@/components/common/InputError';
import { TouristField } from '@/models/forms/interface';
import { formsActions } from '@/models/forms';
import { setTouristBirthday } from '@/models/forms/setTouristBirthday';
import { inputLabels, inputNames, placeholders } from '../../consts';
const field = TouristField.birthday;
export const TouristBirthdayInput = memo(({ _uuid, appearance, birthday, previewedBirthday, errorMessage, }) => {
    const dispatch = useDispatch();
    return (_jsxs("div", { children: [_jsx(Input, { value: previewedBirthday || birthday, onChange: previewedBirthday
                    ? () => { }
                    : (value) => dispatch(setTouristBirthday({ _uuid, value })), name: inputNames.birthday, label: inputLabels.birthday, shouldFitContent: true, placeholder: placeholders.birthday, appearance: appearance, invalid: Boolean(errorMessage), onBlur: () => {
                    dispatch(formsActions.setTouristFieldDirty({ field, _uuid }));
                } }), _jsx(InputError, { error: errorMessage })] }));
});
