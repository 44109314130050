import { createSelector } from '@reduxjs/toolkit';
import { NOTEBOOK_STATE_NAMESPACE } from './consts';
export const notebookSelector = (state) => state[NOTEBOOK_STATE_NAMESPACE];
export const personsSelector = createSelector(notebookSelector, ({ persons }) => persons);
export const notebookFormValuesSelector = createSelector(notebookSelector, ({ formValues }) => formValues);
export const notebookFormErrorsSelector = createSelector(notebookSelector, ({ formErrors }) => formErrors);
export const notebookFormDirtySelector = createSelector(notebookSelector, ({ formDirty }) => formDirty);
export const notebookFormStatusSelector = createSelector(notebookSelector, ({ formStatus }) => formStatus);
export const notebookDeletingPersonStatusSelector = createSelector(notebookSelector, ({ deletingPersonStatus }) => deletingPersonStatus);
export const wasScrollingEmojisAnimationShownSelector = createSelector(notebookSelector, ({ wasScrollingEmojisAnimationShown }) => wasScrollingEmojisAnimationShown);
export const personsIdsUsedForTouristsSelector = createSelector(notebookSelector, ({ personsIdsUsedForTourists }) => personsIdsUsedForTourists);
export const personIdUsedForInsurerSelector = createSelector(notebookSelector, ({ personIdUsedForInsurer }) => personIdUsedForInsurer);
