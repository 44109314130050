import { getDateStartAndInsuranceDays } from '../helpers/utils';
export function transformCalculation(request, tourists) {
    const services = { ...request.services };
    const { dateStart, insuredDays } = getDateStartAndInsuranceDays({
        dateStart: request.dateStart,
        dateEnd: request.dateEnd,
        services,
    });
    return {
        dateStart,
        insuredDays,
        currency: request.currency.toUpperCase(),
        services,
        tourists: tourists.map((tourist) => ({
            ...tourist,
            birthday: tourist.birthday || undefined,
        })),
        countries: request.countries,
        countryGroups: request.countryGroups,
        sports: request.sports,
        assistances: request.assistances,
    };
}
