import { ACCIDENT_CODE, AVIA_CARGO_CODE, CIVIL_LIABILITY_CODE, MEDICINE_CODE, MULTIPOLICY_CODE, OBSERVATION_CODE, TRIP_CANCEL_CODE, URGENCY_STOMATOLOGY_CODE, } from '@/libs/static';
export const REQUEST_STATE_NAMESPACE = 'request';
export const DATE_START_INTERVAL_FROM_TODAY = 3;
export const CHEREHAPA_PARTNER_ID = 1;
export const ALL_SERVICE_DEFAULT = {
    [MEDICINE_CODE]: 50000,
    [MULTIPOLICY_CODE]: 30,
    [OBSERVATION_CODE]: 1500,
    [TRIP_CANCEL_CODE]: 500,
    [ACCIDENT_CODE]: 1000,
    [AVIA_CARGO_CODE]: 500,
    [CIVIL_LIABILITY_CODE]: 10000,
};
export const SERVICE_DEFAULT = {
    [MEDICINE_CODE]: ALL_SERVICE_DEFAULT[MEDICINE_CODE],
    [URGENCY_STOMATOLOGY_CODE]: 1,
};
export const DEFAULT_MARKETING_PARAMS = {
    partnerId: null,
    marker: null,
    marker2: null,
    srcsale: null,
};
export const currencies = ['eur', 'usd'];
