import { createUseStyles } from 'react-jss';
import { mixins } from '@/styles/mixins';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
import { withAlpha } from '@/styles/utils';
import { comparingTableAsideLeftOffset } from './ComparingTableAside';
const PADDING_BOTTOM = 35;
export const getServiceBackgroundColor = (theme) => withAlpha(theme.colors.textTertiary, 0.4);
export const useTableStyles = createUseStyles((theme) => ({
    offers: {
        padding: `0 15px ${PADDING_BOTTOM}px`,
        display: 'flex',
        gap: 6,
        width: 'max-content',
    },
    aside: {
        zIndex: 2,
        position: 'sticky',
        overflow: 'hidden',
        left: comparingTableAsideLeftOffset,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        padding: '104px 3px 0 10px',
        margin: '-10px -4px 0 -10px',
        backgroundColor: withAlpha(theme.colors.cloud, 0.75),
        backdropFilter: 'blur(7px)',
    },
    asideObservedElement: {
        position: 'absolute',
        width: 4,
        height: '100%',
    },
    asideLimitations: {
        width: '100%',
        height: 'auto',
        paddingTop: 9,
        display: 'flex',
        justifyContent: 'flex-end',
        '& div': {
            width: 'fit-content',
        },
    },
    asideLimitationsIcon: {
        flexShrink: 0,
        height: 16,
        ...mixins.centeredContent(),
        width: '16px !important',
        border: `1px solid ${theme.colors.warning}`,
        borderRadius: 999,
        transform: 'translate(4px, 4px)',
        '& svg': {
            width: 11,
            height: 11,
        },
    },
    offer: {
        flexShrink: 0,
        minWidth: 240,
        maxWidth: 270,
        backgroundColor: theme.colors.cloud,
        '&[data-expanded="true"]': {
            minWidth: 340,
            maxWidth: 340,
        },
    },
    offerHeaderWrapper: {
        padding: '8px 8px 10px',
        borderRadius: 12,
        border: `1px solid ${theme.colors.textTertiary}`,
        backgroundColor: getServiceBackgroundColor(theme),
        position: 'relative',
    },
    offerHeader: {
        display: 'flex',
        gap: 10,
        marginBottom: 12,
        height: 34,
    },
    offerImage: {
        height: 32,
        width: 32,
        objectFit: 'contain',
    },
    offerDescription: {
        fontSize: DEKSTOP_FONT_SIZES.small,
        color: '#718299',
        width: 'max-content',
        '&[data-warning="true"]': {
            color: theme.colors.warning,
        },
        '& span': {
            color: '#718299',
        },
    },
    offerTitles: {
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        flexGrow: 1,
        marginTop: -2,
    },
    offerTitle: {
        color: theme.colors.sky,
        fontSize: MOBILE_FONT_SIZES.main,
        width: 'max-content',
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    offerButtons: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        height: 28,
        justifyContent: 'center',
        margin: '12px 0px 2px',
        '&[data-moving-available="true"]': {
            justifyContent: 'space-between',
        },
    },
    offerPriceWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    offerLimitations: {
        padding: '13px 5px 0',
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        fontSize: DEKSTOP_FONT_SIZES.main,
        letterSpacing: '-0.5px',
        color: withAlpha(theme.colors.sky, 0.9),
        '& span': {
            width: '100%',
            textAlign: 'center',
        },
        '& p': {
            color: withAlpha(theme.colors.sky, 0.9),
        },
    },
    offerLimitationsDot: {
        position: 'relative',
        top: -1.5,
        marginRight: 5,
        fontSize: 10,
    },
    service: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        fontSize: DEKSTOP_FONT_SIZES.main,
        fontWeight: 400,
        letterSpacing: GLOBAL_LETTER_SPACING,
        color: withAlpha(theme.colors.sky, 0.9),
        justifyContent: 'flex-end',
        borderWidth: 1,
        borderStyle: 'solid',
        '&[data-centered="true"]': {
            justifyContent: 'center',
            fontWeight: 500,
        },
        '&[data-disadvantage="true"]': {
            color: theme.colors.error,
        },
    },
    bottomGradient: {
        width: '100%',
        background: `linear-gradient(180deg, 
                ${withAlpha(theme.colors.cloud, 0)} 0, 
                ${withAlpha(theme.colors.cloud, 0.5)} 33%, 
                ${theme.colors.cloud}
                )`,
        height: PADDING_BOTTOM + 5,
        position: 'fixed',
        bottom: 0,
        zIndex: 2,
        left: 0,
        pointerEvents: 'none',
    },
    tagQuestionIcon: {
        flexShrink: 0,
        width: 16,
        height: 16,
        paddingRight: 0.5,
        fontWeight: 500,
        borderRadius: 50,
        color: {
            light: theme.colors.textTertiary,
            dark: withAlpha(theme.colors.textPrimary, 0.8),
        }[theme.mode],
        border: `1px solid currentColor`,
        fontSize: 13,
        ...mixins.centeredContent(),
        userSelect: 'none',
        transition: theme.transition.global,
        cursor: 'pointer',
        '&[data-selected="true"]': {
            color: theme.colors.textPrimary,
            opacity: {
                light: 0.6,
                dark: 1,
            }[theme.mode],
        },
        '&:hover': {
            color: theme.colors.sky,
            opacity: 1,
        },
    },
}), { name: 'comparing-table' });
