import { createAsyncThunk } from '@reduxjs/toolkit';
import { toErrorWithMessage } from '@/libs/helpers';
import { captureError } from '@/libs/helpers/utils';
import { processActions } from './slice';
import { createOrder } from './createOrder';
import { EmailConfirmationStatuses } from './consts';
export const emailConfirmation = createAsyncThunk('process/emailConfirmation', async ({ code, email }, listenerApi) => {
    try {
        listenerApi.dispatch(processActions.setEmailConfirmationStatus(EmailConfirmationStatuses.loading));
        const body = {
            code,
            email,
        };
        const { isValid } = await fetch(`${__VZR_API_URL__}antiDos/checkCode`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then((res) => res.json());
        if (isValid) {
            listenerApi.dispatch(processActions.setEmailConfirmationStatus(EmailConfirmationStatuses.success));
            listenerApi.dispatch(createOrder({ code }));
        }
        else {
            listenerApi.dispatch(processActions.setEmailConfirmationStatus(EmailConfirmationStatuses.error));
        }
    }
    catch (error) {
        captureError(toErrorWithMessage(error), 'process/emailConfirmation');
        console.error(error);
        listenerApi.dispatch(processActions.setEmailConfirmationStatus(EmailConfirmationStatuses.error));
        throw new Error('process/emailConfirmation');
    }
});
