import { jsx as _jsx } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import { memo } from 'react';
export const SearchElementWrapper = memo(({ children, code, searchResults, }) => {
    const shouldShow = !searchResults || searchResults.includes(code);
    return _jsx(Component, { shouldShow: shouldShow, children: children });
});
export const SearchBlockWrapper = memo(({ children, codes, searchResults, }) => {
    const shouldShow = !searchResults ||
        codes.some((code) => searchResults.includes(code));
    return shouldShow ? children : null;
});
const Component = ({ children, shouldShow, }) => (_jsx(motion.div, { animate: {
        opacity: shouldShow ? 1 : 0,
        height: shouldShow ? 'auto' : 0,
    }, style: { pointerEvents: shouldShow ? 'auto' : 'none' }, transition: { duration: 0.1 }, children: children }));
