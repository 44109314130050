import { createAsyncThunk } from '@reduxjs/toolkit';
import { closeEditPersonModal } from '@/components/common/EditPersonModal/EditPersonModal';
import { getErrorMessage, wait } from '@/libs/helpers';
import { getUserToken, headersWithToken } from '../user/helpers';
import { transformApiPerson, transformFormToApiPerson, comparePersonWithForm, } from './helpers';
import { ARTIFICIAL_DELAY, SUCCESS_DISPLAY_DURATION, notebookActions, } from './index';
export const submitNotebookForm = createAsyncThunk('/person/{id}', async (id, thunkApi) => {
    thunkApi.dispatch(notebookActions.setAllFieldsDirty());
    const { notebook: { formValues, persons, formStatus: { isValidated }, }, } = thunkApi.getState();
    if (!isValidated) {
        return;
    }
    const changedPerson = persons.find((person) => person.id === id);
    const hasPersonChanged = Boolean(changedPerson && !comparePersonWithForm(changedPerson, formValues));
    const closeModal = () => {
        thunkApi.dispatch(closeEditPersonModal());
        setTimeout(() => {
            thunkApi.dispatch(notebookActions.resetForm());
            thunkApi.dispatch(notebookActions.resetDeletingPersonStatus());
        }, 300);
    };
    const closeModalAfterSuccess = async () => {
        thunkApi.dispatch(notebookActions.setIsSuccessfullySubmitted(true));
        await wait(SUCCESS_DISPLAY_DURATION);
        closeModal();
    };
    if (!hasPersonChanged) {
        await closeModalAfterSuccess();
        return;
    }
    const userToken = getUserToken();
    if (!userToken) {
        return;
    }
    try {
        thunkApi.dispatch(notebookActions.setIsSubmitting(true));
        thunkApi.dispatch(notebookActions.setSubmittingError(null));
        const apiPerson = transformFormToApiPerson(formValues);
        const results = await Promise.allSettled([
            wait(ARTIFICIAL_DELAY),
            fetch(`${__ACCOUNT_API_URL__}person/${id}`, {
                method: 'PUT',
                body: JSON.stringify(apiPerson),
                ...headersWithToken(userToken, {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }),
            }),
        ]);
        const fetchResult = results[1];
        if (fetchResult.status === 'fulfilled') {
            const response = fetchResult.value;
            if (response.status >= 400) {
                const result = await response.json();
                throw new Error(JSON.stringify(result));
            }
        }
        else if (fetchResult.status === 'rejected') {
            throw new Error(fetchResult.reason);
        }
        thunkApi.dispatch(notebookActions.updatePerson({
            id,
            person: transformApiPerson({ ...apiPerson, id }),
        }));
        thunkApi.dispatch(notebookActions.setIsSubmitting(false));
        await closeModalAfterSuccess();
    }
    catch (error) {
        console.log(error);
        const errorMessage = getErrorMessage(error);
        const errorToDisplay = typeof errorMessage === 'string'
            ? errorMessage
            : 'Произошла ошибка при сохранении данных';
        thunkApi.dispatch(notebookActions.setSubmittingError(errorToDisplay));
    }
    finally {
        thunkApi.dispatch(notebookActions.setIsSubmitting(false));
    }
});
