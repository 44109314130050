import { createAsyncThunk } from '@reduxjs/toolkit';
import { searchTrie } from './search';
import { searchActions } from './index';
let debounceTimeout = null;
const DEBOUNCE_DELAY = 50;
export const setSearchInput = createAsyncThunk('', async (input, listenerApi) => {
    try {
        listenerApi.dispatch(searchActions._setInput(input));
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        const isEmpty = !input.trim();
        debounceTimeout = setTimeout(() => {
            listenerApi.dispatch(searchActions._setResults(isEmpty ? null : Array.from(searchTrie.search(input))));
        }, isEmpty ? 0 : DEBOUNCE_DELAY);
    }
    catch (error) {
        console.error(error);
    }
});
