import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { Checkbox as CheCheckbox, CheckboxGroup as CheCheckboxGroup, } from '@che-ins-ui/checkbox';
import { CheckIcon } from '@che-ins-ui/icons/CheckIcon';
import { BREAKPOINTS, useScreenSizes } from '@che-ins-ui/theme';
import { DESKTOP_SIZES_BREAKPOINT } from '@/styles/themes/components';
import { useCheTheme } from '@/styles/themes';
export const checkboxIconDimentions = {
    mobile: { width: 12, height: 12 },
    desktop: { width: 10, height: 10 },
};
export const Checkbox = memo((props) => {
    const { width } = useScreenSizes();
    const theme = useCheTheme();
    const isMobile = width <= BREAKPOINTS[DESKTOP_SIZES_BREAKPOINT];
    const iconDimentions = isMobile
        ? checkboxIconDimentions.mobile
        : checkboxIconDimentions.desktop;
    return (_jsx(CheCheckbox, { checkedIcon: _jsx(CheckIcon, { ...iconDimentions, fill: theme.colors.white }), ...props }));
});
export const CheckboxGroup = ({ options, ...props }) => {
    const theme = useCheTheme();
    const { width } = useScreenSizes();
    const isMobile = width <= BREAKPOINTS[DESKTOP_SIZES_BREAKPOINT];
    const iconDimentions = isMobile
        ? checkboxIconDimentions.mobile
        : checkboxIconDimentions.desktop;
    const mappedOptions = useMemo(() => {
        return options.map((option) => ({
            ...option,
            checkedIcon: (_jsx(CheckIcon, { ...iconDimentions, fill: theme.colors.white })),
        }));
    }, [options, iconDimentions]);
    return _jsx(CheCheckboxGroup, { options: mappedOptions, ...props });
};
