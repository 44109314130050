import { createAsyncThunk } from '@reduxjs/toolkit';
import { createMaskedValue } from '@/libs/helpers/utils';
import { DATE_MASK } from '@/libs/constants';
import { validateDate } from '@/libs/validation/validateDate';
import { TouristField } from './interface';
import { formsActions } from './index';
const field = TouristField.birthday;
function calculateAge(validatedStateBirthday) {
    const [day, month, year] = validatedStateBirthday.split('.').map(Number);
    const birthday = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    if (today.getMonth() < birthday.getMonth() ||
        (today.getMonth() === birthday.getMonth() &&
            today.getDate() < birthday.getDate())) {
        age--;
    }
    return age;
}
export const setTouristBirthday = createAsyncThunk('', async ({ _uuid, value, noMeta = false }, thunkApi) => {
    const maskedValue = createMaskedValue(value, DATE_MASK);
    const { error } = validateDate(maskedValue);
    thunkApi.dispatch(formsActions._setTouristField({ _uuid, field, value: maskedValue }));
    thunkApi.dispatch(formsActions._setTouristFieldError({ _uuid, field, error }));
    if (!error) {
        const age = calculateAge(maskedValue);
        thunkApi.dispatch(formsActions._setTouristAge({ _uuid, age }, noMeta));
    }
});
