import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { modalActions } from '@che-ins-ui/modal';
import { Skeleton } from '@che-ins-ui/skeleton';
import { ChevronIcon } from '@che-ins-ui/icons/ChevronIcon';
import { calculationIsLoadingSelector, matchedOffersSelector, } from '@/models/calculation/selectors';
import { comparingProductsSelector } from '@/models/comparing/selectors';
import { isIframe } from '@/libs/helpers';
import { analytics } from '@/libs/analytics';
import { comparingActions } from '@/models/comparing';
import { useCheTheme } from '@/styles/themes';
import { getMatchingOffersAmount } from './helpers';
import { COMPARING_MODAL_NAME } from './const';
import { useComparingButtonStylesStyles } from './comparingButtonStyles';
import { ComparingButtonOffersLogos } from './ComparingButtonOffersLogos';
export const ComparingButtonFilters = memo(() => {
    if (!isIframe()) {
        return null;
    }
    return _jsx(Button, {});
});
const buttonPadding = 12;
const leftTextVariants = {
    hidden: { left: -170, scale: 2 },
    visible: { left: buttonPadding, scale: 1 },
};
const offersVariants = {
    hidden: { left: 40, scale: 0.4, opacity: 0 },
    visible: { left: buttonPadding, scale: 1, opacity: 1 },
};
const titleVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
};
const arrowVariants = {
    hidden: { right: 40, scale: 0.4, opacity: 0 },
    visible: { right: buttonPadding, scale: 1, opacity: 1 },
};
const rightTextVariants = {
    hidden: { right: -200, scale: 2 },
    visible: { right: buttonPadding, scale: 1 },
};
const Button = memo(() => {
    const classes = useComparingButtonStylesStyles();
    const theme = useCheTheme();
    const dispatch = useDispatch();
    const matchedOffers = useSelector(matchedOffersSelector);
    const isLoadingCalculation = useSelector(calculationIsLoadingSelector);
    const comparing = useSelector(comparingProductsSelector);
    const matchingOffersAmount = getMatchingOffersAmount(comparing, matchedOffers);
    const isClickable = !isLoadingCalculation && Boolean(matchingOffersAmount);
    const onClick = () => {
        if (!isClickable) {
            return;
        }
        dispatch(comparingActions.setWasAddingUsed());
        dispatch(modalActions.open({ name: COMPARING_MODAL_NAME }));
        analytics.sendEvent({
            action: 'comparison_opened',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    };
    const visbileWhenClickable = {
        initial: isClickable ? 'visible' : 'hidden',
        animate: isClickable ? 'visible' : 'hidden',
    };
    const hiddenWhenClickable = {
        initial: isClickable ? 'hidden' : 'visible',
        animate: isClickable ? 'hidden' : 'visible',
    };
    return (_jsxs(motion.button, { className: classes.filtersButton, whileHover: { scale: isClickable ? 1.02 : 1 }, whileTap: { scale: 1 }, onClick: onClick, "data-disabled": !isClickable, children: [_jsx(motion.div, { variants: leftTextVariants, ...hiddenWhenClickable, style: { position: 'absolute', color: theme.colors.sky }, children: "\u0421\u0440\u0430\u0432\u043D\u0435\u043D\u0438\u0435" }), _jsx(motion.div, { variants: offersVariants, ...visbileWhenClickable, style: { position: 'absolute' }, children: _jsx(ComparingButtonOffersLogos, { isVisible: isClickable, maxItemsAmount: 3 }) }), _jsx(motion.div, { variants: titleVariants, ...visbileWhenClickable, className: classes.filtersButtonTitle, children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u0441\u0440\u0430\u0432\u043D\u0435\u043D\u0438\u044E" }), _jsx(motion.div, { variants: rightTextVariants, ...hiddenWhenClickable, transition: { type: 'just' }, style: { position: 'absolute' }, children: Boolean(matchingOffersAmount) || isLoadingCalculation ? (_jsx(Skeleton, { width: "100px", height: "13px", borderRadius: 7 })) : (_jsx("span", { className: classes.filtersButtonNoOffersText, children: comparing.length
                        ? 'Нет предложений'
                        : 'Ничего не выбрано' })) }), _jsx(motion.div, { variants: arrowVariants, ...visbileWhenClickable, className: classes.filtersButtonArrowWrapper, children: _jsx(ChevronIcon, {}) })] }));
});
