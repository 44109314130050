const getOrder = (name, price) => ({
    url: window.location.href,
    name,
    price: Number(price.toFixed(2)),
    count: 1,
    currency: 'RUB',
});
const getOrderInfo = (order) => {
    const { actionField: { id: orderId }, products, } = order.purchase;
    const { name, price } = products[0];
    return {
        orderId,
        price,
        name,
    };
};
export const sendLeadhitOrder = (order) => {
    const { orderId, price, name } = getOrderInfo(order);
    const getCookie = (name) => {
        const matches = document.cookie.match(new RegExp('(?:^|; )' +
            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
            '=([^;]*)'));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    };
    const lh_clid = '5937b71be694aa54b42eff04';
    const order_id = orderId;
    const cart_sum = price.toFixed(2);
    const order_offers = [getOrder(name, price)];
    const url = encodeURIComponent(window.location.href);
    const uid = getCookie('_lhtm_u');
    const vid = getCookie('_lhtm_r')
        ? getCookie('_lhtm_r').split('|')[1]
        : null;
    const path = 'https://track.leadhit.io/stat/lead_form?f_orderid=' +
        order_id +
        '&url=' +
        url +
        '&action=lh_orderid&uid=' +
        uid +
        '&vid=' +
        vid +
        '&ref=direct&f_cart_sum=' +
        cart_sum +
        '&clid=' +
        lh_clid;
    const body = {
        order_id: order_id,
        cart_sum: cart_sum,
        vid: vid,
        uid: uid,
        clid: lh_clid,
        offers: order_offers,
    };
    fetch(path);
    fetch('https://track.leadhit.io/stat/lead_order', {
        method: 'POST',
        body: JSON.stringify(body),
    });
};
