import { getPregnancyLabel } from '@/libs/helpers/utils';
import { ACCIDENT_CODE, AVIA_CARGO_CODE, CIVIL_LIABILITY_CODE, MEDICINE_CODE, OBSERVATION_CODE, PREGNANCY_CODE, TRIP_CANCEL_CODE, } from '@/libs/static';
export const formatMultiValueByCode = (code, value, params = {}) => {
    switch (code) {
        case PREGNANCY_CODE:
            return getPregnancyLabel(value);
        case OBSERVATION_CODE:
        case ACCIDENT_CODE:
        case AVIA_CARGO_CODE:
        case CIVIL_LIABILITY_CODE:
        case TRIP_CANCEL_CODE:
        case MEDICINE_CODE:
            return `${value} ${params.currencyBadge}`;
        default:
            return value;
    }
};
