import { createAsyncThunk } from '@reduxjs/toolkit';
import { DEFAULT_FIELD_ERROR } from '@/libs/validation/constants';
import { formsActions } from './index';
const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const maxLength = 254;
export const setEmail = createAsyncThunk('', async ({ _uuid, value }, thunkApi) => {
    const validation = [
        {
            validator: (value) => Boolean(value?.trim()),
            message: DEFAULT_FIELD_ERROR,
        },
        {
            validator: (value) => emailRegexp.test(value.trim()),
            message: 'Введите корректный email',
        },
        {
            validator: (value) => value.length <= maxLength,
            message: `Максимальная длина ${maxLength} символов`,
        },
    ];
    const error = validation.find(({ validator }) => !validator(value));
    thunkApi.dispatch(formsActions._setEmailValue({ _uuid, value }));
    thunkApi.dispatch(formsActions._setEmailError({
        _uuid,
        error: error ? error.message : null,
    }));
});
