import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { comparingDifferencesOnlySelector } from '@/models/comparing/selectors';
import { ComparingTableService } from './ComparingTableService';
export const ComparingTableOfferServices = memo(({ services, allServices, repeatingServices, missingServices, }) => {
    const servicesList = useMemo(() => allServices.map((current) => {
        const service = services.find(({ service }) => service === current);
        const isDisadvantage = missingServices.some(({ service }) => service === current) && current !== 'Франшиза';
        const getValue = () => {
            if (isDisadvantage && current !== 'Франшиза') {
                return 'Отсутствует';
            }
            if (service) {
                const postfix = service?.postfix;
                return postfix
                    ? postfix[0].toUpperCase() + postfix.slice(1)
                    : 'Включено';
            }
            if (current === 'Франшиза') {
                return missingServices.find(({ service }) => service === current)?.postfix;
            }
            return null;
        };
        return {
            service: current,
            value: getValue(),
            isRepeating: repeatingServices.includes(current),
            isDisadvantage,
        };
    }), [services, allServices, repeatingServices, missingServices]);
    const differencesOnly = useSelector(comparingDifferencesOnlySelector);
    const nonRepeatingServices = servicesList.reduce((acc, { service, isRepeating }) => !isRepeating ? [...acc, service] : acc, []);
    return (_jsx(motion.div, { children: servicesList.map(({ service, value, isRepeating, isDisadvantage }, index) => (_jsx(ComparingTableService, { value: value || '-', isHidden: isRepeating && differencesOnly, isCentered: true, isEven: Boolean((differencesOnly
                ? nonRepeatingServices.indexOf(service)
                : index) % 2), isDisadvantage: isDisadvantage }, service))) }));
});
