import { MIN, SEC } from '@/libs/constants';
export let FORCED_CANCELLATION_TIME = 5 * MIN;
export let DELAY_FROM_LAST_REQUEST = 5 * SEC;
export const PROCESS_STATE_NAMESPACE = 'process';
export const orderStatuses = [
    'inProcessing',
    'fatal',
    'success',
    'successNotPayed',
];
export const DEFAULT_ORDER_STATUS = 'inProcessing';
export const emptyOrder = {
    uuid: '',
    status: DEFAULT_ORDER_STATUS,
    action: 'createPolices',
    progress: 0,
    lastError: '',
    policies: [],
    paymentLink: '',
    paymentUuid: '',
};
export var EmailConfirmationStatuses;
(function (EmailConfirmationStatuses) {
    EmailConfirmationStatuses["none"] = "none";
    EmailConfirmationStatuses["loading"] = "loading";
    EmailConfirmationStatuses["success"] = "success";
    EmailConfirmationStatuses["error"] = "error";
})(EmailConfirmationStatuses || (EmailConfirmationStatuses = {}));
export var KeyboardKey;
(function (KeyboardKey) {
    KeyboardKey["Enter"] = "Enter";
    KeyboardKey["Backspace"] = "Backspace";
    KeyboardKey["Delete"] = "Delete";
    KeyboardKey["ArrowDown"] = "ArrowDown";
    KeyboardKey["ArrowUp"] = "ArrowUp";
    KeyboardKey["Tab"] = "Tab";
    KeyboardKey["Escape"] = "Escape";
})(KeyboardKey || (KeyboardKey = {}));
