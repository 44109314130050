import { createAsyncThunk } from '@reduxjs/toolkit';
import { isIframe } from '@/libs/helpers';
import { getUserToken, headersWithToken } from '../user/helpers';
import { filterUniquePersons, transformApiPerson } from './helpers';
import { notebookActions } from './index';
export const getPersons = createAsyncThunk('/personas', async (_, thunkApi) => {
    const userToken = getUserToken();
    if (!userToken || isIframe()) {
        return;
    }
    try {
        const response = await fetch(`${__ACCOUNT_API_URL__}personas`, {
            method: 'GET',
            ...headersWithToken(userToken),
        });
        const data = await response.json();
        const persons = data?.persons;
        if (!response.ok || !Array.isArray(persons)) {
            throw new Error();
        }
        const transformedPersons = persons.map(transformApiPerson);
        thunkApi.dispatch(notebookActions.setPersons(filterUniquePersons(transformedPersons)));
    }
    catch (error) {
        console.log(error);
    }
});
