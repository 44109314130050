import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import { memo } from 'react';
import { useCheTheme } from '@/styles/themes';
import { useStyles } from './styles';
export const TagButton = memo(({ label, type, postfix, onClick, onPostfixClick, disableTabIndexes, withAppearAnimation, }) => {
    const classes = useStyles();
    const theme = useCheTheme();
    const tabIndex = disableTabIndexes ? -1 : 0;
    return (_jsxs(motion.div, { className: classes.tag, layoutId: label, transition: {
            background: { type: 'just' },
            scale: { duration: 0.15, type: 'just' },
        }, layout: "position", children: [_jsxs("button", { className: classes.mainButton, onClick: onClick, tabIndex: tabIndex, children: [label, Boolean(postfix) && ': ', Boolean(postfix) && _jsx("b", { children: postfix })] }), _jsx("button", { className: classes.postfixButton, onClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onPostfixClick?.();
                }, tabIndex: tabIndex, children: _jsx(motion.svg, { variants: {
                        adding: { rotate: 45, width: 10, height: 10 },
                        removing: { rotate: 0, width: 12, height: 12 },
                    }, initial: type, animate: type, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 14 14", fill: "none", children: _jsx("path", { d: "M2.67377 13.0477C2.44526 13.2915 2.14059 13.4286 1.79022 13.4286C1.11994 13.4286 0.571533 12.8801 0.571533 12.1946C0.571533 11.8595 0.708635 11.5701 0.937139 11.3416L5.26348 6.99998L0.937139 2.65841C0.708635 2.42991 0.571533 2.14047 0.571533 1.80533C0.571533 1.13505 1.11994 0.601878 1.79022 0.601878C2.14059 0.601878 2.4148 0.723747 2.6433 0.967484L6.98487 5.29382L11.3721 0.952251C11.6311 0.69328 11.8901 0.571411 12.21 0.571411C12.8803 0.571411 13.4287 1.11982 13.4287 1.7901C13.4287 2.12524 13.322 2.38421 13.0631 2.64318L8.7215 6.99998L13.0478 11.3263C13.2916 11.5701 13.4134 11.8443 13.4134 12.1946C13.4134 12.8801 12.865 13.4286 12.1948 13.4286C11.8444 13.4286 11.5549 13.2762 11.3264 13.0477L6.98487 8.72138L2.67377 13.0477Z" }) }) })] }));
});
export const TagPlainButton = ({ children, onClick, tabIndex, appearance = 'default', }) => {
    const classes = useStyles();
    return (_jsx("button", { className: classes.plainButton, onClick: onClick, tabIndex: tabIndex, "data-appearance": appearance, children: children }));
};
