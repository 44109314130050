import { FRANCHISE_CODE, ASSISTANCE_CODE, FOREIGN_CODE, ABROAD_CODE, MULTIPOLICY_CODE, MEDICINE_CODE, URGENCY_STOMATOLOGY_CODE, COVID_CODE, OBSERVATION_CODE, INSURED_RETURN_CODE, CHILDREN_RETURN_CODE, TERROR_ASSISTANCE_CODE, DISASTER_ASSISTANCE_CODE, CHRONIC_ARRESTING_CODE, ALLERGY_ARRESTING_CODE, SUNBURN_ASSISTANCE_CODE, ALCOHOL_ASSISTANCE_CODE, PREGNANCY_CODE, RELATIVE_ILL_RETURN_CODE, THIRD_PARTY_TRANSPORTATION_CODE, DENGE_CODE, DOCUMENT_CODE, LEGAL_CODE, SEARCH_ACTIVITIES_CODE, RESCUE_ACTIVITIES_CODE, WORK_CODE, OFFICE_CODE, EDUCATION_CODE, TRIP_CANCEL_CODE, VISA_CANCEL_CODE, ISOLATION_CODE, AVIA_CARGO_CODE, ACCIDENT_CODE, CIVIL_LIABILITY_CODE, REGULAR_DELAY_CODE, AUTO_CODE, DELAY_CARGO_CODE, } from '@/libs/static';
export const selectedFiltersPriority = [
    FOREIGN_CODE,
    ABROAD_CODE,
    MULTIPOLICY_CODE,
    FRANCHISE_CODE,
    MEDICINE_CODE,
    URGENCY_STOMATOLOGY_CODE,
    COVID_CODE,
    OBSERVATION_CODE,
    ASSISTANCE_CODE,
    INSURED_RETURN_CODE,
    CHILDREN_RETURN_CODE,
    TERROR_ASSISTANCE_CODE,
    DISASTER_ASSISTANCE_CODE,
    CHRONIC_ARRESTING_CODE,
    ALLERGY_ARRESTING_CODE,
    SUNBURN_ASSISTANCE_CODE,
    ALCOHOL_ASSISTANCE_CODE,
    PREGNANCY_CODE,
    RELATIVE_ILL_RETURN_CODE,
    THIRD_PARTY_TRANSPORTATION_CODE,
    DENGE_CODE,
    DOCUMENT_CODE,
    LEGAL_CODE,
    SEARCH_ACTIVITIES_CODE,
    RESCUE_ACTIVITIES_CODE,
    WORK_CODE,
    OFFICE_CODE,
    EDUCATION_CODE,
    TRIP_CANCEL_CODE,
    VISA_CANCEL_CODE,
    ISOLATION_CODE,
    AVIA_CARGO_CODE,
    ACCIDENT_CODE,
    CIVIL_LIABILITY_CODE,
    REGULAR_DELAY_CODE,
    AUTO_CODE,
    DELAY_CARGO_CODE,
];
export const SELECTED_FILTERSS_CODES_TO_IGNORE = [
    MEDICINE_CODE,
    URGENCY_STOMATOLOGY_CODE,
];
export const selectedFiltersShortLabelsDictionary = {
    [COVID_CODE]: 'COVID-19',
    [RESCUE_ACTIVITIES_CODE]: 'П/С мероприятия',
};
