import { CODE_INPUT_COMPONENTS_NAMES } from '@che-ins-ui/input';
import { GLOBAL_LETTER_SPACING, GLOBAL_LINE_HEIGHT } from './consts';
export const getCodeInputTheme = (theme) => ({
    base: {
        [CODE_INPUT_COMPONENTS_NAMES.input]: {
            letterSpacing: GLOBAL_LETTER_SPACING,
            lineHeight: GLOBAL_LINE_HEIGHT,
        },
    },
});
