import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { modalSelector } from '@che-ins-ui/modal';
import { useClickOutsideComponents } from '@che-ins-ui/utils';
import { EditIcon } from '@che-ins-ui/icons/EditIcon';
import { getEmojiImage } from '@/models/notebook/helpers';
import { analytics } from '@/libs/analytics';
import { useFocusOutside } from '@/libs/hooks';
import { EDIT_PERSON_MODAL_NAME } from '../EditPersonModal/EditPersonModal';
import { useStyles } from './styles';
const sendEvent = (action) => {
    analytics.sendEvent({
        action,
        label: 'Действие пользователя',
        category: 'Cherehapa',
    });
};
export const InputSuggestions = forwardRef(({ suggestions, onHover, onBlur, areSuggestionsShown, hideSuggestions, onClick, onEdit, isInRussian, }, ref) => {
    const classes = useStyles();
    const suggestionsRef = useRef(null);
    const scrollToTop = () => {
        if (suggestionsRef.current) {
            suggestionsRef.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };
    const { isOpen: isEditPersonModalOpened } = useSelector(modalSelector(EDIT_PERSON_MODAL_NAME));
    const onOutsideEvent = () => {
        if (areSuggestionsShown && !isEditPersonModalOpened) {
            setTimeout(hideSuggestions);
            scrollToTop();
        }
    };
    useClickOutsideComponents([ref], onOutsideEvent);
    useFocusOutside(ref, onOutsideEvent);
    if (suggestions.length === 0) {
        return null;
    }
    const animation = {
        opacity: areSuggestionsShown ? 1 : 0,
        y: areSuggestionsShown ? 0 : -10,
    };
    return (_jsx(motion.div, { initial: animation, animate: animation, transition: {
            y: { type: 'spring', stiffness: 600, damping: 20 },
            opacity: { duration: areSuggestionsShown ? 0.2 : 0.1 },
        }, className: classes.suggestionsWrapper, "data-shown": areSuggestionsShown, children: _jsx("div", { className: classes.suggestions, ref: suggestionsRef, children: suggestions.map((suggestion) => {
                const { _uuid, firstName, lastName, firstNameRu, lastNameRu, emoji, fromNotebook, } = suggestion;
                const props = {
                    key: _uuid,
                    name: isInRussian
                        ? `${lastNameRu} ${firstNameRu}`
                        : `${lastName} ${firstName}`,
                    emoji,
                    onHover: () => onHover(suggestion),
                    onBlur,
                    onClick: () => {
                        onClick(suggestion);
                        sendEvent('vzr_notebook_select');
                    },
                    onEdit: () => {
                        onEdit(_uuid);
                        sendEvent('vzr_notebook_edit');
                    },
                    tabIndex: areSuggestionsShown ? 0 : -1,
                };
                const Component = fromNotebook
                    ? NotebookSuggestion
                    : Suggestion;
                return _jsx(Component, { ...props });
            }) }) }));
});
const NotebookSuggestion = memo(({ name, emoji, onHover, onBlur, onClick, onEdit, tabIndex }) => {
    const classes = useStyles();
    return (_jsxs("button", { className: classes.suggestion, onPointerEnter: onHover, onPointerLeave: onBlur, onClick: onClick, tabIndex: tabIndex, children: [_jsxs("div", { className: classes.titles, children: [_jsxs("div", { className: classes.title, children: [emoji && (_jsx("img", { className: classes.emojiIconImage, src: getEmojiImage(emoji), title: "\u042D\u043C\u043E\u0434\u0437\u0438 \u043C\u043E\u0436\u043D\u043E \u043F\u043E\u043C\u0435\u043D\u044F\u0442\u044C \u0432 \u043B\u044E\u0431\u043E\u0439 \u043C\u043E\u043C\u0435\u043D\u0442 \u0432 \u0440\u0435\u0436\u0438\u043C\u0435 \u0440\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F" })), _jsx("p", { children: name })] }), _jsx("span", { className: classes.subtitle, children: "\u0418\u0437 \u0437\u0430\u043F\u0438\u0441\u043D\u043E\u0439 \u043A\u043D\u0438\u0436\u043A\u0438" })] }), _jsx("div", { className: classes.editWrapper, onClick: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onEdit();
                }, onPointerEnter: onBlur, onPointerLeave: onHover, children: _jsx("div", { className: classes.editIconWrapper, children: _jsx(EditIcon, {}) }) })] }));
});
const Suggestion = memo(({ name, onHover, onBlur, onClick, tabIndex }) => {
    const classes = useStyles();
    return (_jsx("button", { className: classes.nonNotebookSuggestion, onPointerEnter: onHover, onPointerLeave: onBlur, onClick: onClick, tabIndex: tabIndex, children: _jsx("p", { children: name }) }));
});
