import { createAsyncThunk } from '@reduxjs/toolkit';
import { isIframe } from '@/libs/helpers';
import { getUserToken, headersWithToken } from './helpers';
import { userActions } from './index';
export const getPartnerCommissionRate = createAsyncThunk('/actual', async (_, thunkApi) => {
    const userToken = getUserToken();
    if (!userToken || isIframe()) {
        return;
    }
    try {
        const response = await fetch(`${__PARTNER_API_URL__}actual`, {
            method: 'GET',
            ...headersWithToken(userToken),
        });
        const data = await response.json();
        if (!response.ok || !data?.travelRate) {
            throw new Error(response.status.toString());
        }
        const { travelRate } = data;
        thunkApi.dispatch(userActions.setPartnerCommissionRate(travelRate));
    }
    catch (error) {
        thunkApi.dispatch(userActions.setPartnerCommissionRate(null));
        console.error(error);
    }
});
