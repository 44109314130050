import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCalculationFranhcise } from '@/libs/hooks';
import { franchiseDescription } from '@/models/comparing/consts';
import { useStyles } from './styles';
export const FranciseTab = ({ offer }) => {
    const classes = useStyles();
    const { value } = useCalculationFranhcise(offer.calculation);
    const withFranchise = value.toLowerCase() !== 'нет';
    return (_jsxs(_Fragment, { children: [_jsx("p", { className: classes.tabTitle, children: _jsx("span", { children: withFranchise ? (_jsxs("span", { children: ["\u0424\u0440\u0430\u043D\u0448\u0438\u0437\u0430: ", _jsx("b", { style: { fontWeight: 500 }, children: value })] })) : ('В вашем полисе нет франшизы') }) }), _jsx("p", { className: classes.tabText, children: franchiseDescription }), withFranchise || (_jsx("p", { className: classes.tabText, children: "\u0415\u0441\u043B\u0438 \u0444\u0440\u0430\u043D\u0448\u0438\u0437\u044B \u043D\u0435\u0442, \u0442\u043E \u043F\u0440\u0438 \u043E\u0431\u0440\u0430\u0449\u0435\u043D\u0438\u0438 \u0437\u0430 \u043C\u0435\u0434\u0438\u0446\u0438\u043D\u0441\u043A\u043E\u0439 \u043F\u043E\u043C\u043E\u0449\u044C\u044E, \u043E\u043F\u043B\u0430\u0447\u0438\u0432\u0430\u0442\u044C \u0435\u0451 \u043D\u0435 \u043D\u0443\u0436\u043D\u043E." }))] }));
};
