import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input } from '@che-ins-ui/input';
import { PenIcon } from '@che-ins-ui/icons/PenIcon';
import { addDays, addYears, formatDate } from '@che-ins-ui/datepicker';
import { getCountriesLabel, getTouristsLabelValue } from '@/libs/helpers/utils';
import { useWindowScroll } from '@/libs/hooks';
import { isMultipolicySelectedSelector, requestSelector, } from '@/models/request/selectors';
import { touristsFormsSelector } from '@/models/forms/selectors';
import { shouldCloseTravelMobileFormOnScrollSelector } from '@/models/functional/selectors';
import { Countries } from './Countries';
const SCROLL_THRESHOLD_TO_CLOSE_MENU = 130;
export const AccordionTitleButton = ({ isOpen, setIsOpen }) => {
    const { countries, countryGroups, dateStart, dateEnd } = useSelector(requestSelector);
    const shouldCloseTravelMobileFormOnScroll = useSelector(shouldCloseTravelMobileFormOnScrollSelector);
    const shouldCloseRef = useRef(shouldCloseTravelMobileFormOnScroll);
    shouldCloseRef.current = shouldCloseTravelMobileFormOnScroll;
    const tourists = useSelector(touristsFormsSelector);
    const isMultipolicySelected = useSelector(isMultipolicySelectedSelector);
    const contriesLabel = getCountriesLabel(countries.length + countryGroups.length);
    const formattedDateEnd = useMemo(() => {
        const formattedDateEnd = dateEnd;
        if (isMultipolicySelected) {
            const nextYearDate = addYears(dateStart, 1);
            const newDateEnd = addDays(nextYearDate, -1);
            return formatDate(newDateEnd);
        }
        return formattedDateEnd;
    }, [isMultipolicySelected, dateStart, dateEnd]);
    const [lastOpenScrollTop, setLastOpenScrollTop] = useState(-1);
    const { scrollTop } = useWindowScroll();
    const scrollTopRef = useRef(scrollTop);
    scrollTopRef.current = scrollTop;
    const onClick = useCallback(() => {
        setIsOpen(true);
        setTimeout(() => setLastOpenScrollTop(scrollTopRef.current), 300);
    }, []);
    useEffect(() => {
        if (!isOpen || lastOpenScrollTop === -1) {
            return;
        }
        const scrolledWithOpenedMenu = Math.abs(lastOpenScrollTop - scrollTop);
        if (scrolledWithOpenedMenu >= SCROLL_THRESHOLD_TO_CLOSE_MENU &&
            shouldCloseRef.current) {
            setIsOpen(false);
        }
    }, [scrollTop]);
    useEffect(() => {
        if (!isOpen) {
            setLastOpenScrollTop(-1);
        }
    }, [isOpen]);
    if (isOpen) {
        return _jsx(Countries, { mode: "calculation" });
    }
    const datesLabel = `${dateStart} - ${formattedDateEnd}`;
    const touristsLabel = `Возраст: ${getTouristsLabelValue(tourists)}`;
    return (_jsx(Input, { shouldFitContent: true, type: "button", onChange: () => { }, onClick: onClick, label: `${contriesLabel}, ${datesLabel}`, value: touristsLabel, postfix: _jsx(PenIcon, {}), baseAppearance: "header-filters", appearance: "header-mobile-accordion-title" }));
};
