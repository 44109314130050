import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { touristsFormsSelector } from '@/models/forms/selectors';
import { formsActions } from '@/models/forms';
import { MIN_TOURISTS_AMOUNT } from '@/libs/constants';
import { useStyles } from '../styles';
import { useIsMobileViewInsuranceForm } from '../hooks';
import { TouristBirthdayDesktop } from './TouristBirthdayDesktop';
import { TouristBirthdayMobile } from './TouristBirthdayMobile';
export const TouristBirthday = memo(({ _uuid, appearance, birthday, previewedBirthday, errorMessage, }) => {
    const tourists = useSelector(touristsFormsSelector);
    const touristsAmount = tourists.length;
    const classes = useStyles();
    const dispatch = useDispatch();
    const isMobileFormView = useIsMobileViewInsuranceForm();
    const withDeleteButton = touristsAmount > MIN_TOURISTS_AMOUNT;
    const handleRemoveTourist = () => dispatch(formsActions.removeTourist(_uuid));
    const component = useMemo(() => {
        const props = {
            _uuid,
            withButton: withDeleteButton,
            onDelete: handleRemoveTourist,
            appearance,
            birthday,
            previewedBirthday,
            errorMessage,
        };
        return isMobileFormView ? (_jsx(TouristBirthdayMobile, { ...props })) : (_jsx(TouristBirthdayDesktop, { ...props }));
    }, [
        isMobileFormView,
        withDeleteButton,
        handleRemoveTourist,
        appearance,
        _uuid,
        birthday,
        previewedBirthday,
        errorMessage,
    ]);
    return (_jsx("div", { className: classes.birthdayWrapper, children: component }, "birthday"));
});
