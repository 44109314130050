import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useState, forwardRef, } from 'react';
import { useCodeInputStyles } from './styles';
export const CodeInput = forwardRef(({ className, error, index, success, value, inputMode, onFocus, onBlur, onChange, onClick, onPaste, ...props }, ref) => {
    const classes = useCodeInputStyles();
    const [isFocused, setIsFocused] = useState(false);
    const onFocusField = () => {
        if (onFocus) {
            onFocus(index);
        }
        setIsFocused(true);
    };
    const onClickField = (event) => {
        if (onClick) {
            onClick(event);
        }
        setIsFocused(true);
    };
    const onBlurField = (event) => {
        if (onBlur) {
            onBlur(event);
        }
        setIsFocused(false);
    };
    const fieldClassNames = classNames(className, classes.input, isFocused && classes.focused, !isFocused && (!value || value === '_') && classes.disabled, error && classes.error, success && classes.valid);
    const labelClassNames = classNames(className, classes.root);
    return (_jsx("label", { className: labelClassNames, children: _jsx("input", { className: fieldClassNames, inputMode: inputMode, ref: ref, type: "text", value: value === '_' ? '' : value, onBlur: onBlurField, onClick: onClickField, onFocus: onFocusField, onChange: onChange, onPaste: (e) => onPaste(e, index), ...props }) }));
});
export default memo(CodeInput);
