import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@che-ins-ui/button';
import { getAgeLabel } from '@/libs/helpers/utils';
import { MAX_AGE } from '@/libs/constants';
import { useStyles } from '../styles';
const options = new Array(MAX_AGE)
    .fill(null)
    .map((_, age) => ({ value: age, label: getAgeLabel(age) }));
export const Ages = ({ onClick, setIsOpen, selectedAge }) => {
    const classes = useStyles();
    const handleClick = (age) => {
        onClick(age);
        setIsOpen?.(false);
    };
    return (_jsx("div", { className: classes.ages, children: options.map(({ value, label }, index) => {
            return (_jsx(Button, { shouldFitContent: true, onClick: () => handleClick(value), appearance: "age", isSelected: selectedAge === value, children: label }, index));
        }) }));
};
