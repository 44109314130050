import { ACCORDION_COMPONENTS_NAMES } from '@che-ins-ui/accordion';
import { withAlpha } from '@/styles/utils';
import { DESKTOP_SIZES_BREAKPOINT } from './consts';
export const getAccordionTheme = (theme) => ({
    base: {},
    filters: {
        [ACCORDION_COMPONENTS_NAMES.icon]: {
            marginLeft: '2px',
            marginRight: '16px',
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                marginLeft: '0px',
                marginRight: '14px',
            },
        },
    },
    'filter-multi-value': {
        [ACCORDION_COMPONENTS_NAMES.wrapper]: {
            '&:hover': {
                background: withAlpha(theme.colors.brandTertiary, 0.5),
            },
        },
        [ACCORDION_COMPONENTS_NAMES.childrenWrapper]: {
            marginLeft: '45px',
            marginRight: '12px',
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                marginLeft: '40px',
            },
        },
    },
    services: {
        [ACCORDION_COMPONENTS_NAMES.wrapper]: {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column-reverse',
        },
        [ACCORDION_COMPONENTS_NAMES.body]: {
            width: '100%',
        },
        [ACCORDION_COMPONENTS_NAMES.icon]: {
            display: 'none',
        },
    },
});
