import { createUseStyles } from 'react-jss';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        padding: '8px 12px',
        alignItems: 'flex-start',
        gap: 8,
        borderRadius: 8,
        textAlign: 'left',
        '-webkit-tap-highlight-color': 'transparent',
        '-webkit-user-select': 'none',
        userSelect: 'none',
    },
    authImage: {
        height: 14,
        width: 14,
        marginTop: 4,
    },
    authTitle: {
        fontSize: MOBILE_FONT_SIZES.main,
        color: theme.colors.brand,
        fontWeight: 700,
        marginBottom: 2,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    authText: {
        fontSize: MOBILE_FONT_SIZES.small,
        color: theme.colors.textPrimary,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.small,
        },
    },
}), { name: 'auth-button' });
