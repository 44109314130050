import qs from 'qs';
import { ROUTES } from '@/components/routes';
import { transformQuery } from '@/libs/transformers/prepareStateTransformers';
import { currencies } from '@/models/request';
import { COUNTRY_CODES_WITH_INITIAL_CURRENCY_EURO } from '../constants';
import { LSHelper } from '../localStorage';
import { travelAliases } from '../aliases';
export function declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]];
}
export const transformToJson = (response) => response.json();
export function checkIfHomeDomain(window) {
    const hostNames = ['localhost', 'cherehapa'];
    const currentHostname = window.location.hostname;
    return hostNames.some((hostName) => currentHostname.includes(hostName));
}
export const scrollToTop = ({ behavior } = { behavior: 'smooth' }) => {
    window.scrollTo({
        top: 0,
        behavior,
    });
};
const filterDirections = (apiDirections, directions) => apiDirections.filter(({ code }) => directions.includes(code));
export const checkIfCurrencyZoneAccessible = (apiCountries, apiCountryGroups, countries, countryGroups, currency) => {
    const checkMandatoryCurrency = ({ mandatoryCurrency }) => mandatoryCurrency == null ||
        mandatoryCurrency.toLowerCase() === currency;
    const choosenCountries = filterDirections(apiCountries, countries);
    const choosenCountryGroups = filterDirections(apiCountryGroups, countryGroups);
    const isException = [
        choosenCountryGroups.some(({ code }) => ['all-world', 'world-no-russia'].includes(code)),
    ].some(Boolean);
    const areChoosenCountriesLegit = choosenCountries.every(checkMandatoryCurrency);
    const areChoosenCountryGroupsLegit = choosenCountryGroups.every(({ countries }) => countries.every(checkMandatoryCurrency));
    return (isException ||
        (areChoosenCountriesLegit && areChoosenCountryGroupsLegit));
};
export const getZoneCurrency = (apiCountries, apiCountryGroups, countries, countryGroups, currency) => {
    const checkMandatoryCurrency = ({ mandatoryCurrency }) => mandatoryCurrency === 'EUR';
    const choosenCountries = filterDirections(apiCountries, countries);
    const choosenCountryGroups = filterDirections(apiCountryGroups, countryGroups);
    const areChoosenCountriesLegit = choosenCountries.some(checkMandatoryCurrency);
    const areChoosenCountryGroupsLegit = choosenCountryGroups.some(({ countries }) => countries.some(checkMandatoryCurrency));
    return areChoosenCountriesLegit || areChoosenCountryGroupsLegit
        ? 'eur'
        : currency;
};
const isCurrency = (currency) => currencies.includes(currency);
export const getInitialZoneCurrency = () => {
    if (window.location.pathname === ROUTES.insurance) {
        const previousCurrency = LSHelper.getField('request.currency', 'usd');
        return previousCurrency;
    }
    const [_, queryString] = window.location.search.split('?');
    const parsedQuery = qs.parse(queryString);
    const { countries = [], countryGroups = [], currency } = parsedQuery;
    if (!countryGroups.length &&
        countries.length === 1 &&
        countries[0] === 'russia' &&
        isCurrency(currency)) {
        return currency;
    }
    const isEur = [...countries, ...countryGroups].some((code) => COUNTRY_CODES_WITH_INITIAL_CURRENCY_EURO.includes(code));
    return isEur ? 'eur' : 'usd';
};
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export const isErrorWithMessage = (error) => typeof error === 'object' && error !== null && 'message' in error;
export const toErrorWithMessage = (maybeError) => {
    if (isErrorWithMessage(maybeError)) {
        return maybeError;
    }
    try {
        return new Error(JSON.stringify(maybeError));
    }
    catch {
        return new Error(String(maybeError));
    }
};
export const getErrorMessage = (error) => {
    let message = toErrorWithMessage(error).message;
    try {
        const parsedMessage = JSON.parse(message);
        if ('message' in parsedMessage &&
            Array.isArray(parsedMessage.message)) {
            message = parsedMessage.message
                .map((errorText) => errorText + '.')
                .join('\n');
        }
    }
    catch { }
    return message;
};
export const transformToBoolean = (value) => {
    return value === true || value === 'true';
};
export const isIframe = () => window !== window.parent;
export function parseAndNormalizeQueryStringParams() {
    const parsedQuery = parseQueryString();
    return transformQuery(parsedQuery);
}
export const parseQueryString = () => {
    const [_, queryString] = window.location.search.split('?');
    return qs.parse(queryString);
};
export const removeTrailingSlash = (url) => url && url.replace(/\/$/, '');
const replacerToRussian = {
    q: 'й',
    w: 'ц',
    e: 'у',
    r: 'к',
    t: 'е',
    y: 'н',
    u: 'г',
    i: 'ш',
    o: 'щ',
    p: 'з',
    '[': 'х',
    '{': 'х',
    ']': 'ъ',
    '}': 'ъ',
    a: 'ф',
    s: 'ы',
    d: 'в',
    f: 'а',
    g: 'п',
    h: 'р',
    j: 'о',
    k: 'л',
    l: 'д',
    ';': 'ж',
    ':': 'ж',
    "'": 'э',
    '"': 'э',
    z: 'я',
    x: 'ч',
    c: 'с',
    v: 'м',
    b: 'и',
    n: 'т',
    m: 'ь',
    ',': 'б',
    '<': 'б',
    '.': 'ю',
    '>': 'ю',
    '/': '.',
    '\\': '.',
    '`': "'",
    '?': '.',
};
const translateLatinToCyrillicKeyboardKeys = (str) => str
    .toLowerCase()
    .split('')
    .map((char) => replacerToRussian[char] || char)
    .join('');
export const travelFilter = (searchString, options) => {
    const formatedSearchString = searchString
        .trim()
        .toLowerCase()
        .split(' ')[0];
    const replacedSearchString = translateLatinToCyrillicKeyboardKeys(formatedSearchString);
    const countriesMatchingAliasesSet = new Set(travelAliases
        .filter((alias) => alias.search.some((search) => search.startsWith(formatedSearchString) ||
        search.startsWith(replacedSearchString)))
        .map((alias) => alias.name.toLowerCase()));
    return options.unselectedOptions.filter(({ label }) => {
        const formatedLabel = label && String(label).toLowerCase();
        if (!formatedLabel)
            return false;
        const checkForMatch = (word) => word.startsWith(formatedSearchString) ||
            word.startsWith(replacedSearchString);
        return (countriesMatchingAliasesSet.has(formatedLabel) ||
            formatedLabel.split(' ').some(checkForMatch));
    });
};
export const tagToListTransformer = (text, tag = 'p') => text
    .split(`</${tag}>`)
    .filter(Boolean)
    .map((str) => str.replaceAll(`<${tag}>`, '- '));
export const getRestrictions = (calculation) => {
    const { info } = calculation;
    if (!info)
        return [];
    return Object.values(info)
        .flat()
        .filter((i) => i.type === 'limitation')
        .reduce((acc, i) => {
        if (!i.value.match('</p>')) {
            acc.push(i.value);
        }
        else {
            acc.push(...tagToListTransformer(i.value));
        }
        return acc;
    }, []);
};
export const capitalize = (str) => str.replace(/^./, (match) => match.toUpperCase());
const lowercaseExceptFirst = (str) => str.replace(/^(.)(.*)$/, (match, p1, p2) => p1 + p2.toLowerCase());
export const lowercaseExceptFirstAndAfterSpaceOrDash = (str) => str
    .split(/(\s+|-\s*)/)
    .map(lowercaseExceptFirst)
    .join('');
export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const blendHexColor = (hexColor, alpha, hexBackgroundColor) => {
    const hexToRgb = (hex) => parseInt(hex, 16);
    const [r, g, b] = [1, 3, 5].map((offset) => hexToRgb(hexColor.substring(offset, offset + 2)));
    const [backgroundR, backgroundG, backgroundB] = [1, 3, 5].map((offset) => hexToRgb(hexBackgroundColor.substring(offset, offset + 2)));
    const blend = (color, background) => Math.round(color * alpha + background * (1 - alpha))
        .toString(16)
        .padStart(2, '0');
    return `#${blend(r, backgroundR)}${blend(g, backgroundG)}${blend(b, backgroundB)}`;
};
