import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { tabsActions } from '@che-ins-ui/tabs';
import { calculationIsLoadingSelector, calculationSelector, matchedOffersSelector, } from '@/models/calculation/selectors';
import { basketActions } from '@/models/basket';
import { scrollToTop } from '@/libs/helpers';
import { MODAL_PRODUCT_ID_QUERY_PARAM } from '@/libs/constants';
import { functionalActions } from '@/models/functional';
import { INSURANCE_TABS_NAME } from '../insurance/consts';
import { OfferNotFoundModal, offerNotFoundModalOpen, } from '../common/Hint/OfferNotFoundModal';
import { ROUTES } from '../routes';
import { TABS } from '../insurance/components';
import { ComparingModal } from '../common/Comparing/ComparingModal';
import { ComparingButtonOffers } from '../common/Comparing/ComparingButtonOffers';
import { CommissionButton } from '../common/CommissionButton/CommissionButton';
import Offers, { OffersSkeleton } from './components/Offers';
import { useStyles } from './styles';
import { OfferModal } from './components/OfferModal';
import { offerModalClose, offerModalOpen, } from './components/OfferModal/actions';
import { MustReadInfo } from './components/MustReadInfo/MustReadInfo';
export { MoreExpensiveTomorrowAlert } from './components/MoreExpensiveTomorrowAlert';
export const Showcase = memo(() => {
    const { matchedOffers, partiallyOffers, missingOffers } = useSelector(calculationSelector);
    const classes = useStyles();
    const isLoadingCalculation = useSelector(calculationIsLoadingSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [search] = useSearchParams();
    const offers = useSelector(matchedOffersSelector);
    const handleAddToBasket = (offer) => {
        dispatch(basketActions.addToBasket({
            product: offer,
            lastChangesId: nanoid(),
        }));
        scrollToTop({ behavior: 'auto' });
        dispatch(tabsActions.setActiveTab({
            tabsName: INSURANCE_TABS_NAME,
            activePanelName: TABS[0].panelName,
        }));
        navigate(ROUTES.insurance);
        dispatch(offerModalClose());
    };
    useEffect(() => {
        const openedProductId = search.get(MODAL_PRODUCT_ID_QUERY_PARAM);
        if (!openedProductId) {
            dispatch(offerModalClose());
            return;
        }
        if (isLoadingCalculation) {
            dispatch(offerModalOpen({ state: 'loading' }));
            return;
        }
        const offer = offers.find(({ calculation }) => calculation.productId === Number(openedProductId));
        if (offer) {
            dispatch(offerModalOpen({ state: 'found', offer }));
        }
        else {
            dispatch(offerModalClose());
            dispatch(offerNotFoundModalOpen());
        }
    }, [search, isLoadingCalculation, offers]);
    useEffect(() => {
        dispatch(functionalActions.setShouldCloseTravelMobileFormOnScroll(true));
    }, []);
    return (_jsx(_Fragment, { children: _jsxs("div", { className: classNames(classes.offersContainer), children: [_jsx(CommissionButton, { nonDesktopOnly: true }), _jsx(MustReadInfo, {}), isLoadingCalculation ? (_jsx(OffersSkeleton, {})) : (_jsxs(_Fragment, { children: [_jsx(Offers, { handleAddToBasket: handleAddToBasket, offers: matchedOffers, matchedType: "full" }), _jsx(Offers, { handleAddToBasket: handleAddToBasket, offers: partiallyOffers, matchedType: "partial" }), _jsx(Offers, { offers: missingOffers, matchedType: "none" })] })), _jsx(ComparingButtonOffers, {}), _jsx(OfferModal, { handleAddToBasket: handleAddToBasket }), _jsx(OfferNotFoundModal, {}), _jsx(ComparingModal, { handleAddToBasket: handleAddToBasket })] }) }));
});
