import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup } from '@che-ins-ui/button';
import { requestActions } from '@/models/request';
import { requestSelector } from '@/models/request/selectors';
import { apiCountriesSelector, apiCountryGroupsSelector, } from '@/models/api/selectors';
import { checkIfCurrencyZoneAccessible } from '@/libs/helpers';
import { Hint } from '../common/Hint';
export const ChangeCurrencyButtons = () => {
    const apiCountries = useSelector(apiCountriesSelector);
    const apiCountryGroups = useSelector(apiCountryGroupsSelector);
    const dispatch = useDispatch();
    const { currency, countries, countryGroups } = useSelector(requestSelector);
    const handleChangeCurrency = useCallback((newCurrency) => {
        dispatch(requestActions.changeCurrency({
            newCurrency,
            apiCountries,
            apiCountryGroups,
        }));
    }, [apiCountries, apiCountryGroups]);
    const { isUsdAvailable, isEurAvailable } = useMemo(() => {
        return {
            isUsdAvailable: checkIfCurrencyZoneAccessible(apiCountries, apiCountryGroups, countries, countryGroups, 'usd'),
            isEurAvailable: checkIfCurrencyZoneAccessible(apiCountries, apiCountryGroups, countries, countryGroups, 'eur'),
        };
    }, [apiCountries, apiCountryGroups, countries, countryGroups]);
    const disableUsdTooltip = currency === 'usd' || isUsdAvailable;
    const disableEurTooltip = currency === 'eur' || isEurAvailable;
    return (_jsxs(ButtonGroup, { appearance: "currencies", value: currency, onChange: handleChangeCurrency, children: [_jsx(Button, { disabled: currency === 'usd', appearance: "currencies", value: "usd", children: _jsx(Hint, { title: 'Смена валюты невозможна', label: "\u0414\u043E\u043B\u043B\u0430\u0440", id: 'usd-tooltip', actionType: "agree", type: "alert", postfixModalName: "-change-currency", disable: disableUsdTooltip, children: "\u0414\u043B\u044F \u0432\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0445 \u0441\u0442\u0440\u0430\u043D \u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u0438\u0435 \u0442\u043E\u043B\u044C\u043A\u043E \u0432 EUR." }) }), _jsx(Button, { disabled: currency === 'eur', appearance: "currencies", value: "eur", children: _jsx(Hint, { title: 'Смена валюты невозможна', label: "\u0415\u0432\u0440\u043E", id: 'eur-tooltip', actionType: "agree", type: "alert", postfixModalName: "-change-currency", disable: disableEurTooltip, children: "\u0414\u043B\u044F \u0432\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0445 \u0441\u0442\u0440\u0430\u043D \u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u0438\u0435 \u0442\u043E\u043B\u044C\u043A\u043E \u0432 USD." }) })] }));
};
