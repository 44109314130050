import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classNames from 'classnames';
import { CheckIcon } from '@che-ins-ui/icons/CheckIcon';
import { AlertIcon } from '@che-ins-ui/icons/AlertIcon';
import { CloseIcon } from '@che-ins-ui/icons/CloseIcon';
import { Hint } from '@/components/common/Hint';
import { useCheTheme } from '@/styles/themes';
import { ServiceType } from '@/libs/types/global';
import { useStyles } from '../styles';
import { getServiceValue } from '../utils';
const ServiceView = ({ type, currency, serviceCode, serviceName, serviceValue, serviceDescription, isDesktopView, }) => {
    const classes = useStyles();
    const theme = useCheTheme();
    return (_jsxs("div", { className: classNames(classes.service, isDesktopView && classes.serviceDesktop), children: [type === ServiceType.FULL && (_jsx(CheckIcon, { className: classes.serviceIcon, fill: theme.colors.success })), type === ServiceType.PARTIAL && (_jsx(AlertIcon, { className: classes.serviceIcon, fill: theme.colors.warning })), type === ServiceType.NOTCOVERED && (_jsx(CloseIcon, { className: classes.serviceIcon, fill: theme.colors.error })), _jsx("span", { className: classes.serviceName, children: serviceName }), _jsx("div", { className: classes.serviceHint, children: _jsx(Hint, { title: serviceName, id: serviceCode, actionType: "agree", postfixModalName: "-services-descriptopns", withServiceInfoImage: true, children: serviceDescription }) }), _jsx("span", { className: classes.dashedLine, style: { minWidth: '15px' } }), _jsx("span", { className: classes.serviceValue, children: getServiceValue(type, serviceCode, serviceValue, currency) })] }));
};
export default memo(ServiceView);
