import { createAsyncThunk } from '@reduxjs/toolkit';
import { setTouristBirthday } from './setTouristBirthday';
import { formsActions } from './index';
export const setTouristAge = createAsyncThunk('', async ({ _uuid, age }, thunkApi) => {
    const { form: { tourists }, } = thunkApi.getState();
    const tourist = tourists.find((tourist) => tourist._uuid === _uuid);
    if (!tourist || tourist.age === age) {
        return;
    }
    thunkApi.dispatch(formsActions._setTouristAge({ _uuid, age }));
    thunkApi.dispatch(setTouristBirthday({ _uuid, value: '' }));
});
