import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatDate, addDays, getToday } from '@che-ins-ui/datepicker';
import { toErrorWithMessage, transformToJson } from '@/libs/helpers';
import { transformServices } from '@/libs/transformers/serviceTransformer';
import { captureError } from '@/libs/helpers/utils';
export const fetchApiData = createAsyncThunk('api/fetchApiData', async (partnerId) => {
    try {
        const tomorrow = formatDate(addDays(getToday()), true);
        const [companies, exchangeTodayRates, exchangeTomorrowRatesResponse, assistances, countries, countryGroups, services, sports,] = await Promise.all([
            fetch(`${__VZR_API_URL__}companies?isBlocked=0&partnerId=${partnerId}`).then(transformToJson),
            fetch(`${__VZR_API_URL__}exchangeRates`).then(transformToJson),
            fetch(`${__VZR_API_URL__}exchangeRates?date=${tomorrow}`),
            fetch(`${__VZR_API_URL__}assistances`).then(transformToJson),
            fetch(`${__VZR_API_URL__}countries?isPrivate=true`).then(transformToJson),
            fetch(`${__VZR_API_URL__}countryGroups`).then(transformToJson),
            fetch(`${__VZR_API_URL__}services`)
                .then(transformToJson)
                .then(transformServices),
            fetch(`${__VZR_API_URL__}sports`).then(transformToJson),
        ]);
        let exchangeTomorrowRates = [];
        if (String(exchangeTomorrowRatesResponse.status).startsWith('2')) {
            exchangeTomorrowRates = await transformToJson(exchangeTomorrowRatesResponse);
        }
        return {
            companies,
            exchangeTodayRates,
            exchangeTomorrowRates,
            assistances,
            countries,
            countryGroups,
            services,
            sports,
        };
    }
    catch (error) {
        captureError(toErrorWithMessage(error), 'api/fetchApiData');
        console.error(error);
        throw new Error('При получении данных из API произошла ошибка');
    }
});
