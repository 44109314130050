import { modalSelector } from '@che-ins-ui/modal';
import { modalNameMoto } from './DetailsMotoHint';
import { modalNameActiveRest } from './DetailsActiveRestHint';
export const motoHintModalSelector = (appState) => {
    const { isOpen, data: { isIncluded }, } = modalSelector(modalNameMoto)(appState);
    return { isOpen, isIncluded };
};
export const activeRestHintModalSelector = (appState) => {
    const { isOpen, data: { isIncluded }, } = modalSelector(modalNameActiveRest)(appState);
    return { isOpen, isIncluded };
};
