import { createUseStyles } from 'react-jss';
import { MOBILE_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, DEKSTOP_FONT_SIZES, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    tabTitle: {
        marginBottom: '2px',
        fontSize: MOBILE_FONT_SIZES.main,
        color: theme.colors.sky,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    tabText: {
        color: theme.colors.textPrimary,
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    filesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            gap: 8,
        },
    },
    downloadLink: {
        display: 'flex',
        alignItems: 'stretch',
        fontSize: MOBILE_FONT_SIZES.main,
        gap: 12,
        '&:hover': {
            '& span > $downloadLinkDownloadText': {
                textDecoration: 'underline',
            },
        },
        '& span > svg > rect': {
            fill: theme.colors.brand,
        },
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    downloadLinkIcon: {
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '1.13',
    },
    downloadLabel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    downloadSubtitle: {
        color: theme.colors.sky,
        [theme.breakpoints.down('sm')]: {
            marginTop: -3,
        },
    },
    downloadLinkDownloadText: {
        color: theme.colors.brand,
    },
}), { name: 'product-tabs' });
