import { INPUT_COMPONENTS_NAMES } from '@che-ins-ui/input';
import { mixins } from '@/styles/mixins';
import { DEKSTOP_FONT_SIZES, DESKTOP_SEARCH_FORM_BREAKPOINT, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, GLOBAL_LINE_HEIGHT, MOBILE_FONT_SIZES, SEARCH_INPUT_HEIGHT, TABLET_SEARCH_FORM_BREAKPOINT, } from './consts';
const border = '12px';
const COUNTRIES_BORDERS = {
    mobile: `${border} ${border} 0px 0px`,
    desktop: `${border} 0px 0px ${border}`,
};
const DATE_FROM_BORDERS = {
    mobile: `0px 0px 0px 0px`,
    tablet: `0px 0px 0px ${border}`,
    desktop: `0px 0px 0px 0px`,
};
const TOURISTS_BORDERS = {
    mobile: `0px 0px ${border} ${border}`,
    tablet: `0px 0px ${border} 0px`,
    desktop: `0px ${border} ${border} 0px`,
};
const HEADER_LABEL_TOP = {
    mobile: '19px',
    desktop: '21px',
};
export const getInputTheme = (theme) => ({
    base: {
        [INPUT_COMPONENTS_NAMES.wrapper]: {
            height: '56px',
            transition: theme.transition.global,
            backgroundColor: theme.colors.field,
            borderRadius: 12,
            border: `1px solid ${theme.colors.textTertiary}`,
            '&[data-focused="true"]': {
                borderColor: theme.colors.brand,
            },
            '&[data-invalid="true"]': {
                borderColor: theme.colors.error,
            },
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: '48px',
            },
        },
        [INPUT_COMPONENTS_NAMES.input]: {
            letterSpacing: GLOBAL_LETTER_SPACING,
            lineHeight: GLOBAL_LINE_HEIGHT,
            padding: '8px 13px',
            fontSize: MOBILE_FONT_SIZES.input,
            transition: theme.transition.global,
            backgroundColor: 'transparent',
            color: theme.colors.sky,
            borderRadius: 12,
            '-webkit-tap-highlight-color': 'transparent',
            ...mixins.inputPlaceholderColor(theme.colors.textSecondary),
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.input,
                padding: '6px 14px',
            },
        },
        [INPUT_COMPONENTS_NAMES.label]: {
            fontSize: MOBILE_FONT_SIZES.input,
            userSelect: 'none',
            color: theme.colors.textSecondary,
            '&[data-hasvalue="true"]': {
                fontSize: MOBILE_FONT_SIZES.small,
                top: '17px',
                color: theme.colors.textPrimary,
            },
            '&[data-focused="true"], &[data-component-active="true"]': {
                fontSize: MOBILE_FONT_SIZES.small,
                top: '17px',
                color: theme.colors.brand,
            },
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.input,
                '&[data-focused="true"], &[data-hasvalue="true"], &[data-component-active="true"]': {
                    fontSize: DEKSTOP_FONT_SIZES.small,
                    top: '14px',
                },
            },
        },
        [INPUT_COMPONENTS_NAMES.postfixWrapper]: {
            '& svg > path': {
                fill: theme.colors.brand,
            },
        },
        [INPUT_COMPONENTS_NAMES.suggestion]: {
            '&:hover': {
                color: theme.colors.brand,
                background: theme.colors.brandTertiary,
            },
        },
    },
    'with-delete-icon': {
        [INPUT_COMPONENTS_NAMES.postfixWrapper]: {
            backgroundColor: theme.colors.cloud,
            position: 'absolute',
            top: '-10px',
            right: '-22px',
            cursor: 'pointer',
            background: 'white',
            borderRadius: '50%',
        },
    },
    datepicker: {
        [INPUT_COMPONENTS_NAMES.wrapper]: {
            minWidth: '110px',
        },
    },
    'header-filters': {
        [INPUT_COMPONENTS_NAMES.wrapper]: {
            transition: 'none',
            '&[data-component-active]': {
                border: 'none',
            },
        },
        [INPUT_COMPONENTS_NAMES.input]: {
            padding: '7px 13px',
            transition: 'none',
            '&[data-component-active]': {
                padding: '7px 13px',
            },
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                padding: '12px',
                '&[data-component-active]': {
                    padding: '12px',
                },
            },
        },
        [INPUT_COMPONENTS_NAMES.label]: {
            '&[data-focused="true"]': {
                top: HEADER_LABEL_TOP.mobile,
            },
            '&[data-hasvalue="true"]': {
                top: HEADER_LABEL_TOP.mobile,
            },
            '&[data-component-active="true"]': {
                top: HEADER_LABEL_TOP.mobile,
            },
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                '&[data-focused="true"]': {
                    top: HEADER_LABEL_TOP.desktop,
                },
                '&[data-hasvalue="true"]': {
                    top: HEADER_LABEL_TOP.desktop,
                },
                '&[data-component-active="true"]': {
                    top: HEADER_LABEL_TOP.desktop,
                },
            },
        },
        [INPUT_COMPONENTS_NAMES.postfixWrapper]: {
            marginRight: '14px',
            [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
                marginRight: '20px',
            },
        },
    },
    'header-mobile-accordion-title': {
        [INPUT_COMPONENTS_NAMES.wrapper]: {
            boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.1)',
            border: 'none',
            borderRadius: 12,
            height: SEARCH_INPUT_HEIGHT.mobile,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: SEARCH_INPUT_HEIGHT.desktop,
            },
        },
        [INPUT_COMPONENTS_NAMES.label]: {
            color: theme.colors.sky,
            '&[data-hasvalue="true"]': {
                fontSize: MOBILE_FONT_SIZES.input,
                color: theme.colors.sky,
            },
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                '&[data-hasvalue="true"]': {
                    fontSize: DEKSTOP_FONT_SIZES.input,
                    color: theme.colors.sky,
                },
            },
        },
        [INPUT_COMPONENTS_NAMES.input]: {
            color: theme.colors.textPrimary,
            fontSize: MOBILE_FONT_SIZES.small,
            '&[data-hasvalue="true"]': {
                padding: '10px 12px',
            },
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: MOBILE_FONT_SIZES.small,
                '&[data-hasvalue="true"]': {
                    padding: '12px 12px',
                },
            },
        },
        [INPUT_COMPONENTS_NAMES.postfixWrapper]: {},
    },
    'header-countries': {
        [INPUT_COMPONENTS_NAMES.wrapper]: {
            borderRadius: COUNTRIES_BORDERS.mobile,
            [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
                borderRadius: COUNTRIES_BORDERS.desktop,
            },
        },
        [INPUT_COMPONENTS_NAMES.input]: {
            borderRadius: COUNTRIES_BORDERS.mobile,
            [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
                borderRadius: COUNTRIES_BORDERS.desktop,
            },
        },
    },
    'header-date-from': {
        [INPUT_COMPONENTS_NAMES.wrapper]: {
            borderRadius: DATE_FROM_BORDERS.mobile,
            [theme.breakpoints.up(TABLET_SEARCH_FORM_BREAKPOINT)]: {
                borderRadius: DATE_FROM_BORDERS.tablet,
            },
            [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
                borderRadius: DATE_FROM_BORDERS.desktop,
            },
        },
        [INPUT_COMPONENTS_NAMES.input]: {
            borderRadius: DATE_FROM_BORDERS.mobile,
            [theme.breakpoints.up(TABLET_SEARCH_FORM_BREAKPOINT)]: {
                borderRadius: DATE_FROM_BORDERS.tablet,
            },
            [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
                borderRadius: DATE_FROM_BORDERS.desktop,
            },
        },
    },
    'header-date-to': {
        [INPUT_COMPONENTS_NAMES.wrapper]: {
            borderRadius: '0px',
        },
        [INPUT_COMPONENTS_NAMES.input]: {
            borderRadius: '0px',
        },
    },
    'header-tourists': {
        [INPUT_COMPONENTS_NAMES.wrapper]: {
            borderRadius: TOURISTS_BORDERS.mobile,
            [theme.breakpoints.up(TABLET_SEARCH_FORM_BREAKPOINT)]: {
                borderRadius: TOURISTS_BORDERS.tablet,
            },
            [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
                borderRadius: TOURISTS_BORDERS.desktop,
            },
        },
        [INPUT_COMPONENTS_NAMES.input]: {
            borderRadius: TOURISTS_BORDERS.mobile,
            [theme.breakpoints.up(TABLET_SEARCH_FORM_BREAKPOINT)]: {
                borderRadius: TOURISTS_BORDERS.tablet,
            },
            [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
                borderRadius: TOURISTS_BORDERS.desktop,
            },
        },
    },
    preview: {
        [INPUT_COMPONENTS_NAMES.wrapper]: {
            backgroundColor: theme.colors.brandTertiary,
            borderColor: theme.colors.brand,
        },
        [INPUT_COMPONENTS_NAMES.input]: {
            backgroundColor: theme.colors.brandTertiary,
        },
    },
    'preview-success': {
        [INPUT_COMPONENTS_NAMES.wrapper]: {
            borderColor: theme.colors.success,
        },
    },
});
