import { createSlice } from '@reduxjs/toolkit';
import { COMPARING_STATE_NAMESPACE } from './consts';
export const comparingInitialState = {
    comparing: [],
    isDifferencesOnly: false,
    wasAddingUsed: false,
};
const ComparingSlice = createSlice({
    name: COMPARING_STATE_NAMESPACE,
    initialState: comparingInitialState,
    reducers: {
        setDefault: (state) => ({
            ...comparingInitialState,
            wasAddingUsed: state.wasAddingUsed,
        }),
        toggleComparing: (state, action) => {
            const { comparing } = state;
            if (comparing.includes(action.payload)) {
                state.comparing = comparing.filter((id) => id !== action.payload);
            }
            else {
                if (comparing.length) {
                    state.wasAddingUsed = true;
                }
                state.comparing = [...comparing, action.payload];
            }
        },
        clearComparing: (state) => {
            state.comparing = [];
        },
        setDifferenceOnly: (state, action) => {
            state.isDifferencesOnly = action.payload;
        },
        toggleDifferencesOnly: (state) => {
            state.isDifferencesOnly = !state.isDifferencesOnly;
        },
        setWasAddingUsed: (state) => {
            state.wasAddingUsed = true;
        },
    },
});
const { reducer: comparingReducer, actions: comparingActions } = ComparingSlice;
export { comparingReducer, comparingActions };
export { COMPARING_STATE_NAMESPACE };
