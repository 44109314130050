import { createUseStyles } from 'react-jss';
import { MOBILE_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, DEKSTOP_FONT_SIZES, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    title: {
        color: theme.colors.sky,
        fontFamily: theme.font.secondary,
        fontSize: theme.rem(22),
        marginBottom: '3px',
        letterSpacing: 0.25,
    },
    text: {
        color: theme.colors.textPrimary,
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
        '& a': {
            color: theme.colors.brand,
            whiteSpace: 'nowrap',
            '&:-webkit-any-link, &:visited': {
                color: theme.colors.brand,
            },
        },
    },
}), { name: 'info-block-item' });
export const DESKTOP_SUCCESS_PAGE_BREAKPOINT = 'md';
