import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Accordion } from '@che-ins-ui/accordion';
import { Checkbox } from '@/components/common/checkbox';
import { MULTIPOLICY_CODE } from '@/libs/static';
import { MultiPolicyValue } from './MultiPolicyValue';
import { DefaultAccordionMultiValueChildren } from './DefaultAccordionMultiValueChildren';
export const MultiValueAccordion = ({ code, name, isChecked, handleChangeService, serviceValues, label, subDescription, }) => {
    const valueAfterChangeAccordion = isChecked ? 0 : serviceValues[0];
    const accordionChildren = useMemo(() => {
        switch (code) {
            case MULTIPOLICY_CODE:
                return _jsx(MultiPolicyValue, {});
            default:
                return (_jsx(DefaultAccordionMultiValueChildren, { subDescription: subDescription, code: code, serviceValues: serviceValues }));
        }
    }, [subDescription, code, serviceValues]);
    return (_jsx(Accordion, { shouldFitContent: true, title: name, baseAppearance: "filters", appearance: "filter-multi-value", isOpen: isChecked, titleButtonProps: {
            component: Checkbox,
            value: isChecked,
            onChange: (_e) => {
                handleChangeService(code)(valueAfterChangeAccordion);
            },
            label: label,
            appearance: 'filters',
        }, children: accordionChildren }, code));
};
