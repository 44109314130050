import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@che-ins-ui/button';
import { CURRENCIES_BADGES } from '@/libs/constants';
import { formatPrice } from '@/libs/helpers/utils';
import { comparingWasAddingUsedSelector } from '@/models/comparing/selectors';
import { isCommissionShownSelector, partnerCommissionRateSelector, } from '@/models/user/selectors';
import { CardOptionsLabel } from '../CardOptionsLabel';
import { IconsBlock } from '../IconsBlock';
import { CompanyLogo } from '../CompanyLogo';
import { TagsSkeleton } from './TagsSkeleton';
import { useStyles } from './styles';
import { Tags } from './Tags';
import { OfferCommission } from './OfferCommission';
const OfferCard = ({ index, offer, matchedType, handleAddToBasket, }) => {
    const classes = useStyles();
    const comparingWasAddingUsed = useSelector(comparingWasAddingUsedSelector);
    const partnerCommissionRate = useSelector(partnerCommissionRateSelector);
    const isCommissionShown = useSelector(isCommissionShownSelector);
    const isNotMatching = matchedType === 'none';
    const price = matchedType !== 'none' && offer.calculation.priceRub;
    const shouldShowCommission = Boolean(isCommissionShown && price && partnerCommissionRate);
    const cardOptionsLabelOptions = useMemo(() => {
        if (matchedType === 'full') {
            return {
                matchedType,
                productId: offer.calculation.productId,
                lowerSumServices: offer.lowerSumServices,
                missingServices: offer.missingServices,
                totalServices: offer.calculation.matchDetails.totalServices - 9,
            };
        }
        if (matchedType === 'partial') {
            return {
                matchedType,
                productId: offer.calculation.productId,
                lowerSumServices: offer.lowerSumServices,
                missingServices: offer.missingServices,
                missingServicesCount: offer.calculation.matchDetails.missingServices +
                    offer.calculation.matchDetails.lowerSumms,
            };
        }
        return { matchedType };
    }, [offer, matchedType]);
    return (_jsxs("div", { className: classes.root, children: [_jsxs("div", { className: classes.info, children: [_jsxs("div", { className: classes.offerInfo, children: [_jsxs("figure", { className: classes.companyInfo, children: [_jsx(CompanyLogo, { alt: offer.company.name, src: offer.company.linkToLogo, className: classes.companyLogo }), _jsxs("figcaption", { className: classes.companyDescription, children: [_jsx("span", { className: classes.companyName, children: offer.company.name }), _jsx(CardOptionsLabel, { ...cardOptionsLabelOptions })] })] }), _jsx(IconsBlock, { company: offer.company, productId: matchedType !== 'none'
                                    ? offer.calculation.productId
                                    : undefined, comparingOnly: isNotMatching, uncheckCompareOnly: isNotMatching, withComparingText: !comparingWasAddingUsed })] }), _jsx("div", { className: classes.tags, children: matchedType !== 'none' ? (_jsx(Tags, { offer: offer })) : (_jsx(TagsSkeleton, { animated: false })) })] }), _jsxs("div", { className: classes.payButtonBlock, children: [_jsxs(Button, { shouldFitContent: true, baseAppearance: "success", appearance: "card-pay", onClick: () => handleAddToBasket?.(offer), disabled: isNotMatching, children: [_jsx("span", { className: classes.payButtonText, children: "\u041A\u0443\u043F\u0438\u0442\u044C\u00A0" }), price ? (_jsxs("b", { children: [formatPrice(price), "\u00A0", CURRENCIES_BADGES.rub] })) : (_jsx("span", { className: classes.payButtonText, children: "\u043D\u0435\u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E" }))] }), _jsx(OfferCommission, { index: index, isShown: shouldShowCommission, commission: Number(partnerCommissionRate) * Number(price) })] })] }));
};
export default memo(OfferCard);
