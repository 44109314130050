import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { IconButton } from '@che-ins-ui/button';
import { motion } from 'framer-motion';
import { BasketIcon } from '../BasketIcon';
import { TouristBirthdayInput } from './TouristBirthdayInput';
export const TouristBirthdayDesktop = memo(({ _uuid, withButton, onDelete, appearance, birthday, previewedBirthday, errorMessage, }) => {
    const inputAnimation = {
        width: withButton ? 148 : 196,
    };
    const buttonAnimation = {
        scale: withButton ? 1 : 0,
        opacity: withButton ? 1 : 0,
        width: withButton ? 48 : 0,
    };
    return (_jsxs(_Fragment, { children: [_jsx(motion.div, { initial: inputAnimation, animate: inputAnimation, children: _jsx(TouristBirthdayInput, { _uuid: _uuid, appearance: appearance, birthday: birthday, previewedBirthday: previewedBirthday, errorMessage: errorMessage }) }, "birthday"), _jsx(motion.div, { initial: buttonAnimation, animate: buttonAnimation, children: _jsx(IconButton, { appearance: "remove-tourist", icon: _jsx(BasketIcon, {}), height: 48, width: 40, onClick: onDelete, tabIndex: withButton ? 0 : -1 }) })] }));
});
