import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton } from '@che-ins-ui/button';
import { Input } from '@che-ins-ui/input';
import { modalSelector } from '@che-ins-ui/modal';
import { CrossIcon } from '@che-ins-ui/icons/CrossIcon';
import { setNotebookFormGender } from '@/models/notebook/setNotebookFormGender';
import { setNotebookFormBirthday } from '@/models/notebook/setNotebookFormBirthday';
import { validateNotebookForm } from '@/models/notebook/validateNotebookForm';
import { notebookActions } from '@/models/notebook';
import { notebookFormDirtySelector, notebookFormErrorsSelector, notebookFormStatusSelector, notebookFormValuesSelector, } from '@/models/notebook/selectors';
import { NotebookFormFields } from '@/models/notebook/interface';
import { submitNotebookForm } from '@/models/notebook/submitNotebookForm';
import { MainTitle } from '@/components/Success/components/FirstScreen/components/MainTitle';
import { NameField } from '@/models/forms/interface';
import { isAboveMinInsurerAge } from '@/libs/validation/validateDate';
import { inputLabels, inputNames, placeholders, } from '@/components/insurance/consts';
import { InputError } from '../InputError';
import { Checkbox } from '../checkbox';
import { insurerAgeWarning, notebookFormGenders } from './consts';
import { EditPersonFormNameField } from './EditPersonFormNameField';
import { EDIT_PERSON_MODAL_NAME } from './EditPersonModal';
import { useStyles } from './styles';
import { EditPersonFormSubmitButton } from './EditPersonFormSubmitButton';
import { EditPersonFormEmojiPicker } from './EditPersonFormEmojiPicker';
import { openDeletePersonModal } from './DeletePersonModal';
export const EditPersonForm = memo(({ onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const formValues = useSelector(notebookFormValuesSelector);
    const formErrors = useSelector(notebookFormErrorsSelector);
    const formDirty = useSelector(notebookFormDirtySelector);
    const formStatus = useSelector(notebookFormStatusSelector);
    const { isSubmitting, submittingError, isSuccessfullySubmitted } = formStatus;
    const { data: { personId, isFromInsurer }, } = useSelector(modalSelector(EDIT_PERSON_MODAL_NAME));
    const { hasSecondNameRu, gender, birthday } = formValues;
    const onSubmit = () => {
        if (isSubmitting) {
            return;
        }
        dispatch(submitNotebookForm(personId));
    };
    const genderErrorMessage = formDirty.gender && formErrors.gender;
    const birthdayErrorMessage = formDirty.birthday && formErrors.birthday;
    useEffect(() => {
        dispatch(validateNotebookForm());
    }, [formValues]);
    const checkShouldShowPersonCouldNotBeInsurer = () => {
        if (!isFromInsurer || formErrors.birthday) {
            return false;
        }
        return !isAboveMinInsurerAge(birthday);
    };
    const birthdayWarningMessage = checkShouldShowPersonCouldNotBeInsurer() && insurerAgeWarning;
    return (_jsx("div", { className: classes.body, children: _jsxs("div", { className: classes.formWrapper, children: [_jsxs("div", { className: classes.title, children: [_jsx(Button, { appearance: "edit-person-form-delete", onClick: () => dispatch(openDeletePersonModal()), children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" }), _jsx(MainTitle, { children: "\u041B\u0438\u0447\u043D\u044B\u0435 \u0434\u0430\u043D\u043D\u044B\u0435" }), _jsx("div", { className: classes.desktopCloseButtonWrapper, children: _jsx(IconButton, { icon: _jsx(CrossIcon, {}), baseAppearance: "compare-close", onClick: onClose }) })] }), _jsx(EditPersonFormEmojiPicker, {}), _jsxs("div", { className: classes.form, children: [_jsx(EditPersonFormNameField, { field: NameField.lastNameRu }), _jsx(EditPersonFormNameField, { field: NameField.firstNameRu }), _jsx(EditPersonFormNameField, { field: NameField.secondNameRu, disabled: !hasSecondNameRu }), _jsx("div", { className: classes.hasSecondName, children: _jsx(Checkbox, { label: "\u041D\u0435\u0442 \u043E\u0442\u0447\u0435\u0441\u0442\u0432\u0430", appearance: "comparing", value: !hasSecondNameRu, onChange: () => {
                                    dispatch(notebookActions.setHasSecondNameRu(!hasSecondNameRu));
                                } }) }), _jsx(EditPersonFormNameField, { field: NameField.lastName }), _jsx(EditPersonFormNameField, { field: NameField.firstName }), _jsxs("div", { className: classes.gender, children: [_jsx("div", { className: classes.genderButtonsWrapper, children: notebookFormGenders.map(({ gender: g, label, icon }) => (_jsxs(Button, { name: "gender", baseAppearance: "gender", appearance: g, "data-invalid": Boolean(genderErrorMessage), isSelected: g === gender, onClick: () => {
                                            dispatch(setNotebookFormGender(g));
                                        }, tabIndex: -1, children: [icon, " ", label] }, g))) }), _jsx(InputError, { error: genderErrorMessage, isWarning: !birthdayErrorMessage })] }), _jsxs("div", { className: classes.birthday, children: [_jsx(Input, { name: inputNames.birthday, label: inputLabels.birthday, placeholder: placeholders.birthday, baseAppearance: "datepicker", value: birthday, onChange: (value) => {
                                        dispatch(setNotebookFormBirthday(value));
                                    }, invalid: Boolean(birthdayErrorMessage), onBlur: () => {
                                        dispatch(notebookActions.setFormFieldIsDirty(NotebookFormFields.birthday));
                                    }, shouldFitContent: true }), _jsx(InputError, { error: birthdayErrorMessage || birthdayWarningMessage, isWarning: !birthdayErrorMessage })] })] }), _jsx(EditPersonFormSubmitButton, { isLoading: isSubmitting, isSuccessfullyDone: isSuccessfullySubmitted, onClick: onSubmit, disabled: isSubmitting || isSuccessfullySubmitted, errorMessage: submittingError, defaultText: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", successText: "\u0421\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u043E" })] }) }));
});
