import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import isDeepEqual from 'fast-deep-equal';
import { Button } from '@che-ins-ui/button';
import Title from '@/components/common/Title';
import { scrollToTop } from '@/libs/helpers';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { RESTORABLE_QS_PARAMS } from '@/libs/constants';
import { requestActions, SERVICE_DEFAULT } from '@/models/request';
import { setSearchInput } from '@/models/search/setSearchInput';
import { requestAssistancesSelector, requestServiceSelector, requestSportsSelector, } from '@/models/request/selectors';
import { useStyles } from './styles';
export const FiltersPanelHeader = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const queryString = useCalculationQueryString({
        removeParams: [RESTORABLE_QS_PARAMS.mobileFilters],
    });
    const services = useSelector(requestServiceSelector);
    const assistances = useSelector(requestAssistancesSelector);
    const sports = useSelector(requestSportsSelector);
    const resetEnabled = assistances.length > 0 ||
        sports.length > 0 ||
        !isDeepEqual(SERVICE_DEFAULT, services);
    const onClose = () => {
        scrollToTop({ behavior: 'auto' });
        dispatch(setSearchInput(''));
    };
    return (_jsxs("header", { className: classes.header, children: [_jsx(Button, { baseAppearance: "text", appearance: "filters-reset", onClick: () => {
                    dispatch(requestActions.setDefault());
                }, disabled: !resetEnabled, children: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C" }), _jsx(Title, { className: classes.headerTitle, tag: "h4", children: "\u0424\u0438\u043B\u044C\u0442\u0440\u044B" }), _jsx(Button, { onClick: onClose, baseAppearance: "text", passAppearancesToComponent: false, component: Link, to: { search: queryString }, children: "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C" })] }));
});
